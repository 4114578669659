
import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import {Small}  from "../../styles/typography/typography"
import { device } from "../../styles/variables.js"
import {Link} from "react-router-dom";

export const ContainerBlockSt = styled.div`
  height: ${props => props.recommendIsOpen ? "auto" : "0"};
  overflow: hidden;
   @media ${device.laptop} {
        margin-left: -20px;
   }
   

   .recommendCards {
    @media (min-width: 1024px) {
        margin-left: -10px;
    }
    @media (min-width: 1240px)  and (max-width: 1300px) {
        margin-left: 10px;
       }
    @media (min-width: 1300px) {
        margin-left: 20px;
       }
    @media (min-width: 1350px) {
        margin-left: 60px;
       }
   
    @media (min-width: 1350px) {
        margin-left: 20px;
       }
       @media (min-width: 1380px) {
        margin-left: 0px;
       }
   }
   @media ${device.desktop} {
        margin-left: 0;
        max-width: initial;
   }

   p {
    @media (min-width: 1240px) and (max-width: 1380px) {
        margin: 0 auto 30px;
    }
   }
   .description {
        @media (min-width: 1240px) and (max-width: 1380px) {
            margin: 0 auto 30px;
        }
   }
`;
export const RecommendWrapSt = styled.div`
    max-width: 1440px;
    margin-left: auto;
`;

export const RecommendContainerSt = styled.div`
   @media ${device.tablet} {
        width: calc(100vw - 0px);
        padding: 0 0 0 0px;
   }
    @media ${device.laptop} {
        width: calc(100vw - 20px);
        padding-left: 20px;
   }
   @media ${device.laptopMax} {
        width: calc(100vw - 40px);
        padding-left: 20px;
   }
   @media (min-width: 1280px) {
        max-width: 1260px; 
        padding: 0 0 0 0px; 
   }
   @media ${device.desktop} {
        max-width: 1260px; 
        padding: 0 0 0 0px; 
   }
`;

export const RecommendBlockSt = styled.div`
   background-color: ${c.grey_super_light};
   margin-right: -20px;
   margin-left: -20px;
   @media ${device.tablet} {
        margin-right:-100%;
        margin-left: 0;
        margin-top:75px; 
        margin-left: -20px;
    }
    /* @media ${device.laptop} {
        
        margin-left: -20px;
    } */
    @media (min-width: 1280px) {
        margin-left: 0;
   }
    @media ${device.desktop} {
        margin-left: 0;
    }
   
    &.opened {
        background-color: ${c.blue_highlight};
    }
`;

export const RecommendHeaderSt = styled.div` 
    position: relative;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    width:100%;
    margin : 0 auto;
    align-items: center;
    max-height: 90px;    
    div {
        cursor: pointer;
        display: flex;
        align-items: center;
        @media ${device.tablet} {
            gap: 10px;
        }

       
    }
    @media ${device.tablet} {
        padding: 40px 20px;
    }
    @media ${device.laptop} {
        padding: 40px 20px;
    }
     @media (min-width: 1280px) and (max-width: 1300px) {
        .reccommendTitle {
            margin-left: -10px;
        }
     }  
    @media (min-width: 1280px) {
        padding: 40px 60px;
        max-width: initial; 
    }
`;

export const RecommendAddBlockSt = styled.div` 
    position: relative;
    &:hover, &:active {
        text-decoration: underline;
        color: ${c.blue_active};
    }
`;
export const RecommendBodySt= styled.div` 
    @media ${device.laptop} {
        margin : 0 -10px;
    }
    @media ${device.laptopMax} {
        margin : 0;
    }
    @media (min-width: 1240px) and (max-width: 1350px) { 
       div p {
            margin: -10px -10px 30px;
            padding: 0px 30px;
            
       } 
       .description {
            margin: 0 -10px 30px;
            padding: 0px 30px;
       } 
    }
    @media (min-width: 1350px) and (max-width: 1380px) { 
       div p {
            margin: -10px 0px 30px;
            padding: 0px 60px;
            
       } 
      
       .description {
            margin: 0 0 30px;
            margin: 0 30px 30px;
           
       } 
    }
    @media ${device.desktop} {
        margin : 0;
    }
`;

export const SmallSt = styled(Small)` 
    font-size: 0;
    @media ${device.tablet} {
        font-size: 12px;
    }
`;

export const ContainerCollectionSt= styled.div`
    padding-top: 60px;
    overflow:hidden;
    flex: 1 0 auto;
    @media ${device.tablet} {
        padding-top: 0;
    }
`;

export const ContainerSt = styled.div`
    position: relative;
    &:first-child {
        padding: 35px 0 0 0;
    }
`;
export const  ToggleViewSt = styled.div`
    padding: 20px 0;
    
    @media ${device.tablet} {
        padding: 0;
    }
`;
export const SettingsMenuSt = styled.div`
`;
export const SettingsToggleSt = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    span.visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        -webkit-clip: rect(0,0,0,0);
        clip: rect(0,0,0,0);
        overflow: hidden;
    }
    .toggle{
        margin-left: 30px;
        @media ${device.laptop} {
            margin-left: 0;
        }
    }
`;

export const SettingsMenuWrapperSt = styled.div`
    display: none;
    justify-content: space-between; 
    padding: 30px 0;
    border-bottom: 1px solid ${c.grey_light};
    flex-direction: column;
   &.opened  {
    display: flex;
    background-color: ${c.white};
    width: 100%;
    z-index: 99;
    box-shadow:  0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
    width: 100vw;
    z-index: 97;
    margin-left: -20px;
    padding: 0 20px 10px 20px;
    margin-top: -1px;
    @media (min-width: 1350px) and (max-width: 1380px) { 
        margin-left: 0px;
    }
    @media ${device.desktop} {
        margin-left: -20px;
    }
   }
   
    @media ${device.tablet} {
        display: flex;
        flex-direction: initial;
    }

    > div {
    @media ${device.tablet} {
        display: flex;
        flex-direction: initial;
        width: auto;
        justify-content: space-between;
        }  
    } 
`;


export const  HeaderTopSt = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 35px 0;
    border-bottom: 1px solid #EEEEEE;
`;


export const SettingsLeftSt = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0;
   
    @media ${device.tablet} {
        flex-direction: initial;
        padding: 0;
    }
`;
export const SettingsRigthSt = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media ${device.tablet} {
        flex-direction: initial;
    }

`;

export const ItemSettingsSt = styled(SettingsRigthSt)` 
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 20px 0 20px 20px;
    border-top: 1px solid ${c.grey_light};
    border-bottom: 1px solid ${c.grey_light};
    &:first-child {
        border-bottom: none;
    }

    @media ${device.tablet} {
        width: auto;
        border: none;
        padding: 0;
        &:last-child {
            margin-left: 30px;
        }
    }
    cursor: pointer;
    &.active {
        &:active, 
        &:hover {
            span{
                text-decoration: underline;
            }
        }
    }
`;
export const SpanSt = styled(Small)`
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 5px;
`;

export const HeaderSt =  styled.div`
    position: relative;
    margin-top: 40px;
`;

export const LinkSt = styled(Link)`
   padding-left: 5px;
   text-decoration: none;
   &:hover {
        text-decoration: underline;
        color: ${c.blue}; 
   }
`;

export const LinkMockSt = styled.span`
   padding-left: 5px;
   text-decoration: none;
   // &:hover {
   //      text-decoration: underline;
   //      color: ${c.blue}; 
   // }
`;

export const TitleBlockSt =  styled.div`
    display: inline-flex;
    align-items: baseline;
`;

export const WrapItemSt =  styled.div` 
    display: flex;
    align-items: center;
`;

export const SecondLevelSt = styled.ul` 
    @media ${device.mobile} {
        width: 100vw;
        padding: 0px 20px 0;
        overflow: initial;
        margin-right: -20px;
        box-shadow: 0px 4px 5px rgba(66,165,245,0.12), 0px 1px 10px rgba(66,165,245,0.2);
    }

    position: absolute;
    top: 100%;
    
    margin-bottom: 0;
    background-color: ${c.white};
    z-index: 99;
    padding:10px 0;
    list-style: none;
    a:hover {
        text-decoration: none;
    }
    @media ${device.tablet} {
        padding: 10px 0;
        margin-right: initial;
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
        min-width: 200px;
        white-space: nowrap;
        border-radius: 10px;
        &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: 10px;
        height: 25px;
        width:105%;
        background-color:transparent;
        }
    }
    @media ${device.laptopMax} {
        min-width: 200px;
    }
`;

export const SecondLevelItemSt = styled.li` 
    padding: 20px 0 20px 40px;
    cursor: pointer; 
    transition: background-color .3s; 
    &:active:not(.profile-name), 
    &:hover:not(.profile-name) {
        background-color: ${c.blue_highlight};
    }
    &.profile-name {
        cursor: initial;  
        padding: 30px 0 30px 40px;
      
    }
    &:not(.profile-name)
    {
        border-top: 1px solid #EEEEEE; 
    }
    @media ${device.tablet} {
        span {
            text-transform: uppercase;
            font-weight: 600;
            display: flex;
        }
      
        padding: 10px 0 10px 30px;
        display: flex;
        align-items: center;
        &:not(.profile-name)
        {
            border-top: none; 
        }
       
    }
`;