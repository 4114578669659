import styled from "styled-components"
import { colors as c, fonts } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"
import {Small} from "../../styles/typography/typography"

export const ButtonStyled = styled.button`
    border-radius: 200px;
    padding: ${props => props.size === "small" ? "3px 20px" : props.size === "big"  ? "10px 45px" : "9px 20px"};     
    line-height: 14px;
    border: 1px solid ${props => props.primary ? c.white : c.blue};
    box-sizing: border-box;
    text-align: center;
    color: ${props => props.primary ? c.white : c.blue };
    background: ${props => props.primary ? "none" : "none"};
        &:disabled,
        &[disabled]{
            border: 1px solid ${c.grey_passive};
            color: ${c.grey_passive};
            background: none;
        }
        &:hover:not([disabled]),
        &:active:not([disabled]) {
            background: ${props => props.primary ? c.white : c.blue};
            color: ${props => props.primary ? c.black : c.white};
            border: 1px solid ${props => props.primary ? c.white : c.blue};
        }
    cursor: pointer;
    transition: background .3s ease, color .3s ease;
    white-space: nowrap;

    @media ${device.tablet} { 
        width: ${props => props.width ? props.width : "auto"};
        
    }
    @media ${device.desktop} { 
        padding: ${props => props.size === "small" ? "3px 20px" : props.size === "big"  ? "20px 54px" : "9px 20px"};     
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const SmallStyled = styled(Small)`
   font-family: ${fonts.font_family};
`;