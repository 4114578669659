import {
    HOST, FILTER_SAVE, GET_FILTER_MENU
} from '../types'
import i18n from "i18next"

export const filterSave = (save) => {
    return dispatch => {
        dispatch({
            type: FILTER_SAVE,
            payload: save
        })
    }
}

export const getFilterNavigationMenu = () => {
    return dispatch => {
        fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/project-navigation-menu`)
            .then((res)=>{
                return res.json();
            })
            .then((body)=>{
                dispatch({
                    type: GET_FILTER_MENU,
                    payload: body
                })
            })
    }
}

