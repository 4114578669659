import React from 'react';
import {Container} from "../../components/Container/Container";
import LogIn from '../../components/LogIn/LogIn'
import {
   ContainerCatalogStyled
} from "./style";

export default class Auth extends React.Component {

    render() {
        return(
            <ContainerCatalogStyled style={{ paddingTop: "100px"}}>
                <Container>
                    <LogIn /> 
                </Container>
            </ContainerCatalogStyled>
        )
    }
}