import React, { Component } from "react"
import { Container } from '../Container/Container'
import Svg from "../../assets/icons/icons"

import {
  ScrollTopSt,
  ScrollTopBtnSt,
} from "./style";

export default class ScrollToTop extends Component {

  state = {
    isVisible: false
  };

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", () => { scrollComponent.toggleVisibility()})
  }

  toggleVisibility() {
    this.setState({
      isVisible: (window.pageYOffset > 300) ? true : false
    })
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { isVisible } = this.state;
    return (
      <ScrollTopSt>
        <Container>
          {isVisible && (
            <div className="wrap-scroll-btn" onClick={() => this.scrollToTop()} style={{ position: "relative" }}>
              <ScrollTopBtnSt>
                <Svg type="keyboard_arrow" />
              </ScrollTopBtnSt>
            </div>
          )}
        </Container>
      </ScrollTopSt>
    );
  }
}

