import React from 'react';
import {H1, H4} from "../../styles/typography/typography.js";
import {colors} from "../../styles/variables.js"
import Svg from "../../assets/icons/icons";

import {
  DescriptionHeaderStyled,
  TypeStyled,
  TypeItemStyled,
  WrapperStyled,
  StarStyled,
  TooltipWrapStyled,
  SpanStyled,
  LinkStyled
} from './style.js';

import {
  UlSt
} from '../Select/style.js';
import i18n from "i18next";

class TabsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
    };
    this._handleTooltipClick = this._handleTooltipClick.bind(this); 
  }

  _handleTooltipClick = () => {
    this.setState((prevState) => ({ tooltipIsOpen: !prevState.tooltipIsOpen }));
  }

  render() {
    const {name, genre, is_format, channel, channelLogo} = this.props

    return (
    <>
      <DescriptionHeaderStyled>
        <WrapperStyled>
          <H1>{name}</H1>
          <TypeStyled>
            { !genre
                ? null
                :genre.map((item, index) => (
                  <TypeItemStyled key={index}>{item}</TypeItemStyled>
                ))
            }
          </TypeStyled>
          {/* Звезда вставляется только если проект является форматом */}
          {is_format && <StarStyled onClick={this._handleTooltipClick}>
            <Svg width="24" height="24" type="star_border"></Svg>
          </StarStyled>}
          {
            (this.state.tooltipIsOpen)
            ?
            <TooltipWrapStyled>
              <UlSt>
                <SpanStyled>{i18n.t(`catalog.project.${0}.aboutFormat`)}</SpanStyled>
              </UlSt>
            </TooltipWrapStyled>
            : null
          }

        </WrapperStyled>
        { //английское лого
          (window.innerWidth > 767)
          ?<LinkStyled to={`/channel/${channel}`}><img src={channelLogo} width="auto" height="72" /></LinkStyled>
          :<a href="mailto:content@sales.gpm-rtv.ru"><Svg width="24" height="24" type="mail_outline" color={colors.blue_active}></Svg></a>
        }

      </DescriptionHeaderStyled>
    </>
    );
  }
}

export default TabsHeader;
