import React from 'react';
import styled from "styled-components";
import {Medium} from "../../styles/typography/typography";
import {device} from "../../styles/variables";

const PopupAddFavoritesStyled = styled.div`
    position: absolute;
    display: block!important;
    z-index: 50;
    /* top: 60px; */
    //top: -120px;
    left: 0;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 5px rgba(66,165,245,0.12), 0px 1px 10px rgba(66,165,245,0.2);
    background-color: #FFFFFF;
    text-align: center;
    @media ${device.tablet} {
      /* top: 30px; */
      top: -60px;
      width: 110%;
      left: -5%;
    }
`;

export const DispatchMessage = ({message}) => {
    return (
        <PopupAddFavoritesStyled className ="PopupAddFavoritesStyled">
            <Medium>{message}</Medium>
        </PopupAddFavoritesStyled>
    )
}