import {MANAGERS_CONTENT} from "../types";

const initialState = {
    managersContent: false,
}

export const managersContentReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case MANAGERS_CONTENT:
            return{
                ...state,
                managersContent: payload,
            };
        default:
            return state;
    }
}