import React from 'react';
import { connect } from "react-redux";
import { H1, H4, H3} from "../../styles/typography/typography.js";
import { colors } from "../../styles/variables.js"
import Svg from "../../assets/icons/icons";

import { addToFavorites, deleteFromFavorites } from '../../store/favorites/actions';

import {
  WrapStyled,
  LineStyled,
  LineLeftStyled,
  LineRighttyled,
  TooltipWrapStyled,
  TextWrapStyled,
  TextContentStyled,
  MoreContentStyled,
  SmallDescription,
  WrapForMobilefavorites
} from './style.js';

import {
  SpanStyled
} from '../Tabs-header/style.js';

import {
  UlSt
} from '../Select/style.js';

import {
  IconFavoritesSt,
  IconWrapFavoritesSt
} from '../Tabs-left/style.js';


import TabsHeader from "../Tabs-header/Tabs-header";
import TabsCardEvents from "../TabsCardEvents/TabsCardEvents";
import i18n from "i18next";

class TabsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
      moreIsOpen: false,
      bookmarkFavorites: false,
      bookmarkCheck: "not",
    };
    this._handleTooltipClick = this._handleTooltipClick.bind(this);
    this.tooltipeOutside = this.tooltipeOutside.bind(this);
    this._bookmark = this._bookmark.bind(this);
  }

  _handleTooltipClick = (evt) => {
    evt.preventDefault();
    this.setState((prevState) => ({ tooltipIsOpen: !prevState.tooltipIsOpen }));
  }

  _handleMoreClick = (evt) => {
    evt.preventDefault();
    this.setState((prevState) => ({moreIsOpen: !prevState.moreIsOpen }));
  }

  tooltipeOutside() {
    this.setState(() => ({tooltipIsOpen: false} ));
  }

  componentDidUpdate() {
    if (this.state.tooltipIsOpen){
      document.addEventListener('click', this.tooltipeOutside);
    } else  {
      document.removeEventListener('click', this.tooltipeOutside);
    }
  }
  // обработка клика на закладку
  _bookmark(id, type) {
    if (this.state.bookmarkCheck === "not") {
      this.setState({ bookmarkFavorites: this.props.isInFavourites }, () => {
        if (this.state.bookmarkFavorites) {
          this.props.deleteFromFavorites(id, type, this.props.token)
        } else {
          this.props.addToFavorites(id, type, this.props.token);
        }
        this.setState((prevState) => (
          {bookmarkFavorites: !prevState.bookmarkFavorites}
        ));
      });
      this.setState(() => ({bookmarkCheck: "check"}));
    }

    if (this.state.bookmarkCheck === "check") {
      if (this.state.bookmarkFavorites) {
        this.props.deleteFromFavorites(id, type, this.props.token)
      } else {
        this.props.addToFavorites(id, type, this.props.token);
      }
      this.setState((prevState) => (
        {bookmarkFavorites: !prevState.bookmarkFavorites}
      ));
    }
  }
  render() {
    const {manager, location, name, genre, description, header, bigDescription, smallDescription, moreDetails, is_format, channel, episodes, numberSeasons, numberEpisodes, channelLogo, seasonsDes, id, typeBookmark, isInFavourites} = this.props
    const favorietsColor = this.state.bookmarkCheck === "check" ? this.state.bookmarkFavorites : isInFavourites
    //Формирование попапа с колличеством сезонов и качеством
    // const episodesPopup = episodes && episodes.map((item, i)=>{
    //   let quality_sd = 0;
    //   let quality_hd = 0;
    //   for(let i=0; i<item.length; i++){
    //     if(item[i].quality === "sd"){
    //       quality_sd++;
    //     }
    //     else if(item[i].quality === "hd"){
    //       quality_hd++;
    //     }
    //   }
    //   return(<>
    //     <b key={i}>{i+1} сезон:</b><br />
    //     {quality_sd>0 && <>{quality_sd} серий <b>х</b> {item[0].duration_text.substr(0,2)} мин. (SD)<br /></>}
    //     {quality_hd>0 && <>{quality_hd} серий <b>х</b> {item[0].duration_text.substr(0,2)} мин. (HD)<br /></>}
    //   </>)
    // });



    const newEpisodesPopup = numberSeasons && numberSeasons.map((item, i)=>{
      return(<>
        <b key={i}>{i+1} {i18n.t(`catalog.project.${0}.season`)}{seasonsDes[i].status_text && (", " + seasonsDes[i].status_text)}:</b><br />
        {numberEpisodes.length>0 && <>{numberEpisodes[i] ? numberEpisodes[i] : 0} {i18n.t(`catalog.project.${0}.season_episodes`)} <b>х</b> {description[1].text}<br /></>}
      </>)
    })

    return (
    <>
      {(window.innerWidth > 767 && header)
        ?<TabsHeader name={name} genre={genre} is_format={is_format} channel={channel} channelLogo={channelLogo}/>
        :<WrapForMobilefavorites>
          <H1>{name}</H1>
          <IconWrapFavoritesSt onClick={() => this._bookmark([id], typeBookmark)}>
              <Svg width="24" height="24" type={favorietsColor ? "bookmark" : "bookmark_border"} color={colors.blue_active} fill="white"></Svg>               
          </IconWrapFavoritesSt>
        </WrapForMobilefavorites>
        
      }

      {smallDescription && <SmallDescription><H4>{smallDescription}</H4></SmallDescription>}
      <WrapStyled>
        {
          description.map((item, index) =>

              (item.name === "Количество сезонов" || item.name === "Seasons") && numberSeasons.length>1
            ? (item.text > 0 && <LineStyled  key={index}>
              <LineLeftStyled>{item.name}</LineLeftStyled>
              <LineRighttyled onClick={this._handleTooltipClick}>
                <a href="#">{item.text}</a>
                {(this.state.tooltipIsOpen) &&
                  <TooltipWrapStyled>
                    <UlSt>
                      <SpanStyled>
                        {newEpisodesPopup}
                      </SpanStyled>
                    </UlSt>
                  </TooltipWrapStyled>
                }
                </LineRighttyled>
              </LineStyled>)
            : ((item.text.length < 1 || item.text < 1 || item.text === "... - ...") ? "" : <LineStyled key={index}>
                  <LineLeftStyled>{item.name}</LineLeftStyled>
                 {/* <LineRighttyled>{item.text}</LineRighttyled>*/}
                  <LineRighttyled dangerouslySetInnerHTML={{__html:`${item.text}`}}></LineRighttyled>
                </LineStyled>)
          )
        }

      </WrapStyled>
      {bigDescription &&
        <TextWrapStyled>
          <TextContentStyled dangerouslySetInnerHTML={{__html:`${bigDescription}`}}></TextContentStyled>
          {(this.state.moreIsOpen || !bigDescription) &&
            <TextContentStyled>{" " + moreDetails}</TextContentStyled>}
          {(moreDetails && bigDescription) &&
          <MoreContentStyled onClick={this._handleMoreClick}>{(!this.state.moreIsOpen) ? i18n.t(`event.detail`) : i18n.t(`event.turn`)}</MoreContentStyled>}
        </TextWrapStyled>
      }
      {manager &&
        <>
          <TextWrapStyled>
            <H3>{i18n.t(`event.managers_at_this_event`)}</H3>
            <TabsCardEvents manager={manager} location={location} />
          </TextWrapStyled>
        </>
      }
    </>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    isLoading: store.projects.isLoading,
    projects: store.projects.projects,
    token: store.auth.dataToken.token,
    photo: store.customer.customer.photo,
    dop: store.dop.projects,
    query: store.query.projects,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    addToFavorites: (id, type, token) => dispatch(addToFavorites(id, type, token)),
    deleteFromFavorites: (id, type, token) => dispatch(deleteFromFavorites(id, type, token))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabsDescription);
