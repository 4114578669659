import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

const PrivateRoute = (props) => {
  const {render, path, exact, isLoggedIn} = props;
  return (
    <Route
      path={path}
      exact={exact}
      render={(routeProps) => {
        return (
          isLoggedIn
            ? render(routeProps)
            : <Redirect to="/auth" />
        );
      }}
    />
  );
};

const mapStateToProps = (store) => {
  return {
      isLoggedIn: store.auth.isLoggedIn,
      token: store.auth.dataToken.token
  };
}
export {PrivateRoute};
export default connect(mapStateToProps)(PrivateRoute);
