import React from 'react';
import {colors} from "../../styles/variables.js"
import Svg from "../../assets/icons/icons";
import ToggleView from "../../components/ToggleView/ToggleView";
import TabsLeft from "../Tabs-left/Tabs-left";
import AccountChangeFoto from '../AccountChangeFoto/AccountChangeFoto';
import { Container } from "../Container/Container";

import {
  PprojectStyled,
  PprojectLeftStyled, 
  PprojectRightStyled,
  MenuStyled,
  MenuItemStyled,
  LinkStyled,
  MenuItemStyledActive,
  TabsWrap,
  TabsWrapItem, 
  ContainerForToggleViewStyled
} from './style.js';
import {Medium} from "../../styles/typography/typography";
import i18n from "i18next";
import PopupNew from "../PopupNew/PopupNew";
import LogIn from "../LogIn/LogIn";

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 0,
      popupIsOpen: false,
      menuIsOpen: false
    };
    this.handleTabsClick = this.handleTabsClick.bind(this);
  }

  handleTabsClick = (item) => {
    this.setState(() => ({tabs: item}));
  }

  _handleOpenPopupClick = (e) => {
    e.preventDefault();
    this.setState((state) => {
      return {
        popupIsOpen: !state.popupIsOpen,
        menuIsOpen: false,
      }
    });
  }

  render() {

    const { managers, tabsMenu, imgPoster, textSend, type, dashboard, onToggleViewClick, id, typeBookmark, isInFavourites} = this.props

    return (
      <>

      {
        (this.state.popupIsOpen && !this.props.isLoggedIn && window.innerWidth > 768)
        &&
        <PopupNew handleOpenPopupClick={this._handleOpenPopupClick}>
          <LogIn
              viewType ="popup"
              // handleOpenProfileMenuClick={this._handleOpenProfileMenuClick}
              handleOpenPopupClick={this._handleOpenPopupClick} />
        </PopupNew>
      }
      <PprojectStyled className={type === "profile" ? "profile" : ""}>
        <PprojectLeftStyled>
          <TabsLeft 
            managers={managers} 
            imgPoster={imgPoster} 
            textSend={textSend} 
            type={type} 
            tabs={this.state.tabs}
            id={id}
            typeBookmark={typeBookmark}
            isInFavourites={isInFavourites}
          />
        </PprojectLeftStyled>

        <PprojectRightStyled>
          <MenuStyled>
            {tabsMenu.map((tabs, index) =>
                index === this.state.tabs
                ?   <MenuItemStyledActive onClick={()=>this.handleTabsClick(index)} key={index} className={!tabs.tabsName && "not"}>{tabs.tabsName}</MenuItemStyledActive>
                :   (this.props.isLoggedIn === false ?
                        tabs.tabsName === "Видео" ?
                            (window.innerWidth < 768) ?
                                <LinkStyled className="with-icon" to="/auth">Видео </LinkStyled>
                                : <LinkStyled onClick={this._handleOpenPopupClick} className="with-icon" to="/auth">Видео </LinkStyled>
                                : <MenuItemStyled onClick={() => this.handleTabsClick(index)} key={index} className={!tabs.tabsName && "not"}>{tabs.tabsName}</MenuItemStyled>
                                : <MenuItemStyled onClick={() => this.handleTabsClick(index)} key={index} className={!tabs.tabsName && "not"}>{tabs.tabsName}</MenuItemStyled>
                    )
            )}
            {this.state.tabs === 1 && type === "event" && window.innerWidth > 767
              ?<ContainerForToggleViewStyled>
                <ToggleView selected = {dashboard ? "dashboard" : "list" } toggleViewChange={onToggleViewClick} />
              </ContainerForToggleViewStyled>
              : null
            }
          </MenuStyled>
          <TabsWrap>
          {(() => {
            switch (this.state.tabs) {
              case 0:
                return (
                  <TabsWrapItem >{this.props.firstTabs}</TabsWrapItem>
                )
              case 1 :return (
                <TabsWrapItem>{this.props.secondTabs}</TabsWrapItem>
              )
              case 2 :return (
                <TabsWrapItem>{this.props.thirdTabs}</TabsWrapItem>
              )
              case 3 :return (
                <TabsWrapItem>{this.props.fourthTabs}</TabsWrapItem>
              )
              case 4 :return (
                <TabsWrapItem>{this.props.fifthTabs}</TabsWrapItem>
              )
            }
          })()}
          </TabsWrap>
        </PprojectRightStyled>
      </PprojectStyled>
    </>
    );
  }
}

export default Tabs;