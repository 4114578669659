import styled from "styled-components"
import { colors as c, fonts } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"
import {Small} from "../../styles/typography/typography"

export const ApprovalStyled = styled.div`
    width: 100%;
    padding-left: 30px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 40px;
    user-select: none;
    div {
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
      }
    }
  &.approval-error {
    svg {
      fill: #FF0C3E;
    }
  }
`;

export const ApprovalErrorStyled = styled.div`
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #FF0C3E;
`;