import styled from 'styled-components';
import {colors as c, device as d, fonts as f} from "../../styles/variables";
import {Overline as Overline} from  "../../styles/typography/typography";

export const ContainerStyled = styled.div`
   position: relative;
   margin-top: 20px;
   @media ${d.tablet} {
       margin-top: 50px;
   }
`;

export const ContainerForToggleViewStyled = styled.div`
    z-index: 2;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: -60px;
    div{
      width: auto;
    }
`;

export const HeaderStyled = styled.p`
   margin: 0 0 30px;
   padding-top: 50px;
   padding-bottom: 20px;
   font-size: 20px;
   color: ${c.black};
   font-weight: ${f.bold};
   border-bottom: 1px solid #eee;
   @media ${d.tablet} {
       font-size: 30px;
       padding-top: 0;
       padding-bottom: 30px;
   }
`;

export const NumberPassedStyled = styled(Overline)`
   color: ${c.blue_active};
`;