// размеры svg для компонента Card.jsx и CardList.jsx
export const channelListForCards =
    [
        {
            img: '',
            width: '',
            height: ''
        },
        {
            img: 'tnt',
            width: '52',
            height: '18'
        },
        {
            img: 'tv3',
            width: '23',
            height: '22'
        },
        {
            img: 'friday',
            width: '55',
            height: '22'
        },
        {
            img: '2x2',
            width: '45',
            height: '20'
        },
        {
            img: 'tnt4',
            width: '24',
            height: '22'
        },
        {
            img: 'super',
            width: '57',
            height: '17'
        },
    ];

// размеры svg для компонента BannerNew.jsx
export const channelListForBannerNew =
    [

        {
            img: '',
            height: ''
        },
        {
            img: 'tnt',
            height: '20'
        },
        {
            img: 'tv3',
            height: '22'
        },
        {
            img: 'friday',
            height: '22'
        },
        {
            img: '2x2',
            height: '20'
        },
        {
            img: 'tnt4',
            height: '22'
        },
        {
            img: 'super',
            height: '17'
        }
    ]
