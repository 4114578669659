import {
    RECOMM_PROFILE_REQUEST,
    RECOMM_PROFILE_FAIL,
    RECOMM_PROFILE_SUCCESS
} from "../../types"

const initialState = {
    isLoading: false,
    message: '',
    recommendations: []
}

export const recommendationsReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case RECOMM_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case RECOMM_PROFILE_SUCCESS:
            return {
                ...state,
                recommendations: payload,
                isLoading: true
            };
        case RECOMM_PROFILE_FAIL:
            return {
                ...state,
                message: payload,
                isLoading: false
            };
        default:
            return state;
    }
}