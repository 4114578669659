import styled from "styled-components"
import {colors as c} from "../../styles/variables";
import {H3} from "../../styles/typography/typography";
import { device } from "../../styles/variables.js"

export const ContainerCollectionSt = styled.div`
    padding-top: 60px;
    flex: 1 0 auto;
    @media ${device.tablet} {
      padding-top: 0;
      flex: 1 0 auto;
    }
`;

export const HeaderSt =  styled.div`
        position: relative;
        padding: 40px 0 0 0;
        // border-bottom: 1px solid ${c.grey_light};
        max-width: 1140px;
`;
export const TitleSt = styled(H3)` 
    padding: 35px 0 20px 0;
    display: inline;
`;

export const SettingsMenuSt =  styled.div`
  position: relative;
 
  @media ${device.tablet} {
    margin-top: 10px;
  }
`;

export const SettingsToggleSt = styled.div`
    position: absolute;
    top: 31px;
    right: 0;
    display: flex;
    z-index: 97;
    span.visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        -webkit-clip: rect(0,0,0,0);
        clip: rect(0,0,0,0);
        overflow: hidden;
    }
    .toggle{
        margin-left: 30px;
        @media ${device.laptop} {
            margin-left: 0;
        }
    }
   
`;