import styled from "styled-components"
import {device} from "../../styles/variables";

export const ContainerCatalogSt = styled.div`
    padding-top: 60px;
    flex: 1 0 auto;
    @media ${device.tablet} {
        padding-top: 0;
    }
`;
