import styled from "styled-components"
import { colors, fonts, device } from "../../styles/variables.js"
import {
  PprojectWrapEmailStyled,
} from '../Tabs/style.js';

export const WrapStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardStyled = styled.div`
  width: 28%;
  margin-right: 5%;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: flex;
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid ${colors.grey_light};
  }

  &:first-child{
    border-top: 0;
  }

  &.profile {
    width: 100%;
    max-width: 300px;
    border-top: 0;
  }
`;

export const CardImageStyled = styled.img`
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
  max-width: 200px;
  height: auto;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 27%;
    margin-top: 0;
    margin-bottom: auto;
  }
  
  &.profile {
    display: none;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: block;
    }
  }
`;

export const CardWrapStyled = styled.div`
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    ${PprojectWrapEmailStyled} {
      margin-top: auto;
      padding: 5px;
    }
  }
`;

export const CardTextStyled = styled.div`
  margin-bottom: 10px;
`;

export const CardsmallTextStyled = styled.div`
  font-size: 16px;
  margin-bottom: 10px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 14px;
    margin-bottom: 0;
  }
`;
