import styled from "styled-components"
import { colors, fonts, device} from "../../styles/variables.js"

export const FotoStyled = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${colors.grey_light};
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &.notline {
    margin-bottom: 0;
    border-bottom: 0;

  }
`;

export const FotoLeftStyled = styled.div`  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const FotoImgStyled = styled.img`
  cursor: pointer;
  display: inline-block;
  width: 23.5%;
  height: auto;
  margin-right: 2%;
  margin-top: 2%;
  position: relative;

  &:nth-child(4n+4) {
    margin-right: 0;
  }
  &:nth-child(1) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }
  &:nth-child(2) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }

  @media ${device.mobile} {
    width: 48%;
    margin-right: 0;
    margin-top: 4%;
  }
`;

export const FotoRightStyled = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const FotoLinkStyled = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue_active};
  font-family: ${fonts.font_family};
  display: block;
  width: 120px;
  text-align: left;
  padding-left: 7px;
  white-space: nowrap;
  cursor: pointer;
  text-transform: uppercase;
  @media ${device.mobile} {
    display: block;
    padding-top: 18px;
    padding-left: 0;
    width: auto;
    text-align: left;
  }
`;

export const PostertStyled = styled.div`
  padding: 35px 0 100px 0;
  display: flex;
  flex-wrap: wrap;
  @media ${device.mobile} {
    justify-content: space-between;
    padding: 30px 0 30px 0;
  }
`;
export const PostertItemStyled = styled.img`
  width: 24%;
  height: auto;
  margin-right: 1%;
  margin-top: 1%;
  &:nth-child(4n+4) {
    margin-right: 0;
  }
  &:nth-child(1) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }
  &:nth-child(2) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }
  @media ${device.mobile} {
    width: 48%;
    margin-right: 0;
    margin-top: 4%;
  }
`;
