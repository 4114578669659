
import styled from "styled-components"
import { device } from "../../styles/variables.js"
import { colors as c } from "../../styles/variables.js"
import {Link} from "react-router-dom";

export const ContainerStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-around;   
    
    div:first-child {
      margin-right: 0 !important;   
    }

    @media ${device.tablet} {
        &:first-child {
            margin-right: 0;
            max-width: ${props => props.container === "header" ? "auto" : "450px"};
        }
        justify-content: space-between;
    }
    @media ${device.laptop} {
        &:first-child {
            margin-right: 0;
            max-width: ${props => props.container === "header" ? "auto" : "680px;"};
        }
        margin-left: 8px;
        flex-wrap: nowrap;
    }
`;

export const LinkStyled = styled(Link)`
    display: flex;
    width: 100%; 
    justify-content:  ${props => props.container === "header" ? "space-between" : "center"};
    
    div:first-child {
      margin-left: -15px;
    }
    div:last-child {
      margin-right: -17px !important;
    }
    
    div {
      width: 80px;
      height: 36px;     
      background-repeat: no-repeat;   
      background-size: auto 80%;
      background-position: center;
      
      &:hover {    
        filter: invert(52%) sepia(60%) saturate(1293%) hue-rotate(184deg) brightness(103%) contrast(92%);
        transition: all .3s;
        //подобрать цвет к фильтру https://codepen.io/sosuke/pen/Pjoqqp
      }
          
      &.opacity {        
        opacity: 0.4;   
        transition: all .3s;
        
        &:hover {    
          filter: none;
          opacity: 1;     
        }
      }
      
    }
`;

export const SVGStyled = styled.svg`
        width: auto;
        height: auto;
        fill: ${props => props.color && props.color};
        opacity: ${props => props.container === "header" ? ".4" : "1"};
        transition: fill .3s ease, opacity .3s ease;
        &:hover {
            fill: ${props => props.container === "header" ? `#fff;` : `${c.blue_active}`};
            opacity: ${props => props.container === "header" ? "1" : "1"};
        }
    
        &:active {
            fill: ${props => props.container === "header" ? `${c.white}` : `${c.blue_active}`};
            opacity: 1;
        }
        
        @media ${device.mobile}  { 
            &:hover {
                fill: ${props => props.container === "header" ? `#31394e;` : `${c.blue_active}`};
                opacity: ${props => props.container === "header" ? "1" : "1"};
            }
        }

`;

export const ItemStyled = styled.div`
    width: ${props => props.container === "header" ? "66px" : "33%"};
    height: ${props => props.container === "header" ? "30px" : "50px"};
    display: flex;
    justify-content: center;
    align-items: center; 

    @media ${device.tablet} {
        width: ${props => props.container === "header" ? "auto" : "60px"};
        height: ${props => props.container === "header" ? "auto" : "27px"};
        &:not(:last-child) {
            margin-right: ${props => props.container === "header" ? "15px;" : "initial"}; 
        }
        &.productions { 
            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
    
     @media ${device.laptop} {
        width: ${props => props.container === "header" ? "auto" : "80px"};
        height: ${props => props.container === "header" ? "auto" : "36px"};
        &:first-child {
            margin-left: ${props => props.container === "header" ? "0" : "-10px"}
        }
        &:not(:last-child) {
            margin-right: ${props => props.container === "header" ? "25px;" : "initial"}; 
        }
    }
    @media ${device.desktop} {
        &.productions { 
            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
`;
