import styled from "styled-components"

import {colors as c, device as d, fonts as f} from "../../styles/variables";

import {Link} from "react-router-dom";

export const BannerFonSt = styled.div`
   background: ${c.black};
`;

export const ContainerTopBannerSt = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    @media (min-width: 1280px)  {
        padding: 0 0 0 20px;
    }
    @media (min-width: 1320px)  {
        padding: 0 0 0 40px;
    }

    @media (min-width: 1360px) {
        padding: 0 0 0 20px;
    }
    @media ${d.tablet} {
      height: 400px;
    }
    @media ${d.desktop} {
       margin-left: calc((100% - 1260px)/2);
       max-width: calc(100% - (100% - 1260px)/2);
       padding: 0 0 0 0px;
    }
`;

export const LeftSt = styled.div`
    width: 100%;
    order: 2;
    padding: 40px 20px;
    @media ${d.tablet} {
       width: 50%;
       order: 1;
       padding: 25px 20px 10px;
    }
    @media ${d.laptop} {
       width: 40%;
    }
    @media (min-width: 1280px) {
        padding: 0 0 0 20px;
        width: 35%;
        padding: 25px 50px 10px 0;
    }
    @media (min-width: 1350px) and  (max-width: 1360px){
        width: 35%;
        padding: 25px 50px 10px 0px;
    }
    @media (min-width: 1360px){
        width: 35%;
        padding: 25px 50px 10px 40px;
    }
    @media ${d.desktop} {
       width: 35%;
       padding: 25px 50px 10px 0;
    }
    @media (min-width: 1440px) {
       width: 24%;
    }
`;

export const AboutUsTitleSt = styled.p`
    color: ${c.blue_active};
    font-weight: ${f.bold};
    text-transform: uppercase;
`;

export const AboutUsTextSt = styled.p`
    color: ${c.white};
    font-size: 14px;
    padding: 15px 0;
`;

export const AboutUsLinkSt = styled.a`
    color: ${c.blue_active};
    text-decoration: none;
`;

export const AboutUsMoreDetailsSt = styled(Link)`
    color: ${c.blue_active};
    text-transform: uppercase;
    text-decoration: none;
    font-size: 10px;
`;

export const ContainerSt = styled.div`
    position: relative;
    width: 100%;
    order: 1;
    @media ${d.tablet} {
        overflow: hidden;
        width: 50%;
        order: 2;
    }
    @media ${d.laptop} {
       width: 60%;
    }
    @media (min-width: 1280px) {
       width: 65%;
    }
    @media ${d.desktop} {
       width: 65%;
    }
    @media (min-width: 1440px) {
       width: 76%;
    }
`;

export const BannersContainerSt = styled.div`
   
`;

export const CarouselSt = styled.div`
    width: ${props => props.widthEl * 42}%;
    margin-left: -${props => props.marginCount * 42 - (props.widthEl===props.marginCount+2 && 16)}%;
    display: flex;
    transition: margin-left .5s, width .5s;
    @media ${d.tablet} {
        width: ${props => props.widthEl * 33.34}%;
        margin-left: -${props => props.marginCount * 33.34}%;
    }
    @media ${d.laptop} {
        overflow: hidden;
        width: ${props => props.widthEl * 25}%;
        margin-left: -${props => props.marginCount * 25}%;
        &:hover{
            width: ${props => props.widthEl * 25+25}%;
        }
    }
    @media (min-width: 1440px) {
        width: ${props => props.widthEl * 20}%;
        margin-left: -${props => props.marginCount * 20}%;
        &:hover{
            width: ${props => props.widthEl * 20+18}%;
        }
    }
      @media (min-width: 1640px) {
        &:hover{
          width: ${props => props.widthEl * 20+20}%;
        }
      }
     @media (min-width: 1920px) {
        width: ${props => props.widthEl * 16.7}%;
        margin-left: -${props => props.marginCount * 16.7}%;
        &:hover{
            width: ${props => props.widthEl * 16.7+16.8}%;
        }
    }
`;


// КНОПКИ
export const ButtonSt = styled.div`
    width: 12%;
    ${props => props.side}: 0;
    opacity: .6;
    background: ${c.black};
    position: absolute;
    z-index: 9;
    bottom: 22%;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
    @media ${d.tablet} {
       width: 80px;
       bottom: 40%;
    }
`;

export const ButtonPaddingSt = styled.div`
    padding-bottom: 100%;
`;

export const PlugSt = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover; 
    background-position: center;
    min-height: 340px;
    @media ${d.mobile} {    
      background-position: 50% 30px;
      background-size: cover;
    }
`;

export const ArrowSt = styled.svg`
   width: 22%;
   position: absolute;
   margin: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
`;