import React from 'react';
import Svg from "../../assets/icons/icons"

import {
    CheckboxSt,
    LabelSt,
    CheckboxSquareSt,
} from './style';

const Checkbox = ({ checked = false, label, ...props }) => {

    return (
        <CheckboxSt>
            <CheckboxSquareSt checked={checked}>
                <Svg type={checked ? "check_box" : "check_box_outline_blank"} />
            </CheckboxSquareSt>
            <LabelSt>
                {label}
            </LabelSt>
        </CheckboxSt>
    );
};

export default Checkbox;