import React from 'react'
import {
    DivStContainerOne,
    DivStContainerLine,
    DivStContainerLeft,
    DivStContainerMenu,
    DivStContainerRight,
    DivStContainer,
    DivStContainerMobileTopLogo,
    DivStContainerMobileSiteContent,
    DivStContainerTextLeft,
    DivStAbout,
    DivStContactBlock,
    DivStContactItem,
    DivStRedBlock,
    DivStNoImgStyled,
    DivStContactContainerImg
} from './style'
import {Link, NavLink} from 'react-router-dom'
import {H1, H2, H3, H4, Small} from '../../styles/typography/typography'
import Svg from '../../assets/icons/icons'
import i18n from 'i18next'
import {useSelector} from 'react-redux';

const Channel = ({store: {img, channelName, titleGpm, title, text}, storeChannel}) => {

    const url = `/channel/${channelName}` // url для "Контент канала"
    //тоже самое что и props.store.titleGpm ? true : false
    const gpm = !!titleGpm //условие для нескольких css, html первой страницы

    /**
     * В массиве channelArr зашиты каналы в нужном порядке
     * Массив channelArrServer содержит каналы которые приходят с сервера
     * uniqueArr выводит каналы с сервера которых нет в массиве channelArr
     * channelArr объединяет каналы с сервера и зашитые каналы в коде
     *
     * Порядок вывода каналов находится в массиве channelArr
     * С севрера не приходят иконки продуктов, они зашиты в switch
     */
    let channelArr = ['gpm_etv', 'tnt', 'tv3', 'tnt4', 'friday', '2x2', 'subbota', '2x2prod', '123-production', 'comedy-production', 'good-story'] //массив зашитых каналов в нужном порядке
    let channelArrServer = []
    for (let channel in storeChannel) {
        channelArrServer = [...channelArrServer, channel]
    }
    const uniqueArr = [...channelArrServer].filter(item => !channelArr.includes(item))

    channelArr = channelArr.concat(uniqueArr)

    const menuLogo = channelArr.map(c => {

        // первый логотип меню
        const linkOne = <DivStContainerOne  key={ `${ c }` }>
            <NavLink exact activeClassName="active" to="/about">
                <svg width={(window.innerWidth < 768) ? "100" : "80"} version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 -250 1920 923">
                    {(i18n.language === 'en') ?
                        <g><path className="st0" d="M406.82,5.05L0,127.37v290.58h432.64L406.82,5.05z M54.27,357.68V140.01l304.76-91.63l19.34,309.31H54.27z"/>
                        <path className="st0" d="M335.89,81.92L80.55,158.7v172.89h271.54L335.89,81.92z M126.76,291.66V171.35L295.2,120.7l10.69,170.96 H126.76z"/>
                        <path className="st0" d="M267.9,155.61l-110.14,33.12v78.67h117.13L267.9,155.61z"/>
                        <path className="st0" d="M685.56,350.59c0,26.01-3.18,66.36-55.21,66.36h-61.59c-52.03,0-55.22-40.35-55.22-66.36V111.67
                        c0-26.01,3.19-66.36,55.22-66.36h61.59c52.03,0,55.21,40.35,55.21,66.36v52.56h-74.32V98.4c0-6.38-4.78-12.22-11.68-12.22
                        c-4.25,0-11.68,2.65-11.68,12.22v265.45c0,9.57,7.43,12.22,11.68,12.22c6.9,0,11.68-5.83,11.68-12.22v-88.12h-11.15v-40.9h85.47
                        V350.59z M729.05,45.31h116.81c52.03,0,55.21,40.35,55.21,66.36v112.55c0,26.01-3.18,66.38-55.21,66.38h-42.48v126.35h-74.33
                        V45.31z M812.94,249.72c7.97,0,13.81-6.92,13.81-18.6V104.77c0-11.68-5.84-18.58-13.81-18.58h-9.56v163.53H812.94z M1134.64,45.31
                        h96.63v371.64h-74.33V183.88h-2.65l-38.23,233.08h-59.47l-38.22-233.08h-2.66v233.08h-74.33V45.31h96.63l48.32,261.2
                        L1134.64,45.31z M1359.4,45.31h150.78v40.88h-76.46v115.2h76.46v40.9h-76.46v133.78h79.64v40.88H1359.4V45.31z M1582.36,86.19
                        h-44.59V45.31h163.53v40.88h-44.59v330.76h-74.34V86.19z M1725.67,45.31h75.39l20.18,273.95h3.18l20.18-273.95H1920l-47.8,371.64
                        h-98.76L1725.67,45.31z"/></g>
                        :
                        <g><path class="st0" d="M525.86,42.34h145.11v41.77h-68.96V422.9h-76.15V42.34z"/>
                            <path class="st0" d="M705.75,42.34h176.09V422.9h-76.15V84.11H781.7V422.9h-76.15V42.34H705.75z"/>
                            <path class="st0" d="M1124.49,42.34h98.94V422.9h-76.15V184.25h-2.8L1105.3,422.9h-60.96l-39.18-238.65h-2.8V422.9h-76.15V42.34 h98.94l49.57,267.44L1124.49,42.34z"/>
                            <path class="st0" d="M1340.36,42.34h119.73c53.37,0,56.57,41.37,56.57,67.96v115.33c0,26.58-3.2,67.96-56.57,67.96h-43.57V422.9
					h-76.15C1340.36,422.9,1340.36,42.34,1340.36,42.34z M1426.3,251.61c8.19,0,14.19-7,14.19-18.99V103.3
					c0-11.99-6-18.99-14.19-18.99h-9.79v167.3H1426.3z"/>
                            <path class="st0" d="M1587.2,84.11h-45.77V42.34h167.5v41.77h-45.77V422.9H1587V84.11H1587.2z"/>
                            <path class="st0" d="M1743.71,42.34h119.73c53.37,0,56.57,41.37,56.57,67.96v60.96c0,41.37-28.78,52.77-51.57,51.57v3.8
					c51.17-1,51.57,37.98,51.57,51.57v76.75c0,26.58-3.2,67.96-56.57,67.96h-119.73V42.34z M1829.66,206.04
					c8.2,0,14.19-7,14.19-18.99V103.3c0-11.99-6-18.99-14.19-18.99h-9.79v121.73L1829.66,206.04z M1829.66,381.13
					c8.2,0,14.19-7,14.19-18.99V267c0-11.99-6-18.99-14.19-18.99h-9.79v133.32h9.79V381.13z"/>
                            <path className="st0" d="M416.58,0.1L0,125.35V422.9h443.03L416.58,0.1z M55.57,361.19v-222.9l312.07-93.83l19.81,316.73H55.57z"/>
                            <path className="st0" d="M343.95,78.82L82.48,157.43v177.04h278.06L343.95,78.82z M129.8,293.59v-123.2l172.48-51.86l10.95,175.06 H129.8z"/>
                            <path className="st0" d="M274.33,154.26l-112.78,33.91v80.56h119.94L274.33,154.26z"/>
                        </g>}
                </svg>
            </NavLink>
        </DivStContainerOne>

        // остальные логотипы зашитые в channelArr
        const link = (type, width = 80) => <NavLink key={ `${ c }` } activeClassName="active" to={`/about/${c}`}>
            <Svg width={width}
                 height="36"
                 type={type}
                 color="#42A5F5"/>
        </NavLink>

        /**
         * если приходит новый телеканал, берем его лого из logo_en или logo_ru добавляем в ег img
         * там невозможно подобрать идеальный цвет
         * подобрать цвет к фильтру https://codepen.io/sosuke/pen/Pjoqqp
         * https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/54000884#54000884
         * Когда появится поддержка браузеров можно будет поменять на filter: drop-shadow(60px 0px green)
         */
        const linkNew = <NavLink
            key={ `${ c }` }
            activeClassName="active"
            to={`/about/${c}`}>
            <img
                src={(i18n.language === 'en') ? storeChannel && storeChannel[c][0].logo_en : storeChannel && storeChannel[c][0].logo_ru}
                className={'w80'}/>
        </NavLink>

        switch (c) {
            case 'gpm_etv' :
                return linkOne
                break;
            case '123-production' :
                return link('tv123')
                break;
            case '2x2prod' :
                return link(i18n.t('about.logo.0.tv2x2_anim'))
                break;
            case 'comedy-production' :
                return link('comedy')
                break;
            case 'good-story' :
                return link('good_story')
                break;
            default :
                return linkNew
        }

    })

    // Менеджеры в блоке "Контакты"
    const managersContent = useSelector((state) => state.managersContent.managersContent) //менеджеры
    const managers = managersContent.data && managersContent.data.map((item) => {
        return (
            <DivStContactItem key={ `${ item.full_name }` }>
                {item.photo ? <DivStContactContainerImg><img src={item.photo} alt=""/></DivStContactContainerImg> : <DivStNoImgStyled/>}
                <H4>{item.full_name}</H4>
                <p>{item.position}</p>
                <a href={`mailto:${item.email}`}>
                    <Svg
                        type="mail_outline"
                        color="#42A5F5"
                    />
                    <Small bold uppercase>{item.email}</Small>
                </a>
            </DivStContactItem>
        )
    })

    return (
        <>
            <DivStContainer>

                <DivStContainerLeft>
                    <img src={img} alt=""/> <br/>
                    {/* не выводим этот блок для первой страницы*/}
                    {!gpm && (<DivStContainerTextLeft>
                        <Svg
                            type="star_border"
                            color="#42A5F5"
                        />
                        <Link to={url}> {i18n.t(`about.channel_content`)}</Link>
                    </DivStContainerTextLeft>)}

                </DivStContainerLeft>

                <DivStContainerRight>

                    <DivStContainerMobileTopLogo>
                        <div><H1>{title}</H1></div>
                        {/* добавляем класс "w60" для gpm */}
                        <img src={img} className={gpm ? "w60" : false} alt=""/>
                    </DivStContainerMobileTopLogo>

                    <DivStContainerMenu>
                        {menuLogo}
                    </DivStContainerMenu>


                    {/* на первой странице должен быть заголовок H2*/}
                    {gpm ? (<H2 className="oneTitile">{titleGpm}</H2>) : (<H1 className="mobileNone">{title}</H1>)}

                    {/* не выводим этот Мобильный блок для первой страницы*/}
                    {!gpm && (<DivStContainerMobileSiteContent>
                        <div>
                            <Svg
                                type="star_border"
                                color="#42A5F5"
                            />
                            <Link to={url}> {i18n.t(`about.channel_content`)}</Link>
                        </div>
                    </DivStContainerMobileSiteContent>)}

                    <DivStContainerLine/>

                    <DivStAbout dangerouslySetInnerHTML={{__html: `${text}`}}/>

                </DivStContainerRight>

            </DivStContainer>
            {/* менеджеры нужны только для первой страницы */}
            {gpm && (<DivStContactBlock>
                <H3>{i18n.t('about.contacts')}</H3>
                {managers}
                <DivStContactItem>
                    <DivStRedBlock>
                        <Svg
                            type="info"
                            color="#FF0C3E"
                            className="mobileInfo"
                        />
                        <H4>{i18n.t('about.for_content_blocking')}</H4>
                        <a href="mailto:crp@gpm-rtv.ru">
                            <Svg
                                type="mail_outline"
                                color="#42A5F5"
                            />
                            <Small bold uppercase>crp@gpm-rtv.ru</Small>
                        </a>
                    </DivStRedBlock>
                    <div className="mobileText">
                        <H4>{i18n.t('about.for_content_blocking')}</H4>
                        <a href="mailto:crp@gpm-rtv.ru">
                            <Svg
                                type="mail_outline"
                                color="#42A5F5"
                            />
                            <Small bold uppercase>crp@gpm-rtv.ru</Small>
                        </a>
                    </div>
                </DivStContactItem>
            </DivStContactBlock>)}
        </>
    )
}

export default Channel