import React from 'react';

import Card from "../Card/Card";
import Popup from '../../components/Popup/Popup.jsx';

import { colors as c } from "../../styles/variables.js"
import {Medium, Small} from "../../styles/typography/typography";

import {
    HeaderSt,
    DescriptionContainerSt,
    ContainerSt,
    ContentSt,
    CardsContainerSt,
    CarouselSt,
    ArrowSt,
    ButtonSt,
    ButtonStPadding,
    LinkSt
} from './style.js';
import {
    PopupFrameStyled
} from '../Popup/style.js';

import i18n from "i18next";

export default class Carousel extends React.Component {

    state ={
        maxRight: 0,
        marginCount: 0, // margin-left у CarouselSt - за чет которого осуществляется листание карусели
        rightButtonDisplay: false,
        leftButtonDisplay: false,
        closeAllCardsDiscription: false, // контроль закрытия второго описания
        popupIsOpen: false,
    };

    handleClick = (evt, id) => {
        evt.preventDefault()
        this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
        this.setState(() => ({ trailer: id }));
    }

    // при открытии второго описания закрываем первое
    _closeAllCardsDiscription(){
        this.setState(() => {
            return { closeAllCardsDiscription: true }
        })
        setTimeout(() => {
            this.setState(() => {
                return { closeAllCardsDiscription: false }
            })
        }, 50)
    }

    // ref - для получения ширины контейнара карусели
    cardsContainerSt = React.createRef();

    // Движение карусели ВПРАВО
    carouselMoveRight(){
        let newMarginCount = this.state.marginCount + 1;
        if(this.props.cards.length - newMarginCount === this.state.maxRight){
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    rightButtonDisplay: false,
                    leftButtonDisplay: true
                }
            })
        }
        else{
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    leftButtonDisplay: true
                }
            })
        }
    }

    // При открытии описания в крайней с права карте, происходит движение карусели вправо
    _openDecrStepRight(indexCard){
        if(indexCard===this.state.marginCount+this.state.maxRight){
            this.carouselMoveRight();
        }
    }

    // Движение карусели ВЛЕВО
    carouselMoveLeft(){
        let newMarginCount = this.state.marginCount - 1;
        if(newMarginCount === 0){
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    leftButtonDisplay: false,
                    rightButtonDisplay: true
                }
            })
        }
        else{
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    rightButtonDisplay: true
                }
            })
        }
    }

    // Движение карусели СВАЙПОМ
    swipeZeroDot = 0;
    swipeBreak = true;
    // получение первой точки свайпа
    swipeSlide(e) {
        this.swipeZeroDot = e.changedTouches[0].pageX;
    }
    //отработка горизонтального свайпа
    swipeSlideEnd(e){
        if(this.swipeBreak){
            this.swipeBreak = false;
            let finalDot = this.swipeZeroDot - e.changedTouches[0].pageX;
            if(finalDot>1 && this.state.rightButtonDisplay){
                this.carouselMoveRight();
            }
            else if(finalDot<-1 && this.state.leftButtonDisplay){
                this.carouselMoveLeft();
            }
            setTimeout(() => this.swipeBreak = true, 300);
        }
    }

    componentDidMount() {
        // Показываем правую стрелку
        setTimeout(() => {
            if(this.cardsContainerSt.current){
                if(this.cardsContainerSt.current.offsetWidth > 943 && this.props.cards.length > 5){
                    this.setState(() => {
                        return {
                            rightButtonDisplay: true,
                            maxRight: 5,
                        }
                    })
                }
                else if(this.cardsContainerSt.current.offsetWidth < 944 && this.cardsContainerSt.current.offsetWidth > 727 && this.props.cards.length > 4){
                    this.setState(() => {
                        return {
                            rightButtonDisplay: true,
                            maxRight: 4,
                        }
                    })
                }
                else if(this.cardsContainerSt.current.offsetWidth < 728 && this.props.cards.length > 2){
                    this.setState(() => {
                        return {
                            rightButtonDisplay: true,
                            maxRight: 2,
                        }
                    })
                }
            }
        }, 1000)
    }

    render(){
        const { cards, type, title, link, render, description } = this.props;
        // Передаем в allCards все карты полученные с сервера по запросу.
        // Если карта последняя в ряду, то descriptionOpenLeft={true} - для открытия описания в лево
        // и для нее не задаются дополнительная функция (_openDecrStepRight) для перелистывания слайдера одновременно с открытием описания проекта
        const allCards = cards.map((item, i)=>{
            if(i === cards.length-1 && cards.length>1){
                return(
                    <Card { ...item } key={i+1} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={true} _closeAllCardsDiscription={() => this._closeAllCardsDiscription()} closeAllCardsDiscription={this.state.closeAllCardsDiscription} handleClick={(evt, id) => this.handleClick(evt, id)}/>
                )
            }
            else{
                return(
                    <Card { ...item } key={i+1} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} _closeAllCardsDiscription={() => this._closeAllCardsDiscription()} closeAllCardsDiscription={this.state.closeAllCardsDiscription} handleClick={(evt, id) => this.handleClick(evt, id)} _openDecrStepRight={(i) => this._openDecrStepRight(i)} index={i+1} />
                )
            }
        })

        return (
            <>
                <ContainerSt>
                    <HeaderSt>
                        {title}
                        {link &&
                            <LinkSt to={link} onClick={() => {render && render(); localStorage.setItem('filterStart', title)}}>
                                <Small uppercase bold color={c.blue}>{i18n.t(`home.see_all`)}</Small>
                            </LinkSt>
                        }
                    </HeaderSt>
                    <DescriptionContainerSt className ="description">
                        <Medium>{description}</Medium>
                    </DescriptionContainerSt>
                    <ContentSt>
                        <CardsContainerSt ref={this.cardsContainerSt}>
                            <CarouselSt widthEl={cards.length} marginCount={this.state.marginCount} onTouchStart={(e)=>this.swipeSlide(e)} onTouchMove={(e)=>this.swipeSlideEnd(e)}>
                                {allCards}
                            </CarouselSt>
                        </CardsContainerSt>
                        {this.state.leftButtonDisplay && <ButtonSt side="left" onClick={() => this.carouselMoveLeft()}>
                            <ButtonStPadding></ButtonStPadding>
                            <ArrowSt width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.668 3.79967L6.36658 13.9997L16.668 24.1997L13.4966 27.333L0.00131342 13.9997L13.4966 0.66634L16.668 3.79967Z" fill="white"/>
                            </ArrowSt>
                        </ButtonSt>}
                        {this.state.rightButtonDisplay && <ButtonSt side="right" onClick={() => this.carouselMoveRight()}>
                            <ButtonStPadding></ButtonStPadding>
                            <ArrowSt width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.332027 24.2003L10.6334 14.0003L0.332031 3.80032L3.50342 0.666993L16.9987 14.0003L3.50341 27.3337L0.332027 24.2003Z" fill="white"/>
                            </ArrowSt>
                        </ButtonSt>}
                    </ContentSt>
                </ContainerSt>

                    {/*попап с видео*/}
                    {this.state.popupIsOpen
                        ? <Popup
                            handleClick={this.handleClick} >
                            <PopupFrameStyled src={this.state.trailer}/>
                        </Popup>
                        : null
                    }
            </>
        );
    }
}
