import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAIL,
    GET_GENRES_SUCCESS,
  } from "../types";

const initialState = {
    isLoading: false,
    message: '',
    projects: {}
}

export const allProjectsReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_PROJECTS_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case GET_GENRES_SUCCESS:
            return {
                ...state,
                genres: payload,
                isLoading: false
            };
        case GET_PROJECTS_SUCCESS:
            return {
                ...state,
                allProjects: payload,
                isLoading: true
            };
        case GET_PROJECTS_FAIL:
            return {
                ...state,
                message: payload,
                isLoading: false
            };
        default:
            return state;
    }
}