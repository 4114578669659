import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"
import { NavLink } from 'react-router-dom';

export const FooterSt = styled.footer`
    background: ${c.black};
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 40px;
    flex-wrap: wrap;
    margin-top: 60px;
    flex: 0 0 auto;
    @media ${device.tablet} {
        padding: 40px 0 40px;
        margin-top: 50px;
    }
    @media ${device.laptop} {
        padding: 60px 0 60px;
        margin-top: 50px;
    }
`;
export const WrapperSt  = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: baseline;

    @media ${device.desktop} {
        padding-bottom: 0;
        padding-top: 20px;
    }

    @media ${device.laptop} {
        justify-content: space-between;
        padding-top: 0;
        align-items: center;
    }
`;

export const WrapperLogosSt = styled(WrapperSt)`
    justify-content: center;
    width:100%;
    padding-bottom: 35px;
    order: -1;

    @media ${device.laptop} {
        justify-content: space-between;
        width: 60%;
        max-width: 680px;
        padding-top: 0;
        padding-bottom: 0;
        order: initial;
    }
`;

export const  WrapperBottomSt=  styled(WrapperSt)`
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    p { 
        text-align: center;
    }
    @media ${device.laptop} {
        justify-content: flex-end;
        padding-top: 10px;
        p { 
            text-align: left;
        }
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
`;

export const WrapperTextSt = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: ${c.white};
    font-size: 12px;
    line-height: 14px;

    @media ${device.laptop} {
        width: 60%;
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 12px;
        flex-direction: row;
    }
    span {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        text-align: center;
        @media ${device.laptop} {
            text-align: initial;
        }
        
    }
    & > p 
       
        {  
            span {
                font-size: 12px;
                line-height: 14px;
                font-weight: 500
            }
            span, a {
                transition: color .3s;
                &:hover {color: ${c.blue_active} }
            }

            
            &:first-child {
                cursor: pointer;
            }
            
            &:not(:last-child) {
                margin-bottom:23px;
                @media ${device.laptop} {
                    margin-bottom:0;
                }
            
            }
            &:last-child {
                display:flex;
                &:hover {color: red; }
            }
        }
`; 

export const LinkSt = styled(NavLink)`
  color: ${c.white};
  text-decoration: none;
  display: flex;
`;

export const BrSt = styled.br`
    @media ${device.laptop} {
        display:none;
    }
`;