import styled from "styled-components"
import {Link} from "react-router-dom";
import {colors as c, device as d, fonts as f} from "../../styles/variables";
import {Overline as Overline} from  "../../styles/typography/typography";

const backgroundColor = [c.blue_active, c.rose, c.orange]

function backgroundColorGet(color){
    return backgroundColor[(color-1)%3];
}

export const BannerSt = styled.div`
    overflow: hidden;
    position: relative;
    top: 60px;
    width: 0;
    flex-grow: 1;
    height: 79vw;
    @media ${d.mobile} {
        margin-left: ${props => props.marginCount * 42 - (props.widthEl === props.marginCount + 2 && 16)}%;
        ${props => !props.openDesc && "margin-left: 0!important;"}
        ${props => props.openDesc && "position: absolute; width: 100vw; height: 115vw; z-index: 98;"}      
    }
    @media ${d.tablet} {
        top: 0;
    }
    @media ${d.tablet} {
        transition: .5s;
        height: 400px; 
        &:hover{
            flex-grow: 0;
            width: 33vw;
            &>div{
                background: ${c.blue_active};
                &>div{
                   height: 100%;
                    &>p{
                       opacity: 1;
                       height: auto;
                       transition: .2s opacity .5s;
                        div, p{
                          span{
                            color: ${c.white}!important;
                            font-size: 12px!important;
                          }
                        }
                    }
                    &>div{
                        &>a{
                            opacity: 1!important;
                            transition: .2s opacity .4s;
                        }
                    }
                }
            }
        }
    }
    @media ${d.laptop} {
        overflow: hidden;
        &:hover{
            width: calc((100vw * 0.63) / 2);
            ${props => props.discriptionOpenLeft && "margin-right:14vw;"}
        }
    }
    @media (min-width: 1440px) {
         &:hover{
            width: calc(((100vw - (100vw - 1260px)/2) * 0.76) / 2.5);
            ${props => props.discriptionOpenLeft && "margin-right:12vw;"}
         }
      
    }
     @media (min-width: 1920px) {
         &:hover{
            width: calc(((100vw - (100vw - 1260px)/2) * 0.76) / 3);
            ${props => props.discriptionOpenLeft && "margin-right:10vw;"}
            ${props => props.lastCardLeftMargin && "margin-right:9vw;"}
         }
    }
`;

export const MobileClickSt = styled.div`
    @media ${d.mobile} {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 7;
        ${props => props.openDesc && "display: none;"}
    }
`;

export const ImageSt = styled.img`
    display: block;
    position: absolute;
    z-index: 6;
    height: auto;
    width: 100vw;
      @media ${d.tablet} {
        z-index: 0;
        width:33vw;
        height: auto;
      }
    @media ${d.laptop} {
      width: calc((100vw * 0.63) / 2);
    }
  @media (min-width: 1440px) {
    width: calc(((100vw - (100vw - 1260px)/2) * 0.76) / 2.5);
  }
    @media (min-width: 1920px) {
      width: calc(((100vw - (100vw - 1260px)/2) * 0.76) / 3);
    }
`;

export const ContainerSt = styled.div`
    position: absolute;
    z-index: 6;
    bottom: 0;
    width: 100%;
  
    max-width: 100vw;
    height: 53%;
    background: linear-gradient(to top, ${props => props.index && backgroundColorGet(props.index)}, transparent);
    transition: .5s background;
    @media ${d.mobile} {
        ${props=>props.discriptionOpenLeft && "width: 150%;"}
        ${props => props.openDesc && `background: ${c.blue_active};`}
    }
    @media ${d.tablet} {
        height: 45%; 
    }
`;

export const OpenDiscriptionSt = styled.div`
    width: 36px;
    height: 36px;
    background: ${c.white};
    opacity: .6;
    position: absolute;
    z-index: 1;
    right: 0;
    display: none;
    @media ${d.mobile} {
        ${props => props.openDesc && "display: block;"}
    }
`;

export const CenterSvgSt = styled.svg`
       position: absolute;
       margin: auto;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
`;

export const DiscriptionSt = styled.div`
   position: absolute;
   bottom: 0;
   height: 75%;
   width: 100%;
   padding: 15px 10px 20px 20px;
   transition: .5s height;
   @media ${d.mobile} {
        ${props => props.openDesc && `height: 100%`}
   }
   @media ${d.tablet} {
        height: 70%;
   }
`;

export const TitleSt = styled.h4`
    display: inline;
    color: ${c.white};
    font-size: 16px;
    cursor: pointer;
    &:hover{
        border-bottom: 1px solid ${c.white};
    }
    @media ${d.tablet} {
        font-size: 20px;
   }
`;

export const LinkSt = styled(Link)`
    color: ${c.white};
    text-decoration: none;
`;

export const DiscriptionTextSt = styled.p`
    margin-top: 15px;
    padding-right: 10px;
    color: ${c.white};
    opacity: 0;
    height: 0;
    font-size: 12px;
    max-height: 50%;
    overflow: auto;
    &::-webkit-scrollbar {: ;
        width: 0;
    }
    @media ${d.mobile} {
        max-height: 55%;
        ${props => props.openDesc && `opacity: 1; height: auto; transition: .2s opacity .5s;`}
    }
`;

export const DiscriptionBottomSt = styled.div`
    position: absolute;
    width: 100%;
    bottom: 15px;
    display: flex;
    align-items: center;
      img{
        margin-left: 1px;
      }
`;

export const DiscriptionChannelSVGSt = styled.svg`
   transform: scale(.8);
   marginLeft: -12px;
   fill: #ffffff;
`;

export const DiscriptionRARSSt = styled.span`
    font-size: 10px;
    color: #ffffff;
`;

export const DiscriptionDateSt = styled(Overline)`
  color: #fff;
`;

export const DiscriptionPlayVideoSt = styled.a`
   text-decoration: none;
   font-size: 10px;
   color: #ffffff;
   position: absolute;
   right: 40px;
   opacity: 0;
   display: flex;
   align-items: center;
    @media ${d.mobile} {
        ${props => props.openDesc && "opacity: 1!important; transition: .2s opacity .4s;"}
    }
`;

export const HideUnderDescriptionSt = styled.div`
    display: ${props => props.openDesc ? "block" : "none"};
    @media ${d.mobile} {
      width: 100%;
      background: ${c.black};
      position: fixed;
      top: 0;
      height: 100vh;
      opacity: .7;
      z-index: 5;
    }
`;

export const BannerBottomSt = styled.div`
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, black 50%, transparent)!important;
  width: 100%;
  height: 40%;
  @media (min-width: 1440px) {
    background: linear-gradient(to top, black 25%, transparent)!important;
  }
`;