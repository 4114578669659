import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../Button/Button'
import Approval from "../Approval/Approval";
import Select from '../Select/Select'
import SelectMultiple from '../SelectMultiple/SelectMultiple'
import TextField from '../TextField/TextField';
import CheckboxNew from '../CheckboxNew/CheckboxNew'
import Svg from "../../assets/icons/icons"
import { register, login, getRegisterInfo, resetPassword, clearMessages } from '../../store/auth/actions';
import i18n from "i18next";
import { validateEmail, validatePass, emptyField } from "../../services/utils";
import { add } from './add';
import { adaptData, adaptTypeRightData, sortingData } from "../../services/login";
import { H4, Medium } from "../../styles/typography/typography"
import { colors as c } from "../../styles/variables"
import {
    InputWrapStyled,
    InputIconWrapStyled,
    TabContainerStyled,
    ContentStyled,
    FormStyled,
    ListStyled,
    ItemStyled,
    LinkStyle,
    InfoFormStyled,
    RememberBlockStyled,
    TextBlockStyled,
    PopupLoginStyled,
    RowStyled,
    ButtonWrapStyled,
    ErrorStyled,
    ErrorBlockStyled,
} from "./style";


const initialState = {

    removeAllСhoice: false,
    tabIndex: 0,
    isShowPass: false,
    isShowNewPass: false,
    isShowConfirmPass: false,
    isShowRestorePass: false,
    isShowSuccessRePas: false,
    rememberMe: false,
    approval: false,
    approvalError: false,
    email: "",
    password: "",
    formErrors: { password: "", email: "", fullName: "", newEmail: "", confirmPassword: "", country: "", newPassword: "", company: "", genresChosen: "", rightChosen: "", territoryChosen: "", },
    // для работы селектов
    closeAllSelect: false, // контроль закрытия всех селектов

    emailValid: false,
    passwordValid: false,
    formLoginValid: false,

    formRestPasValid: false,


    territoryValid: false,
    rightValid: false,
    genresValid: false,
    formRegValid: false,

    fullName: "",
    newEmail: "",
    country: "",
    company: "",
    genres: "",
    newPassword: "",
    confirmPassword: "",
    loading: false
};

class LogIn extends React.Component {

    state = initialState;

    componentDidMount() {
        this.props.clearMessagesAction();
        this.props.getRegisterInfoAction()
    }

    componentDidUpdate(prevProps) {
        if (this.props.successRequest !== prevProps.successRequest) {
            if (this.props.successRequest) {
                this.setState({ isShowRestorePass: false, isShowSuccessRePas: true })
            }
        }
    }
    // отслеживания изменений в форме регистрации
    handleFieldChange(e) {
        const isCheckbox = e.target.type === "checkbox"
        const name = e.target.name;
        const value = e.target.value

        this.setState(
            {
                [e.target.name]: isCheckbox
                    ? e.target.checked
                    : e.target.value
            },
            () => { this.validateFieldReg(name, value) });

    }

    // отслеживания изменений в форме входа
    handleFieldLoginChange(e) {
        const isCheckbox = e.target.type === "checkbox"
        const name = e.target.name;
        const value = e.target.value

        this.setState(
            {
                [e.target.name]: isCheckbox
                    ? e.target.checked
                    : e.target.value
            },
            () => { this.validateFieldLogin(name, value) });

    }

    // отслеживания изменений в форме сброса пароля
    handleFieldPassResetChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(
            { [name]: e.target.value },
            () => { this.validateFieldPassReset(name, value) }
        );
    }

    validateFieldReg(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;

        let newEmailValid = this.state.emailValid;
        let fullnamelValid = this.state.fullnamelValid;
        let newPasswordValid = this.state.newPasswordValid;
        let confirmPasswordValid = this.state.confirmPasswordValid;
        let companyValid = this.state.companyValid;
        // let positionValid = this.state.positionValid;

        switch (fieldName) {
            case 'fullName':
                fullnamelValid = value.length >= 4;
                fieldValidationErrors.fullName = (value.length < 4 && value.length !== 0) ? i18n.t(`login.fullname_length_less_than_4_characters`) : (value.length === 0) ? i18n.t(`login.fill_in_the_field`) : "";
                break;
            case 'newEmail':
                newEmailValid = validateEmail(value);
                fieldValidationErrors.newEmail = (value === "") ? i18n.t(`login.fill_in_the_field`) : newEmailValid ? "" : i18n.t(`login.invalid_email_format`);
                break;
            case 'newPassword':
                newPasswordValid = validatePass(value);
                fieldValidationErrors.newPassword = (value === "") ? i18n.t(`login.fill_in_the_field`) : newPasswordValid ? "" : i18n.t(`login.password_length_less_than_8_characters`);
                break;
            case 'company':
                companyValid = value.length > 0;
                fieldValidationErrors.company = emptyField(value)
                break;
            // case 'position':
            //     positionValid = value.length > 0;
            //     fieldValidationErrors.position = emptyField(value)
            //     break;
            case 'confirmPassword':
                if (this.state.newPassword !== "" && this.state.newPassword === this.state.confirmPassword) {
                    if (!validatePass(value)) {
                        confirmPasswordValid = false
                        fieldValidationErrors.newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
                    }
                }
                else {
                    fieldValidationErrors.confirmPassword = i18n.t(`login.passwords_do_not_match`) //Пароли не совпадают
                    confirmPasswordValid = false
                }
                if (this.state.newPassword === this.state.confirmPassword) {
                    fieldValidationErrors.confirmPassword = ""
                    confirmPasswordValid = true
                }
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            newEmailValid: validateEmail(this.state.newEmail),
            fullnamelValid: fullnamelValid,
            newPasswordValid: newPasswordValid,
            confirmPasswordValid: confirmPasswordValid,
            companyValid: companyValid,
            // positionValid: positionValid
        }, this.validateRegForm
        );
    }



    updateSelectData = (data, selectName) => {
        const value = sortingData(data);

        let fieldValidationErrors = this.state.formErrors;
        let genresValid = this.state.genresValid;
        let territoryValid = this.state.territoryValid;
        let rightValid = this.state.rightValid;

        this.setState(
            { selectName: value }
        );

        switch (selectName) {
            case 'genres':
                genresValid = value.length >= 1;
                fieldValidationErrors.genresChosen = genresValid ? "" : i18n.t(`login.select_genres_of_interest`);
                break;
            case 'territory':
                territoryValid = value.length >= 1;
                fieldValidationErrors.territoryChosen = territoryValid ? "" : i18n.t(`login.select_the_territory_of_the_rights_of_interest`);
                break;
            case 'right':
                rightValid = value.length >= 1;
                fieldValidationErrors.rightChosen = rightValid ? "" : i18n.t(`login.select_type_of_rights`);
                break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                genresValid: genresValid,
                rightValid: rightValid,
                territoryValid: territoryValid,
            }, this.validateRegForm
        );
        this._closeAllSelect()
    }


    validateRegForm() {
        this.setState({
            formRegValid:
                this.state.fullnamelValid &&
                this.state.newEmailValid &&
                this.state.newPasswordValid &&
                this.state.confirmPasswordValid &&
                this.state.companyValid &&
                // this.state.positionValid &&
                this.state.genresValid &&
                this.state.rightValid &&
                this.state.territoryValid
        });
    }


    // валидация формы входа изменении поля
    validateFieldLogin(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;
        switch (fieldName) {
            case 'email':
                emailValid = validateEmail(value)
                fieldValidationErrors.email = (value === "") ? i18n.t(`login.fill_in_the_field`) : emailValid ? "" : i18n.t(`login.invalid_email_format`);
                break;
            case 'password':
                passwordValid = value.length >= 1;
                fieldValidationErrors.password = passwordValid ? "" : i18n.t(`login.fill_in_the_field`);
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            passwordValid: passwordValid
        }, this.validateLoginForm);
    }

    validateLoginForm() {
        this.setState({ formLoginValid: this.state.emailValid && this.state.passwordValid });
    }

    // валидация формы сброса пароля
    validateFieldPassReset(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        switch (fieldName) {
            case 'email':
                emailValid = validateEmail(value)
                fieldValidationErrors.email = (!value) ? i18n.t(`login.fill_in_the_field`) : emailValid ? "" : i18n.t(`login.invalid_email_format`);
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            formRestPasValid: emailValid,

        });
    }

    // кнопка "Забыли пароль"/ Вернуться к входу - переход между вкладками восстановления пароля
    toResetPassword(e) {
        e.preventDefault();
        this.setState({
            isShowRestorePass: !this.state.isShowRestorePass,
            formErrors: { password: '', email: '', fullName: '', newEmail: '', confirmPassword: '', country: '', newPassword: '', company: '', genresChosen: '', rightChosen: '', territoryChosen: '', },
        })
        this.props.clearMessagesAction()
    }


    // переключения по вкладкам вход/ регистрация
    handleToggleTabs(index) {
        this.setState({
            tabIndex: index,
            formErrors: { password: "", email: "", fullName: "", newEmail: "", confirmPassword: "", country: "", newPassword: "", company: "", genresChosen: "", rightChosen: "", territoryChosen: "", },
        })
        this.props.clearMessagesAction()
    }

    handleLogInSubmit(e) {
        e.preventDefault();
        const isValid = this.validateLogIn();
        add()
        if (isValid) {
            this.setState(
                {
                    formErrors: {
                        email: "",
                        password: "",
                    }
                });
            this.props.loginAction({
                email: this.state.email,
                password: this.state.password
            })
        }
        else {
            this.setState({
                loading: false,
            });
        }
    }


    // валидация всех полей формы входа
    validateLogIn = () => {
        let [email, password] = ["", ""];
        const isValidateEmail = validateEmail(this.state.email);

        email = (!this.state.email)
            ? i18n.t(`login.fill_in_the_field`) //Заполните поле 
            : (this.state.email && !isValidateEmail)
                ? i18n.t(`login.invalid_email_format`) //Неверный формат e-mail
                : ""

        password = (!this.state.password) ? i18n.t(`login.fill_in_the_field`) : "" //Заполните поле

        if (email || password) {
            this.setState(
                {
                    formErrors: {
                        email: email,
                        password: password,
                    }
                });
            return false
        }
        return true
    }


    handleRestorePassSubmit(e) {
        e.preventDefault();
        const isValidateResetPass = this.validateResetPass();
        if (isValidateResetPass) {
            this.setState({ formErrors: { email: "" } });
            this.props.resetPasswordAction({ email: this.state.email })
        }
    }

    // удалить после реализации кнопки disabled
    validateResetPass = () => {
        let email = "";
        const isValidateEmail = validateEmail(this.state.email);

        email = (!this.state.email)
            ? i18n.t(`login.fill_in_the_field`) //Заполните поле 
            : (this.state.email && !isValidateEmail)
                ? i18n.t(`login.invalid_email_format`) //Неверный формат e-mail
                : ""

        if (email) {
            this.setState(
                {
                    formErrors: {
                        email: email,
                    }
                });
            return false
        }

        return true

    }

    validateReg = () => {
        const isValidateEmail = validateEmail(this.state.newEmail);
        const isValidatePass = validatePass(this.state.newPassword);

        const genres = document.querySelector('input[name="genres"]').value;
        const right = document.querySelector('input[name="right"]').value;
        const territory = document.querySelector('input[name="territory"]').value;

        let [fullName, newEmail, country, company, newPassword, confirmPassword, genresChosen, rightChosen, territoryChosen] = ["", "", "", "", "", "", "", "", "", ""];

        fullName = (!this.state.fullName) ? i18n.t(`login.fill_in_the_field`) : "";  //Заполните поле
        newEmail = (!this.state.newEmail)
            ? i18n.t(`login.fill_in_the_field`) //Заполните поле 
            : (this.state.newEmail && !isValidateEmail)
                ? i18n.t(`login.invalid_email_format`) //Неверный формат e-mail
                : ""

        company = (!this.state.company) ? i18n.t(`login.fill_in_the_field`) : "";  //Заполните поле
        // position = (!this.state.position) ? i18n.t(`login.fill_in_the_field`) : "";  //Заполните поле

        // fullName = (!this.state.position) ? i18n.t(`login.fill_in_the_field`) : "";  //Заполните поле
        country = (!this.state.country) ? i18n.t(`login.choose_the_country`) : "";  //Заполните поле

        newPassword = (!this.state.newPassword)
            ? i18n.t(`login.choose_the_country`)
            : (this.state.newPassword && !isValidatePass)
                ? i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
                : "";

        if (this.state.newPassword && !isValidatePass) {
            newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
        }

        if (this.state.newPassword && this.state.newPassword === this.state.confirmPassword) {
            if (!isValidatePass) {
                newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
            }
        }
        else {
            confirmPassword = i18n.t(`login.passwords_do_not_match`) //Пароли не совпадают
        }

        rightChosen = (right.length === 0) ? i18n.t(`login.select_type_of_rights`) : ""; //Выберите вид прав
        genresChosen = (genres.length === 0) ? i18n.t(`login.select_genres_of_interest`) : ""; //Выберите интересующие жанры
        territoryChosen = (territory.length === 0) ? i18n.t(`login.select_the_territory_of_the_rights_of_interest`) : ""; //Выберите территорию интересующих прав

        if (fullName || company || country || newEmail || newPassword || confirmPassword || genresChosen) {
            this.setState({
                formErrors: {
                    fullName: fullName,
                    newEmail: newEmail,
                    newPassword: newPassword,
                    company: company,
                    country: country,
                    // position: position,
                    confirmPassword: confirmPassword,
                    genresChosen: genresChosen,
                    rightChosen: rightChosen,
                    territoryChosen: territoryChosen,
                }
            });
            return false
        }

        return true
    }

    // вкладка регистрация отправка данных
    handleRegSubmit(e) {
        e.preventDefault();
        const isValidateReg = this.validateReg();

        const genres = document.querySelector('input[name="genres"]').value.split(',');
        const right = document.querySelector('input[name="right"]').value.split(',');
        const territory = document.querySelector('input[name="territory"]').value.split(',');

        if (!this.state.approval) {
            this.state.approvalError = true;
        }

        if (isValidateReg && this.state.approval) {
            this.setState({
                formErrors: {
                    fullName: "",
                    newEmail: "",
                    confirmPassword: "",
                    country: "",
                    newPassword: "",
                    company: "",
                    genresChosen: "",
                    rightChosen: "",
                    territoryChosen: "",
                }
            });
            this.props.registerAction({
                full_name: this.state.fullName,
                position: " - ",
                email: this.state.newEmail,
                country_id: this.state.country,
                company_name: this.state.company,
                password: this.state.newPassword,
                password_confirmation: this.state.confirmPassword,
                rights: right,
                countries: territory,
                genres: genres,
            })
        }
    }

    // при открытии второго селектора закрываем первый
    _closeAllSelect() {
        this.setState(() => {
            return { closeAllSelect: true }
        })
        setTimeout(() => {
            this.setState(() => {
                return { closeAllSelect: false }
            })
        }, 50)
    }


    showSearchingResults(x) {
        this.setState(() => {
            return {
                country: x,
            }
        });
    }

    // Согласие на обработку персональных данных
    approvalCheck () {
        this.setState(()=> {
            return {
                approval: !this.state.approval
            }
        })
        this.state.approvalError = false;
    }

    render() {
        //Вид прав
        const typeRightsArr = this.props.register_info.rights;
        const typeRightsArrAdapt = typeRightsArr.map((typeRight) => adaptTypeRightData(typeRight));
        const typeRightsList = [i18n.t(`login.type_of_rights`), typeRightsArrAdapt]
        // Территория интересующих прав
        const countriesArr = this.props.register_info.countries;
        const countriesArrAdapt = countriesArr.map((country) => adaptData(country));
        const territoryRightsList = [i18n.t(`login.territory_of_the_rights_of_interest`), countriesArrAdapt]
        // Интересующие жанры
        const genresArr = this.props.register_info.genres;
        const genresArrAdapt = genresArr.map((genre) => adaptData(genre));
        const genresList = [i18n.t(`login.select_genres_of_interest*`), genresArrAdapt]
        const tabsItems = [
            { title: i18n.t(`login.login`) },
            { title: i18n.t(`login.registration`) },
        ];
        const countryList = [i18n.t(`login.country`), countriesArrAdapt];
        const serverErrors = Object.values(this.props.errors);

        const viewType = this.props.viewType;


        /* вкладка восстановление пароля */
        const contentForTabRecoveryPass =
            <FormStyled
                onSubmit={(e) => this.handleRestorePassSubmit(e)}
                action="#" method="post"
            >
                <InfoFormStyled> {i18n.t(`login.inform.${0}.text1`)} <br /> {i18n.t(`login.inform.${0}.text2`)}
                    <br /> {i18n.t(`login.inform.${0}.text3`)}
                </InfoFormStyled>
                <RowStyled>
                    <InputWrapStyled>
                        <TextField
                            handleFieldChange={(e) => this.handleFieldPassResetChange(e)}
                            error={this.state.formErrors.email}
                            type="email"
                            value={this.state.email}
                            name="email"
                            className={this.state.emailValid ? 'valid' : this.state.formErrors.email !== "" ? "invalid" : ""}
                            placeholder={i18n.t(`login.your_email`)}
                        />
                    </InputWrapStyled>
                </RowStyled>
                <ButtonWrapStyled className="restore">
                    <Button as="a" href="/" label={i18n.t(`login.restore_password`)} size="big" width="100%" type="submit" />
                </ButtonWrapStyled>
                <TextBlockStyled>
                    <LinkStyle
                        onClick={(e) => this.toResetPassword(e)}
                        color={c.blue_active}>{i18n.t(`login.back_to_entrance`)}
                    </LinkStyle>
                </TextBlockStyled>
                {/* Здесь сообщение об ошибке */}
                <ErrorBlockStyled>
                    <ErrorStyled> {this.props.message}</ErrorStyled>
                    {serverErrors.map((error) => (
                        <ErrorStyled> {error}</ErrorStyled>
                    ))}
                </ErrorBlockStyled>
            </FormStyled>

        // вкладка Входа 
        const contentForTabLogin =
            <FormStyled
                onSubmit={(e) => this.handleLogInSubmit(e)}
                action="#" method="post"
            >
                <RowStyled>
                    <InputWrapStyled>
                        <TextField
                            handleFieldChange={(e) => this.handleFieldLoginChange(e)}
                            type="email"
                            name="email"
                            placeholder="Email"
                            error={this.state.formErrors.email}
                            className={this.state.emailValid ? 'valid' : this.state.formErrors.email !== "" ? "invalid" : ""}
                            primary
                            value={this.state.email}
                        />
                    </InputWrapStyled>
                </RowStyled>
                <RowStyled>
                    <InputWrapStyled>
                        <TextField
                            handleFieldChange={(e) => this.handleFieldLoginChange(e)}
                            error={this.state.formErrors.password}
                            className={this.state.password ? 'valid' : this.state.formErrors.password !== "" ? "invalid" : ""}
                            type={this.state.isShowPass ? "text" : "password"}
                            name="password"
                            placeholder={i18n.t(`login.password`)}
                            value={this.state.password}
                        />
                        <InputIconWrapStyled onClick={() => this.setState({ isShowPass: !this.state.isShowPass })}>
                            <Svg type={this.state.isShowPass ? "visibility" : "visibility_off"} />
                        </InputIconWrapStyled>
                    </InputWrapStyled>
                </RowStyled>
                <RememberBlockStyled className="RememberBlockStyled" onClick={() => this.setState({ rememberMe: !this.state.rememberMe })}>
                    <CheckboxNew
                        name="rememberMe"
                        checked={this.state.rememberMe}
                    />
                    <Medium>{i18n.t(`login.remember_me`)}</Medium>
                </RememberBlockStyled>
                <Button as="a" href="/" label={i18n.t(`login.login_button`)} size="big" type="submit" width="100%" />
                <TextBlockStyled
                    onClick={(e) => this.toResetPassword(e)}
                    className="toResetPassword"
                >
                    <LinkStyle color={c.blue_active}>{i18n.t(`login.forgot_your_password`)}</LinkStyle>
                </TextBlockStyled>
                {/* Здесь сообщение об ошибке */}
                <ErrorBlockStyled>
                    <ErrorStyled> {this.props.message}</ErrorStyled>
                    {serverErrors.map((error) => (
                        <ErrorStyled> {error}</ErrorStyled>
                    ))}
                </ErrorBlockStyled>
            </FormStyled>

        /* вкладка регистрации */
        const contentForTabReg =
            <FormStyled
                onSubmit={(e) => this.handleRegSubmit(e)}
                action="#" method="post"
                style={{ minHeight: '40vh' }}
            >
                <RowStyled>
                    <TextField
                        handleFieldChange={(e) => this.handleFieldChange(e)}
                        type="text"
                        name="fullName"
                        placeholder={i18n.t(`login.full_name`)}
                        minLength="4"
                        maxLength="50"
                        error={this.state.formErrors.fullName}
                        className={this.state.fullnamelValid ? "valid" : this.state.formErrors.fullName !== "" ? "invalid" : ""}
                    />
                </RowStyled>

                <RowStyled>
                    <InputWrapStyled className="half">
                        <TextField
                            handleFieldChange={(e) => this.handleFieldChange(e)}
                            name="newEmail"
                            type="email"
                            placeholder="Email*"
                            error={this.state.formErrors.newEmail}
                            className={this.state.newEmailValid ? "valid" : this.state.formErrors.newEmail !== "" ? "invalid" : ""}
                        />
                    </InputWrapStyled>
                    <InputWrapStyled className="half">
                        <Select
                            primary
                            content={countryList}
                            className={this.state.formErrors.country !== "" ? "invalid" : ""}
                            error={this.state.formErrors.country}
                            name="country"
                            selectWidth="true"
                            _showSearchingResults={(x) => this.showSearchingResults(x)}
                        />
                    </InputWrapStyled>
                </RowStyled>
                <RowStyled>
                    <InputWrapStyled className="half">
                        <TextField
                            className={(this.state.company !== "") ? "valid" : this.state.formErrors.company !== "" ? "invalid" : ""}
                            error={this.state.formErrors.company}
                            handleFieldChange={(e) => this.handleFieldChange(e)}
                            type="text"
                            name="company"
                            maxLength="100"
                            placeholder={i18n.t(`login.сompany`)}
                        />
                    </InputWrapStyled>
                    {/*<InputWrapStyled className="half">*/}
                    {/*    <TextField*/}
                    {/*        className={(this.state.position !== "") ? "valid" : this.state.formErrors.position !== "" ? "invalid" : ""}*/}
                    {/*        error={this.state.formErrors.position}*/}
                    {/*        handleFieldChange={(e) => this.handleFieldChange(e)}*/}
                    {/*        type="text"*/}
                    {/*        name="position"*/}
                    {/*        maxLength="100"*/}
                    {/*        placeholder={i18n.t(`login.position`)}*/}
                    {/*    />*/}
                    {/*</InputWrapStyled>*/}
                </RowStyled>
                <RowStyled>
                    <InputWrapStyled className="half">
                        <TextField
                            handleFieldChange={(e) => this.handleFieldChange(e)}
                            type={this.state.isShowNewPass ? "text" : "password"}
                            name="newPassword"
                            placeholder={i18n.t(`login.create_password`)}
                            error={this.state.formErrors.newPassword}
                            maxLength="30"
                            className={this.state.newPasswordValid ? "valid" : this.state.formErrors.newPassword !== "" ? "invalid" : ""}
                        />
                        <InputIconWrapStyled onClick={() => this.setState({ isShowNewPass: !this.state.isShowNewPass })}>
                            <Svg type={this.state.isShowNewPass ? "visibility" : "visibility_off"} />
                        </InputIconWrapStyled>
                    </InputWrapStyled>
                    <InputWrapStyled className="half" >
                        <TextField
                            handleFieldChange={(e) => this.handleFieldChange(e)}
                            type={this.state.isShowConfirmPass ? "text" : "password"}
                            name="confirmPassword"
                            placeholder={i18n.t(`login.confirm_password`)}
                            maxLength="30"
                            error={this.state.formErrors.confirmPassword}
                            className={this.state.confirmPasswordValid ? "valid" : this.state.formErrors.confirmPassword !== "" ? "invalid" : ""}
                        />
                        <InputIconWrapStyled onClick={() => this.setState({ isShowConfirmPass: !this.state.isShowConfirmPass })}>
                            <Svg type={this.state.isShowConfirmPass ? "visibility" : "visibility_off"} />
                        </InputIconWrapStyled>
                    </InputWrapStyled>
                </RowStyled>
                {/* Территория интересующих прав* */}
                <RowStyled>
                    <SelectMultiple
                        updateData={this.updateSelectData}
                        closeAllSelect={this.state.closeAllSelect}
                        _closeAllSelect={() => this._closeAllSelect()}
                        validateReg={this.validateReg}
                        primary
                        content={territoryRightsList}
                        name="territory"
                        selectWidth="true"
                        error={this.state.formErrors.territoryChosen}
                        className={this.state.territoryValid ? "valid" : this.state.formErrors.territoryChosen !== "" ? "invalid" : ""}
                    />
                </RowStyled>
                {/* вид прав */}
                <RowStyled>
                    <SelectMultiple
                        updateData={this.updateSelectData}
                        closeAllSelect={this.state.closeAllSelect}
                        _closeAllSelect={() => this._closeAllSelect()}
                        primary
                        content={typeRightsList}
                        name="right"
                        selectWidth="true"
                        error={this.state.formErrors.rightChosen}
                        className={this.state.rightValid ? "valid" : this.state.formErrors.rightChosen !== "" ? "invalid" : ""}
                    />
                </RowStyled>
                <RowStyled>
                    <SelectMultiple
                        handleFieldChange={(e) => this.handleFieldChange(e)}
                        updateData={this.updateSelectData}
                        viewType={viewType}
                        _closeAllSelect={() => this._closeAllSelect()}
                        closeAllSelect={this.state.closeAllSelect}
                        primary
                        content={genresList}
                        position="top"
                        name="genres"
                        selectWidth="true"
                        error={this.state.formErrors.genresChosen}
                        className={this.state.genresValid ? "valid" : this.state.formErrors.genresChosen !== "" ? "invalid" : ""}
                    />
                </RowStyled>

                {/* подтверждение согласия на обработку персональных данных */}
                <Approval
                    checked={this.state.approval}
                    approvalError={this.state.approvalError}
                    link={'reg'}
                    approvalCheck={()=>this.approvalCheck()}
                />

                <ButtonWrapStyled>
                    <Button label={i18n.t(`login.register`)} size="big" type="submit" />
                </ButtonWrapStyled>
                {/* Здесь сообщение об ошибке */}
                <ErrorBlockStyled>
                    <ErrorStyled> {this.props.message}</ErrorStyled>
                    {serverErrors.map((error) => (
                        <ErrorStyled> {error}</ErrorStyled>
                    ))}
                </ErrorBlockStyled>
            </FormStyled>

        return (
            <>
                <PopupLoginStyled>
                    <ListStyled>
                        {tabsItems.map((item, i) => (
                            <ItemStyled
                                key={i}
                                className={this.state.tabIndex === i ? "active" : ""}
                                onClick={() => this.handleToggleTabs(i)}
                            >
                                <H4 color={this.state.tabIndex === i ? "" : c.blue_active}>{item.title}</H4>
                            </ItemStyled>
                        ))}
                    </ListStyled>

                    <TabContainerStyled>
                        <ContentStyled className={this.state.tabIndex === 0 ? "opened" : "closed"}>
                            {!this.state.isShowRestorePass && !this.state.isShowSuccessRePas
                                ? contentForTabLogin // вкладка Входа 
                                : this.state.isShowRestorePass
                                    ? contentForTabRecoveryPass // вкладка восстановление пароля 
                                    :
                                    // Успешная отправка сброса пароля на email 
                                    <FormStyled>
                                        <InfoFormStyled>{i18n.t(`login.password_reset_link`)}</InfoFormStyled>
                                    </FormStyled>
                            }
                        </ContentStyled>
                        {/* вкладка Регистрации */}
                        <ContentStyled
                            onClick={() => this.props.clearMessagesAction()}
                            className={this.state.tabIndex === 1 ? "opened" : "closed"}>
                            {this.props.loadingRegisterInfo ?
                               // верстка вкладки регистрация
                               contentForTabReg
                                : 'Loading'}
                        </ContentStyled>
                    </TabContainerStyled>
                </PopupLoginStyled>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        message: store.auth.message,
        errors: store.auth.errors,
        successRequest: store.auth.successRequest,
        register_info: store.auth.register_info,
        loadingRegisterInfo: store.auth.loading_register_info
    }
}
const mapDispatchToProps = dispatch => {
    return {
        registerAction: userInfo => dispatch(register(userInfo)),
        loginAction: userInfo => dispatch(login(userInfo)),
        getRegisterInfoAction: () => dispatch(getRegisterInfo()),
        resetPasswordAction: email => dispatch(resetPassword(email)),
        clearMessagesAction: () => dispatch(clearMessages())
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));