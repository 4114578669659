import styled from "styled-components"
import { colors, fonts, device } from "../../styles/variables.js"
import {NavLink} from "react-router-dom";

export const PprojectStyled = styled.div`
  padding-top: 50px;      
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &.profile {
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      padding-top: 0px;
    }
  }
`;

export const PprojectImageStyled = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  padding-right: 30px;
  padding-top: 76px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
  &.profile {
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      margin-top: 40px;
    }
  }
`;

export const PprojectWrapEmailStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  position: relative;
    svg{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
`;

export const PprojectEmailStyled = styled.a`
  text-decoration: none;
  color: ${colors.blue_active};
  font-family: ${fonts.font_family};
  position: relative;
  margin-left: 30px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  font-weight: ${fonts.bold};
  display: inline;

  &:hover {
    cursor: pointer;
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      border-top: 1px solid ${colors.blue_active};
    }
  }
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-weight: ${fonts.medium};
    font-size: 10px;
    line-height: 10px;
  }
`;

export const PprojectLeftStyled = styled.div`
  width: 32%;
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    
    width: 100%;
    display: flex;
    justify-content: center;
    order: 1;
    border-top: 1px solid ${colors.grey_light};
    border-bottom: 1px solid ${colors.grey_light};
    margin: 30px 0;
  }
`;

export const PprojectRightStyled = styled.div`
  width: 68%;
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 100%; 
  }
`;

export const MenuStyled = styled.ul`
  display: flex;
  padding-bottom: 50px;
  position: relative;
  overflow-y: auto; 

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    padding-bottom: 25px;
    justify-content: space-between;
    max-width: 425px;
  }
`;

export const MenuStyledVideo = styled(MenuStyled)`
  padding-bottom: 0px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    padding-bottom: 0px;
  }
`;

export const MenuItemStyled = styled.li`
  white-space: nowrap;

  /*от ...  до 1023 px*/
  @media ${device.laptop} {
    font-size: 20px;
    line-height: 20px;
  }
  list-style: none;
  display: block;
  cursor: pointer;
  color: ${colors.blue_active};
  font-family: ${fonts.font_family};
  font-weight: ${fonts.bold};
  font-size: 17px;
  line-height: 17px;
  position: relative;
  margin-right: 5%;
  
  &:last-child {
    margin-right: 0;
  }  
  
  &:hover {
    color: ${colors.black_text};
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      border-top: 1px solid ${colors.black_text};
    }
  }
  &.not {
    display: none;
  }

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 12px;
    line-height: 12px;
    margin-right: 5px;
  }
`;

export const LinkStyled = styled(NavLink)`
  white-space: nowrap;
  text-decoration: none;

  /*от ...  до 1023 px*/
  @media ${device.laptop} {
    font-size: 20px;
    line-height: 20px;
  }
  list-style: none;
  display: block;
  cursor: pointer;
  color: ${colors.blue_active};
  font-family: ${fonts.font_family};
  font-weight: ${fonts.bold};
  font-size: 17px;
  line-height: 17px;
  position: relative;
  margin-right: 5%;
  
  &:last-child {
    margin-right: 0;
  }  
  
  &:hover {
    color: ${colors.black_text};
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      border-top: 1px solid ${colors.black_text};
    }
  }
  &.not {
    display: none;
  }

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 12px;
    line-height: 12px;
    margin-right: 5px;
  }
`;

export const MenuItemStyledActive = styled(MenuItemStyled)`  
  color: ${colors.black_text};
  &::after {
    content: "";
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    border-top: 1px solid ${colors.black_text};
  }
`;


export const TabsSmallHeader = styled(MenuItemStyled)`  
  color: ${colors.black_text};
  cursor: default;
  &:hover {
    &::after {
      border-top: 0;
    }
  }
`;

export const TabsWrap = styled.div`
  color: ${colors.black_text};
`;

export const TabsWrapItem = styled.div`
  color: ${colors.black_text};
`;

export const ContainerForToggleViewStyled = styled.li`
  z-index: 2;
  list-style: none;
  display: block;
  position: absolute;
  right: 0;
`;

export const ImgTopStyled = styled.img`
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
    width: 104%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2%;
    right: 0;
    object-fit: cover;
  }
`;
export const WrapImgMobStyled = styled.div`
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    position: relative;
    display: block;
    margin-top: 60px;
    height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid ${colors.grey_light}
  }
`;
export const DivStyled = styled.div`
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 17px;
    width: 140px;
    display: flex;
    align-items: center;
    a {
       margin-left: 5px; 
    }
  }
`;

export const PprofileLeftStyled = styled.div`
  /*от 0 до 767 px*/
  @media ${device.mobile} {
  }
`;

export const PprofileImgStyled = styled.img`
  /*от 0 до 767 px*/
  @media ${device.mobile} {
  }
`;
