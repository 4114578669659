import React from 'react';
import { H4 } from "../../styles/typography/typography.js";
import { colors} from "../../styles/variables.js"
import {
  AdditionalWrapStyled,
  AdditionalContainerStyled,
  AdditionalTextStyled, 
  AdditionalLogo,
  AdditionalDownloadStyled,
  AdditionalDownloadWrap,
  VideoWrapStyled,
  VideoPreviewStlyed,
  VideoPlayStlyed,
  VideoTextStlyed,
} from './style.js';
import i18n from "i18next";


class TabsAdditional extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 0
    };
  }
  
  render() {

    const {extraImg, extraVideos, extraMaterials} = this.props
    
    return (
      <React.Fragment>

        {/*кадры*/}
        {extraImg.length > 0 && <><H4 color="black_text">{i18n.t(`catalog.project.${0}.personnel`)}</H4>
          <AdditionalWrapStyled>
            <AdditionalContainerStyled>
            {extraImg && extraImg.map((cadre, index) =>
                <AdditionalDownloadWrap>
                  <img src={cadre.media} width="160" height="90" key={index}  />
                  <AdditionalDownloadStyled href={cadre.download_url} />
                </AdditionalDownloadWrap>
              )}
            </AdditionalContainerStyled>
          </AdditionalWrapStyled>
        </>}

        {/*видео*/}
        {extraVideos.length > 0 && <><H4 color="black_text">{i18n.t(`catalog.project.${0}.video`)}</H4>
          <AdditionalWrapStyled>
            <AdditionalContainerStyled>
              {extraVideos[0] && extraVideos[0].map((item)=>
                <VideoWrapStyled>
                  <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                  {/*<VideoPlayStlyed width="60" height="60" viewBox="0 0 80 79" fill="none" >*/}
                  {/*  <g opacity="0.6"><circle cx="40.2303" cy="39.2323" r="32.6932" fill="white"/></g>*/}
                  {/*  <path d="M51.6728 40.0491L35.3262 50.6744L35.3262 29.4238L51.6728 40.0491Z"/>*/}
                  {/*</VideoPlayStlyed>*/}
                  <VideoTextStlyed>
                      {item.title} | {item.duration_text}
                  </VideoTextStlyed>
                  <AdditionalDownloadStyled href={item.download_url} />
                </VideoWrapStyled>
              )}
              {extraVideos[1] && extraVideos[1].map((item)=>
                  <VideoWrapStyled>
                    <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                    <VideoTextStlyed>
                      {item.title} | {item.duration_text}
                    </VideoTextStlyed>
                    <AdditionalDownloadStyled href={item.download_url} />
                  </VideoWrapStyled>
              )}
              {extraVideos[2] && extraVideos[2].map((item)=>
                  <VideoWrapStyled>
                    <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                    <VideoTextStlyed>
                      {item.title} | {item.duration_text}
                    </VideoTextStlyed>
                    <AdditionalDownloadStyled href={item.download_url} />
                  </VideoWrapStyled>
              )}
            </AdditionalContainerStyled>
          </AdditionalWrapStyled>
        </>}

        {/*файлы*/}
        {extraMaterials.length > 0 && <><H4 color="black_text">{i18n.t(`catalog.project.${0}.materials`)}</H4>
          {extraMaterials.map((materials, index) =>
              <AdditionalTextStyled href={materials.download_url} key={index}>
                <AdditionalLogo width="24" height="24" viewBox="0 0 24 24">
                  <use xlinkHref="../images/sprite.svg#download"  fill={colors.blue_active}></use>
                </AdditionalLogo>
                {materials.name}
              </AdditionalTextStyled>
            )
          }
        </>}

      </React.Fragment>
    );
  }
}

export default TabsAdditional;
