import {
    STORAGE_KEY,
    HOST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REGISTER_INFO_REQUEST,
    REGISTER_INFO_SUCCESS,
    REFRESH_TOKEN,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CLEAR_MESSAGES,
} from '../types'
import { getFavotitesInfo } from '../favorites/actions';
import { checkStatus } from '../../services/utils';
import i18n from "i18next"

export const register = (user) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/register`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then(
                    response => response.json()
                )
                .then(
                    (data) => {
                        if (!data.user) {
                            let err = new Error(data.message);
                            err.errors = data.errors;
                            throw err;
                        }
                        localStorage.setItem(STORAGE_KEY, JSON.stringify(data.user));
                        localStorage.setItem('dataToken', JSON.stringify({
                            token: data.token,
                            token_type: data.token_type,
                            token_validity: data.token_validity
                        }));
                        dispatch({
                            type: REGISTER_SUCCESS,
                            payload: data
                        })
                        return data
                    })
                .then(() => {
                    if (window.location.pathname === '/auth') {
                        window.history.back()
                    }
                })
                .catch((dataErr) => {
                    let message;
                    if (dataErr.errors && dataErr.message) {
                        message = { message: dataErr.message, errors: dataErr.errors }
                    } else if (dataErr.message) {
                        message = { message: dataErr.message, errors: {} }
                    } else {
                        message = {
                            message: (message.response &&
                                message.response.data &&
                                message.response.data.message) ||
                                message.message ||
                                message.toString(),
                            errors: {}
                        }
                    }

                    dispatch({
                        type: REGISTER_FAIL,
                        payload: message
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const login = (user) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(user)
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.token) {
                        throw new Error(data.message)
                    }
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(data.user));
                    localStorage.setItem('dataToken', JSON.stringify({
                        token: data.token,
                        token_type: data.token_type,
                        token_validity: data.token_validity
                    }));

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: data
                    })
                    dispatch(getFavotitesInfo(data.token))
                    // dispatch({
                    //     type: FAVORITES_INFO_SUCCESS,
                    //     payload: {
                    //         collection: data.user.favourites.collection,
                    //         event: data.user.favourites.event,
                    //         project: data.user.favourites.project,
                    //     }
                    // })
                    return data;
                })
                .then(() => {
                    if (window.location.pathname === '/auth') {
                        window.history.back()
                    }
                })
                .catch((message) => {
                    let errors
                    if (message) {
                        errors = message
                    }
                    else {
                        errors = (message.response &&
                            message.response.data &&
                            message.response.data.message) ||
                            message.message ||
                            message.toString() || 'Errors'
                    }

                    dispatch({
                        type: LOGIN_FAIL,
                        payload: message
                    })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem(STORAGE_KEY)
    localStorage.removeItem('dataToken')
    // if (window.location.pathname === '/profile') {
    //     window.location.assign('/')
    // }
    dispatch({
        type: LOGOUT
    });
};

export const getRegisterInfo = () => {
    return dispatch => {
        try {
            dispatch({
                type: REGISTER_INFO_REQUEST,
            })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/register-info`)
                .then(checkStatus)
                .then(data => {
                    // if (!data.countries) {
                    //     throw new Error('');
                    // }
                    dispatch({
                        type: REGISTER_INFO_SUCCESS,
                        payload: data
                    })
                    return data;
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    dispatch({
                        type: REGISTER_FAIL,
                        payload: message
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}
export const refreshToken = (token) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/refresh`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(token)
            })
                .then(
                    response => response.json()
                )
                .then(data => {
                    if (!data.token) {
                        throw new Error()
                    }
                    localStorage.setItem("dataToken", JSON.stringify(data));
                    dispatch({
                        type: REFRESH_TOKEN,
                        payload: data
                    })
                    return data;
                })
                .catch((message) => {
                    let errors
                    if (message) {
                        errors = message
                    }
                    else {
                        errors = (message.response &&
                            message.response.data &&
                            message.response.data.message) ||
                            message.message ||
                            message.toString() || 'Errors'
                    }

                    dispatch({
                        type: LOGOUT,
                        payload: "Авторизуйтесь снова"
                    })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGOUT,
                payload: "Авторизуйтесь снова"
            })
            return Promise.reject();
        }
    }
}

export const resetPassword = (email) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/password/email`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(email)
            })
                .then(checkStatus)
                .then(
                    (data) => {
                        dispatch({
                            type: RESET_PASSWORD_SUCCESS,
                            payload: data 
                        })
                        return data
                    })
                .catch((err) => {
                    let errors;
                    if (err.errors && err.message) {
                        errors = { message: err.message, errors: err.errors }
                    } else if (err.message) {
                        errors = { message: err.message, errors: {} }
                    } else {
                        errors = {
                            message: (err.response &&
                                err.response.data &&
                                err.response.data.message) ||
                                err.message ||
                                err.toString(),
                            errors: {}
                        }
                    }
                    dispatch({
                        type: RESET_PASSWORD_FAIL,
                        payload: errors
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const resetPasswordSecondStep = (token, email, newPassword, confirmPassword) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/password/reset`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "token": token,
                    "email": email, 
                    "password": newPassword,
                    "password_confirmation": confirmPassword
                })
            })
                .then(checkStatus)
                .then(
                    (data) => {
                        dispatch(login({ email: email, password: newPassword }))
                        return data
                    }).catch((err) => {
                        let errors;
                        if (err.errors && err.message) {
                            errors = { message: err.message, errors: err.errors }
                        } else if (err.message) {
                            errors = { message: err.message, errors: {} }
                        } else {
                            errors = {
                                message: (err.response &&
                                    err.response.data &&
                                    err.response.data.message) ||
                                    err.message ||
                                    err.toString(),
                                errors: {}
                            }
                        }
                        dispatch({
                            type: RESET_PASSWORD_FAIL,
                            payload: errors
                        })

                    })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const clearMessages = () => {
    return {
        type: CLEAR_MESSAGES
    }
}