import React from "react";
import Svg from "../../assets/icons/icons"
import {colors} from "../../styles/variables.js"
import { H4 } from "../../styles/typography/typography"
import {
  CardStyled,
  CardImageStyled,
  CardTextStyled,
  WrapStyle,
  CardsmallTextStyled,
  CardWrapStyled
} from "./style";

import {
  PprojectWrapEmailStyled,
  PprojectEmailStyled,
} from '../Tabs/style.js';

class Header extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          menuIsOpen: false,
      };
      this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
    }

    render() {
      const {manager, location} = this.props
      return (
        <WrapStyle>
        {manager &&
          manager.map((item, i) =>
            <CardStyled key={i}>
              <CardImageStyled src={item.photo} width="200" height="283"></CardImageStyled>
              <CardWrapStyled>
                <CardTextStyled><H4>{item.full_name}</H4></CardTextStyled>
                <CardsmallTextStyled>{item.position}</CardsmallTextStyled>
                <PprojectWrapEmailStyled>
                  <Svg width="24" height="24" type="mail_outline" color={colors.blue_active}></Svg>
                  <PprojectEmailStyled href={"mailto:" + item.email}>{item.email}</PprojectEmailStyled>
                </PprojectWrapEmailStyled>
              </CardWrapStyled>
            </CardStyled>
          )
        }
          {(location.pdf && location.img)  &&
            <CardStyled>
              <CardImageStyled src={location.img} width="200" height="283"></CardImageStyled>
              <CardWrapStyled>
                <H4>Локация</H4>
                <PprojectWrapEmailStyled>
                  <Svg width="24" height="24" type="download" color={colors.blue_active}></Svg>
                  <PprojectEmailStyled href="" download={location.pdf}>скачать</PprojectEmailStyled>
                </PprojectWrapEmailStyled>
              </CardWrapStyled>
            </CardStyled>
          }
        </WrapStyle>
      );
    }
}

export default Header;