import styled from "styled-components"
import { colors } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"


export const LineOneStyled  = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.grey_light};
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    border-top: 0;
  }
`;
