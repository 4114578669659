import {
    STORAGE_KEY,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REGISTER_INFO_REQUEST,
    REGISTER_INFO_SUCCESS,
    REFRESH_TOKEN,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    CLEAR_MESSAGES
} from "../types"

const user = JSON.parse(localStorage.getItem(STORAGE_KEY))
const dataToken = JSON.parse(localStorage.getItem('dataToken'))
// const initialState = user
//     ? { isLoggedIn: true, ...user, message: '' }
//     : { isLoggedIn: false, user: null, message: '' }

const initialState = {
    isLoggedIn: user ? true : false,
    dataToken: {
        token: dataToken ? dataToken.token : "",
        token_type: dataToken ? dataToken.token_type : "",
        token_validity: dataToken ? dataToken.token_validity : "",
    },
    user: user ? user : {
        company_name: "",
        description: "",
        email: "",
        favourites: [],
        full_name: "",
        id: "",
        phone: "",
        photo: "",
        position: "",
        status: "",
        favourites_count: 0
    },
    loading_register_info: false,
    register_info: {
        countries: [],
        genres: [],
        rights: []
    },
    successRequest: false,
    message: '',
    errors: []
}

export const authReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                user: payload.user,
                dataToken: {
                    token: payload.token,
                    token_type: payload.token_type,
                    token_validity: payload.token_validity
                },
                isLoggedIn: true,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                message: payload.message,
                errors: payload.errors
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: payload.user,
                dataToken: {
                    token: payload.token,
                    token_type: payload.token_type,
                    token_validity: payload.token_validity
                },
                isLoggedIn: true,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                // user: null,
                message: payload.message
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                dataToken: {
                    token: "",
                    token_type: "",
                    token_validity: null,
                },
                user: {
                    company_name: "",
                    description: "",
                    email: "",
                    favourites: [],
                    full_name: "",
                    id: "",
                    phone: "",
                    photo: "",
                    position: "",
                    status: ""
                },
                message: ''
            };
        case REGISTER_INFO_REQUEST:
            return {
                ...state,
                loading_register_info: false
            };
        case REGISTER_INFO_SUCCESS:
            return {
                ...state,
                register_info: payload,
                loading_register_info: true
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                message: payload.message,
                successRequest: true
            };
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                errors: payload.errors,
                message: payload.message, 
                successRequest: false
            };
        case CLEAR_MESSAGES:
            return {
                ...state,
                message: '',
                errors: []
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                dataToken: {
                    token: payload.token,
                    token_type: payload.token_type,
                    token_validity: payload.token_validity
                }
            };
        default:
            return state;
    }
}
