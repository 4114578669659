import React from 'react';
import { colors as c } from "../../styles/variables.js"
import Svg from "../../../src/assets/icons/icons.js"
import {
    ContainerStyled,
    SvgSlyled,
    LabelStyled,
} from './style.js';

/**
 *
 * @param selected   - по умолчинию selected = dashboard, переключение с помощью функкции 
 * @param label    - если передавать label, будет label = Вид, если нет, то только иконки
 * @param toggleViewChange  - функция переключения вида

 Пример <ToggleView label selected = {this.state.dashboard  ? "dashboard" :"list" }  toggleViewChange={this.onToggleViewClick}  />
 *
 *
 *
 */
const ToggleView = ({ selected = "dashboard", label = false, toggleViewChange, ...props }) => {

    const listIcons = [["dashboard", true], ["list", false]];

    return (
        <ContainerStyled>
            {label   
                ? <LabelStyled> Вид </LabelStyled>
                : ""

            }
            {listIcons.map((item, i) => (
                <SvgSlyled
                onClick={()=>toggleViewChange(item[i])}
                key = {i} 
                className={selected === item[0]  ? "selected" : ""} >
                        <Svg 
                        width = '24'
                        height = '24'
                        key = {i}
                        type = {item[0]}
                        color = {c.blue_active}/>
                </SvgSlyled>
            ))}
        </ContainerStyled>
    )
}

export default ToggleView;