import React from 'react';
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";

import { Container } from "../Container/Container";
import Select from "../Select/Select";
import Checkbox from "../Checkbox/Checkbox";
import Tag from "../Tag/Tag";
import GetCards from "../GetCards/GetCards";
import Search from "../Search/Search";
import ToggleView from "../ToggleView/ToggleView";
import Logos from "../Logos/Logos";
import {DispatchMessage} from "../DispatchMessage/dispatchMessage";

import {filterSave} from "../../store/filterSave/actions";

import { HOST } from "../../store/types";
import Svg from "../../assets/icons/icons";
import {colors as c} from "../../styles/variables";

import {
    GeneralContainerSt,
    MobileOpenFilterSt,
    MobileOpenFilterSvgSt,
    SearchPanelSt,
    HrSt,
    FilterPanelSt,
    FilterContainerSt,
    MobileLogosSt,
    ContainerLogosSt,
    SelectContainerSt,
    SvgTabletSt,
    CheckboxSt,
    TagContainerSt,
    TitleSt,
    MobileSt,
    MobileHalfSt,
    MobileHalfRightSt,
    Container_menu_mobileSt,
    FoundSt,
} from './style.js';

import i18n from "i18next";
import ToggleViewRed from '../ToggleViewRed/ToggleViewRed'

class Filter extends React.Component {

    state ={
        //для переключения режима показа карточек
        dashboard: true,
        // открытие закрытие фильтра
        openFilter: window.innerWidth < 768 ? false : true,
        tuneFrame: false,
        arrowTop: false,
        // для работы селектов
        closeAllSelect: false, // контроль закрытия всех селектов
        // категории и жанры полученные с  сервера
        filter: {
            "filter[category_id]": [],
            "filter[genre_id]": [],
            "filter[duration]": [[]],
            "filter[quality]":[],
            "filter[channel_id]": [],
        },
        // значение селектов
        renderFilter: false,
        showCategoryFilter: "0",
        showGenreFilter: "0",
        showYearFilter: "0",
        showDurationFilter: "0",
        showQualityFilter: "0",
        showFormatFilter: "0",
        showChannelFilter: "0",
        searchWord: "",
        // сортировка результата по названию, false - asc, true - desc
        sortFilter: "asc",
        // выделяем селект (категорию или жанр) если работу фильтра вызывает переход по ссылке "смотреть все"
        selectChooseFromLink: false,
        selectValueFromLink: false,
        //
        countCards: -1,
        //
        foundMessage: false,
    };

    // Получаем категории и жанры с сервера
    onGetState(){
        fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/project-navigation-menu`)
            .then((res)=>{
                return res.json();
            })
            .then((body)=>{
                this.setState(() => {
                    return{filter: body};
                });
            }).then(() => {
                this.filterPostLink();
                    this.setState(() => {
                        return{
                            showCategoryFilter: this.props.filterSave.saveCategoryFilter,
                            showGenreFilter: this.props.filterSave.saveGenreFilter,
                            showYearFilter: this.props.filterSave.saveYearFilter,
                            showDurationFilter: this.props.filterSave.saveDurationFilter,
                            showQualityFilter: this.props.filterSave.saveQualityFilter,
                            showFormatFilter: this.props.filterSave.saveFormatFilter,
                            showChannelFilter: this.props.filterSave.saveChannelFilter,
                            searchWord: this.props.filterSave.saveSearchWord,
                        };
                    });
                    if(this.state.searchWord || this.state.showCategoryFilter!=="0" || this.state.showGenreFilter!=="0" || this.state.showYearFilter!=="0" || this.state.showDurationFilter!=="0" || this.state.showQualityFilter!=="0" || this.state.showFormatFilter!=="0" || this.state.showChannelFilter!=="0"){
                        this.props._hiddenContentPage(true)
                    }
                    this._searchCard(this.props.filterSave.saveSearchWord)
            });
    }

    componentDidMount() {
        //Вызов функции получающий категории и жанры с сервера
        this.onGetState();
    }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.render !== prevProps.render) {
            this.filterPostLink();
        }
        if(this.state.showCategoryFilter !== nextState.showCategoryFilter ||
            this.state.showGenreFilter !== nextState.showGenreFilter ||
            this.state.showYearFilter !== nextState.showYearFilter ||
            this.state.showDurationFilter !== nextState.showDurationFilter ||
            this.state.showQualityFilter !== nextState.showQualityFilter ||
            this.state.showFormatFilter !== nextState.showFormatFilter ||
            this.state.showChannelFilter !== nextState.showChannelFilter ||
            this.state.searchWord !== nextState.searchWord){

            if(localStorage.getItem('filterStart')){
                this.props._filterSave({
                    saveSearchWord: this.state.searchWord,
                    saveCategoryFilter: this.state.showCategoryFilter,
                    saveGenreFilter: this.state.showGenreFilter,
                    saveYearFilter: this.state.showYearFilter,
                    saveDurationFilter: this.state.showDurationFilter,
                    saveQualityFilter: this.state.showQualityFilter,
                    saveFormatFilter: this.state.showFormatFilter,
                    saveChannelFilter: this.state.showChannelFilter
                })
            }
            else {
                this.props._filterSave({
                    saveCategoryFilter: "0",
                    saveChannelFilter: "0",
                    saveDurationFilter: "0",
                    saveFormatFilter: "0",
                    saveGenreFilter: "0",
                    saveQualityFilter: "0",
                    saveSearchWord: "",
                    saveYearFilter: "0"
                })
            }

        }
    }



    filterPostLink() { //  фильр обрабатывает жанр/категорию при переходе через "смотреть все"
        if(localStorage.getItem('filterStart') !== ""){
            this.state.filter["filter[category_id]"].map((item)=>{
                if( localStorage.getItem('filterStart') === item.title){
                    this._showSearchingResults(item.id, 'category');
                    const value = localStorage.getItem('filterStart')
                    this.setState(() => {
                        return{
                            selectChooseFromLink: 'category',
                            selectValueFromLink: value
                        };
                    });
                }
            });
            this.state.filter["filter[genre_id]"].map((item)=>{
                if( localStorage.getItem('filterStart') === item.title){
                    this._showSearchingResults(item.id, 'genre');
                    const value = localStorage.getItem('filterStart')
                    this.setState(() => {
                        return{
                            selectChooseFromLink: 'genre',
                            selectValueFromLink: value
                        };
                    });
                }
            });
            setTimeout(() => {
                this.setState(() => {
                    return{
                        selectChooseFromLink: false
                    };
                });
            }, 50)
            localStorage.setItem('filterStart', '')
        }
    }

    // проверяем есть ли хотя бы одна найденная карта. В зависимости от результата выводим нужный текст
    _countCards(x){
        this.setState(() => {
            return{countCards: x}
        })
    }

    // В мобильной версии показываем колличество найденных карт
    _foundShow(){
        setTimeout(() => {
            this.setState(() => {
                return { foundMessage: true };
            });
        }, 1500)
        setTimeout(() => {
            this.setState(() => {
                return { foundMessage: false };
            });
        }, 3000)
    }

    // показываем карты как - плитка/список
    _onToggleViewClick(x) {
        this.setState({
            dashboard: x
        });
    }

    // открытие закрытие фильтра
    _openFilter(){
        this.setState(({openFilter, tuneDashboard})=>{
            return {
                openFilter: !this.state.openFilter,
                tuneFrame: !this.state.tuneFrame,
                arrowTop: !this.state.arrowTop,
            }
        })
    }

    // при открытии второго селектора закрываем первый
    _closeAllSelect(){
        this.setState(() => {
            return { closeAllSelect: true }
        })
        setTimeout(() => {
            this.setState(() => {
                return { closeAllSelect: false }
            })
        }, 50)
    }

    // сортировка результата по названию
    _sortFilter(){
        this.setState(({sortFilter})=>{
            return{
                sortFilter: this.state.sortFilter==="asc" ? "desc" : "asc"
            };
        });
        this._filterRender();
    }

    // скидываем настройки фильтра
    _clearFilter(){
        this.props._hiddenContentPage(false);
        this.setState(() => {
            return {
                renderFilter: false,
                showCategoryFilter: "0",
                showGenreFilter: "0",
                showYearFilter: "0",
                showDurationFilter: "0",
                showQualityFilter: "0",
                showFormatFilter: "0",
                showChannelFilter: "0",
                searchWord: "",
            }
        })
    }

    // рендеринг фильтра
    _filterRender(){
        this.setState(() => {
            return{
                renderFilter: false
            }
        })
        setTimeout(() => {
            this.setState(() => {
                return{
                    renderFilter: true
                }
            })
        }, 10)
    }

    allSelect = [
        ["category", () => this.setState(() => {return{showCategoryFilter: "0"}}), (option)=>this.setState(() => {return{showCategoryFilter: option}})],
        ["genre", () => this.setState(() => {return{showGenreFilter: "0"}}), (option)=>this.setState(() => {return{showGenreFilter: option}})],
        ["year", () => this.setState(() => {return{showYearFilter: "0"}}), (option)=>this.setState(() => {return{showYearFilter: option===i18n.t(`filter.year`) ? "0" : option}})],
        ["duration", () => this.setState(() => {return{showDurationFilter: "0"}}), (option)=>this.setState(() => {return{showDurationFilter: option}})],
        ["quality", () => this.setState(() => {return{showQualityFilter: "0"}}), (option)=>this.setState(() => {return{showQualityFilter: option}})],
        ["format", () => this.setState(() => {return{showFormatFilter: "0"}}), (option)=>this.setState(() => {return{showFormatFilter: option}})],
        ["channel", () => this.setState(() => {return{showChannelFilter: "0"}}), (option)=>this.setState(() => {return{showChannelFilter: option}})]
    ]

    // показываем и скрываем результат фильтрации
    _showSearchingResults(option, selectName){
        this._foundShow();
        this.setState(() => {
            return{countCards: -1}
        })
        this.allSelect.map((item)=>{
            if(selectName===item[0]){
                if(option==="0"){
                    item[1]();
                }
                else{
                    item[2](option);
                }
            }
        });
        setTimeout(() => {
            if(this.state.searchWord || this.state.showCategoryFilter!=="0" || this.state.showGenreFilter!=="0" || this.state.showYearFilter!=="0" || this.state.showDurationFilter!=="0" || this.state.showQualityFilter!=="0" || this.state.showFormatFilter!=="0" || this.state.showChannelFilter!=="0"){
                this.props._hiddenContentPage(true)
            }
            else{ this.props._hiddenContentPage(false); }
            this._filterRender();
        }, 10)
    }

    // поиск карты по названию
    _searchCard(word){
        if(word !== ""){
            this.props._hiddenContentPage(true);
            this.setState(() => {
                return {
                    searchWord: word
                }
            })
        }
        this._filterRender();
    }

    render() {

        const { filterSave, filterMenu } = this.props;

        let DashboardControl = this.state.searchWord || this.state.showCategoryFilter!=="0" || this.state.showGenreFilter!=="0" || this.state.showYearFilter!=="0" || this.state.showDurationFilter!=="0" || this.state.showQualityFilter!=="0" || this.state.showFormatFilter!=="0" || this.state.showChannelFilter!=="0";
        let FilterStart = (DashboardControl && this.state.renderFilter) ? true : false;

        // Все варианты значений выбора
        const category = [i18n.t(`filter.category.${0}.category`), [{name: i18n.t(`filter.category.${0}.category`), id: "0"}]];
        this.state.filter["filter[category_id]"].map((item, i)=>{
            category[1].push({name: item.title, id: item.id});
        });
        const genre = [i18n.t(`filter.genre.${0}.genre`), [{name: i18n.t(`filter.genre.${0}.genre`), id: "0"}]];
        this.state.filter["filter[genre_id]"].map((item, i)=>{
            genre[1].push({name: item.title, id: item.id});
        });
        const year = [i18n.t(`filter.year`), [i18n.t(`filter.year`)]];
        for(let i=2000; i<new Date().getFullYear()+1; i++){
            year[1].push(i);
        }
        const duration = [i18n.t(`filter.duration.${0}.duration`), [{name: i18n.t(`filter.duration.${0}.duration`), id: "0"}]];
        this.state.filter["filter[duration]"][0].map((item)=>{
            duration[1].push({name: item + " "+i18n.t(`filter.duration.${0}.minutes`)+".", id: item});
        });
        const quality = [i18n.t(`filter.quality`), [{name: i18n.t(`filter.quality`), id: "0"}]];
        this.state.filter["filter[quality]"].map((item)=>{
            quality[1].push({name: item.toUpperCase(), id: item});
        });
        const channel = [i18n.t(`filter.channel.${0}.channel`), [{name: i18n.t(`filter.channel.${0}.channel`), id: "0"}]];
        this.state.filter["filter[channel_id]"].map((item, i)=>{
            channel[1].push({name: item.title, id: i+1});
        });

        // Получаем теги
        const allTags = [
            [i18n.t(`filter.category.${0}.category`), 'category', category[1][this.state.showCategoryFilter].name, category[1][this.state.showCategoryFilter].id],
            [i18n.t(`filter.genre.${0}.genre`), 'genre', this.state.filter["filter[genre_id]"].map((item)=>{if( this.state.showGenreFilter === item.id){return item.title}}), this.state.showGenreFilter],
            [i18n.t(`filter.year`), 'year', this.state.showYearFilter, this.state.showYearFilter],
            [i18n.t(`filter.duration.${0}.duration`), 'duration', this.state.showDurationFilter + " " + i18n.t(`filter.duration.${0}.minutes`), this.state.showDurationFilter],
            [i18n.t(`filter.quality`), 'quality', this.state.showQualityFilter.toUpperCase(), this.state.showQualityFilter],
            ["0", "format", i18n.t(`filter.format`), this.state.showFormatFilter],
            [i18n.t(`filter.channel.${0}.channel`), 'channel', channel[1][this.state.showChannelFilter].name, channel[1][this.state.showChannelFilter].id]
        ];
        const allTagsInsert =  allTags.map((item, i)=>{
            return(item[3]!=="0" && <span key={i} onClick={() => this._showSearchingResults("0", item[1])}><Tag name={item[2]}/></span>);
        });

        // Получаем селекты
        const allSelects = [
            ['category', '160px', category, this.state.showCategoryFilter],
            ['genre', '190px', genre, this.state.showGenreFilter],
            ['year', '130px', year, this.state.showYearFilter],
            ['duration', '160px', duration, this.state.showDurationFilter],
            ['quality', '130px', quality, this.state.showQualityFilter],
            ['format', '140px', "", this.state.showFormatFilter],
            ['channel', '120px', channel, this.state.showChannelFilter]
        ];
        const allSelectsInsert =  allSelects.map((item, i)=>{
            if(item[0] !== 'format'){
                return(
                    <SelectContainerSt ContainerWidth={item[1]} key={i}>
                        <Select
                            _closeAllSelect={() => this._closeAllSelect()}
                            closeAllSelect={this.state.closeAllSelect}
                            _showSearchingResults={(x)=>this._showSearchingResults(x, item[0])}
                            removeChoice={item[3]}
                            content={item[2]}
                            selectName={item[0]}
                            selectChooseFromLink={this.state.selectChooseFromLink ||
                                (item[0]==="category" && filterSave.saveCategoryFilter!=="0") ||
                                (item[0]==="genre" && filterSave.saveGenreFilter!=="0") ||
                                (item[0]==="year" && filterSave.saveYearFilter!=="0") ||
                                (item[0]==="duration" && filterSave.saveDurationFilter!=="0") ||
                                (item[0]==="channel" && filterSave.saveChannelFilter!=="0") ||
                                (item[0]==="quality" && filterSave.saveQualityFilter!=="0")
                            }
                            selectValueFromLink={this.state.selectValueFromLink}
                            filterSave={
                                (item[0]==="category" && filterSave.saveCategoryFilter!=="0" && filterMenu["filter[category_id]"].filter((item)=>item.id===filterSave.saveCategoryFilter)[0].title) ||
                                (item[0]==="genre" && filterSave.saveGenreFilter!=="0" && filterMenu["filter[genre_id]"].filter((item)=>item.id===filterSave.saveGenreFilter)[0].title) ||
                                (item[0]==="year" && filterSave.saveYearFilter!=="0" && filterSave.saveYearFilter) ||
                                (item[0]==="duration" && filterSave.saveDurationFilter!=="0" && filterSave.saveDurationFilter + " " + i18n.t(`filter.duration.${0}.minutes`)) ||
                                (item[0]==="channel" && filterSave.saveChannelFilter!=="0" && filterMenu["filter[channel_id]"].filter((item)=>item.id===filterSave.saveChannelFilter)[0].title) ||
                                (item[0]==="quality" && filterSave.saveQualityFilter!=="0" && filterSave.saveQualityFilter.toUpperCase())
                            }
                        />
                    </SelectContainerSt>
                );
            }
            else{
                return(
                    <SelectContainerSt ContainerWidth={item[1]} key={i}>
                        <CheckboxSt borderActive={filterSave.saveFormatFilter!=="0" && true}>
                            <Checkbox
                                name={i18n.t(`filter.format`)}
                                _showSearchingResults={(x)=>this._showSearchingResults(x ? "1" : "0", "format")}
                                removeChoice={item[3]}
                                filterSave={filterSave.saveFormatFilter!=="0" && true}
                            />
                        </CheckboxSt>
                    </SelectContainerSt>
                )
            }
        });
        return (
            <>
            <GeneralContainerSt>
                <MobileOpenFilterSt onClick={() => this._openFilter()} openFilter={this.state.openFilter}>{i18n.t(`filter.filters`)}
                    <MobileOpenFilterSvgSt>
                        <Svg className={this.state.arrowTop && "keyboard_arrow_top"} type="keyboard_arrow"  />
                    </MobileOpenFilterSvgSt>
                </MobileOpenFilterSt>
                <Container>
                    <SearchPanelSt>
                        { (window.innerWidth < 768)
                            ? null
                            : <>
                                <Search
                                    _clearFilter={() => this._clearFilter()}
                                    filter={true}
                                    searchWord={this.state.searchWord !== ""}
                                    size = "90%"
                                    isButtonShow = "true"
                                    iconSearch = "search"
                                    placeholder = {filterSave.saveSearchWord || i18n.t(`filter.add_by_name`)}
                                    searchBoxIsOpen = {true}
                                    tune={true}
                                    tuneFrame={!this.state.tuneFrame}
                                    _openFilter={() => this._openFilter()}
                                    _click={(word)=>this._searchCard(word)}
                                />
                                {DashboardControl && <ToggleView selected = {this.state.dashboard  ? "dashboard" : "list" }  toggleViewChange={(x)=>this._onToggleViewClick(x)} />}
                                {(!DashboardControl && (this.props.history.location.pathname==="/catalog" || /channel/.test(this.props.history.location.pathname))) && <ToggleViewRed></ToggleViewRed>}
                           </>
                        }
                    </SearchPanelSt>
                        <FilterPanelSt openFilter={this.state.openFilter}>
                            <HrSt />
                            <FilterContainerSt>
                                {(window.innerWidth < 768) && <MobileLogosSt>

                                    <Container_menu_mobileSt>
                                        <NavLink  activeClassName="active" to="/channel/tnt"><Svg width="80" height="36" type="tnt_80_36" color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/tv3"><Svg width="80" height="36" type={i18n.t(`about.logo.${0}.tv3`)} color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/friday"><Svg width="80" height="36" type={i18n.t(`about.logo.${0}.friday`)} color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/2x2"><Svg width="80" height="36" type="2x2_80_36" color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/tnt4"><Svg width="80" height="36" type="tnt4_80_36" color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/subbota"><Svg width="80" height="36" type={i18n.t(`about.logo.${0}.super`)} color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/gpm-etv"><Svg width="80" height="36" type={i18n.t(`about.logo.${0}.gpm`)} color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/2x2prod"><Svg width="80" height="36" type={i18n.t(`about.logo.${0}.tv2x2_anim`)} color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/123-production"><Svg width="80" height="36" type="tv123" color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/comedy-production"><Svg width="80" height="36" type="comedy" color='#42A5F5'/></NavLink>
                                        <NavLink  activeClassName="active" to="/channel/good-story"><Svg width="80" height="36" type="good_story" color='#42A5F5'/></NavLink>
                                    </Container_menu_mobileSt>

                                    <ContainerLogosSt>
                                        <Logos container="header" color={c.blue_active}/>
                                    </ContainerLogosSt>
                                </MobileLogosSt>}
                                {allSelectsInsert /* вставляем все селекты */ }
                               <SvgTabletSt dashboardControl={DashboardControl} ContainerWidth="25px" onClick={() => this._sortFilter()}>
                                    {i18n.language === 'ru' ? <Svg type="sort_by_alpha_ru" /> : <Svg type="sort_by_alpha_en" />}
                                </SvgTabletSt>
                               <SvgTabletSt dashboardControl={DashboardControl} ContainerWidth="25px" onClick={() => this._clearFilter()}>
                                    <Svg type="close" />
                                </SvgTabletSt>
                                <MobileSt>
                                    <MobileHalfSt>
                                        {DashboardControl && <ToggleView selected = {this.state.dashboard  ? "dashboard" : "list" }  toggleViewChange={(x)=>this._onToggleViewClick(x)} />}
                                        {(!DashboardControl && (this.props.history.location.pathname==="/catalog" || /channel/.test(this.props.history.location.pathname))) && <ToggleViewRed></ToggleViewRed>}
                                    </MobileHalfSt>
                                    <MobileHalfSt>
                                        <MobileHalfRightSt>
                                            <div><Svg type="sort_by_alpha" /></div>
                                            <div onClick={() => this._clearFilter()} style={{marginLeft:"30px"}}><Svg type="close"/></div>
                                        </MobileHalfRightSt>
                                    </MobileHalfSt>
                                </MobileSt>
                            </FilterContainerSt>
                        </FilterPanelSt>
                </Container>
            </GeneralContainerSt>

            <Container padding="true">
                {DashboardControl && <TagContainerSt>
                    <TitleSt>{i18n.t(`filter.searching_results`)}  {this.state.searchWord !=="" && (i18n.t(`filter.for`) + ` "${this.state.searchWord}"`)}</TitleSt>
                    {allTagsInsert /* вставляем все теги */}
                </TagContainerSt>}
                {FilterStart && <>
                    <GetCards
                        _countCards={(x)=>this._countCards(x)}
                        sourceCards={`projects?filter%5Bsearch%5D=${this.state.searchWord}
                            &filter%5Bcategory_id%5D=${this.state.showCategoryFilter}
                            &filter%5Bgenre_id%5D=${this.state.showGenreFilter}
                            &filter%5Byear%5D=${this.state.showYearFilter}
                            &filter%5Bduration%5D=${this.state.showDurationFilter}
                            ${this.state.showQualityFilter!=="0" ? ("&filter%5Bquality%5D%5B%5D=" + [this.state.showQualityFilter]): ""}
                            &filter%5Bis_format%5D=${this.state.showFormatFilter}
                            &filter%5Bchannel_id%5D=${this.state.showChannelFilter}
                            &sort%5Bcolumn%5D=title.${i18n.language}&sort%5Border%5D=${this.state.sortFilter}`
                        }
                        type="catalog"
                        cardDisplayType="grid"
                        tile={this.state.dashboard}
                        filter={true}
                    />
                </>}
            </Container>
            {(this.state.countCards > 0 && this.state.foundMessage) && <FoundSt><DispatchMessage message={`${i18n.t(`filter.found_on_request`)} ${this.state.countCards} `} /></FoundSt>}
            {(this.state.countCards === 0 && this.state.foundMessage) && <FoundSt><DispatchMessage message={`${i18n.t(`filter.no_results_found`)}`} /></FoundSt>}
        </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        filterSave: store.filterSave.filterSave,
        filterMenu: store.filterSave.filterMenu
    };
}

const mapDispatchToProps = dispatch => {
    return {
        _filterSave: (save) => dispatch(filterSave(save)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
