import styled from "styled-components"
import { fonts as f, colors as c, device } from "../../styles/variables.js"

export const DivStBlueblock = styled.div`  
      margin-bottom: -50px;
      height: 72.3vh; 
      display: flex;
      align-items: center;
      justify-content: center; 
`;
export const DivStBlueblockimg = styled.div` 

    & a {
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;        
        }
    }
      
`;
export const TextSTyled = styled.div` 
      white-space: break-spaces;
      padding: 70px 0 30px 0; 
`;