import styled from "styled-components"
import {fonts as f, colors as c, device} from "../../styles/variables.js"

export const DivStContainer = styled.div`
  
    @media ${device.mobile}  {     
        margin-bottom: 30px; 
    }    
    
    @media ${device.tablet}  {    
        margin-bottom: 30px; 
    } 
    @media (min-width: 1280px) {  
        display: flex;
        padding-top: 70px;
        margin-bottom: 120px;
    }
    
`;

export const DivStContainerLeft = styled.div`
    
    @media ${device.mobile} {
        display: none;
    }  
      
    @media ${device.tablet} {
        display: none;
    }
    @media (min-width: 1280px) {  
        display: block;
        width: 300px; 
        margin-right: 100px;
        text-align: center;
        padding-top: 100px;    
        
        & img { 
            height: 184px;
            max-width: 300px;
        } 
    }
           
    & a { 
        font-style: normal;
        font-weight: ${f.bold}; 
        font-family: ${f.font_family};
        font-size: 12px;
        line-height: 14px; 
        letter-spacing: 0.03em;
        text-transform: uppercase; 
        color: #42A5F5; 
    }
           
    & .rightpadding {  
        padding-right: 40px;
    }
    
    & img {
        padding-bottom: 25px;
    }
    
    & svg  {
        margin-bottom: -8px;  
    }
    
`;

export const DivStContainerTextLeft = styled.div`
     text-align: left;    
`;

export const DivStContainerRight = styled.div`
 
     @media ${device.mobile} {
       width: 100%;
       font-size: 14px;
       line-height: 16.44px;
       font-weight: ${f.regular};  
       
       & img { 
           width: 100%;
           margin: 0px 0 -4px 0px;
       }       
           
        .mobileNone {
           display: none; 
        }
        
        & .oneTitile {
            padding: 30px 0 ;
            border-bottom: 2px solid #EEEEEE;
            margin-bottom: 30px;
        }
       
    }    
    
    @media ${device.tablet} {
       width: 100%;
       font-size: 14px;
       line-height: 16.44px;
       font-weight: ${f.regular};  
       
       & img { 
             width: auto;
             margin:0;
       }       
           
        .mobileNone {
           display: none; 
        }
        
        & .oneTitile {
            padding: 30px 0 ;
            border-bottom: 2px solid #EEEEEE;
            margin-bottom: 30px; 
            text-transform: none;  
        }
       
    }     
    
    @media (min-width: 1280px) {     
        width: 780px; 
        font-family: ${f.font_family};
        font-weight: ${f.regular};  
        font-size: 16px;
        line-height: 20px;  
        color: #272D3D;         
        padding-left: 20px;

        
        & img { 
             width: auto;
             margin:0;
        }    
    
        & .oneTitile {
            padding: 0 ;
            border-bottom: none;
            margin-bottom: 0px;
        }
        
        .mobileNone {
           display: block; 
        }

    }   
        
    & a {
       color: ${c.blue};
       text-decoration: none;
    }    
    
    & a:hover, & a:focus { 
       text-decoration: underline;
    }     
        
    
`;

export const DivStContainerMenu = styled.div`
    
    @media ${device.mobile}  {
        overflow-x: auto;  
        display: flex;        
        padding-bottom: 10px;        
        width: calc(100% + 40px);
        margin-left: -20px;
        padding: 20px;
        z-index: 5;
        position: relative;
                    
        & a:nth-child(2)  {        
            margin-bottom: 0px;
        } 
        
        & a:nth-child(8) {       
            margin-left: 10px;
        }

          & div {
            margin-right: 10px;
          }
    }
    
    @media ${device.tablet}  {
        overflow-x: auto;    
        padding-bottom: 10px;        
        width: calc(100% + 40px);
        display: flex; 
        margin-left: -20px;
        padding: 20px; 
        justify-content: center;
            
        & a:nth-child(2)  {        
            margin-bottom: 0px;
        } 
        
        & a:nth-child(8) {       
            margin-left: 10px;
        }
        
        
    }    
    
    @media (min-width: 1280px) {  
        width: 100%;
        display: flex;    
        flex-wrap: wrap;
        padding-bottom: 100px; 
        padding-left: 0;

        margin-left: 0;
            
        & a:nth-child(2)  {        
            margin-bottom: 17px;
        } 
        
        & a:nth-child(8) {       
            margin-left: -35px;
        } 
    }
    
    & a  { 
        width: 90px;
        text-align: center;
    } 

    
    & a:hover svg {
        fill: #31394e;   
        transition: all 0.3s ease 0s;
    }    
    
    & a.active svg {
        fill: #31394e;
    }
    
    & a.active img {
         filter: invert(90%) sepia(30%) saturate(1000%) hue-rotate(200deg);
    } 
    
    & .w80 {
     width: 80px;
     height: 36px; 
     filter: invert(49%) sepia(70%) saturate(1106%) hue-rotate(186deg) brightness(105%) contrast(92%);
          
     &:hover {
      filter: invert(90%) sepia(30%) saturate(1000%) hue-rotate(200deg);
      transition: all .3s;
     } 
     
    } 
    
`;

export const DivStContainerOne = styled.div`
    
    & a:hover svg {
        fill: #42A5F5;
    }
    
    @media ${device.mobile}  {
        & a svg {
            margin-right: 0px;
            padding-right: 10px;
        }    
    }
    @media (min-width: 1280px) { 
        & a svg {
            margin-left: -23px;
            margin-right: 90px;
        }
        
        & a:hover svg {
            fill: #31394e;
        }
    }    
    
`;

export const active = styled.a` 
    & svg {
        fill: #000;
    }
`;


export const DivStContainerLine = styled.div` 

    @media ${device.mobile}  {  
        display:none;
    } 
    
    @media ${device.tablet}  {  
        display:none;
    } 
    
    @media (min-width: 1280px) {  
    /* @media (min-width: 1280px) {   */
        display:block;
        margin: 50px 0; 
        border: 1px solid #EEEEEE;
    }    
    
`;

export const BlueblockStyled = styled.div` 
    
    @media ${device.mobile}  {  
        background: #42A5F5;
        padding: 0;        
        color: #fff;
        margin-top: 20px;
        margin-left: -20px;
        margin-bottom: 30px;
        width: calc(100% + 40px);
        
        & .mpbilediv {
            padding: 40px 35px;
        }    
        & .mpbilediv h2 {
            color: #fff;
        }        
    } 
    
    @media ${device.tablet} { 
        background: #42A5F5;
        font-weight: ${f.bold}; 
        font-family: ${f.font_family};
        font-size: 36px;
        line-height: 42px;
        color: #fff;
        padding: 300px 60px 60px 80px;
        margin-top: 180px;
        margin-bottom: 75px;   
        
        & h2 {
            font-weight: ${f.bold};
            color: #fff;
        }
    }
    @media (min-width: 1280px) {  
        background: #42A5F5;
        font-weight: ${f.bold}; 
        font-family: ${f.font_family};
        font-size: 36px;
        line-height: 42px;
        color: #fff;
        padding: 300px 60px 60px 80px;
        margin-top: 180px;
        margin-bottom: 75px;   
        
        & h2 {
            font-weight: ${f.bold};
        }
    }
`;

export const BlueblockimgStyled = styled.div` 

    
    @media ${device.mobile}  {
        width: 100%;
        position: relative;
        margin: 0 auto;
        max-width: 360px;
    } 
    
    @media ${device.tablet} { 
        width: 720px;
        position: absolute;
        margin: -419px 0 0 -115px;
        
        & img {
            vertical-align: bottom;
        }
    }
    @media (min-width: 1280px) {  
        width: 720px;
        position: absolute;
        margin: -419px 0 0 -200px;
        
        & img {
            vertical-align: bottom;
        }
    }  
`;

export const DivStContactBlock = styled.div` 
    margin-top: 100px; 
    border-top: 1px solid #EEEEEE;
    /* padding: 35px 65px 120px; */
    padding: 35px 65px 70px;
    display: flex;
    flex-wrap: wrap; 
    color: #272D3D;
    
    & h3 {
        width: 100%;
        margin-bottom: 35px;
    }       
    
    @media ${device.mobile}  {
        margin-top: 30px; 
        padding: 25px 0 0 0;
    }   
`;

export const DivStContactItem = styled.div`  
    width: 220px;
    margin-bottom: 30px;
    margin-right: 75px;
    
    &:last-child {
        margin-bottom: 0;
        border: none;
        margin-right: 0px;
    }
  
    & img {
        width: 200px;
        margin-bottom: 20px;
    } 
    
    & h4 { 
        display: block;
        padding: 0px 0 10px 0;
    }
    
    & p { 
        padding: 0px 0 30px 0;
    }
    
    & svg {
        margin-bottom: -8px;
        margin-right: 8px;
    }
    
    & a {
        text-decoration: none;
    }    
    
    & a:hover span{
        border-bottom: 1px solid ${c.blue};
    }
    
    & span {
        color: ${c.blue};
    }
    
    & .mobileText {
        display: none;
    }
    
    @media ${device.mobile}  { 
        width: 280px;
        border-bottom: 1px solid #EEEEEE; 
        padding-top: 15px;
    
       & img {
           max-width: 70px;
           float: left;
           margin-right: 15px;
       }
       
       & p { 
            font-size: 16px;
            line-height: 19px;  
        }
        
       & h4 {
            font-size: 14px;
            line-height: 16px; 
            padding-bottom: 15px;            
       } 
       
       & span {
            font-size: 10px;
            line-height: 11px;             
       }
       
        & .mobileText {
            display: flex;
            width: 190px;
            flex-wrap: wrap;
            padding: 0 0 0 15px;
            height: 100px;
            
            & h4 {
                font-size: 14px;
                line-height: 16px;
                padding: 0;
            }
            
            & a {
                display: block;
                margin-top: 32px;
            } 
            
            & a span{
                color: #42A5F5;
            } 
            
            & a:hover span{
                border-bottom: 1px solid #42A5F5;
            } 
            
            
        }
       
    }      
    
`;

export const DivStContactContainerImg = styled.div`
  max-height: 283px;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const DivStRedBlock = styled.div`  
    width: 200px;
    border: 2px solid ${c.red};
    padding: 20px;
    font-weight: ${f.bold};  
    height: 283px;
    color: ${c.red};
    display: flex;
    flex-wrap: wrap;
    
    & a, & span  {
        color: ${c.red};
        display: flex;       
    }   
    
    & span  {   
        display: block;
        margin-bottom: 4px;
    }
    
    & svg  {   
        margin-top: -5px;
        margin-bottom: 0px;
    }
    
    & a  {
        align-self: flex-end;
    }
    
    & a:hover span  {
        border-bottom: 1px solid ${c.red}; 
    }
    
    & h4 {    
        align-items: flex-end; 
        color: ${c.red};
        font-weight: ${f.bold};  
    }
    
    & .mobileInfo {
        display: none;
    }
    
    @media ${device.mobile}  {
        width: 70px;
        height: 100px;
        padding: 0; 
        float: left;
        
        & h4, & a {
            display: none;
        } 
        
        & .mobileInfo {
            margin: 38px auto;
            display: block;
        }  
    }
`;

export const DivStContainerMobileTopLogo = styled.div`
    @media ${device.mobile}  {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        width: calc(100% + 40px);
        margin-left: -20px;
        height: 100px;
        padding: 0 20px 0px;
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12);
        
        & img {
            max-width: 100px;
        }  
        
        & .w60 {
            max-width: 60px;
        }
        
        & div {        
            max-width: 200px;
        }
    }
    
    @media ${device.tablet}  {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        width: calc(100% + 40px);
        margin-left: -20px;
        height: 100px;
        padding: 0 20px 0px;
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12);
        
        & img {
            max-width: 100px;
        }     
           
        & h1 {
            font-weight: 700;
            font-size: 30px;
            line-height: 34px;       
        }
        
        & .w60 {
            max-width: 60px;
        }
    }
    @media (min-width: 1280px) {  
        display: none;
    } 
`;

export const DivStContainerMobileSiteContent = styled.div` 
    
    @media ${device.mobile}  {
        display: flex;
        justify-content: space-between;
        align-items: center;   
        padding: 20px 0; 
        margin: 20px 0 20px;
        border-top: 2px solid #EEEEEE;
        border-bottom: 2px solid #EEEEEE;
        
        & div {        
            max-width: 320px;
            margin: 0 auto;
            
            & svg {
                margin-bottom: -6px;
            }
            
            & a {
                margin-right: 20px;
            } 
        }        
    }      
       
    @media ${device.tablet}  {
        display: flex;
        justify-content: space-between;
        align-items: center;   
        padding: 20px 0; 
        margin: 20px 0 20px;
        border-top:2px solid #EEEEEE ;
        border-bottom: 2px solid #EEEEEE;
        
        & div {        
            max-width: 320px;
            margin: 0 auto;
            
            & svg {
                margin-bottom: -6px;
            }
            
            & a {
                margin-right: 20px;
            }
            
        }
        
    }     
    @media (min-width: 1280px) {  
        display: none;
    }    
        
    & a { 
        font-style: normal;
        font-weight: ${f.regular}; 
        font-family: ${f.font_family};
        font-size: 10px;
        line-height: 12px; 
        letter-spacing: 0.03em;
        text-transform: uppercase; 
        color: #42A5F5; 
        text-decoration: none;
    }
    
    & a:hover {
       border-bottom: 1px solid #42A5F5;
    }  
    
`;


export const DivStAbout = styled.div`
    white-space: break-spaces;
    word-break: break-word;
  
    & .kuwVjQ {
        font-size: 20px;
        line-height: 24px;
        @media ${device.desktop}{
            font-size: 36px;
            line-height: 42px;
        }
    }
  
  & .sc-gGTGfUekFupG {
    @media ${device.mobile} {
      background: #42A5F5;
      padding: 0;
      color: #fff;
      margin-top: 20px;
      margin-left: -20px;
      margin-bottom: 30px;
      width: calc(100% + 40px);

      & .mpbilediv {
        padding: 40px 35px;
      }

      & .mpbilediv h2 {
        color: #fff;
      }
    }

    @media ${device.tablet} {
      background: #42A5F5;
      font-weight: ${f.bold};
      font-family: ${f.font_family};
      font-size: 36px;
      line-height: 42px;
      color: #fff;
      padding: 300px 60px 60px 80px;
      margin-top: 180px;
      margin-bottom: 75px;

      & h2 {
        font-weight: ${f.bold};
        color: #fff;
      }
    }
    @media (min-width: 1280px) {
      background: #42A5F5;
      font-weight: ${f.bold};
      font-family: ${f.font_family};
      font-size: 36px;
      line-height: 42px;
      color: #fff;
      padding: 300px 60px 60px 80px;
      margin-top: 180px;
      margin-bottom: 75px;

      & h2 {
        font-weight: ${f.bold};
      }
    }
    
    /**/
    & .sc-iumJynhJXkRl{
      @media ${device.mobile}  {
        width: 100%;
        position: relative;
        margin: 0 auto;
        max-width: 360px;
      }

      @media ${device.tablet} {
        width: 720px;
        position: absolute;
        margin: -419px 0 0 -115px;

        & img {
          vertical-align: bottom;
        }
      }
      @media (min-width: 1280px) {
        width: 720px;
        position: absolute;
        margin: -419px 0 0 -200px;

        & img {
          vertical-align: bottom;
        }
      }
    }
  }
`

export const DivStNoImgStyled = styled.div`
     border: 2px solid;
     width: 200px;
     height: 283px;
     margin-bottom: 20px;
     
     @media ${device.mobile}  {
        width: 70px;
        height: 100px;
        float: left;
        margin-right: 15px;
      }
`