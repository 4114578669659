import styled from "styled-components";
import {colors as c, device as d} from "../../styles/variables.js"
import {H3, Small} from "../../styles/typography/typography";

export const GeneralContainerSt = styled.div`
    box-shadow: 0 4px 4px ${c.blue_highlight};
    margin-bottom: -1px;
    @media ${d.tablet} {
      padding: 20px 0;
      margin-bottom: 45px;
    }
    
`;

export const MobileOpenFilterSt = styled(Small)`
    background: ${props=>props.openFilter && c.blue_highlight};    
    display: block;
    position: relative;
    padding: 20px;
    text-transform: uppercase;
    font-weight: 900;
    @media ${d.tablet} {
        display: none;
    }
`;

export const MobileOpenFilterSvgSt = styled.div`
    position: absolute;
    right: 20px;
    top: 15px;
`;

export const SearchPanelSt = styled.div`
    @media ${d.tablet} {
      display: flex;
      gap: 30px;
      margin: 20px 0;
    }
`;

export const HrSt = styled.hr`
    display: none;   
    border: none;
    border-top: 1px solid #eeeeee;
    @media ${d.tablet} {
      display: block;
      margin: 5px 10px 20px;
    }
`;

export const FilterPanelSt = styled.div`
    ${props=>props.openFilter ? "" : "height:0; overflow:hidden;"}
    @media ${d.tablet} {
      ${props=>props.openFilter && "margin: 20px -10px;"}
    }
`;

export const FilterContainerSt = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Container_menu_mobileSt = styled.div`

    @media ${d.mobile}  { 
        overflow-x: auto;
        display: flex;        
        padding-bottom: 10px;        
        width: 100%; 
        padding: 20px;
        z-index: 5;
        position: relative;
                    
        & a:nth-child(2)  {        
            margin-bottom: 0px;
        } 
         
    } 
    
    & a  { 
        width: 90px;
        text-align: center;
    } 

    
    & a:hover svg {
        fill: #31394e;  
        transition: all .3s;
    }    
    
    & a.active svg {
        fill: #31394e;
    }
`;

export const MobileLogosSt = styled.div` 
    width: calc(100% + 40px); 
    margin: 0 0 0 -25px;
    &::-webkit-scrollbar {
      width: 0;
    }
`;

export const ContainerLogosSt = styled.div`
  min-width: 400px;
  display: flex;
  svg{
    opacity: 1;
  }
`;

export const SelectContainerSt = styled.div`
    width: 100%;
    margin: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${d.tablet} {
      margin: 5px 10px;
      width: ${props=>props.ContainerWidth};
    }
`;

export const SvgTabletSt = styled.div`
    display: none;
    margin: 5px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: ${props=>props.ContainerWidth};
    ${props => !props.dashboardControl && "opacity: .3; cursor: auto;"}
    @media ${d.tablet} {
      display: flex;
    }
`;

export const CheckboxSt = styled.div`
    width: 100%;
    height: 100%;
    height: 36px;
    border-radius: 50px;
    border: 1px solid ${props=>props.borderActive ? `${c.black_text}` : `${c.grey_light}`};
    padding: 0 25px;
`;

export const TagContainerSt = styled.div`  
    @media ${d.tablet} {
        display:flex ;
        align-items: baseline;
    }
`;

export const BorderLineSt = styled.div`
  border-top: 1px solid #eeeeee;
`;

export const TitleSt = styled(H3)` 
    padding: 30px 10px 10px 0;
    @media ${d.tablet} {
      display: inline-block;
      padding: 30px 10px 0 0;
    }
`;

export const MobileSt = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    @media ${d.tablet} {
        display: none;
    }
`;

export const MobileHalfSt = styled.div`
    width: 50%;
`;

export const MobileHalfRightSt = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const FoundSt = styled.div`
    position: fixed;
    width: calc(100% - 40px);
    z-index: 111;
    top: 70vh;
    margin: 0 20px;
    @media ${d.tablet} {
        display: none;
    }
`;