import styled from "styled-components"
export const SvgStyled = styled.svg` 
    &.keyboard_arrow_left {
        transform:  rotate(90deg);
    }

    &.keyboard_arrow_top, &.download_top, &.arrow_townward  {
        transform:  rotate(180deg);
    }

    &.keyboard_arrow_right {
        transform:  rotate(270deg);
    }
`;