import React from 'react';
import { H4 } from "../../styles/typography/typography.js";
import Popup from '../../components/Popup/Popup.jsx';
import {
  AdditionalWrapStyled,
  AdditionalContainerStyled,
  VideoWrapStyled,
  VideoPreviewStlyed,
  VideoPlayStlyed,
  VideoTextStlyed,
  WrapStyledNotLine,
} from '../Tabs-additional/style.js';

import {
  PopupFrameStyled
} from '../Popup/style.js';

import {
  MenuStyledVideo,
  MenuItemStyled,
  MenuItemStyledActive,
  TabsSmallHeader
} from '../Tabs/style.js';
import i18n from "i18next";


class TabsVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsSmall: 0
    };
  }

  handletabsSmallClick = (item) => {
    this.setState(() => ({tabsSmall: item}));
  }

  render() {
    const {season, trailerBlock, handleClick, popupIsOpen, fotosIndex, handleClickPlus, handleClickMinus, trailer, seasonsNow} = this.props;

   
    return (
      <React.Fragment>

        {trailerBlock.length > 0 && <>
          <H4 color="black_text">{i18n.t(`catalog.project.${0}.trailer`)}</H4>
          <AdditionalWrapStyled>
            <AdditionalContainerStyled>
            {trailerBlock.map((item, index) =>
              <VideoWrapStyled key={index}>
                <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                <VideoPlayStlyed width="60" height="60" viewBox="0 0 80 79" fill="none" onClick={() => handleClick(index, true)}>
                  <g opacity="0.6"><circle cx="40.2303" cy="39.2323" r="32.6932" fill="white"/></g>
                  <path d="M51.6728 40.0491L35.3262 50.6744L35.3262 29.4238L51.6728 40.0491Z"/>
                </VideoPlayStlyed>
                <VideoTextStlyed>
                  {item.title} | {item.duration_text}
                </VideoTextStlyed>
              </VideoWrapStyled>
            )}
            </AdditionalContainerStyled>
          </AdditionalWrapStyled>
        </>}

        {season.length > 0 && <>
          <MenuStyledVideo>
            <TabsSmallHeader>{i18n.t(`catalog.project.${0}.episodes`)}</TabsSmallHeader>
            {
              seasonsNow.map((tabs, index) =>
              index === this.state.tabsSmall
                ? <MenuItemStyledActive onClick={()=>this.handletabsSmallClick(index)} key={index}>{tabs}&nbsp;{i18n.t(`catalog.project.${0}.season`)}</MenuItemStyledActive>
                : <MenuItemStyled onClick={()=>this.handletabsSmallClick(index)} key={index}>{tabs}&nbsp;{i18n.t(`catalog.project.${0}.season`)}</MenuItemStyled>
              )
            }
          </MenuStyledVideo>
        </>}

        <WrapStyledNotLine>
          <AdditionalContainerStyled>
          {season[this.state.tabsSmall] && season[this.state.tabsSmall].map((item, index) =>
            <VideoWrapStyled key={index}>
              <VideoPreviewStlyed src={item.preview_ru} width="300" height="168" />
              <VideoPlayStlyed width="60" height="60" viewBox="0 0 80 79" fill="none"
                onClick={() => handleClick(index)}>
                <g opacity="0.6"><circle cx="40.2303" cy="39.2323" r="32.6932" fill="white"/></g>
                <path d="M51.6728 40.0491L35.3262 50.6744L35.3262 29.4238L51.6728 40.0491Z"/>
              </VideoPlayStlyed>
              <VideoTextStlyed>
                {i18n.t(`catalog.project.${0}.season`)} {seasonsNow[this.state.tabsSmall]} | {i18n.t(`catalog.project.${0}.episode`)} {item.episode_number} | {item.duration_text}
              </VideoTextStlyed>
            </VideoWrapStyled>
          )}
          </AdditionalContainerStyled>
        </WrapStyledNotLine>

      {
        popupIsOpen
          ? <Popup
            arrows={true}
            handleClickMinus={handleClickMinus}
            handleClick={handleClick}
            handleClickPlus={handleClickPlus}  
            fotosAll={trailer ? trailerBlock.length : season[this.state.tabsSmall].length } 
            fotosIndex={fotosIndex}>
              <PopupFrameStyled src={trailer ? trailerBlock[fotosIndex].embed_url : season[this.state.tabsSmall][fotosIndex].embed_url}/>
            </Popup>
          : null
      }
      </React.Fragment>
    );
  }
};

export default TabsVideo;
