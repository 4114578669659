import {
    FILTER_SAVE, GET_FILTER_MENU
} from "../types"

const initialState = {
    filterSave: {
        saveCategoryFilter: "0",
        saveChannelFilter: "0",
        saveDurationFilter: "0",
        saveFormatFilter: "0",
        saveGenreFilter: "0",
        saveQualityFilter: "0",
        saveSearchWord: "",
        saveYearFilter: "0"
    },
    filterMenu: [],
}

export const filterSaveReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case FILTER_SAVE:
            return {
                ...state,
                filterSave: payload,
            };
        case GET_FILTER_MENU:
            return {
                ...state,
                filterMenu: payload,
            };
        default:
            return state;
    }
}