import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import { Medium } from "../../styles/typography/typography"
import { device } from "../../styles/variables.js"


export const PopupLoginSt = styled.div`
   background-color: ${c.white};
   width: 100%;
   box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
   max-width: 500px;
   width: 100%;
        padding: 0;
        margin: 0 auto;
`;


export const ContentSt = styled.div`
        display:block;
        padding: 0 15px;
        max-width: 500px;
        @media ${device.tablet} {
            padding: 0 80px; 
            min-width: 500px;
        }  
`;

export const FormSt = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TabContainerSt = styled.div`
    padding: 25px 0 60px;
    
`;

export const ButtonWrapSt = styled.div`
    /* padding: 15px 0 0; */
    padding: 45px 0 0;
    width: 100%;
    display:flex;
    justify-content: center;
    button {
            @media (max-width: 320px) {
                max-width: 250px;
            }
        }
`;

export const InfoFormSt = styled(Medium)`
    text-align: left;
    margin-top: 30px;
    margin-bottom: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-height: 150px;
    display: flex;
    align-items: center;
    text-align: center;

    white-space: pre-line;
`;

export const TitleResetSt = styled.div`
    padding-left: 30px;
    margin-bottom: 45px;
`;