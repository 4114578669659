import React from 'react'
import {DivStBlueblock, DivStBlueblockimg, TextSTyled} from './style.js'
import {H3, Big, Small }  from "../../styles/typography/typography"
import i18n from "i18next"
import {ContainerCatalogStyled} from "../../components/Container/style"
import {Container} from "../../components/Container/Container"
// TODO: поменять (ContainerCatalogStyled на DivStContainerCatalog) (Container на DivStContainer) (TextSTyled на DivStText)
const NotFound = () => {
    return (
        <>
              <ContainerCatalogStyled>
                <Container>
                    <DivStBlueblock>
                        <DivStBlueblockimg>
                            <H3>404</H3>
                            <TextSTyled>
                                <Big dangerouslySetInnerHTML={{__html: i18n.t(`page404.the_page_does_not_exist`)}} />
                            </TextSTyled>
                            <Small>
                                <a href="/">{i18n.t(`page404.go_to_main_page`)}</a>
                            </Small>
                        </DivStBlueblockimg>
                    </DivStBlueblock>
                </Container>
              </ContainerCatalogStyled>
        </>
    );
}

export default NotFound