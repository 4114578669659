import styled from "styled-components"
import { colors, fonts, device} from "../../styles/variables.js"

export const AdditionalWrapStyled = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${colors.grey_light};

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const WrapStyledNotLine = styled(AdditionalWrapStyled)`
  border-bottom: 0px solid ${colors.grey_light};
`;

export const AdditionalContainerStyled = styled.div`
  max-width: 661.05px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
  }
`;


export const AdditionalTextStyled = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 12px;
  line-height: 12px;
  margin-top: 20px;
  font-family: ${fonts.font_family};
  font-weight: ${fonts.normal};
  color: ${colors.blue_active};

  &:last-child {
    margin-bottom: 100px;  
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      margin-bottom: 0px;  
    }
  }
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const AdditionalDownloadWrap = styled.div`  
  cursor: pointer;
  display: inline-block;
  width: 23.5%;
  height: auto;
  margin-right: 2%;
  margin-top: 2%;
  position: relative;

  &:nth-child(4n+4) {
    margin-right: 0;
  }
  &:nth-child(1) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }
  &:nth-child(2) {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }

  @media ${device.mobile} {
    width: 48%;
    margin-right: 0;
    margin-top: 4%;
  }
  img {
      width: 100%;
      height: auto;
      /*object-fit: cover;
      height: 5vw;*/
    }
  /*от 0 до 767 px*/
  @media ${device.mobile} {

    img {
      width: 100%;
      height: auto;
      /*object-fit: cover;
      height: 29vw;*/
    }
  }
`;

export const AdditionalDownloadStyled = styled.a`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px; 
  background: rgb(0,0,0, 0.4);
  &:hover {
    &::after {
      transform: scale(1.1)
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 5V11H14.17L12 13.17L9.83 11H11V5H13ZM15 3H9V9H5L12 16L19 9H15V3ZM19 18H5V20H19V18Z' fill='white'/%3E%3C/svg%3E");
    transition: transform .3s;
  }
`;

export const AdditionalLogo = styled.svg`
  fill: ${colors.blue_active};
  margin-right: 5px;
`;


export const VideoWrapStyled = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 2%;
  margin-bottom: 2%;
  width: 45%;
  background-color: #000;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-right: 0;
    margin-bottom: 5%;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const VideoPreviewStlyed = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  opacity: .7;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const VideoPlayStlyed = styled.svg`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  g {
    opacity: 0.6;
  }
  path {
    fill: #42A5F5;
  }
  &:hover {
    g {
      opacity: 1;
    }
  }
`;
export const VideoTextStlyed = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  line-height: 12px;
  font-family: ${fonts.font_family};
  font-weight: ${fonts.bold};
  color: ${colors.white};
`;

export const PopupFrameStyled = styled.iframe`
  display: block;
  width: 49vw;
  height: 27.7vw;
`;
