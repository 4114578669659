import React from 'react';
import {Container} from "../../components/Container/Container";
import Feedback from '../../components/Feedback/Feedback'
import {
   ContainerCatalogStyled
} from "../auth/style";

export default class FeedbackPage extends React.Component {

    render() {
        return(
            <ContainerCatalogStyled style={{ paddingTop: "100px"}}>
                <Container>
                    <Feedback /> 
                </Container>
            </ContainerCatalogStyled>
        )
    }
}