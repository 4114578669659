import React from 'react';
import { connect } from "react-redux";
import OutsideClickHandler from 'react-outside-click-handler';

import GetCards from "../../components/GetCards/GetCards";
import ToggleView from "../../components/ToggleView/ToggleView";
import Search from "../../components/Search/Search";
import Grid from "../../components/Grid/Grid";
import {Container} from "../../components/Container/Container";
import {DispatchMessage} from "../../components/DispatchMessage/dispatchMessage";

import {addToFavorites, deleteFromFavorites, requestFromManager,requestFromManagerbyContent} from "../../store/favorites/actions";
import {getCustomerInfo} from "../../store/customer/actions";

import { H3, Small, Medium } from "../../styles/typography/typography"
import Svg from "../../../src/assets/icons/icons.js"
import { colors as c } from "../../styles/variables.js"

import {
    ContainerCollectionSt,
    HeaderSt,
    LinkSt,
    LinkMockSt,
    TitleBlockSt,
    SettingsMenuSt,
    SettingsMenuWrapperSt,
    SettingsToggleSt,
    SettingsLeftSt,
    SettingsRigthSt,
    ItemSettingsSt,
    SpanSt,
    ContainerBlockSt,
    RecommendContainerSt,
    RecommendBlockSt,
    RecommendHeaderSt,
    RecommendAddBlockSt,
    SmallSt,
    HeaderTopSt,
    WrapItemSt,
    SecondLevelItemSt,
    SecondLevelSt
} from "./style";

import i18n from "i18next";

class Favorites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: false,
            dashboard: true,
            recommendIsOpen: false,
            chooseCard: false,
            allchooseCard: [],
            allchooseCardCollections: [],
            allchooseCardEvents: [],
            cards: [],
            menuWithRequestIsOpen: false,
            //попап после отправки на сервер
            dispatchMessageControl: false,
            requestFromManager: false,
            popupAddFavorites: false,
        };

        this._handleOpenMenuClick = this._handleOpenMenuClick.bind(this);
        this._onToggleViewClick = this._onToggleViewClick.bind(this);
        this._handleOpenRecommendBlockClick = this._handleOpenRecommendBlockClick.bind(this);
    }
    _handleOpenMenuClick = () => {
        this.setState((state) => {
            return {
                menuIsOpen: !this.state.menuIsOpen,
            }
        });
    }
    _handleCloseMenuClick = () => {
        this.setState((state) => {
            return {
                menuIsOpen: false,
            }
        });
    }

    _handleOpenMenuWithRequestClick = () => {
        this.setState((state) => {
            return {
                requestFromManager: false,
                menuWithRequestIsOpen: !state.menuWithRequestIsOpen,
            }
        });
    }

    _onToggleViewClick(x) {
        this.setState({
            dashboard: x
        });
        if(x !== this.state.dashboard){
            this.setState(() => {
                return { chooseCard: false }
            })
        }
    }

    _handleOpenRecommendBlockClick = () => {
        this.setState((state) => {
            return {
                recommendIsOpen: !state.recommendIsOpen,
            }
        });
    }

    _chooseCard() {
        this.setState(() => {
            return { chooseCard: !this.state.chooseCard }
        })
    }

    // запрос у менеджера
    _requestManager(){
        if(this.state.allchooseCard.length>0) {
            this.props.requestFromManagerAction(this.state.allchooseCard, this.props.token);
            this.setState(() => {
                return {
                    chooseCard: true,
                    allchooseCard: [],
                    allchooseCardCollections: [],
                }
            })
            setTimeout(()=>{
                this.setState(() => {
                    return {
                        menuWithRequestIsOpen: false,
                    }
                })
            }, 1500)

            setTimeout(()=>{
                this.setState(() => {
                    return {
                        chooseCard: false,
                    }
                })
            }, 1)
        }
    }

    //запрос на контент
    _handleContentRequestClick(){
        if(this.state.allchooseCard.length>0){
               this.props.requestFromManagerbyContentAction(this.state.allchooseCard, this.props.token);
               this.setState(() => {
                   return {
                       chooseCard: true,
                       allchooseCard: [],
                       dispatchMessageControl: true,
                     
                   }
               })
            setTimeout(()=>{
                this.setState(() => {
                    return {
                        menuWithRequestIsOpen: false,
                    }
                })
            }, 1500)

            setTimeout(()=>{
                this.setState(() => {
                    return {
                        chooseCard: false,
                    }
                })
            }, 1)
        }
    }

    //добавить все выбранные карты в state.allchooseCard
    _getallchooseCard(allCards, status, type){
        if(type==="catalog"){
            if(status==="add"){
                let add = this.state.allchooseCard;
                !this.state.allchooseCard.includes(allCards) && add.push(allCards);
                this.setState(() => {
                    return { allchooseCard: add }
                })
            }
            else if(status==="remove"){
                let remove = this.state.allchooseCard.filter(function (item){
                    return item !== allCards;
                });
                this.setState(() => {
                    return { allchooseCard: remove }
                })
            }
            else if(status==="addAll"){
                let add = this.state.allchooseCard;
                !this.state.allchooseCard.includes(allCards) && add.push(allCards);
                this.setState(() => {
                    return { allchooseCard: add}
                })
            }
            else if(status==="removeAll"){
                this.setState(() => {
                    return { allchooseCard: []}
                })
            }
        }
        else if(type==="collections"){
            if(status==="add"){
                let add = this.state.allchooseCardCollections;
                !this.state.allchooseCardCollections.includes(allCards) && add.push(allCards);
                this.setState(() => {
                    return { allchooseCardCollections: add }
                })
            }
            else if(status==="remove"){
                let remove = this.state.allchooseCard.filter(function (item){
                    return item !== allCards;
                });
                this.setState(() => {
                    return { allchooseCardCollections: remove }
                })
            }
            else if(status==="addAll"){
                let add = this.state.allchooseCardCollections;
                !this.state.allchooseCardCollections.includes(allCards) && add.push(allCards);
                this.setState(() => {
                    return { allchooseCardCollections: add}
                })
            }
            else if(status==="removeAll"){
                this.setState(() => {
                    return { allchooseCardCollections: []}
                })
            }
        }
    }

    //Отправка прочеканных карт на сервер в избранное
    _sendChooseCard(){
        if(this.state.allchooseCard.length>0){
            this.props.deleteFromFavoritesAction(this.state.allchooseCard, "catalog", this.props.token);
            this.setState(() => {
                return { allchooseCard: []}
            })
        }
        if(this.state.allchooseCardCollections.length>0){
            this.props.deleteFromFavoritesAction(this.state.allchooseCardCollections, "collections", this.props.token);
            this.setState(() => {
                return { allchooseCardCollections: []}
            })
        }
        this.setState(() => {
            return { chooseCard: false }
        })
    }

    // добавить подборку в избранное
    _addSelectionToFavorites(){
        this.props.addToFavoritesAction(this.state.cards, "catalog", this.props.token);
        this.setState(() => {
            return {
                chooseCard: false,
                allchooseCard: [],
                dispatchMessageControl: true
            }
        })
    }

    // получение title и id карт из коллеций
    _getCards(cards) {
        let newArraw = this.state.cards;
        cards.map((item)=>{
            newArraw.push(item.id);
        })
        this.setState(() => {
            return {
                cards: newArraw
            }
        });
    }

    componentDidMount() {
        this.props.getCustomerInfoAction(this.props.token);
    }

    componentDidUpdate(prevProps) {
        if(this.props.favoritesProject.length !== prevProps.favoritesProject.length || (this.props.messageallProjectsAdded !== prevProps.messageallProjectsAdded && this.props.messageallProjectsAdded)){
            if(this.state.dispatchMessageControl){
                this.setState(() => {
                    return { popupAddFavorites: true };
                });
                setTimeout(()=>{
                    this.setState(() => {
                        return { popupAddFavorites: false };
                    });
                }, 1500)
            }
            this.setState(() => {
                return { dispatchMessageControl: false };
            });
        }
        //попап после запроса у менеджера
        if(this.props.favoritesMessage !== prevProps.favoritesMessage){
            this.setState(() => {
                return { requestFromManager: true };
            });
            setTimeout(()=>{
                this.setState(() => {
                    return { requestFromManager: false };
                });
            }, 1500)
            this.setState(() => {
                return { dispatchMessageControl: false };
            });
        }

        if(this.state.allchooseCard.length===0 && this.state.allchooseCardCollections.length===0 && this.state.allchooseCardEvents.length===0 && this.state.menuWithRequestIsOpen){
            this.setState(() => {
                return { menuWithRequestIsOpen: false };
            });
        }
    }

    render() {
        const { selection_id, messageallProjectsAdded, count, favoritesMessage, manager_email, project, event, collection } = this.props;

        const chooseCardsCheck = (this.state.allchooseCard.length>0 || this.state.allchooseCardCollections.length>0 || this.state.allchooseCardEvents.length>0);  
        return (
            <>
                <ContainerCollectionSt>
                    {/* блок рекомендации менеджера  */}
                    {selection_id && <Container>
                        <RecommendBlockSt className={this.state.recommendIsOpen ? "opened" : ""} >
                            <RecommendContainerSt>
                                <RecommendHeaderSt className ="RecommendHeaderStyled">
                                    <div onClick={this._handleOpenRecommendBlockClick} className="reccommendTitle">
                                        <Medium
                                            extra_bold={(window.innerWidth < 768) ? "" : "extra_bold"}
                                            bold={(window.innerWidth < 768) ? "bold" : ""}
                                            uppercase> {i18n.t(`favorites.managers_recommendation`)}
                                        </Medium>
                                        <Svg
                                            className={this.state.recommendIsOpen ? "keyboard_arrow_top" : ""}
                                            type="keyboard_arrow" />
                                    </div>
                                    <RecommendAddBlockSt onClick={()=>this._addSelectionToFavorites()}>
                                        <Svg type="add" color={c.blue_active} />
                                        <SmallSt uppercase color={c.blue_active}> {i18n.t(`favorites.add_a_selection_to_favorites`)}</SmallSt>
                                        {(this.state.popupAddFavorites && window.innerWidth > 767) && <DispatchMessage message={messageallProjectsAdded ? i18n.t(`favorites.all_projects_have_added`) : i18n.t(`favorites.added_to_favorites`)} />}
                                    </RecommendAddBlockSt>
                                    {(this.state.popupAddFavorites && window.innerWidth < 768) && <DispatchMessage message={messageallProjectsAdded ? i18n.t(`favorites.all_projects_have_added`) : i18n.t(`favorites.added_to_favorites`)} />}
                                </RecommendHeaderSt>
                                <ContainerBlockSt recommendIsOpen={this.state.recommendIsOpen}>
                                    <Container className ="recommendCards">
                                        <GetCards
                                            getChannelContent={true}
                                            getSelections={true}
                                            sourceCards={`selections/${selection_id}`}
                                            type="catalog"
                                            title={""}
                                            description={""}
                                            cardDisplayType="carousel"
                                            tile={this.state.dashboard}
                                            _getCards={(cards)=>{this._getCards(cards)}}
                                        />
                                    </Container>
                                </ContainerBlockSt>
                            </RecommendContainerSt>
                        </RecommendBlockSt>
                    </Container>}

                    {/* конец блока рекомендации менеджера  */}
                    <Container padding="true">
                        <HeaderSt>
                            <HeaderTopSt>
                                <TitleBlockSt>
                                    <H3>{i18n.t(`favorites.favorites`)}</H3>
                                    <LinkMockSt><Small uppercase color={c.blue}>{count}</Small></LinkMockSt>
                                </TitleBlockSt>
                                {(window.innerWidth < 768)
                                    ? ""
                                    : <>
                                        <div style={{ display: 'none' }}>
                                            <Search
                                                iconSearch="search"
                                                placeholder={i18n.t(`favorites.add_by_name`)}
                                                searchBoxIsOpen={true}
                                            />
                                        </div>
                                        <ToggleView selected={this.state.dashboard ? "dashboard" : "list"} toggleViewChange={(x)=>this._onToggleViewClick(x)} />
                                    </>
                                }
                            </HeaderTopSt>
                            <OutsideClickHandler  onOutsideClick={this._handleCloseMenuClick}>
                            <SettingsMenuSt>
                                {(window.innerWidth < 768)
                                    ?
                                    <>
                                    
                                    <SettingsToggleSt onClick={()=>this._requestManager()}>
                                        <Svg type="mail_outline" color={chooseCardsCheck ? c.blue_active : c.grey_passive} />
                                      
                                    {this.state.menuIsOpen 
                                    ? 
                                        <div onClick={this._handleOpenMenuClick}
                                            className="toggle" aria-label={i18n.t(`favorites.open_the_menu`)} type="button">
                                            <span className="visually-hidden">{i18n.t(`favorites.open_the_menu`)}</span>
                                            <Svg type="close" />
                                        </div>
                                        : 
                                        <div onClick={this._handleOpenMenuClick}
                                            className="toggle" aria-label={i18n.t(`favorites.open_the_menu`)} type="button">
                                            <span className="visually-hidden">{i18n.t(`favorites.open_the_menu`)}</span>
                                            <Svg type="settings" />
                                        </div>
                                    }

                                    </SettingsToggleSt>
                                        {(this.state.requestFromManager && !this.state.menuIsOpen) && <DispatchMessage message={favoritesMessage[1].added.length>0 ? i18n.t(`favorites.request_has_been_sent`) : i18n.t(`favorites.request_already_exists`)} />}
                                    </>
                                    : ""
                                }

                                {/* выделить все, запрос у менеджера, удалить */}
                                <SettingsMenuWrapperSt className={this.state.menuIsOpen && window.innerWidth < 768 ? "opened" : ""}>
                                    <SettingsLeftSt>
                                        <form>
                                            <input type="checkbox" checked={this.state.chooseCard && "checked"} onChange={() => this._chooseCard()} /> {i18n.t(`favorites.select_all`)}
                                        </form>
                                    </SettingsLeftSt>
                                    <SettingsRigthSt>
                                        {(window.innerWidth < 768) 
                                         ?
                                            <>
                                                {manager_email &&
                                                <ItemSettingsSt className={this.state.chooseCard ? "active" : ""} onClick={()=>this._requestManager()}>
                                                    <Svg
                                                        type="mail_outline"
                                                        color={chooseCardsCheck? c.blue_active : c.grey_passive} />
                                                    <SpanSt color={chooseCardsCheck ? c.blue_active : c.grey_passive}>{i18n.t(`collections.request_content`)}</SpanSt>
                                                        
                                                </ItemSettingsSt>}
                                                {/* запрос на доп.материалы */}
                                                {manager_email &&
                                                <ItemSettingsSt onClick={()=>this._requestManager()}>
                                                    <Svg
                                                        type="add"
                                                        color={chooseCardsCheck ? c.blue_active : c.grey_passive} />
                                                    <SpanSt color={chooseCardsCheck ? c.blue_active : c.grey_passive}>{i18n.t(`profile.add_promo_materials`)}</SpanSt>
                                                </ItemSettingsSt>}
                                                {this.state.requestFromManager && <DispatchMessage message={favoritesMessage[1].added && (favoritesMessage[1].added.length>0 ? i18n.t(`favorites.request_has_been_sent`) : i18n.t(`favorites.request_already_exists`))} />}
                                            </>

                                         :

                                         manager_email &&
                                         <ItemSettingsSt>
                                            {/* открыть/закрыть меню запрос менеджеру */}
                                            <WrapItemSt className = "WrapItemStyled" onClick={chooseCardsCheck ? this._handleOpenMenuWithRequestClick : null}>
                                                <SpanSt color={chooseCardsCheck ? c.black : c.grey_passive}>{i18n.t(`collections.request_from_the_manager`)}</SpanSt>
                                                <Svg className={this.state.menuWithRequestIsOpen ? "keyboard_arrow_top" : ""} type="keyboard_arrow" 
                                                color={chooseCardsCheck ? c.black : c.grey_passive} />
                                            </WrapItemSt>

                                            {(this.state.menuWithRequestIsOpen) 
                                                && (
                                                <SecondLevelSt>
                                                    {manager_email &&
                                                        <SecondLevelItemSt onClick={()=>this._handleContentRequestClick()}>
                                                        <Svg type="mail_outline" color= {c.blue_active} />
                                                        <LinkSt >
                                                            <Small color= {c.blue_active}>{i18n.t(`collections.content`)}</Small>
                                                        </LinkSt>
                                                        </SecondLevelItemSt>
                                                    }
                                                    {manager_email &&
                                                        <SecondLevelItemSt onClick={()=>this._requestManager()}>
                                                            <Svg type="add" color= {c.blue_active} />
                                                            <LinkSt >
                                                                <Small color= {c.blue_active}>{i18n.t(`profile.add_promo_materials`)}</Small>
                                                            </LinkSt>
                                                        </SecondLevelItemSt>
                                                    }
                                                    {/* popup с инфой */}
                                                    {this.state.requestFromManager && 
                                                        <DispatchMessage message={favoritesMessage[1].added && (favoritesMessage[1].added.length>0 ? i18n.t(`favorites.request_has_been_sent`) : i18n.t(`favorites.request_already_exists`))} />
                                                    }
                                                </SecondLevelSt>
                                                )
                                            }
                                     </ItemSettingsSt>
                                     
                                    }
                                    <ItemSettingsSt onClick={()=>this._sendChooseCard()}>
                                        <Svg type="delete_outline"
                                            color={chooseCardsCheck ? c.black : c.grey_passive} />
                                        <SpanSt color={chooseCardsCheck ? c.black : c.grey_passive}>{i18n.t(`favorites.delete`)}</SpanSt>
                                    </ItemSettingsSt>
                                    </SettingsRigthSt>
                                    {(window.innerWidth < 768)
                                        ?
                                        <>
                                            <div style={{ display: 'none' }}>
                                                <Search
                                                    iconSearch="search"
                                                    placeholder={i18n.t(`favorites.add_by_name`)}
                                                    searchBoxIsOpen={true}
                                                />
                                            </div>
                                        </>
                                        : ""
                                    }
                                </SettingsMenuWrapperSt>
                                
                            </SettingsMenuSt>
                            </OutsideClickHandler>
                        </HeaderSt>
                        {project && <Grid
                            type="catalog"
                            title={""}
                            description={""}
                            cards={project}
                            cardsLength={project.length}
                            tile={this.state.dashboard}
                            chooseCard={this.state.chooseCard}
                            page={"favorites"}
                            _getallchooseCard={(allCards, status, type)=>this._getallchooseCard(allCards, status, type)}
                        />}
                        {event && (event.length>0 && <Grid
                            type="events"
                            title={i18n.t(`header.menuList.1.title`)}
                            cards={event}
                            cardsLength={event.length}
                            tile={this.state.dashboard}
                            chooseCard={this.state.chooseCard}
                            page={"favorites"}
                            _getallchooseCard={(allCards, status, type)=>this._getallchooseCard(allCards, status, type)}
                        />)}
                        {collection && (collection.length>0 && <Grid
                            type="collections"
                            title={i18n.t(`home.collections`)}
                            cards={collection}
                            cardsLength={collection.length}
                            tile={this.state.dashboard}
                            chooseCard={this.state.chooseCard}
                            page={"favorites"}
                            _getallchooseCard={(allCards, status, type)=>this._getallchooseCard(allCards, status, type)}
                        />)}
                    </Container>
                </ContainerCollectionSt>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        messageallProjectsAdded: store.favorites.messageallProjectsAdded,
        favoritesMessage: store.favorites.message,
        favoritesProject: store.favorites.project,
        selection_id: store.customer.customer.selection_id,
        isLoggedIn: store.auth.isLoggedIn,
        collection: store.favorites.collection,
        event: store.favorites.event,
        project: store.favorites.project,
        count: store.favorites.count,
        token: store.auth.dataToken.token,
        manager_email: store.customer.customer.managers && (store.customer.customer.managers[0] && store.customer.customer.managers[0].email),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addToFavoritesAction: (id, type, token) => dispatch(addToFavorites(id, type, token)),
        deleteFromFavoritesAction: (id, type, token) => dispatch(deleteFromFavorites(id, type, token)),
        requestFromManagerAction: (id, token) => dispatch(requestFromManager(id, token)),
        requestFromManagerbyContentAction: (id, token) => dispatch(requestFromManagerbyContent(id, token)),
        getCustomerInfoAction: (token) => dispatch(getCustomerInfo(token)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Favorites)); 
