import React from 'react';
import { colors as c } from "../../styles/variables.js"
import Svg from "../../../src/assets/icons/icons.js"
import {
    ContainerStyled,
    SvgSlyled
} from '../ToggleView/style.js';
import { connect } from 'react-redux'
import { switchViewInCatalogAction } from '../../store/switchView/actions'

const ToggleViewRed = ({switchView, _switchViewInCatalog}) => {

    return (
        <ContainerStyled>
            <SvgSlyled className={!switchView ? "selected" : null} onClick={() => _switchViewInCatalog(false)}>
                <Svg width = '24' height = '24' type = {"dashboard"} color = {c.blue_active}/>
            </SvgSlyled>
            <SvgSlyled className={switchView ? "selected" : null} onClick={() => _switchViewInCatalog(true)}>
                <Svg width = '24' height = '24' type = {"list"} color = {c.blue_active}/>
            </SvgSlyled>
        </ContainerStyled>
    )
}

const mapStateToProps = (store) => {
  return {
    switchView: store.switchViewInCatalog.switchViewInCatalog
  };
}

const mapDispatchToProps = dispatch => {
  return {
    _switchViewInCatalog: (boolen) => dispatch(switchViewInCatalogAction(boolen))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleViewRed);