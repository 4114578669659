
import React from "react";
import Button from '../Button/Button'
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom"
import Svg from "../../assets/icons/icons"
import {
    SearchWrapSt,
    CloseIconSt,
    SearchIconSt,
    SearchInputSt,
    TuneIconSt,
    SubmitInputSt,
} from './style';
class Search extends React.Component {

    constructor(props) {
        super(props);
        this.allSearchInput = React.createRef();
        this.searchInput = React.createRef();
        this.formRef = React.createRef();
    }

    // поиск в фильтре
    filterSearchSubmit = (e) => {
        const { _click } = this.props;
        e.preventDefault();
        _click(this.searchInput.current.value);
    }

    // общий поиск по сайту
    searchSubmit = (e) => {
        const { _wholeSiteSearch, history } = this.props;
        e.preventDefault();
        const searchWord = this.allSearchInput.current.value;

        if (searchWord) {
            _wholeSiteSearch(searchWord);
            history.push(`/search`);
        }
    }

    // сброс фильтра и поиска
    resetFilter = (e) => {
        const { _clearFilter } = this.props;
        e.preventDefault();

        _clearFilter();
        this.formRef.current.reset();
    }

    render() {
        const {
            searchBoxIsOpen = "true",
            iconSearch = "close",
            placeholder = i18n.t(`filter.search`),
            handleOpenSearchBoxClick,
            isWrap = true,
            isButtonShow = false,
            size = "400px",
            className,
            tune,
            tuneFrame,
            _click,
            filter,
            searchWord } = this.props;

        // верстка строки поиска для фильтра 
        const contentSearchForFilter =
            <>
                <SearchWrapSt size={size}>
                    {searchBoxIsOpen
                        ?
                        (iconSearch === "search")
                            ?
                            // иконка поиска
                            <SearchIconSt
                                inputRef={el => this.inputElement = el}
                                onClick={
                                    !filter
                                        ? handleOpenSearchBoxClick
                                        : !searchWord
                                            ? (e) => { this.filterSearchSubmit(e) }
                                            : (e) => { this.resetFilter(e) }
                                }>
                                {!searchWord && <Svg type="search" />}
                                {searchWord && <Svg type="close" />}
                            </SearchIconSt>
                            :
                            // иконка закрыть поиск
                            <CloseIconSt onClick={handleOpenSearchBoxClick} >
                                <Svg type="close" />
                            </CloseIconSt>
                        :
                        <SearchIconSt onClick={handleOpenSearchBoxClick} >
                            <Svg type="search" />
                        </SearchIconSt>
                    }
                    {tune && <TuneIconSt onClick={() => this.props._openFilter()}>
                        {tuneFrame && <Svg type="frame" />}
                        <Svg type="tune" />
                    </TuneIconSt>}
                    <form
                        ref={this.formRef}
                        onSubmit={(e) => this.filterSearchSubmit(e)}
                    >
                        <SearchInputSt
                            ref={this.searchInput}
                            type="text"
                            placeholder={placeholder}
                            className={searchBoxIsOpen ? "opened" : ""}
                            name="search-form"
                            id="searchInput"
                        />
                        {!filter && <SubmitInputSt />}
                    </form>
                </SearchWrapSt>
                {isButtonShow &&
                    <Button
                        label={i18n.t(`filter.to_find`)}
                        size="middle"
                        _click={() => _click(this.searchInput.current.value)} />
                }
            </>

        // верстка строки поиска в шапке
        const contentSearchForHeader =
            <>
                {searchBoxIsOpen
                    ?
                    (iconSearch === "search")
                        ?
                        <SearchIconSt onClick={handleOpenSearchBoxClick}>
                            <Svg type="search" />
                        </SearchIconSt>
                        :
                        <CloseIconSt onClick={handleOpenSearchBoxClick}>
                            <Svg type="close" />
                        </CloseIconSt>
                    :
                    <SearchIconSt className={className} onClick={handleOpenSearchBoxClick}>
                        <Svg type="search" />
                    </SearchIconSt>
                }
                <form
                    onSubmit={(e) => this.searchSubmit(e)}
                    autoComplete="off">
                    <SearchInputSt
                        ref={this.allSearchInput}
                        type="text"
                        placeholder={placeholder}
                        className={searchBoxIsOpen ? "opened" : ""}
                        name="search-form"
                    />
                    <SubmitInputSt type="submit" />
                </form>
            </>
        return (
            (isWrap)
                ?
                <>
                    {/* верстка строки поиска для фильтра  */}
                    {contentSearchForFilter}
                </>
                :
                <>
                    {/* верстка строки поиска в шапке */}
                    {contentSearchForHeader}
                </>
        )
    }
}

export default withRouter(withTranslation()(Search));
