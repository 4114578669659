import styled from "styled-components"
import { device } from "../../styles/variables.js"

export const PopupSt = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100%;
    overflow: auto;
    background-color: rgba(49, 57, 78, .8);
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: scroll;
`;

export const PopupWrapSt = styled.div`
    position: fixed;
    width: auto;
        @media (min-width: 1280px) { 
            min-height: 724px;
            margin: 4% auto 0;
        }

        @media (min-width: 1500px) { 
            min-height: 860px;
            margin: 5% auto 0;
        }

`;
export const PopupCloseSt = styled.a`
    display:block;
    position: absolute;
    z-index: 101;
    right: 0px;
    top: -20px;
    width: 16px;
    height: 16px;
    cursor:pointer;
    transition: transform .3s;
        &:hover{
            transform: rotate(180deg);
        }

    &:before, &:after {
        content: "";
        position: absolute;
        left: 7px;
        height: 16px;
        width: 2px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    /* 768 - ... */
    @media ${device.tablet} {
        right: -24px;
        top: -24px;
        width: 22px;
        height: 22px;
        &:before, &:after {
            left: 9px;
            height: 22px;
            width: 3px;
        }
    }
`;