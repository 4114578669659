import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import {Small, Medium} from "../../styles/typography/typography"
import { device } from "../../styles/variables.js"


export const PopupLoginStyled  = styled.div`
   background-color: ${c.white};
   /* width: calc(100% - 40px);
   margin: 100px 20px;  */
   width: 100%;
   margin: 100px auto; 
   box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
   @media ${device.tablet} {
        max-width: 800px;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        min-height: 515px;
    } 
`;

export const InputWrapStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    &.half {
        width: 100%;
        & + .half {
            margin-top: 30px;
        }
        @media ${device.tablet} {
            width: 48%;
            & + .half {
                margin-top: 0;
            }   
        }
    }
`;

export const  InputIconWrapStyled = styled.div`
    position: absolute;
    right: 20px;
    z-index: 88;
    display: flex;
    cursor: pointer;
`;

export const TabContainerStyled= styled.div`
    padding: 50px 0;
`;

export const LinkStyle = styled(Medium)`
    cursor: pointer;
`;

export const InfoFormStyled= styled(Medium)`
  text-align: center;
  margin-bottom: 30px;
`;

export const ContentStyled= styled.div`
    display: none;
 
    &.opened {
        display:block;
        padding: 0 15px;
       
        button {
            @media (max-width: 320px) {
                max-width: 250px;
            }
        }
        @media ${device.tablet} {
            padding: 0 50px;
            min-width: 500px;
        }  
    }

    &:first-child(.opened) {
        width: 50%;
        @media ${device.tablet} {
            width: 32%;
        }
    }
    
    &:last-child.opened {
        padding: 0 15px;
        @media ${device.tablet} {
            padding: 0 50px;
        }  
        @media ${device.laptop} {
            padding: 0 50px;
            min-width: 800px;
        } 
        @media ${device.desktop} {
            padding: 0 50px;
            min-width: 800px;
        } 
    }
`;

export const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 30px; */
    
`;

export const  ListStyled = styled.ul`
    display: flex;
`;

export const  ItemStyled = styled.li`
    list-style: none;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 20px 0;

    &:first-child:not(.active) {
        width:50%;
        @media ${device.tablet} {
            width: 32%;
        }
    }
    &:last-child.active {
        width:50%;
        @media ${device.tablet} {
            width: 68%;
        }
    }
    &:not(.active) {
        box-shadow: inset -4px -4px 20px 1px rgba(66, 165, 245, 0.05), inset -4px -4px 10px 1px rgba(66, 165, 245, 0.08);
    }

`;

export const RememberBlockStyled = styled.div`
    justify-content: flex-start;
    width: 100%;
    padding-left: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 40px;
`;

export const  TextBlockStyled = styled.div`
    margin-top: 20px;
`;
export const RowStyled = styled.div`
    width: 100%;
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 15px 0;

    @media ${device.tablet} {
        flex-direction: row;
    }  
`;

export const ButtonWrapStyled = styled.div`
    margin-top: 30px;
    &.restore {
        margin-top: 15px;
    }
`;
export const SelectMultipleStyled = styled.div`
    position: relative;
`;

export const SelectOptionsStyled = styled.div`
    position: absolute;

    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    color: #363b3e;
    display: none;
    left: 0;
    max-height: 221px;
    overflow-y: scroll;
    top: 50px;
    width: 100%;
    z-index: 5;
`;

export const ErrorStyled = styled(Small)`
    color: ${c.error};
    display: block;
`;

export const ErrorBlockStyled =styled.div`
    margin-top:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;