import styled from "styled-components"

import { colors as c } from "../../styles/variables.js"
import { device as d } from "../../styles/variables.js"

import {Overline as Overline} from  "../../styles/typography/typography";
import {H4 as H4} from  "../../styles/typography/typography";
import {Small as Small} from  "../../styles/typography/typography";
import {Link} from "react-router-dom";

export const CardSt = styled.div`
    width: 100%;
    @media ${d.tablet}{
       margin-top: 10px;
       margin-bottom: 10px;
       margin-right: 2%;
       &:nth-child(2n-1){
            margin-right: 7%;
       }
    }
`;

export const ContainerSt = styled.div`
    display: flex;
    position: relative;
`;

export const BookmarkSt = styled.div`
  position: relative;
  width: ${props=>props.chooseCard ? "50%" : "100%"};
  height: 24px;
  background: rgb(49, 57, 78, ${props=>props.opacity ? "1" : ".6"});
  cursor: pointer;
`;

export const LinkSt = styled(Link)`
    color: ${c.black_text};
    text-decoration: none;
    overflow: hidden;
    height: 100%;
    display: block;
    transition: box-shadow .3s ease;
`;

export const ImgContainerSt = styled.div`
    width: 100px;
    height: 100px;
    @media ${d.tablet} {
       min-width: 120px;
       ${props=>props.openCard && "height: 140px;"}
    }
`;

export const ImgSt = styled.img`
   width: 70px;
   height: auto;
   display: block;
   @media ${d.tablet}{
        transition: .3s width;
        ${props=>props.openCard && "width: 100px;"}
   }
`;

export const ContentSt = styled.div`
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TitleSt = styled(H4)`
    margin-right: 30px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
    @media ${d.tablet} {
        max-width: 300px;
    }
`;

export const ArrowSt = styled.div`
    display: none;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: -10px;
    width: 30px;
    height: 30px;
    ${props=>props.openCard && "transform:rotate(180deg);"}
    background: url(../images/arrow.svg) 10px no-repeat;
    @media ${d.tablet} {
       display: block;
    }
`;

export const HiddenContentSt = styled.div`
    
`;

export const InfoSt = styled(Overline)`
    display: block;
    margin: 15px 0;
    color ${c.grey_text};
`;

export const DiscriptionSt = styled(Small)`
    display: block;
    height: 0;
    opacity: 0;
    padding-right: 20px;
    max-height: 85px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
    color ${c.black_text};
    ${props=>props.openCard && "height: auto; opacity: 1; transition: .3s opacity"}
`;

export const FooterSt = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;
    // @media ${d.tablet}{
    //    margin: 15px 0;
    // }
`;

export const FooterLeftSt = styled.div`
    display: flex;
    align-items: center;
     width: 33.3%;
`;

export const DiscriptionChannelSVGSt = styled.svg`
   transform: scale(.6) translate(-30%, 0);;
   fill: ${c.black_text};
`;

export const DiscriptionRARSSt = styled.span`
    font-size: 10px;
    color: ${c.black_text};
`;

export const DateSt = styled(Overline)`
    display: block;
`;

export const TrailerContainerSt = styled.a`
    text-decoration: none;
    color: ${c.white};
    display: flex;
    margin-right: 8px;
    align-items: center;
    cursor: pointer;
    @media ${d.laptop}{
        width: 33.3%;
    }
`;

export const TrailerSt = styled(Overline)`
    display: none;
    margin-right: 8px;
    color ${c.blue_active};
    @media ${d.laptop}{
        display: block;
    }
`;

export const ToFavoritesContainerSt = styled.div`
    display: none;
    @media ${d.tablet}{
        width: 50%;
        display: flex;
        align-items: center;
    }
`;

export const ToFavoritesSt = styled(Overline)`
    color ${c.blue_active};
    width: 100%;
    text-align: right;
    cursor: pointer;
`;

export const LineSt = styled.hr`
    color: #eeeeee;
    opacity: 0.3;
    margin: 20px 0;
`;

export const ChooseCardTopSt = styled.div`
    position: absolute; 
    top: 0; 
    width: 100%; 
    height: 100%; 
    background: #ffffff; 
    opacity: .7; 
    z-index: 9;
`;