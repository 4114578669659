import React from "react";

import {
  WrapperSt,
  InputSt,
  ErrorWrapperSt,
  ErrorSt,
} from "./style";

const TextField = (
  {
    handleFieldChange,
    type = "text",
    primary = true,
    required,
    placeholder,
    minLength,
    maxLength,
    name,
    width,
    className,
    disabled = false,
    value,
    error }) => {
  return (
    <>
      <WrapperSt width={width}>
        <InputSt
          type={type}
          placeholder={placeholder}
          name={name} required={required}
          minLength={minLength}
          maxLength={maxLength}
          primary={primary}
          disabled={disabled}
          onChange={handleFieldChange}
          value={value}
          className={className}
        />
        <ErrorWrapperSt>
          <ErrorSt>{error}</ErrorSt>
        </ErrorWrapperSt>
      </WrapperSt>
    </>
  )
};

export default TextField;