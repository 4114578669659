import styled from "styled-components"
import {fonts, device} from "../../styles/variables";

export const WrapAboutSt = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 65px;
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-bottom: 30px;  
  }
`;

export const ItemSt = styled.div`
  width: 75%;

  &:last-child {
    width: 25%;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: none;
    }
  }
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const WrapTopSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
  }

`;

export const IconSt = styled.div`
  cursor: pointer;
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
  }

  &.close svg {    
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      transform: rotate(180deg)
    }
  }
`;

export const HeaderSt = styled.div`
  font-family: ${fonts.font_family};
  font-size: 16px;
  line-height: 16px;
  font-weight: 800;
  padding-bottom: 15px;
  text-transform: uppercase;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
  }
`;

export const WrapMobSt = styled.div`    
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
  }
  &.close {    
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: none;
    }
  }
`;

export const TextSt = styled.span`
  font-family: ${fonts.font_family};
  font-size: 14px;
  line-height: 16px; 
  font-weight: 400;
`;

export const LogoSt = styled.div`
  width: 100%;
  text-align: right;
  img {
    width: auto;
    max-width: 100%;
  }  
`;
