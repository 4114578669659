import React from 'react';
import {ApprovalErrorStyled, ApprovalStyled} from "./style";
import CheckboxNew from "../CheckboxNew/CheckboxNew";
import i18n from "i18next";
import {Medium} from "../../styles/typography/typography";

const Approval = ({checked, approvalError, link, approvalCheck}) => {
    return (
        <ApprovalStyled className={approvalError && 'approval-error'}>
            <div>
                <div onClick={() => approvalCheck()}>
                    <CheckboxNew
                        name="approval"
                        checked={checked}
                    />
                </div>
                <Medium>
                    <span onClick={() => approvalCheck()}>{i18n.t(`login.approval1`)}</span>
                    {(i18n.language === 'en') ?
                        <a className="approval" href={`documents/approval_${link}_en.pdf`} target="_blank">{i18n.t(`login.approval2`)}</a>
                        :
                        <a className="approval" href={`documents/approval_${link}_ru.pdf`} target="_blank">{i18n.t(`login.approval2`)}</a>
                    }
                </Medium>
            </div>
            {approvalError && <ApprovalErrorStyled>{i18n.t(`login.approvalErrorText`)}</ApprovalErrorStyled>}
        </ApprovalStyled>
    );
};

export default Approval;