import styled from "styled-components";
import {colors as c} from "../../styles/variables";
import {Small} from "../../styles/typography/typography";


export const CheckboxSt = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer; 
`;

export const LabelSt = styled(Small)`
    display: block;
    margin-left: 10px;
`;

export const CheckboxSquareSt = styled.div`
   display: flex;
   &>svg{
        fill: ${props=>props.checked ? c.blue_active : c.black_text};
    }
`;