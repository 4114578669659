
import styled from "styled-components"
import { NavLink } from 'react-router-dom';
import { colors as c } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"
import { Medium } from "../../styles/typography/typography"


export const ContainerHeaderSt = styled.div`
    width:100%;
    box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${c.white};
    z-index: 99;
    @media ${device.tablet} {
        position: relative;
    }    
`;
export const HeaderSt = styled.header`
    height: 60px;
    color: ${c.black}; 
    width:100%;
    margin: 0 auto;
    position: relative; 
    @media ${device.tablet} {
       height: 70px;
    }    
    @media ${device.laptopMax} {
        max-width:1260px;
        padding: 0;
    }
   @media ${device.desktop} {
       max-width:1260px;
       padding: 0;
   }
`;

export const HeaderWrapperSt = styled.div`
    position: relative;
    height: 100%;
    display:flex;
    flex-direction:column; 
    justify-content: center;

    &.navigation {
        position: relative;
        z-index: 1;
    
    }
    &.navigation__logo {
        position: absolute;
    } 
`;


export const HeaderSecondRowSt= styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width:1260px;
    margin: 0 auto;
   

    &.login {
        justify-content: flex-start;
        @media (min-width: 769px) {
            > div {
                margin: 0 auto;
                padding: 0 20px;
            }
            ;
        } 

        
        @media (min-width: 1250px) {
            > div {
                margin: 0 0 0 9.2%;
                padding: 0 0px 0 5px;
            }
            ;
        } 
        
        @media (min-width: 1360px) {
            > div {
                margin: 0 0 0 9.3%;
            }
            ;
        }
    }
`;

export const  WrapSecondRow = styled.div`
    background-color: ${c.black};
    padding: 15px 0; 
    box-shadow: rgba(66, 165, 245, 0.2) 0px 11px 7px -7px inset, rgba(66, 165, 245 , 0.1) 0px -11px 10px -7px inset;
    @media ${device.tablet} {
        padding: 15px;
    }
`;

export const LogoSt = styled(NavLink)`
    padding: 0;
    position: absolute;
    z-index: 2;
    left: 40px;
    @media ${device.tablet} {
        left: 0;
    }
`;

export const ToggleSt = styled.button` 
    
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2;
    display: block;
    width: 18px;
    height: 12px;
    background-color: ${c.white};
    cursor: pointer;
    border: none;
    
  
  
    @media ${device.tablet} {
        display: none;
    }

    span.visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
    } 

    &::before {
        content: "";
      
        position: absolute;
        top: 0;
        right: 0;
      
        width: 18px;
        height: 2px;
      
        background-color: ${c.black};
        box-shadow: 0 5px 0 0 ${c.black}, 0 10px 0 0 ${c.black};
      }
      
     &:active::before {
        background-color: rgba(255, 255, 255, 0.3);
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);;
    }
  
`;


export const MobileBlockSt = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 0; 
    z-index: 99; 
    @media ${device.tablet} {
        display: none; 
    }
`;

export const AccountIconSt = styled.span`
    display: flex;
    order: 2;
    margin-left: 30px;
    z-index: 95;
    @media ${device.tablet} {
        margin-left: 0;
    }
`;


export const BookmarkIconSt = styled(NavLink)`  
    display: flex;
    position: relative;
    order: -1;
    /* padding-left: 20px; */
    padding-right: 30px;
    @media ${device.tablet} {
        padding-left: 0;
    }

`;


export const ToggleLangSt = styled.div`
    z-index: 12;
    display: flex;
    
    text-transform: uppercase;
    padding-left: 40px;
    align-items:center; 
    cursor: pointer;

    span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
    }
  

    @media ${device.tablet} {
        right: 15px;
        position: relative;
        right: 0;
        padding-left: 0;
        span {
            transition: color .3s;
        }
        &:hover span:not(.lang) {color: ${c.blue_active} }
    }
`;

export const ArrowSt = styled.span`
    display : none; 
    @media ${device.tablet} {
        display : block;
        width: 10px;
        height: 6px;
        background: url('../images/arrow.svg') top left no-repeat;
        margin-left: 8px;
    }
    &.opened {
        transform: rotate(180deg);
    }

    
`;

export const NavSt = styled.nav`

    position: relative;
    width: 100%;
    height: 60px;
    margin: 0 auto;
    background-color: white;
    @media ${device.mobile} {
        &.closed ul,
        &.closed .toggle-lang {
            &.user-list-opened,
            &.user-list-opened ul {
            display: block;
            margin-left: calc(100vw + 20px);
            margin-top: -30px;
            }
        }
    }


    @media ${device.mobile} {
     .wrapper {
      transition: .7s left;
      position: fixed;
      top: 60px;
      right: 0;
      z-index: 10;
      left: ${props => props.left ? 0 : "-100%"};
   
      min-height: 100vh;
      width: 100vw;
      background-color: ${c.white};
      padding: 30px 20px 0;
      overflow: initial;
      margin-right: -20px;
      box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
     }
    }
    
    @media ${device.tablet} {
        position: relative;
        z-index: 1;
        height: 70px;
        &.closed .list-item,
        &.closed .toggle-lang{
            display: flex;
        }

    }

    @media ${device.tablet} {
        width: auto;
        margin: 0;
    }

    &.opened button {
        &::before,
        &::after {
            content: "";
      
            position: absolute;
            top: 50%;
            left: 0;
        
            width: 18px;
            height: 2px;
        
            background-color: ${c.black};
        }
      
        &::before {
          transform: rotate(45deg);
          box-shadow: none;
        }
      
        &::after {
          transform: rotate(-45deg);
        }
      
        &:active::before,
        &:active::after {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
`;

export const WrapperSt = styled.div`
    height: 60px;
    align-items: center;
    @media ${device.tablet} {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        height: 70px;
    }
    @media ${device.laptopMax} {
          justify-content: flex-end;
          margin-right: 40px;
          box-sizing: border-box;
      }

    @media ${device.desktop} {
          justify-content: flex-end;
          margin-right: 40px;
          box-sizing: border-box;
      }
`;

export const LinkSt = styled(NavLink)`
    display: flex;  
    padding: 12px 0 12px 60px;
    color: ${c.black_text};
    text-decoration: none;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    @media ${device.tablet} {
        padding: 0;
    }


    &.selected {
        background: rgba(66,165,245,0.1);
        @media ${device.tablet} {
            color: ${c.blue_active};
            span:not(.not-hover) {color: ${c.blue_active}};
            svg {fill: ${c.blue_active}};
            background: none       ;
        }
        
    }

    &.with-icon {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        @media ${device.tablet} {
            font-size: 0;
        }
        @media ${device.laptopMax} {
            font-size: 14px;
            line-height: 16px;
        }
        @media ${device.desktop} {
            font-size: 14px;
            line-height: 16px;
        }
        svg {
            @media ${device.mobile} {
                right: 0;
                position: absolute;
                left: 24px;
            }
        }
    }

    &.user-list-account {
        @media ${device.mobile} {
            padding: 20px 0 20px 60px;   
        }
            svg {
            @media ${device.mobile} {
                right: 0;
                position: absolute;
                left: 25px;
            }
        }
        
        }
       
`;

export const UserNameSt = styled(Medium)`
    @media ${device.laptopMax} {
        max-width: 110px;
        white-space: nowrap;    
        overflow: hidden; 
        text-overflow: ellipsis;  
    }
`;

export const ListSt = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;

    @media ${device.tablet} {
        display: flex;
        a svg {
            transition: fill .3s;
        }
        a span {
            transition: color .3s;
        }
        a:hover svg {fill: ${c.blue_active};}
        a:hover span:not(.not-hover) {color: ${c.blue_active};} 
    }
    span {
        @media ${device.tablet} { 
            font-size: 0;
            margin-left: 8px;
        } 
        @media ${device.laptopMax} {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
        @media ${device.desktop} {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
    }

`;


export const SiteListSt = styled(ListSt)`
    position:relative;
    @media ${device.tablet} {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: auto;
        border-bottom: none;
        margin-bottom: 0;

        flex-wrap: nowrap;
        width: auto;
        li {
            display: flex;
            justify-content: center;
            a {
                transition: color .3s;
                &:hover,  &:active {
                    color: ${c.blue_active}
                }
            }
           
            &:first-child {
                margin-left: 0;
            }
        }
    }
    @media ${device.laptopMax} {
        flex-wrap: nowrap;
        width: auto;
        margin-left: 0;
        width: 630px;
    }
    @media ${device.desktop} {
        flex-wrap: nowrap;
        width: auto;
        margin-left: 0;
        width: 630px;
    }
`;


export const DownloadLinkSt = styled.a`
    display: flex;  
    padding: 12px 0 12px 60px;
    color: ${c.black_text};
    text-decoration: none;
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    @media ${device.tablet} {
        padding: 0;
        svg {
            transition: fill .3s; 
        }
        span {
            transition: color .3s; 
        }
        &:hover svg {
            fill: ${c.black_text};
        }
    }

    &.with-icon {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        @media ${device.tablet} {
            font-size: 0;
        }
        
        @media ${device.laptopMax} {
            font-size: 14px;
            line-height: 16px;
        }
        @media ${device.desktop} {
            font-size: 14px;
            line-height: 16px;
        }
        svg {
            @media ${device.mobile} {
                right: 0;
                position: absolute;
                left: 24px;
            }
        }
    }
       
`;
export const BookmarkListSt = styled(ListSt)` 
    position: relative;
    border-top: 1px solid ${c.grey_light};
    margin-top: 30px;
    padding-top: 20px;
    span {
        @media ${device.mobile} {
            font-size: 14px;
            line-height: 16px;
        }
    }
    
    @media ${device.tablet} {
        padding: 0 35px 0 35px;
        border-right: 2px solid ${c.grey_light};
        margin-right: 25px;
        border-top: none;
        margin-top:0;
    }
    @media ${device.laptopMax} {
        padding: 0 75px 0 55px;
        flex-grow: 1;
        justify-content: center;
        margin-right: 20px;
        padding: 0;
    }

    @media ${device.desktop} {
        padding: 0 75px 0 55px;
        flex-grow: 1;
        justify-content: center;
        margin-right: 20px;
        padding: 0;
    }
 `;

export const FavoritesWrapSt = styled.div`
    position: relative;
    display: flex;
 `;

export const MiddleListSt = styled(ListSt)` 
    border-bottom: 1px solid ${c.grey_light};
    padding-bottom: 10px;
    margin-bottom: 20px;
    span {
        @media ${device.mobile} {
            font-size: 14px;
            line-height: 16px;
        }
    }
    
    @media ${device.tablet} {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: auto;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-left: 40px;
    }
    @media ${device.laptopMax} {
        margin-left:0;
    }
    @media ${device.desktop} {
        margin-left:0;
    }
`;

export const UserListSt = styled(ListSt)` 
    position: absolute;
    right: 20px;
    z-index: 99;
    display: none;
    @media ${device.tablet} {
        &.user-list-opened {
            display: block;
        }
    }

    a {
        font-size: 0;
        padding: 0;
    }
    
    @media ${device.tablet} {
        position: relative;
        display: flex;
        margin: 0 25px 0 10px;
        right: 0;
       
    }
    @media ${device.laptopMax} {
        width: auto;
        margin: 0 35px 0 0;
        a {
            font-size: initial;
        }
    }
    @media ${device.desktop} {
        width: auto;
        margin: 0 35px 0 0;
        a {
            font-size: initial;
        }
    }

    &.user-list-opened {
        display: block;
        position: absolute;
        right: 0;
        z-index: 99;
        width: 100%;
        top: 60px;
        background: white;

        .profile-name {
            display: flex;
            align-items: center;
        }
       
        span {
            @media ${device.mobile} {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
   
`;


export const CountBookmarkSt = styled.div`  
    background-color: ${c.magenta};
    color: ${c.white}; 
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
    span.count {
        font-size: 10px;
        line-height: 10px;
        margin-left: 0;
    }
    &.opacity {
        opacity: 0;
    }
    @media ${device.tablet} {
        right: -5px;
    }
    @media ${device.laptopMax} {
        right: -20px;
    }
    @media ${device.desktop} {
        right: -20px;
    }

    
`;

export const ItemSt = styled.li`  
    color: ${c.black_text};
    display: flex;
    margin-right: -20px;
    margin-left: -20px;
    transition: background .3s; 
    &:hover,  &:active{
        background: ${c.blue_highlight};
    }

    @media ${device.tablet} {
        margin-right: 15px;
        display: flex;
        margin-left: 0;
        &:last-child {
            margin-right: 0;
        }
        &:hover,  &:active{
        background: none;
        }

        span {
            white-space: nowrap;
        }
    }
    @media (min-width: 850px) {
        margin-right:30px;
       
    }
    @media ${device.laptopMax} {
        margin-left: 40px;
        margin-right: 0;
    }
    @media ${device.desktop} {
        margin-left: 40px;
        margin-right: 0;
    }

   

    svg {
        @media ${device.mobile} {
            right: 0;
            position: absolute;
            left: 24px;
        }
    }
`;


export const SecondLevelSt = styled.ul` 
    @media ${device.mobile} {
        width: 100vw;
        padding: 0px 20px 0;
        overflow: initial;
        margin-right: -20px;
        box-shadow: 0px 4px 5px rgba(66,165,245,0.12), 0px 1px 10px rgba(66,165,245,0.2);
        &.switch-lang {
            width: 100%;
            display:flex; 
            position: relative;
            box-shadow: none;
            li {
                border-top: none;
                span {
                    font-size: 14px;
                    line-height: 16px;
                }
                span.active {color: ${c.blue_active}}
                
                padding: 0;
                display: flex;
                padding-right: 40px;
                &:active, 
                &:hover {
                    background-color: transparent;
                }
            }
        }
        &.profile-menu {
            &:after {
            content: "";
            position: absolute;
            height: 100vh;
            width: 2px;
            background: #31394E;
            opacity: 0.8;
            width: 100%;
            width: 100vw;
            padding: 0px 20px 0;
            overflow: initial;
            margin-right: -20px;
            left:0;
            overflow: hidden;
            }
        }
        
    }

    position: absolute;
    top: 100%;
    
    margin-bottom: 0;
    background-color: ${c.white};
    z-index: 99;
    padding:10px 0;
    list-style: none;



    @media ${device.tablet} {
        padding: 10px 0;
        margin-right: initial;
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
        min-width: 255px;
        white-space: nowrap;

        &.switch-lang {
            display:none;
        }
        &.switch-lang.opened{
            display: block;
            min-width: 105px;
            right: -10px;
            &:hover span {color:initial};
        }
        right: -14px;
        border-radius: 10px;
        &:before {


            content: "";
      
        position: absolute;
        top: -5px;
        left: -5px;
        right: 10px;
        height: 25px;
        width:105%;
        background-color:transparent;
        }
    }
    @media ${device.laptopMax} {
        min-width: 220px;
    }
   
    @media ${device.desktop} {
        min-width: 220px;
    }
   

`;

export const SecondLevelItemSt = styled.li` 
    padding: 20px 0 20px 40px;
    cursor: pointer; 
    transition: background-color .3s; 
    &:active:not(.profile-name), 
    &:hover:not(.profile-name) {
        background-color: ${c.blue_highlight};
    }
    &.profile-name {
        cursor: initial;  
        padding: 30px 0 30px 40px;
      
    }
    &:not(.profile-name)
    {
        border-top: 1px solid #EEEEEE; 
    }
    @media ${device.tablet} {
        span {
            font-size:16px;
        }
        &.profile-name {
            padding: 10px 0 10px 30px;
        }
        padding: 10px 0 10px 30px;
        display: flex;
        align-items: center;
        &:not(.profile-name)
        {
            border-top: none; 
        }
       
    }
`;




export const LogosWrapSt = styled.div`
    max-width: 850px;
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
export const AdditionalWrapSt= styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.tablet} {
        display: flex;
        flex-direction: row;
    }
    @media ${device.laptopMax} {
        display: flex;
        flex-direction: row;
        width: 850px;
        justify-content: space-between;
        margin-right: -20px;
    }

    @media (min-width: 1248px) {
        margin-right: -10px;
    }
    @media (min-width: 1265px) {
        margin-right: 7px;
    }
    @media (min-width: 1360px) {
        margin-right: 15px;
    }
    
    @media (min-width: 1600px) {
        margin-right: 10px;
    }

    @media (min-width: 1650px) {
        margin-right: 15px;
    }

   
`;