import {
    SWITCH_VIEW_IN_CATALOG
} from "../types";

const initialState = {
    switchViewInCatalog: false
}

export const switchViewInCatalogReducer = (state = initialState, action) => {
    const { type, boolen } = action
    switch (type) {
        case SWITCH_VIEW_IN_CATALOG:
            return {
                ...state,
                switchViewInCatalog: boolen
            };
        default:
            return state;
    }
}