import styled from "styled-components"
import {colors, device, fonts} from "../../styles/variables.js"
import {
    CardSt,
    ArrowSt,
    FooterSt,
    TrailerContainerSt,
    ToFavoritesContainerSt,
    ToFavoritesSt
  } from "../CardList/style.js";

export const AnonsStyled = styled.div`
  padding: 0 0 30px; 
  font-size: 14px;
  line-height: 16px;
`;

export const WrapContentStyled = styled.div`
  display: block;
  width: 90%;
  margin-left: auto;
  padding: 20px 0;
  font-size: 14px;
  line-height: 16px;
  font-family: ${fonts.font_family};
  /*стили для проджект в профайле*/
  &.project {
    display: flex;
    width: 98%;
    margin-left: 2%;
    flex-wrap: wrap;
    ${FooterSt} {
      width: 100%;
    }
  }

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: calc(100vw - 40px);
    margin: 0;
  }

  /* стили для CardList*/
  ${CardSt} {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid ${colors.grey_light};
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
      padding-top: 20px;
      border-bottom: 0;
    }
    &.proj {
      padding-top: 0px;
    }
  }

  ${FooterSt} {
    width: 35%;

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 100%;
    }
  }

  ${ToFavoritesContainerSt} {

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: flex;
    }
  }

  ${ToFavoritesSt} {
    padding-right: 10px;

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: none;
    }
  }

  ${TrailerContainerSt} {
    width: auto;
  }

  ${ArrowSt} {
    right: 0;
  }
`;

export const BlockLeftStyled = styled.div`
  width: 70%;
`;
export const BlockRightStyled = styled.div`
  width: 30%;
`;
export const ContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.grey_light};
  flex-wrap: wrap;
  margin-top: 10px;
  width: auto;
  :last-child{
    border-bottom: 0;
  }
`;
export const ItemStyled = styled.div`
  margin: 0 5px 10px;
  border: 1px solid ${colors.grey_passive};
  color: ${colors.grey_passive};
  padding: 5px 10px;
  font-size: 12px;
  line-height: 12px;
  font-family: ${fonts.font_family};
  border-radius: 200px;
`;

export const TooltipProfileSt = styled.div`
  position: absolute;
  right: 3px;
  top: 40px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 5;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
  &.last {
    right: 25px;
    top: -40px;
  }
  &.last-dop {
    right: 25px;
    top: -73px;
  }
`;

export const TooltipItemProfileSt = styled.a`
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding: 10px 10px;
  border-bottom: 1px solid ${colors.grey_light};
  color: ${colors.black_text};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.blue_highlight};
  }
  &:last-child  {
    border-bottom: 1px solid transparent;
  }
`;
export const WrapperSt = styled.div`
  position: relative;
`;
export const ClickSt = styled.div`
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
`;
export const ClickRecommendSt = styled.a`
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  right: 0;
  cursor: pointer;
`;
export const TextRequestSt = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.black_text};
  font-family: ${fonts.font_family};
  max-width: 360px;
`;

export const WrapSt = styled.div`
`;