import React from 'react';
import { connect } from 'react-redux';

import { addToFavorites, deleteFromFavorites } from '../../store/favorites/actions';
import { dateFormation } from '../../services/utils';
import { channelListForCards as channelList } from '../../services/constants';

import {
  BookmarkSt,
  CardSt,
  ImgSt,
  LinkSt,
  LabelSt,
  TextSt,
  DateSt,
  ButtonSt,
  CenterSvgSt,
  ContainerFormSt,
  СhoosePictureContainerSt,
  FormСhooseSt,
  DiscriptionSt,
  OpenDiscriptionSt,
  CrossSt,
  TitleSt,
  DiscriptionContainerSt,
  DiscriptionContentSt,
  ProjectTextSt,
  ProjectDiscriptionSt,
  HrSt,
  FooterSt,
  FooterContentSt,
  FooterContainerSt,
  FooterContentASt,
  CardMarginRightSt,
  LabelWrapperSt,
  WrapperBookmarkSt,
  ChooseCardTopSt,
  LinkTitleSt,
  DiscriptionChannelSVGSt,
} from './style';

import i18n from 'i18next';

class Card extends React.Component {

  state = {
    openCardControl: ['width: 0; height: 0; z-index: 1;', 'opacity: 0;', 'opacity: 0'],
    openCard: false,
    exceptionFromLastCard: false,
    chooseCard: false,
    bookmarkCheck: (this.props.page === 'favorites' || this.props.isInFavourites) ? true : false,
    cardHiddenOnFavourites: false,
    isRequested: ((this.props.page === 'favorites' || this.props.page === 'collections' || this.props.page === 'collection') && this.props.is_requested),
  };

  componentDidUpdate(prevProps) {
    // при выборе "выбрать все" закрываем открытое описание
    if (this.props.chooseCard !== prevProps.chooseCard && prevProps.chooseCard === false) {
      this.props._getallchooseCard(this.props.id, 'addAll', this.props.page === 'favorites' && this.props.type);
      this.closeCard();
      this.setState(() => {
        return { chooseCard: true };
      });
    }
    else if (this.props.chooseCard !== prevProps.chooseCard && prevProps.chooseCard === true) {
      this.props._getallchooseCard(this.props.id, 'removeAll', this.props.page === 'favorites' && this.props.type);
      this.setState(() => {
        return { chooseCard: false };
      });
    }
    // при открытии второго описания закрываем первое
    if (this.props.closeAllCardsDiscription !== prevProps.closeAllCardsDiscription) {
      this.setState(() => {
        return {
          exceptionFromLastCard: false,
          openCardControl: ['width: 0; height: 0; z-index: 1;', 'opacity: 0', 'opacity: 0'],
        };
      });
    }

    if (this.props.favoritesProject !== prevProps.favoritesProject) {
      this.props.favoritesProject.forEach((item) => {
        if (item.id === this.props.id) {
          this.setState(() => {
            return {
              bookmarkCheck: true,
              chooseCard: false,
            };
          });
        }
      });
    }
    // если отправлен запрос на контент проекта, то проставляется галка об этом
    if (this.props.favoritesMessage !== prevProps.favoritesMessage) {
      this.props.favoritesMessage && this.props.favoritesMessage[1] && this.props.favoritesMessage[1].added && this.props.favoritesMessage[1].added.map((item)=>{
        if (item === this.props.id) {
          this.setState(() => {
            return {
              isRequested: this.props.page === 'favorites' || this.props.page === 'collections' || this.props.page === 'collection',
            };
          });
        }
      });
    }
  }

  // Открытие описания каточки проектов
  openCard() {
    this.setState(() => {
      return { openCard: true };
    });
    if (!this.props.discriptionOpenLeft && this.props._openDecrStepRight) {
      this.props._openDecrStepRight(this.props.index);
    }
    if (this.props._closeAllCardsDiscription) this.props._closeAllCardsDiscription(); // закрываем второе описание если оно открыто
    setTimeout(() => {
      this.setState(() => {
        return { openCardControl: ['width: 138%; height: 100%; z-index: 11;', 'opacity: 1; transition: opacity .2s .5s;', 'opacity: .7; transition: opacity .3s .5s;'] }
      });
      if (this.props.discriptionOpenLeft) {
        this.setState(() => {
          return { exceptionFromLastCard: true };
        });
      }
    }, 100);
  }

  // Закрытие описания каточки проектов
  closeCard() {
    this.setState(() => {
      return { openCardControl: ['width: 0; height: 0; z-index: 1;', 'opacity: 0', 'opacity: 0'] };
    });
    if (this.props.discriptionOpenLeft) {
      this.setState(() => {
        return { exceptionFromLastCard: false };
      });
    }
  }

  // выбрать карту/снять выбор карты
  chooseCard() {
    this.state.chooseCard ? this.props._getallchooseCard(this.props.id, 'remove', this.props.page === 'favorites' && this.props.type) : this.props._getallchooseCard(this.props.id, 'add', this.props.page === 'favorites' && this.props.type);
    this.closeCard();
    this.setState(() => {
      return { chooseCard: !this.state.chooseCard };
    });
  }

  // обработка клика на закладку
  _bookmark(id, type) {
    if (this.state.bookmarkCheck) {
      this.props.deleteFromFavoritesAction(id, type, this.props.token);
    } else {
      this.props.addToFavoritesAction(id, type, this.props.token);
    }
    this.setState(() => {
      return { bookmarkCheck: !this.state.bookmarkCheck };
    });
    if (this.props.page === 'favorites') {
      this.setState(() => {
        return { cardHiddenOnFavourites: true };
      });
    }
  }

  // после клика по карте, при переходе на страницу проекта, поднимаемся в самый верх экрана
  _clickTop() {
    window.scrollTo(0, 0);
  }

  render() {

    const {
      date_start, date_end, genres, token, page, type, isLoggedIn, zIndexCard, widthCard, id, title, slug, poster_en, poster_ru, discriptionOpenLeft, year_start, year_end, category, duration, channel, description, age_restriction, trailer_embed, handleClick
    } = this.props;

    // Получение списка с жанрами
    let genresList = '';
    if (genres) {
      genresList = genres.map((item, i) => {
        return ((i !== 0 ? ', ' : '') + item.title);
      });
    }

    // проверка необходимости чекбоксов и связанных сними элеменетов верстки
    const checkbox = token && (page === 'collections' || (page === 'favorites' && type !== 'events')) && isLoggedIn;

    // ссылка на проект, мероприятие или коллекцию в зависимости от значения type
    // если значение type === "collections", то используется id, в противном случае slug
    const link = `/${type}/` + (type === 'collections' ? id : slug);

    return (
        <>
        <CardSt zIndexCard={zIndexCard} exceptionFromLastCard={this.state.exceptionFromLastCard} widthCard={widthCard} style={this.state.cardHiddenOnFavourites ? { display: "none" } : { display: "block" }}>

            {isLoggedIn && (
                <WrapperBookmarkSt chooseCard={checkbox}>
                    {/* ЗАКЛАДКИ */}
                    <BookmarkSt onClick={() => this._bookmark([id], type)} isOpacity={this.state.bookmarkCheck} chooseCard={checkbox}>
                        <div style={checkbox ? { marginLeft: '4px', position: 'relative', height: '100%'} : {position: 'relative', height: '100%' }}>
                            {this.state.bookmarkCheck ?
                                (page === 'favorites' ?
                                    <CenterSvgSt width="12" height="14" viewBox="0 -1 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z" fill="white" /></CenterSvgSt>
                                    : <CenterSvgSt width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V13.75L6 11.5L11.25 13.75V1.75C11.25 0.925 10.575 0.25 9.75 0.25Z" fill="white" /></CenterSvgSt>)
                                : <CenterSvgSt width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V13.75L6 11.5L11.25 13.75V1.75C11.25 0.925 10.575 0.25 9.75 0.25ZM9.75 11.5L6 9.865L2.25 11.5V1.75H9.75V11.5Z" fill="white" /></CenterSvgSt>}
                        </div>
                    </BookmarkSt>
                    {/* ПРОЕКТЫ - чекбокс "выбрать" */}
                    {token && (page === 'collections' || (page === 'favorites' && type !== 'events')) && isLoggedIn && !this.state.isRequested &&
                    <ContainerFormSt chooseCard={this.state.chooseCard}>
                        <СhoosePictureContainerSt>
                            {!this.state.chooseCard && <CenterSvgSt width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 4.25V14.75H3.75V4.25H14.25ZM14.25 2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V4.25C15.75 3.425 15.075 2.75 14.25 2.75Z" fill="white"/>
                            </CenterSvgSt>}
                            {this.state.chooseCard && <CenterSvgSt width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V4.25C15.75 3.425 15.075 2.75 14.25 2.75ZM14.25 14.75H3.75V4.25H14.25V14.75ZM13.4925 7.25L12.435 6.185L7.4925 11.1275L5.5575 9.2L4.4925 10.2575L7.4925 13.25L13.4925 7.25Z" fill="white"/>
                            </CenterSvgSt>}
                        </СhoosePictureContainerSt>
                        <FormСhooseSt>
                            <input type="checkbox" onChange={() => this.chooseCard()} />
                        </FormСhooseSt>
                    </ContainerFormSt>}
                    {this.state.isRequested && <ContainerFormSt style={{ background: 'rgb(49,57,78,1)' }}>
                        <СhoosePictureContainerSt>
                           <CenterSvgSt width="18" height="19" viewBox="0 4 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.00003 16.1698L4.83003 11.9998L3.41003 13.4098L9.00003 18.9998L21 6.99984L19.59 5.58984L9.00003 16.1698Z" fill="white"/>
                            </CenterSvgSt>
                        </СhoosePictureContainerSt>
                    </ContainerFormSt>}
                </WrapperBookmarkSt>
            )}

            <CardMarginRightSt>
                <LinkSt to={link} onClick={() => this._clickTop()}>

                    <ImgSt src={(i18n.language === 'en') ? (poster_en === '' ? '../img-test/default-card-img.jpg' : poster_en) : (poster_ru === "" ? "../img-test/default-card-img.jpg" : poster_ru)}></ImgSt>
                    {/* КОЛЛЕКЦИИ */}
                    {type === 'collections' && ``/*<LabelWrapperSt type={type} backgroundColor={zIndexCard}></LabelWrapperSt>*/}

                    {/* ВЫСТАВКИ */}
                    {type === 'events' &&
                      <LabelWrapperSt type={type}>
                        <LabelSt type={type}>
                            <TextSt>{title}</TextSt>
                        </LabelSt>
                        <DateSt>
                            <TextSt>
                                {dateFormation(date_start, date_end)}
                            </TextSt>
                        </DateSt>
                      </LabelWrapperSt>}
                </LinkSt>

                {(this.state.chooseCard && type !== 'events') && <ChooseCardTopSt></ChooseCardTopSt>}

                {/* ПРОЕКТЫ */}
                {type === 'catalog'
                  && <ButtonSt chooseCard={this.state.chooseCard}>
                        <CenterSvgSt width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.667967 12.0997L5.81866 6.99967L0.667968 1.89967L2.25366 0.333008L9.0013 6.99967L2.25366 13.6663L0.667967 12.0997Z" fill="white" />
                        </CenterSvgSt>
                        {!this.state.chooseCard && <OpenDiscriptionSt onClick={() => this.openCard()}></OpenDiscriptionSt>}
                    </ButtonSt>}

                {/* ПРОЕКТЫ - описание */}
                {type === 'catalog' && <DiscriptionSt openCardControl={this.state.openCardControl} discriptionOpenLeft={discriptionOpenLeft}>
                    <DiscriptionContainerSt openCardControl={this.state.openCardControl}>
                        {this.state.openCard && <DiscriptionContentSt>
                            <LinkTitleSt to={link} onClick={() => this._clickTop()} >
                                <TitleSt>{title}</TitleSt>
                            </LinkTitleSt>
                            <ProjectTextSt>{category && category.title + (genresList.length>0 ? ', ' : '')}{genresList}</ProjectTextSt>
                            <ProjectTextSt>{i18n.t(`card.production_year`)}: {year_start}{!year_end ? ' — ...' : year_end === year_start ? '' : (' — ' + year_end)}</ProjectTextSt>
                            <ProjectTextSt>{i18n.t(`card.timing`)}: {duration}</ProjectTextSt>
                            <HrSt></HrSt>
                            <ProjectDiscriptionSt dangerouslySetInnerHTML={{__html:`${description}`}}></ProjectDiscriptionSt>
                        </DiscriptionContentSt>}
                        <FooterSt>
                            <FooterContainerSt>
                                <FooterContentSt paddingRight>
                                    {channel && <DiscriptionChannelSVGSt width={channelList[channel.id] && channelList[channel.id].width} height={channelList[channel.id] && channelList[channel.id].height}>
                                        <use xlinkHref={`images/logos-sprite.svg#${channelList[channel.id] && channelList[channel.id].img}`}></use>
                                    </DiscriptionChannelSVGSt>}
                                </FooterContentSt>
                                <FooterContentSt>
                                    {age_restriction && (age_restriction + " +")}
                                </FooterContentSt>
                            </FooterContainerSt>
                            {trailer_embed && <FooterContentASt href={trailer_embed} target="_blank" onClick={handleClick ? (evt) => handleClick(evt, trailer_embed) : null} >
                                <FooterContentSt paddingRight>
                                    {i18n.t(`card.trailer`)}
                                </FooterContentSt>
                                <FooterContentSt>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="13" cy="13" r="12" stroke="white" />
                                        <path d="M17.375 13.3125L11.125 17.375L11.125 9.25L17.375 13.3125Z" fill="white" />
                                    </svg>
                                </FooterContentSt>
                            </FooterContentASt>}
                        </FooterSt>
                    </DiscriptionContainerSt>
                    <CrossSt openCardControl={this.state.openCardControl}>
                        <CenterSvgSt width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.59 14L14 12.59L8.41 7L14 1.41L12.59 -6.1633e-08L7 5.59L1.41 -5.50326e-07L-6.1633e-08 1.41L5.59 7L-5.50326e-07 12.59L1.41 14L7 8.41L12.59 14Z" fill="#42A5F5" />
                        </CenterSvgSt>
                        <OpenDiscriptionSt onClick={() => this.closeCard()}></OpenDiscriptionSt>
                    </CrossSt>
                </DiscriptionSt>}

            </CardMarginRightSt>
        </CardSt>
        </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.auth.dataToken.token,
    isLoggedIn: store.auth.isLoggedIn,
    favoritesProject: store.favorites.project,
    favoritesMessage: store.favorites.message,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToFavoritesAction: (id, type, token) => dispatch(addToFavorites(id, type, token)),
    deleteFromFavoritesAction: (id, type, token) => dispatch(deleteFromFavorites(id, type, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
