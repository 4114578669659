import React from "react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import OutsideClickHandler from 'react-outside-click-handler';

import {Container} from "../../components/Container/Container";
import GetCards from "../../components/GetCards/GetCards";
import ToggleView from "../../components/ToggleView/ToggleView";
import Svg from "../../assets/icons/icons";
import Search from "../../components/Search/Search";
import {DispatchMessage} from "../../components/DispatchMessage/dispatchMessage";

import {addToFavorites, requestFromManager, requestFromManagerbyContent} from "../../store/favorites/actions";

import {colors as c} from "../../styles/variables";
import {Small} from "../../styles/typography/typography";

import {LinkSt} from "../../components/Carousel/style";
import {ContainerCollectionSt, HeaderSt, SettingsMenuSt, SettingsToggleSt} from "./style";
import {
    H3St,
    ItemSettingsSt,
    SettingsLeftSt,
    SettingsMenuWrapperSt,
    SettingsRigthSt,
    SpanSt,
    WrapItemSt,
    SecondLevelSt,
    SecondLevelItemSt,
    HeaderTopSt,
    TitleBlockSt
} from "../collections/style";

import i18n from "i18next";

class Collection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: false,
            dashboard: true,
            chooseCard: false,
            collectionId: [],
            allchooseCard: [],
            menuWithRequestIsOpen: false,
            //попап после отправки на сервер
            dispatchMessageControl: false,
            requestFromManager: false,
            popupAddFavorites: false,
            settingsMobTimeout: false,
        };

        this._handleOpenMenuClick = this._handleOpenMenuClick.bind(this);
        this._handleOpenMenuWithRequestClick = this._handleOpenMenuWithRequestClick.bind(this);
        this._onToggleViewClick = this._onToggleViewClick.bind(this);
        this._handleContentRequestClick = this._handleContentRequestClick.bind(this);
    }

    _handleOpenMenuClick = () => {
        this.setState((state) => {
            return {
                menuIsOpen: !state.menuIsOpen,
            }
        });
    }
    _handleOpenMenuWithRequestClick = () => {
        this.setState((state) => {
            return {
                requestFromManager: false,
                menuWithRequestIsOpen: !state.menuWithRequestIsOpen,
            }
        });
    }

    _onToggleViewClick(x) {
        this.setState({
            dashboard: x
        });
        if(x !== this.state.dashboard){
            this.setState(() => {
                return { chooseCard: false }
            })
        }
    }

    //запрос на контент
    _handleContentRequestClick(){
        if(this.state.allchooseCard.length>0){
            this.props.requestFromManagerbyContentAction(this.state.allchooseCard, this.props.token);
            this.setState(() => {
                return {
                    chooseCard: true,
                    allchooseCard: [],
                    dispatchMessageControl: true,

                }
            })
            setTimeout(() => {
                this.setState(() => {
                    return {
                        menuWithRequestIsOpen: false,
                    }
                })
            }, 1500)

            setTimeout(()=>{
                this.setState(() => {
                    return {
                        chooseCard: false,
                    }
                })
            }, 1)
        }
    }

    //выбрать чекетом все карты на странице
    _chooseCard() {
        this.setState(() => {
            return { chooseCard: !this.state.chooseCard }
        })
    }

    // запрос у менеджера
    _requestManager(){
        if(this.state.allchooseCard.length>0){
            this.props.requestFromManagerAction(this.state.allchooseCard, this.props.token);
            this.setState(() => {
                return {
                    chooseCard: true,
                    allchooseCard: [],
                    dispatchMessageControl: true,

                }
            })
            setTimeout(() => {
                this.setState(() => {
                    return {
                        menuWithRequestIsOpen: false,
                    }
                })
            }, 1500)

            setTimeout(() => {
                this.setState(() => {
                    return {
                        chooseCard: false,
                    }
                })
            }, 1)
        }
    }

    //добавить все выбранные карты в state.allchooseCard
    _getallchooseCard(allCards, status){
        if(status==="add"){
            let add = this.state.allchooseCard;
            !this.state.allchooseCard.includes(allCards) && add.push(allCards);
            this.setState(() => {
                return { allchooseCard: add }
            })
        }
        else if(status==="remove"){
            let remove = this.state.allchooseCard.filter(function (item){
                return item !== allCards;
            });
            this.setState(() => {
                return { allchooseCard: remove }
            })
        }
        else if(status==="addAll"){
            let add = this.state.allchooseCard;
            !this.state.allchooseCard.includes(allCards) && add.push(allCards);
            this.setState(() => {
                return { allchooseCard: add}
            })
        }
        else if(status==="removeAll"){
            this.setState(() => {
                return { allchooseCard: []}
            })
        }
    }

    // Отправка прочеканных карт на сервер в избранное
    _sendChooseCard(){
        if(this.state.allchooseCard.length>0){
            this.props.addToFavoritesAction(this.state.allchooseCard, "catalog", this.props.token);
        }
        this.setState(() => {
            return {
                chooseCard: false,
                allchooseCard: [],
                dispatchMessageControl: true
            }
        })
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState(() => {
                return { settingsMobTimeout: true };
            });
        }, 1000)
    }

    componentDidUpdate(prevProps) {
        //попап после отправки на сервер
        if(this.props.favoritesProject.length !== prevProps.favoritesProject.length || (this.props.messageallProjectsAdded !== prevProps.messageallProjectsAdded && this.props.messageallProjectsAdded)){
            if(this.state.dispatchMessageControl){
                this.setState(() => {
                    return { popupAddFavorites: true };
                });
                setTimeout(() => {
                    this.setState(() => {
                        return { popupAddFavorites: false };
                    });
                }, 1500)
            }
            this.setState(() => {
                return { dispatchMessageControl: false };
            });
        }
        //попап после запроса у менеджера
        if(this.props.favoritesMessage !== prevProps.favoritesMessage){
            this.setState(() => {
                return { requestFromManager: true };
            });
            setTimeout(() => {
                this.setState(() => {
                    return { requestFromManager: false };
                });
            }, 1500)
            this.setState(() => {
                return { dispatchMessageControl: false };
            });
        }

        if(this.state.allchooseCard.length===0 && this.state.menuWithRequestIsOpen){
            this.setState(() => {
                return { menuWithRequestIsOpen: false };
            });
        }
    }

    render() {
        const { collectionComponents, isLoggedIn, manager_email, favoritesMessage, messageallProjectsAdded, numberCollection, history } = this.props;

        return(
            <ContainerCollectionSt collectionComponents={collectionComponents}>
                <Container padding="true">
                    {!collectionComponents && <HeaderSt>
                        <HeaderTopSt>
                            <TitleBlockSt>
                                <H3St>{i18n.t(`collections.collections`)}</H3St>
                                <LinkSt to={"/collections"}><Small uppercase bold color={c.blue}>{i18n.t(`home.see_all`)}</Small></LinkSt>
                            </TitleBlockSt>
                            {(window.innerWidth < 768)
                                ? ""
                                : <>
                                    <div style={{ display: 'none' }}>
                                        <Search
                                            iconSearch="search"
                                            placeholder={i18n.t(`favorites.add_by_name`)}
                                            searchBoxIsOpen={true}
                                        />
                                    </div>
                                    <ToggleView selected={this.state.dashboard ? "dashboard" : "list"} toggleViewChange={(x)=>this._onToggleViewClick(x)} />
                                </>
                            }
                        </HeaderTopSt>
                    </HeaderSt>}
                    <OutsideClickHandler  onOutsideClick={() => {this.setState ({ menuIsOpen: false})}}>
                        <SettingsMenuSt id={"test"}>
                            {(window.innerWidth < 768 && isLoggedIn && this.state.settingsMobTimeout)
                                ?
                                <SettingsToggleSt>
                                    <div
                                        onClick={this._handleOpenMenuClick}
                                        className="toggle" aria-label={i18n.t(`collections.open_the_menu`)} type="button">
                                        <span className="visually-hidden">{i18n.t(`collections.open_the_menu`)}</span>
                                        <Svg type={this.state.menuIsOpen ? "close" : "settings"} />
                                    </div>

                                </SettingsToggleSt>
                                : ""
                            }
                            {isLoggedIn && (
                                <div style={{ display: 'block' }}>
                                    <SettingsMenuWrapperSt className={this.state.menuIsOpen && window.innerWidth < 768 ? "opened" : ""}>
                                        <>
                                            <div>
                                                <SettingsLeftSt>
                                                    <form style={{ display: 'block' }}>
                                                        <input type="checkbox" checked={this.state.chooseCard && "checked"} onChange={() => this._chooseCard()} /> {i18n.t(`collections.select_all`)}
                                                    </form>
                                                </SettingsLeftSt>
                                                <SettingsRigthSt>
                                                    { (window.innerWidth >  768) &&
                                                    <ItemSettingsSt>
                                                        {/* открыть/закрыть меню запрос менеджеру */}
                                                        <WrapItemSt className = "WrapItemStyled" onClick= {this.state.allchooseCard.length > 0 ? () => this._handleOpenMenuWithRequestClick() : undefined}>
                                                            <SpanSt color={this.state.allchooseCard.length >0 ? c.black : c.grey_passive}>{i18n.t(`collections.request_from_the_manager`)}</SpanSt>
                                                            <Svg className={this.state.menuWithRequestIsOpen ? "keyboard_arrow_top" : ""} type="keyboard_arrow"
                                                                 color={this.state.allchooseCard.length>0 ? c.black : c.grey_passive} />
                                                        </WrapItemSt>

                                                        {(this.state.menuWithRequestIsOpen)
                                                        && (
                                                            <SecondLevelSt>
                                                                {manager_email &&
                                                                <SecondLevelItemSt onClick={() => this._requestManager()}>
                                                                    <Svg type="add" color= {c.blue_active} />
                                                                    <LinkSt >
                                                                        <Small color= {c.blue_active}>{i18n.t(`profile.add_promo_materials`)}</Small>
                                                                    </LinkSt>
                                                                </SecondLevelItemSt>
                                                                }
                                                                {manager_email &&
                                                                <SecondLevelItemSt onClick={() => this._handleContentRequestClick()}>
                                                                    <Svg type="mail_outline" color= {c.blue_active} />
                                                                    <LinkSt>
                                                                        <Small color= {c.blue_active}>контент</Small>
                                                                    </LinkSt>
                                                                </SecondLevelItemSt>
                                                                }
                                                                {/* popup с инфой */}
                                                                {this.state.requestFromManager &&
                                                                <DispatchMessage message={favoritesMessage[1].added && (favoritesMessage[1].added.length>0 ? i18n.t(`favorites.request_has_been_sent`) : i18n.t(`favorites.request_already_exists`))} />
                                                                }
                                                            </SecondLevelSt>
                                                        )
                                                        }
                                                    </ItemSettingsSt>
                                                    }
                                                    {/* для моб версии */}
                                                    {(window.innerWidth < 768) && (
                                                        <>
                                                            {/* запрос на контент */}
                                                            {manager_email && <ItemSettingsSt onClick={() => this._handleContentRequestClick()}>
                                                                <Svg
                                                                    type="mail_outline"
                                                                    color={this.state.allchooseCard.length>0 ? c.blue_active : c.grey_passive} />
                                                                <SpanSt color={this.state.allchooseCard.length>0 ? c.blue_active : c.grey_passive}>{i18n.t(`collections.request_content`)}</SpanSt>
                                                            </ItemSettingsSt>}
                                                            {/* запрос на доп.материалы */}
                                                            {manager_email && <ItemSettingsSt onClick={() => this._requestManager()}>
                                                                <Svg
                                                                    type="add"
                                                                    color={this.state.allchooseCard.length>0 ? c.blue_active : c.grey_passive} />
                                                                <SpanSt color={this.state.allchooseCard.length>0 ? c.blue_active : c.grey_passive}>{i18n.t(`profile.add_promo_materials`)}</SpanSt>

                                                            </ItemSettingsSt>}
                                                        </>
                                                    )}

                                                    {/* ДОБАВИТЬ В ИЗБРАННОЕ   */}
                                                    <ItemSettingsSt onClick={() => this._sendChooseCard()}>
                                                        <Svg type="bookmark_border"
                                                             color={this.state.allchooseCard.length>0 ? c.black : c.grey_passive} />
                                                        <SpanSt color={this.state.allchooseCard.length>0 ? c.black : c.grey_passive}>{i18n.t(`collections.add_to_favourites`)}</SpanSt>
                                                        {this.state.popupAddFavorites && <DispatchMessage message={messageallProjectsAdded ? i18n.t(`favorites.already_added_to_favorites`) : i18n.t(`favorites.added_to_favorites`)} />}
                                                    </ItemSettingsSt>
                                                    {this.state.requestFromManager &&  (window.innerWidth < 768) && <DispatchMessage message={favoritesMessage[1].added && (favoritesMessage[1].added.length>0 ? i18n.t(`favorites.request_has_been_sent`) : i18n.t(`favorites.request_already_exists`))} />}
                                                </SettingsRigthSt>
                                            </div>

                                        </>
                                    </SettingsMenuWrapperSt>
                                </div>
                            )}
                        </SettingsMenuSt>
                    </OutsideClickHandler>
                    <GetCards
                        sourceCards={`collections/${numberCollection ? numberCollection : history.location.pathname.substr(history.location.pathname.lastIndexOf('/',)+1)}`}
                        link={!collectionComponents ? false : "collections/" + numberCollection}
                        type="catalog"
                        cardDisplayType="grid"
                        tile={this.state.dashboard}
                        chooseCard={this.state.chooseCard}
                        page="collections"
                        _getallchooseCard={(allCards, status)=>this._getallchooseCard(allCards, status)}
                    />
                </Container>
            </ContainerCollectionSt>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        messageallProjectsAdded: store.favorites.messageallProjectsAdded,
        favoritesMessage: store.favorites.message,
        favoritesProject: store.favorites.project,
        token: store.auth.dataToken.token,
        isLoggedIn: store.auth.isLoggedIn,
        manager_email: store.auth.user.managers && (store.auth.user.managers[0] && store.auth.user.managers[0].email),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addToFavoritesAction: (id, type, token) => dispatch(addToFavorites(id, type, token)),
        requestFromManagerAction: (id, token) => dispatch(requestFromManager(id, token)),
        requestFromManagerbyContentAction: (id, token) => dispatch(requestFromManagerbyContent(id, token))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Collection)));
