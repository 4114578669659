import styled from "styled-components"
import {colors, fonts, device} from "../../styles/variables";

export const WrapTableSt = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 20px;
  padding-bottom: 120px;
`;

export const TableSt = styled.div`
  width: 856px;
  border-bottom: 1px solid ${colors.grey_light};
  /* ширина таблицы profile/рекомендации */
  &.recommend {    
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 727px;
    }
  }
`;

export const LineItemSt = styled.div`
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 10px 0;
  color: ${props => props.colorSt ? props.colorSt : ""};
  /*ширина колонок для страницы profile/проекты*/
  
  &.profile-project {
    width: ${props => props.widthSt}px;
  }
  
  &.arrow {
    width: 24px;
  }
  &.name {
    width: 246px;
    color: ${colors.blue_active};
  }
  &.status {
    width: 90px;
  }
  &.start {
    width: 110px;
  }
  &.end {
    width: 110px;
  }
  &.contract {
    width: 120px;
  }
  &.specification {
    width: 100px;
  }
  &.points {
    width: 30px;
    text-align: right;
    cursor: pointer;
      font-size: 16px;
      font-weight: 800;
    span {
      display: block;
      margin-left: auto;
      padding-right: 5px;
    }
  }
  &.points-blue {
    color: ${colors.blue_active};
  }
  /*end*/
  
  /*ширина колонок для страницы profile/рекомендации*/
  &.recommend-arrow {
    width: 56px;
  }    
  &.selection {
    width: 400px;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 200px;
    }
  }
  &.recommend-start {
    width: 175px;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 200px;
    }
  }
  
  &.recommend-end {
    width: 175px;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 200px;
    }
  }
  &.recommend-mail {
    width: 50px;
    text-align: right;
    svg {
      fill: ${colors.blue_active};
      cursor: pointer;
    }
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 71px;
    }
  }
  /*end*/
`;

export const LineSt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.grey_light};
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: ${colors.blue_highlight};
  }

  &.header { 
    font-size: 12px;
    line-height: 16px;
    :hover {
      background-color: transparent;
      cursor: auto;
    }
    ${LineItemSt} {
      font-weight: 800;
      display: flex;
      align-items: center;
      &.name {
        color: ${colors.black_text};
      }
      &.recommend-mail svg {
        margin-left: 26px;
        fill: ${colors.black_text};
      }
      &.active svg {
        fill: ${colors.blue_active};
      }
      svg {
        margin-left: -24px;
        fill: ${colors.grey_passive};
      }
      span {
        cursor: pointer;
      }
      svg.arrow-rotate {
        transform: rotate(180deg);
        fill: ${colors.blue_active};
      }
    }
  }

  &.open {
    background-color: ${colors.blue_highlight};
  }

  svg.arrow-up {
    transform: rotate(180deg);
  }
`;

export const WrapContentSt = styled.div`
  display: block;
  width: 90%;
  margin-left: auto;
  padding: 20px 0; 
  font-size: 14px;
  line-height: 16px;
  font-family: ${fonts.font_family};
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: calc(100vw - 40px);
    margin: 0;
  }
`;