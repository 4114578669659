import styled from "styled-components"
import { colors, device } from "../../styles/variables.js"

export const PopupStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(49, 57, 78, .8);
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const PopupWrapStyled = styled.div`
    position: relative;
`;

export const PopupGalleryWrap = styled.div`
    position: relative;
    width: 87.5%;
    margin: 0 auto;
    max-width: 540px;

    /* 768 - ... */
    @media ${device.tablet}{
        width: 67%;
        max-width: 960px;
    }

    /* 1200 - ... */
    @media (min-width: 1150px){
        width: auto;
        max-width: 960px;
    }
`;

export const PopupCloseStyled = styled.a`
    display:block;
    position: absolute;
    z-index: 101;
    right: 0px;
    top: -20px;
    width: 16px;
    height: 16px;
    cursor:pointer;

    &:before, &:after {
        content: "";
        position: absolute;
        left: 7px;
        height: 16px;
        width: 2px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }

    /* 768 - ... */
    @media ${device.tablet} {
        right: -24px;
        top: -24px;
        width: 22px;
        height: 22px;
        &:before, &:after {
            left: 12px;
            height: 22px;
            width: 3px;
        }
    }
`;

export const PopupImageStyled = styled.img`
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 90vh;
    margin: 0 auto;
`;

export const PopupFrameStyled = styled.iframe`
    display: block;
    width: 49vw;
    height: 27.7vw;
    margin: 0 auto;

    /* 767 - ... */
    @media ${device.mobile}{
        width: 80vw;
        height: 45.7vw;
    }
`;

export const ButtonStyled = styled.div`
    display: block;
    width: 36px;
    height: 36px;
    background: ${colors.black};
    position: absolute;
    z-index: 101;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.oppositeSide}: calc(100% - 17px);
    cursor: pointer;
    opacity: .6;
    box-shadow: 0px 8px 10px rgba(66, 165, 245, .2);
    &:hover {
        opacity: 1;
    }

    /* 768 - 1299 */
    @media ${device.tablet} {
        width: 80px;
        height: 80px;
        ${props => props.oppositeSide}: calc(100% + 1.3vw);
    }
    /* 1300 - ... */
    @media ${device.desktop} {
        ${props => props.oppositeSide}: calc(100% + 2.3vw);
    }
`;

export const ArrowStyled = styled.svg`
   position: absolute;
   margin: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 10px;

   /* 768 - ... */
   @media ${device.tablet} {
        width: auto;
       ${props => props.oppositeSide}: calc(100% + 1.3vw);
   }
`;

export const PopupLoginStyled  = styled.div`
   background-color: #ffffff;
   width :100%;
   height:600px;
`;
