import React from "react";

import Card from "../Card/Card";
import CardList from "../CardList/CardList";

import {Medium, Small} from "../../styles/typography/typography";
import { colors as c } from "../../styles/variables.js"

import {
    ContainerSt,
    HeaderSt,
    ContentSt,
    ContentDSt,
    DescriptionContainerSt,
    ContentDCLSt
} from './style.js';
import {LinkSt} from "../Carousel/style";

import i18n from "i18next";

export default class Grid extends React.Component {

    state ={
       closeAllCardsDiscription: false,
    };

    // при открытии второго описания закрываем первое
    _closeAllCardsDiscription(){
        this.setState(() => {
            return { closeAllCardsDiscription: true }
        })
        setTimeout(() => {
            this.setState(() => {
                return { closeAllCardsDiscription: false }
            })
        }, 50)
    }

    render(){
        const { cards, type, page, tile, chooseCard, title, _getallchooseCard, past, widthCard, link, description } = this.props;
        const now = new Date();
        let orderCards = 0;
        let cardsCount = 0;

        const AllCards = cards.map((item, i)=>{
            if(type === "events"){ // Если карточка относится к типу "events", то она обрабытывается следующим образом
                if(page==="favorites"){
                    cardsCount++;
                    return(
                        tile ? <Card { ...item } key={item.slug} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} page={page} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/> : <CardList { ...item } key={item.slug} type={type} page={page}/>
                    )
                }
                else{
                    if(String(item.date_start).substr(0,4) === String(title)){ // Карта выводится на экран если год указанный поле "date" совпадает с годом в заголовке
                        if(now.getFullYear() === title){ // если тякущий год, то проверяется еще не закончилось ли уже событие
                            if(!past && (now.getMonth()+1 < Number(item.date_end.substr(5,2)) || (now.getMonth()+1 === Number(item.date_end.substr(5,2)) && now.getDate() < Number(item.date_end.substr(8,2))))){
                                cardsCount++;
                                return(
                                    tile ? <Card { ...item } key={item.slug} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/> : <CardList { ...item } key={item.slug} type={type}/>
                                )
                            }
                            else if(past && (now.getMonth()+1 > Number(item.date_end.substr(5,2)) || (now.getMonth()+1 === Number(item.date_end.substr(5,2)) && now.getDate() > Number(item.date_end.substr(8,2))))){
                                cardsCount++;
                                return(
                                    tile ? <Card { ...item } key={item.slug} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/> : <CardList { ...item } key={item.slug} type={type}/>
                                )
                            }
                        }
                        else{
                            cardsCount++;
                            return(
                                tile ? <Card { ...item } key={item.slug} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/> : <CardList { ...item } key={item.slug} type={type}/>
                            )
                        }
                    }
                }
            }
            else{ // Если карточка не относится к типу "events"
                orderCards++;
                if(widthCard ? orderCards%3 === 0 : orderCards%5 === 0){
                    cardsCount++;
                    return(
                        tile
                            ? <Card { ...item } key={item.title} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={true} chooseCard={chooseCard} _closeAllCardsDiscription={() => this._closeAllCardsDiscription()} closeAllCardsDiscription={this.state.closeAllCardsDiscription} page={page} widthCard={widthCard} _getallchooseCard={_getallchooseCard}/>
                            : <CardList { ...item } key={item.title} type={type} page={page} widthCard={widthCard} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/>
                    )
                }
                else{
                    cardsCount++;
                    return(
                        tile
                            ? <Card { ...item } key={item.title} type={type} zIndexCard={cards.length-i} discriptionOpenLeft={false} chooseCard={chooseCard} _closeAllCardsDiscription={() => this._closeAllCardsDiscription()} closeAllCardsDiscription={this.state.closeAllCardsDiscription} page={page} widthCard={widthCard} _getallchooseCard={_getallchooseCard}/>
                            : <CardList { ...item } key={item.title} type={type} page={page} widthCard={widthCard} chooseCard={chooseCard} _getallchooseCard={_getallchooseCard}/>
                    )
                }
            }
        })

        return (
            <>
                {cardsCount>0 && <ContainerSt>
                    {title && <HeaderSt page={page==="collections"}>
                        {title}
                        {link && <LinkSt to={link}><Small uppercase bold color={c.blue}>{i18n.t(`collections.go_to_collection`)}</Small></LinkSt>}
                    </HeaderSt>}
                    <DescriptionContainerSt>
                        <Medium>{description}</Medium>
                    </DescriptionContainerSt>
                    <ContentSt>
                        {(tile || window.innerWidth<768)?<ContentDSt>{AllCards}</ContentDSt>:<ContentDCLSt><div>{AllCards.filter((item)=>{if(item!==undefined) return item}).map((item, i)=>{if(i%2===0){return(item)}})}</div><div>{AllCards.filter((item)=>{if(item!==undefined) return item}).map((item, i)=>{if(i%2!==0){return(item)}})}</div></ContentDCLSt>}
                    </ContentSt>
                </ContainerSt>}
            </>
        );
    }
}