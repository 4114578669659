import React from 'react';

import {dateFormation} from "../../services/utils";
import {channelListForBannerNew as channelList} from "../../services/constants";

import {
    BannerSt,
    MobileClickSt,
    ContainerSt,
    OpenDiscriptionSt,
    CenterSvgSt,
    ImageSt,
    DiscriptionSt,
    TitleSt,
    LinkSt,
    DiscriptionTextSt,
    DiscriptionBottomSt,
    DiscriptionDateSt,
    DiscriptionPlayVideoSt,
    HideUnderDescriptionSt, BannerBottomSt
} from './style.js';
import i18n from "i18next";

export default class BannerNew extends React.Component {

    state = {
        isOpenDesc: false
    }

    // открытие описания в мобильной версии
    openBanner(){
        this.setState(() => {
            return {
                isOpenDesc: true
            }
        })
    }

    // закрытие описания в мобильной версии
    closeBanner(){
        this.setState(() => {
            return {
                isOpenDesc: false
            }
        })
    }

    render() {
        const {date_start, date_end, widthEl, marginCount, discriptionOpenLeft, lastCardLeftMargin, contentType, index, slug, channel, poster_en, poster_ru, title, trailer_embed, description } = this.props;

        // ссылка на проект или мероприятие в зависимости от значения contentType
        const link = contentType === ((i18n.language === 'en') ? 'project' : 'проект') ? ("/catalog/" + slug) : ("/events/" + slug);

        return (
            <BannerSt onTouchMove={this.state.isOpenDesc ? (event => event.stopPropagation()) : undefined} widthEl={widthEl} openDesc={this.state.isOpenDesc} marginCount={marginCount} discriptionOpenLeft={discriptionOpenLeft} lastCardLeftMargin={lastCardLeftMargin}>
                <MobileClickSt onClick={() => this.openBanner()} openDesc={this.state.isOpenDesc}></MobileClickSt>
                {window.innerWidth > 767 // В десктоп версии картинка с ссылкой, в мобильной только картинка
                    ? <LinkSt to={link}><ImageSt src={(i18n.language === 'en') ? poster_en : poster_ru}></ImageSt></LinkSt>
                    : <ImageSt src={(i18n.language === 'en') ? poster_en : poster_ru}></ImageSt>
                }
                <ContainerSt index={index+1} openDesc={this.state.isOpenDesc} discriptionOpenLeft={discriptionOpenLeft}>
                    <OpenDiscriptionSt onClick={() => this.closeBanner()} openDesc={this.state.isOpenDesc}>
                        <CenterSvgSt width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.59 14L14 12.59L8.41 7L14 1.41L12.59 -6.1633e-08L7 5.59L1.41 -5.50326e-07L-6.1633e-08 1.41L5.59 7L-5.50326e-07 12.59L1.41 14L7 8.41L12.59 14Z" fill="#42A5F5"/>
                        </CenterSvgSt>
                    </OpenDiscriptionSt>
                    <DiscriptionSt openDesc={this.state.isOpenDesc}>
                        <TitleSt><LinkSt to={link}>{title.substr(0, 30)}{title.length > 30 && "..."}</LinkSt></TitleSt>
                        <DiscriptionTextSt openDesc={this.state.isOpenDesc} dangerouslySetInnerHTML={{__html:`${description}`}}></DiscriptionTextSt>
                        <DiscriptionBottomSt>
                            {channel && <img
                                width={""}
                                height={channelList[channel.id] ?channelList[channel.id].height : "22"}
                                src={(i18n.language === 'en') ? channel.logo_icon_en :  channel.logo_icon_ru} />
                            }
                           <DiscriptionDateSt> {dateFormation(date_start, date_end)}</DiscriptionDateSt>
                            {trailer_embed && <DiscriptionPlayVideoSt target="_blank" href={trailer_embed} openDesc={this.state.isOpenDesc}>
                                <span style={{paddingRight:"5px"}}>{i18n.t(`bannerNew.wath_trailer`)}</span>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <circle cx="15" cy="15" r="12" stroke="white"/>
                                    <path d="M19.375 15.3125L13.125 19.375L13.125 11.25L19.375 15.3125Z" fill="white"/>
                                </svg>
                            </DiscriptionPlayVideoSt>}
                        </DiscriptionBottomSt>
                    </DiscriptionSt>
                </ContainerSt>
                <HideUnderDescriptionSt openDesc={this.state.isOpenDesc}></HideUnderDescriptionSt>
                <BannerBottomSt ></BannerBottomSt>
            </BannerSt>
        );
    }
}