import React from 'react';
import { HOST } from "../../store/types";
import {Redirect, withRouter} from 'react-router-dom';
import { connect } from "react-redux";
import GetCards from "../../components/GetCards/GetCards";
import Tabs from '../../components/Tabs/Tabs.jsx'
import TabsDescription from '../../components/Tabs-description/Tabs-description.jsx'
import TabsFoto from '../../components/Tabs-foto/Tabs-foto.jsx'
import TabsVideo from '../../components/Tabs-video/Tabs-video.jsx'
import TabsAdditional from '../../components/Tabs-additional/Tabs-additional.jsx'
import { requestFromManager, requestFromManagerbyContent } from '../../store/favorites/actions';
import Svg from "../../assets/icons/icons";
import { colors } from "../../styles/variables.js"
import {
  LineStyled,
  DivInPSTyled,
  TooltipSt,
  TooltipItemSt,
  PprojectWrapButton
} from './style.js';

import {
  PprojectWrapEmailStyled,
  PprojectEmailStyled
} from '../../components/Tabs/style.js';

import i18n from "i18next";
import { withTranslation } from "react-i18next";

import { Container } from "../../components/Container/Container";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import Grid from "../../components/Grid/Grid";
import { ContainerCatalogStyled } from "../../components/Container/style";


class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
      history: this.props.history.location.pathname,
      //
      popupIsOpen: false,
      fotosIndex: 0,
      trailer: false,
      //для компонента Grid 
      dashboard: true,
      chooseCard: false,
      //контент
      contentPage: [],
      sendRequest: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickPlus = this.handleClickPlus.bind(this);
    this.handleClickMinus = this.handleClickMinus.bind(this);
  }

  handleClick = (id, statusTreiler) => {
    this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
    this.setState(() => ({ fotosIndex: id }));
    this.setState(() => ({ trailer: statusTreiler }));
  }

  handleClickPlus = () => {
    this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex + 1 }));
  }

  handleClickMinus = () => {
    this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex - 1 }));
  }

  //Получаем содержимое страницы
  _onContentPage(slug, token) {

    if (token) {
      var bearer = 'Bearer ' + token
    }
    fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/projects/${slug}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': bearer,
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((body) => {

        if(!body.id){ //404 редирект
          this.setState({ redirect: true })
        }

        this.setState(() => {
          return { contentPage: body };
        });
      });
  }

  componentDidMount() {
    // Вызов функции получающий содержимое страницы
    this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.indexOf('/', 1) + 1), this.props.token);
  }

  componentDidUpdate(prevProps) {
    //переход на другой проект
    if (this.props.history.location.pathname !== this.state.history) {
      this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.indexOf('/', 1) + 1), this.props.token);
      this.setState(() => {
        return { history: this.props.history.location.pathname }
      })
    }

    //всплывающее окно для кнопок
    if(this.props.favoritesMessage !== prevProps.favoritesMessage) {
      if(this.props.favoritesMessage[0] === true ) {
        this.setState(() => {
            return { sendRequest: "Запрос отправлен" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      } else {
        this.setState(() => {
          return { sendRequest: "Запрос уже существует" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      }
    }
  }

  render() {

    //редирект на 404
    const { redirect } = this.state;
    if (redirect) { return <Redirect to='/404'/>; }

    let categoryGenres = [this.state.contentPage.category && this.state.contentPage.category.title];
    this.state.contentPage.genres && this.state.contentPage.genres.map((item) => { return (categoryGenres.push(item.title)) });
    // режиссеры
    let directors = [];
    this.state.contentPage.directors && this.state.contentPage.directors.map((item) => { return (directors.push(item.full_name)) });
    // сценаристы
    let screenwriters = [];
    this.state.contentPage.screenwriters && this.state.contentPage.screenwriters.map((item) => { return (screenwriters.push(item.full_name)) });
    // продюсеры
    let producers = [];
    this.state.contentPage.producers && this.state.contentPage.producers.map((item) => { return (producers.push(item.full_name)) });
    // актеры
    let actors = [];
    this.state.contentPage.actors && this.state.contentPage.actors.map((item) => { return (actors.push(item.full_name)) });
    // операторы
    let operators = [];
    this.state.contentPage.operators && this.state.contentPage.operators.map((item) => { return (operators.push(item.full_name)) });
    // эпизоды
    let episodes = [];
    this.state.contentPage.data && (this.state.contentPage.data.episodes && delete this.state.contentPage.data.episodes[""]);
    this.state.contentPage.data && (this.state.contentPage.data.episodes && (episodes = Object.values(this.state.contentPage.data.episodes)));
    let seasonsNow = []
    seasonsNow = this.state.contentPage.data && (this.state.contentPage.data.episodes && Object.keys(this.state.contentPage.data.episodes));

    // эпизоды для полей "колличество сезонов" и "колличество эпизодов"
    let numberSeasons = (this.state.contentPage.seasons && (!this.state.contentPage.episodes_count && !this.state.contentPage.seasons_count)) ? this.state.contentPage.seasons.map(() => { return ([]) }) : [];
    let numberEpisodes = [];
    this.state.contentPage.seasons && this.state.contentPage.seasons.map((item) => { return (numberEpisodes.push(item.episodes_amount)) });

    // получаем количество всех эпизодов во всех сезонах
    let allEpisodes = episodes.reduce((sum, item) => {
      return sum + item.reduce((sum) => {
        return sum + 1;
      }, 0)
    }, 0);
    // трейлеры
    let channel = "";
    this.state.contentPage.data && (this.state.contentPage.data.trailers && (channel = Object.values(this.state.contentPage.data.trailers)));
    
     // превью кадры
    let cadresSmall = [];
    this.state.contentPage.data && (this.state.contentPage.data.cadres && this.state.contentPage.data.cadres.map((item) => { return (cadresSmall.push(item.media_preview)) }));
    
    // кадры
    let cadres = [];
    this.state.contentPage.data && (this.state.contentPage.data.cadres && this.state.contentPage.data.cadres.map((item) => { return (cadres.push(item.media)) }));

    
    // постеры
    let posters = [];
    this.state.contentPage.data && (this.state.contentPage.data.posters && this.state.contentPage.data.posters.map((item) => { return (posters.push(item.media)) }));

    // доп материалы / кадры
    let extraImg = [];
    this.state.contentPage.data_extra && (this.state.contentPage.data_extra.cadres && (extraImg = Object.values(this.state.contentPage.data_extra.cadres)));
    // доп материалы / видео
    let extraVideos = [];
    this.state.contentPage.data_extra && (this.state.contentPage.data_extra.other_videos && (extraVideos.push(Object.values(this.state.contentPage.data_extra.other_videos))));
    this.state.contentPage.data_extra && (this.state.contentPage.data_extra.trailers && (extraVideos.push(Object.values(this.state.contentPage.data_extra.trailers))));
    this.state.contentPage.data_extra && (this.state.contentPage.data_extra.episodes && (extraVideos.push(Object.values(this.state.contentPage.data_extra.episodes)[0])));
    // доп материалы / файлы
    let extraMaterials = [];
    this.state.contentPage.data_extra && (this.state.contentPage.data_extra.files && (extraMaterials = Object.values(this.state.contentPage.data_extra.files)));

    const testProject = {
      description: [ 
        {name: i18n.t(`catalog.project.${0}.year_of_production`), text: (!this.state.contentPage.year_start ? '...' : this.state.contentPage.year_start) + (!this.state.contentPage.year_end ? ' — <div style="background: #42A5F5;font-size: 12px;border-radius: 21px;padding: 8px 10px;color: #fff;display: inline;">In  progress</div>' : this.state.contentPage.year_end === this.state.contentPage.year_start ? "" : (" - " + this.state.contentPage.year_end))},
        {name: i18n.t(`catalog.project.${0}.runtime`), text: this.state.contentPage.duration ? String(this.state.contentPage.duration) + " " + i18n.t(`catalog.project.${0}.min`) : ""},
        {name: i18n.t(`catalog.project.${0}.seasons`), text: this.state.contentPage.seasons ?(this.state.contentPage.seasons && (this.state.contentPage && !this.state.contentPage.seasons_count)) ? this.state.contentPage.seasons.length : String(this.state.contentPage && this.state.contentPage.seasons_count) : ""},
        {name: i18n.t(`catalog.project.${0}.col_episodes`), text: this.state.contentPage.seasons ? (this.state.contentPage.seasons && (this.state.contentPage && !this.state.contentPage.episodes_count)) ? this.state.contentPage.seasons.reduce((sum, item) => {return sum + item.episodes_amount}, 0) : String(this.state.contentPage && this.state.contentPage.episodes_count) : ""},
        {name: i18n.t(`catalog.project.${0}.PG`), text: this.state.contentPage.age_restriction ? String(this.state.contentPage.age_restriction) + "+" : ""},
        {name: i18n.t(`catalog.project.${0}.production`), text: (this.state.contentPage.production ? this.state.contentPage.production.title : "")},
        {name: i18n.t(`catalog.project.${0}.director`), text: directors.length > 0 && directors.join(', ')},
        {name: i18n.t(`catalog.project.${0}.screenwriter`), text: screenwriters.length > 0 && screenwriters.join(', ')},
        {name: i18n.t(`catalog.project.${0}.producer`), text: producers.length > 0 && producers.join(', ')},
        {name: i18n.t(`catalog.project.${0}.actors`), text: actors.length > 0 && actors.join(', ')},
        {name: i18n.t(`catalog.project.${0}.actors`), text: operators.length > 0 && operators.join(', ')}
        // {name: "Оператор", text: operators.length > 0 && operators.join(', ') : " ... "}
      ],
      moreDetails: null,
    }

    const tabsMenu = [
      { tabsName: i18n.t(`catalog.project.${0}.description`) },
    //  { tabsName: (((episodes.length > 0 || channel.length > 0) && this.props.isLoggedIn) && i18n.t(`catalog.project.${0}.video`)) },
      { tabsName: (((episodes.length > 0 || channel.length > 0)) && i18n.t(`catalog.project.${0}.video`)) },
      { tabsName: (posters.length > 0 || cadres.length > 0) && i18n.t(`catalog.project.${0}.photo`) },
      { tabsName: (extraImg.length > 0 || extraVideos.length > 0 || extraMaterials.length > 0) && i18n.t(`catalog.project.${0}.additional_materials`) }
    ]
// Rightdiv={(i18n.language === 'en') ? '/images/default-top-img-en.png' : '/images/default-top-img-ru.png'}
    return (
      <>
        <HeaderPage
          Leftimg={(i18n.language === 'en') ? (this.state.contentPage.image_en === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.image_en) : (this.state.contentPage.image_ru === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.image_ru)}
          Rightdiv={(i18n.language === 'en') ? (this.state.contentPage.trailer_poster_en === "" ? '/images/default-top-img-en.png' : this.state.contentPage.trailer_poster_en) : (this.state.contentPage && this.state.contentPage.trailer_poster_ru === "" ? '/images/default-top-img-ru.png' : this.state.contentPage.trailer_poster_ru)}
          Rightvideo={this.state.contentPage.trailer_embed} />
       
       <ContainerCatalogStyled> 
       <Container>
          {/* компонент табов - в пропсах передаем меню и компоненты вкладок*/}
          <Tabs
            tabsMenu={tabsMenu}
            imgPoster={(i18n.language === 'en') ? (this.state.contentPage.poster_en === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.poster_en) : (this.state.contentPage.poster_ru === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.poster_ru)}
            textSend={i18n.t(`catalog.project.${0}.get_in_touch_with_the_manager`)}
            id={this.state.contentPage.id && this.state.contentPage.id}
            isInFavourites={this.state.contentPage.isInFavourites && this.state.contentPage.isInFavourites}
            typeBookmark="catalog"
            isLoggedIn={this.props.isLoggedIn}

            firstTabs={<TabsDescription
              header={true}
              name={this.state.contentPage.title}
              genre={categoryGenres}
              description={testProject.description}
              smallDescription={this.state.contentPage.tagline}
              bigDescription={this.state.contentPage.description ? this.state.contentPage.description : ""}
              moreDetails={testProject.moreDetails ? testProject.moreDetails : ""}
              is_format={this.state.contentPage.is_format}
              channel={this.state.contentPage.channel && this.state.contentPage.channel.slug}
              channelLogo={this.state.contentPage.channel && ((i18n.language === 'en') ? this.state.contentPage.channel.logo_en : this.state.contentPage.channel.logo_ru)}
              episodes={episodes}
              numberSeasons={numberSeasons}
              numberEpisodes={numberEpisodes}
              seasonsDes={this.state.contentPage.seasons && this.state.contentPage.seasons}
              id={this.state.contentPage.id && this.state.contentPage.id}
              isInFavourites={this.state.contentPage.isInFavourites && this.state.contentPage.isInFavourites}
              typeBookmark="catalog"
            />}

            secondTabs={<TabsVideo
              season={episodes}
              trailerBlock={channel}
              handleClickMinus={this.handleClickMinus}
              handleClickPlus={this.handleClickPlus}
              handleClick={this.handleClick}
              popupIsOpen={this.state.popupIsOpen}
              fotosIndex={this.state.fotosIndex}
              trailer={this.state.trailer}
              seasonsNow={seasonsNow}
            />}

            thirdTabs={
              <TabsFoto
                posters={posters}
                cadresSmall={cadresSmall}
                cadre={cadres}
                handleClickMinus={this.handleClickMinus}
                handleClickPlus={this.handleClickPlus}
                handleClick={this.handleClick}
                popupIsOpen={this.state.popupIsOpen}
                fotosIndex={this.state.fotosIndex}
                project={true}
              />}

            fourthTabs={<TabsAdditional
              extraImg={extraImg}
              extraVideos={extraVideos}
              extraMaterials={extraMaterials}
            />}
          />
          <LineStyled></LineStyled>
          <Container padding = "true">
          {this.state.contentPage.related_projects && <Grid
                type="catalog"
                title={i18n.t(`catalog.project.${0}.see_also`)}
                cards={this.state.contentPage.related_projects}
                //cardsLength={this.props.collection.length}
                tile={this.state.dashboard}
                chooseCard={this.state.chooseCard}
            />}
          </Container>
        </Container>
        </ContainerCatalogStyled>
      </>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    token: store.auth.dataToken.token,
    isLoggedIn: store.auth.isLoggedIn,
    favoritesMessage: store.favorites.message,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    requestFromManager: (ids, token) => dispatch(requestFromManager(ids, token)),
    requestFromManagerbyContent: (ids, token) => dispatch(requestFromManagerbyContent(ids, token)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project))

