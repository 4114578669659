import React, { Component } from 'react'
import { connect } from "react-redux";

import { Container } from "../../components/Container/Container";
import { Loading } from "../../components/Loading/Loading";
import Table from "../../components/Table/Table";
import Tabs from '../../components/Tabs/Tabs.jsx'
import AccountProfile from '../../components/AccountProfile/AccountProfile.jsx'
import AccountAbout from "../../components/AccountAbout/AccountAbout";
import AccountChangeFoto from '../../components/AccountChangeFoto/AccountChangeFoto';

import { getCustomerInfo } from '../../store/customer/actions';
import { getRegisterInfo } from '../../store/auth/actions';
import { getProfileRecommendations } from '../../store/profile/recommendations/actions';
import { getDopProjects } from '../../store/profile/dop/actions';
import { getQyeryProjects } from '../../store/profile/profileQuery/actions';
import { getProfileProjects } from '../../store/profile/projects/actions';

import i18n from "i18next";

class Profile extends Component {

  componentDidMount() {
    this.props.getCustomerInfo(this.props.token)
    this.props.getProfileRecommendations(this.props.token)
    this.props.getDopProjects(this.props.token)
    this.props.getQyeryProjects(this.props.token)
    this.props.getProfileProjects(this.props.token)
    this.props.getRegisterInfo()
  }

  // адаптер для селекта страна
  adaptTypesCountryData = (item) => {
    return Object.assign({}, item, {
      id: item.id,
      name: item.title
    });
  };

  // преобразование данных для мультиселекта
  adaptCountriesData = (item) => {
    return Object.assign({}, item, {
      id: item.id,
      name: item.title,
    });
  };

  // адаптер для мультиселектов
  adaptTypesData = (item, customer) => {
    let checked = true;
    let name = item.title;
    if (customer === "customer") {
      checked = false;
    }
    if (!item.title) {
      name = item.name;
    }
    return Object.assign({}, item, {
      id: item.id,
      name: name,
      checked: checked
    });
  };

  // объединяет массив котрые приходят с разных api и преобразует их в нужный для мультиселектов формат
  unionOfArrays = (itemFavorites, item) => {
    const customerItem = itemFavorites.map((data) => this.adaptTypesData(data, "customer"));
    const registerItem = item.map((data) => this.adaptTypesData(data));
    registerItem.map((data) => {  
      customerItem.filter((it) => {
        it.id === data.id
        ? it.checked = true
        : it.id = it.id
      });
    });
    return customerItem
  };

  render() {

    const { customer, register_info, isLoading, projects, query, dop, recommendations } = this.props;

    //вид прав
    const typeRightsRegister = register_info.rights
    const typeRights = customer.rights
    const typeRightsArrAdapt = isLoading && this.unionOfArrays(typeRightsRegister, typeRights)

    //жанры
    const typeGeneresRegister = register_info.genres
    const typeGeneres = customer.genres
    const typeGeneresAdapt = isLoading && this.unionOfArrays(typeGeneresRegister, typeGeneres);

    //территория интересуюхих прав
    const typeСountriesRegister = register_info.countries
    const typeСountries = customer.countries
    const typeСountriesAdapt = isLoading && this.unionOfArrays(typeСountriesRegister, typeСountries);
    
    // страны
    const typeСountry = isLoading && typeСountriesRegister.map((data) => this.adaptTypesCountryData(data));

    // данные для вкладки профиль 
    const testProject = {
      id: 1,
      poster: customer.photo ? customer.photo : "../images/notimage.png",
      logoCompany: i18n.language === 'en' ? (isLoading && customer.company !== null ? customer.company.logo_eng : "") : (isLoading && customer.company !== null ? customer.company.logo_ru : ""),
      verifed: customer.status,
      managerName: customer.full_name,
      mail: customer.email,
      company: customer.company_name,
      position: customer.position,
      fullPosition: customer.position + " " + customer.company_name,
      /*нет пароля*/
      password: customer.password,

      /*селекты*/      
      country: [isLoading && customer.country.title, typeСountry],
      territory: ["", typeСountriesAdapt],
      typeOfRights: ["", typeRightsArrAdapt],
      genres: ["", typeGeneresAdapt],
      managers: customer.managers
    }

    const tabsMenu = [
      { tabsName: i18n.t(`profile.profile`) },
      { tabsName: projects.all && Object.keys(projects.all).length !== 0 && (customer.managers && Object.keys(customer.managers).length !== 0) && i18n.t(`profile.projects`)},
      { tabsName: query?.all && Object.keys(query.all).length !== 0 && (customer.managers && Object.keys(customer.managers).length !== 0) &&  i18n.t(`profile.in_processing`)},
      { tabsName: dop.all && Object.keys(dop.all).length !== 0 && (customer.managers && Object.keys(customer.managers).length !== 0) && i18n.t(`profile.add_promo_materials`)},
      { tabsName: Object.keys(recommendations).length !== 0 && (customer.managers && Object.keys(customer.managers).length !== 0)  && i18n.t(`profile.recommendations`) }
    ]
    return (
      <div className="page">
        {
          isLoading ? (
            <>
              {/*Header для мобильной версии, так как нет в контейненре */}
              <AccountChangeFoto imgPoster={testProject.poster} type="mobile"></AccountChangeFoto>

              <Container>
                {/* компонент табов - в пропсах передаем меню и компоненты вкладок*/}
                <Tabs
                  tabsMenu={tabsMenu}
                  imgPoster={testProject.poster}                 
                  managers={testProject.managers}
                  type="profile"

                  firstTabs={
                    <AccountProfile
                      status={customer.status}
                      verifed={testProject.verifed}
                      managerName={testProject.managerName}
                      mail={testProject.mail}
                      company={testProject.company}
                      position={testProject.position}
                      fullPosition={testProject.fullPosition}
                      password={testProject.password}

                      /*селекты*/
                      country={testProject.country}
                      territory={testProject.territory}
                      typeOfRights={testProject.typeOfRights}
                      genres={testProject.genres}
                      logoCompany={testProject.logoCompany}
                    />
                  }

                  secondTabs={
                    <>
                      <AccountAbout logoCompany={testProject.logoCompany} aboutCompany={i18n.t(`profile.aboutCompany`)} />
                      <Table type="profileProject" dataProject={projects} />
                    </>
                  }
                  thirdTabs={<Table type="profileRequest" query={query} />}
                  fourthTabs={<Table type="profileDop" dopMaterials={dop} />}
                  fifthTabs={
                    <>
                      <span>{i18n.t(`profile.here_you_will`)}</span>
                      {isLoading
                          ? <Table type="profileRecommend" recommendations={recommendations} />
                          : <Loading />
                      }
                    </>
                  }
                />
              </Container>
            </>
          ) : <Loading />
        }</div>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    isLoggedIn: store.auth.isLoggedIn, 
    isLoading: store.customer.isLoading,
    customer: store.customer.customer,
    register_info: store.auth.register_info,
    token: store.auth.dataToken.token,
    recommendations: store.recommendations.recommendations,
    projects: store.projects.projects,
    dop: store.dop.projects,
    query: store.query.projects,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCustomerInfo: (token) => dispatch(getCustomerInfo(token)),
    getRegisterInfo: () => dispatch(getRegisterInfo()),
    getProfileRecommendations: (token) => dispatch(getProfileRecommendations(token)),
    getDopProjects: (token) => dispatch(getDopProjects(token)),
    getQyeryProjects: (token) => dispatch(getQyeryProjects(token)),
    getProfileProjects: (token) => dispatch(getProfileProjects(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);