import i18n from "i18next"

// LocalStorage
export const STORAGE_KEY = "user"

// Получаем хост сайта
const hostWithOutDomain = document.location.host.indexOf('.com') !== -1 ?  document.location.host.split('.com')[0] : 
document.location.host.split('.ru')[0]
export const HOST = hostWithOutDomain === "screeningroom.gpm-rtv" ? "https://screeningroom.gpm-rtv" : " https://screen-stage.gpm-rtv";

// Auth
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// Reset password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// Clear messages
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

// Get info Form auth
export const REGISTER_INFO_REQUEST = "REGISTER_INFO_REQUEST";
export const REGISTER_INFO_SUCCESS = "REGISTER_INFO_SUCCESS";

// Message Form auth
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Get customer Profile info
export const CUSTOMER_INFO_REQUEST = "CUSTOMER_INFO_REQUEST";
export const CUSTOMER_INFO_SUCCESS = "CUSTOMER_INFO_SUCCESS";
export const CUSTOMER_INFO_FAIL = "CUSTOMER_INFO_FAIL";

// Update customer Profile info
export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAIL = "CUSTOMER_UPDATE_FAIL";

// Update customer photo in profile
export const CUSTOMER_UPDATE_PHOTO_REQUEST = "CUSTOMER_UPDATE_PHOTO_REQUEST";
export const CUSTOMER_UPDATE_PHOTO_SUCCESS = "CUSTOMER_UPDATE_PHOTO_SUCCESS";
export const CUSTOMER_UPDATE_PHOTO_FAIL = "CUSTOMER_UPDATE_PHOTO_FAIL";

// Get recommendations for profile page
export const RECOMM_PROFILE_REQUEST = "RECOMM_PROFILE_REQUEST";
export const RECOMM_PROFILE_SUCCESS = "RECOMM_PROFILE_SUCCESS";
export const RECOMM_PROFILE_FAIL = "RECOMM_PROFILE_FAIL";

// Get projects for profile page
export const PROJECTS_PROFILE_REQUEST = "PROJECTS_PROFILE_REQUEST";
export const PROJECTS_PROFILE_SUCCESS = "PROJECTS_PROFILE_SUCCESS";
export const PROJECTS_PROFILE_FAIL = "PROJECTS_PROFILE_FAIL";


// Get projects for dop. page
export const DOP_PROFILE_REQUEST = "DOP_PROFILE_REQUEST";
export const DOP_PROFILE_SUCCESS = "DOP_PROFILE_SUCCESS";
export const DOP_PROFILE_FAIL = "DOP_PROFILE_FAIL";

// Get projects for query page
export const QUERY_PROFILE_REQUEST = "QUERY_PROFILE_REQUEST";
export const QUERY_PROFILE_SUCCESS = "QUERY_PROFILE_SUCCESS";
export const QUERY_PROFILE_FAIL = "QUERY_PROFILE_FAIL";

// Get favorites info
export const FAVORITES_INFO_REQUEST = "FAVORITES_INFO_REQUEST";
export const FAVORITES_INFO_SUCCESS = "FAVORITES_INFO_SUCCESS";
export const FAVORITES_INFO_FAIL = "FAVORITES_INFO_FAIL";

// Add favorites
export const FAVORITES_ADD_EVENT = "FAVORITES_ADD_EVENT";
export const FAVORITES_ADD_PROJECT = "FAVORITES_ADD_PROJECT";
export const FAVORITES_ADD_COLLECTION = "FAVORITES_ADD_COLLECTION";

// Delete favorites
export const FAVORITES_DELETE_EVENT = "FFAVORITES_DELETE_EVENT";
export const FAVORITES_DELETE_PROJECT = "FFAVORITES_DELETE_PROJECT";
export const FAVORITES_DELETE_COLLECTION = "FFAVORITES_DELETE_COLLECTION";
export const FAVORITES_INFO_ALL_ADDED = "FAVORITES_INFO_ALL_ADDED";

// Feedback form
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_FAIL = "FEEDBACK_FAIL";

// Channel content
export const CHANNEL_CONTENT = "CHANNEL_CONTENT";
export const PRODUCTION_CONTENT = "PRODUCTION_CONTENT";

// Page content
export const PAGE_CONTENT = "PAGE_CONTENT";

// Managers content
export const MANAGERS_CONTENT = "MANAGERS_CONTENT";

//
export const FILTER_SAVE = "FILTER_SAVE";
export const GET_FILTER_MENU = "GET_FILTER_MENU";

// список проектов 
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";
export const GET_GENRES_SUCCESS = "GET_GENRES_SUCCESS";

// переключения вида всего контента в каталоге
export const SWITCH_VIEW_IN_CATALOG = "SWITCH_VIEW_IN_CATALOG";

