import styled from "styled-components";
import {colors as c} from "../../styles/variables";
import {Medium} from "../../styles/typography/typography";


export const CheckboxStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

export const MediumStyled = styled(Medium)`
    display: block;
`;

export const CheckboxSquareStyled = styled.div`
    &>svg{
        fill: ${props=>props.checked ? c.blue_active : c.black_text};
    }
`;