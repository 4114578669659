import { HOST, MANAGERS_CONTENT } from "../types";
import { checkStatus } from '../../services/utils';
import i18n from "i18next";

export const getManagersContent = () => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/managers?filter%5Bin_contacts_block%5D=1`)
                .then(checkStatus)
                .then(
                    (data) => {
                        dispatch({
                            type: MANAGERS_CONTENT,
                            payload: data
                        })
                        return data
                    })
        } catch (error) {

        }
    }
}