import React from "react";
import Tag from '../Tag/Tag'
import Button from '../Button/Button'
import CheckboxNew from '../CheckboxNew/CheckboxNew'
import i18n from "i18next";
import { replaceItem, getSelectedTags, getSelectedTagsId } from "../../services/utils";
import Svg from "../../../src/assets/icons/icons"
import {
    SelectSt,
    СhosenSt,
    MediumSt,
    ArrowSt,
    ListSt,
    UlWrapSt,
    UlSt,
    ButtonWrapSt,
    SelectedLabelsSt,
    CheckboxContainerSt,
} from './style';
import { WrapperSt, ErrorWrapperSt, ErrorSt } from "../TextField/style";


export default class Select extends React.Component {

    state = {
        open: false,
        selectValue: this.props.content[0],
        inputValue: [],
        values: this.props.content[1],
        checkedAll: false,
        tagsForSelect: getSelectedTags(this.props.content[1]),
        idsTagForSelect: getSelectedTagsId(this.props.content[1])
    };

    componentDidUpdate(prevProps) {
        // при открытии второго селектора закрываем первый
        if (this.props.closeAllSelect !== prevProps.closeAllSelect && this.state.open === true) {
            this.setState(() => {
                return { open: false }
            })
        }
    }

    // выбранные чекбоксы
    updateInputValue(item) {
        const itemUpd = this.state.values.find(i => i.name === item);

        let newArray = [...this.state.tagsForSelect, item];

        const dataUpd = ({
            id: itemUpd.id,
            name: itemUpd.name,
            checked: !itemUpd.checked,
        });

        if (this.state.tagsForSelect.includes(item)) {
            newArray = newArray.filter(val => val !== item);
        }
        const valuesNew = replaceItem(this.state.values, dataUpd);
        this.setState({
            tagsForSelect: newArray,
            values: valuesNew,
            idsTagForSelect: getSelectedTagsId(valuesNew)

        });
    }

    // удаление тега
    removeTag = (e) => {
        e.preventDefault();
        const meta = e.target.parentNode.getAttribute("data-value");
        const itemUpd = this.state.values.find(item => item.name === meta);

        const dataUpd = ({
            id: itemUpd.id,
            name: itemUpd.name,
            checked: !itemUpd.checked,
        });
        const valuesNew = replaceItem(this.state.values, dataUpd);

        let tagsForSelectUpd = [...this.state.tagsForSelect, meta];
        if (this.state.tagsForSelect.includes(meta)) {
            tagsForSelectUpd = tagsForSelectUpd.filter(val => val !== meta);
        }
        this.setState({
            tagsForSelect: tagsForSelectUpd,
            values: valuesNew,
            idsTagForSelect: getSelectedTagsId(valuesNew)
        });
    }

    // открываем селект
    handleOpenSelectClick = () => {
        if (this.props._closeAllSelect && this.state.open === false) this.props._closeAllSelect(); // при открытии второго селектора закрываем первый
        setTimeout(() => {
            this.setState(({ open }) => {
                return { open: !this.state.open }
            })
        }, 100)
    }


    // выбрать все
    handleSelectAll = () => {
        let tagsListNew = [];
        this.state.values.map((item) => {
            tagsListNew.push({
                name: item.name,
                id: item.id,
                checked: !this.state.checkedAll,
            });
        })

        let tagsForSelect = [];
        this.state.values.map((item) => {
            tagsForSelect.push(
                item.name
            );
        })

        this.setState({
            tagsForSelect: !this.state.checkedAll ? tagsForSelect : [],
            values: tagsListNew,
            checkedAll: !this.state.checkedAll,
            idsTagForSelect: getSelectedTagsId(tagsListNew)
        });
    }


    render() {
        const { primary, action, name, className, error, position, viewType } = this.props
        const tags = this.state.tagsForSelect;
        const tagsForSelect = this.state.tagsForSelect.join(', ');
        const label = (this.state.tagsForSelect.length === 0) ? this.state.selectValue : tagsForSelect;

        const labelSelectAll = i18n.t(`login.select_all`) ? i18n.t(`login.select_all`) : 'Выбрать все';
        const li = this.state.values.map((item, i) => {
            return (
                <CheckboxContainerSt key={i}>
                    <div className="checkboxContainer" onClick={() => this.updateInputValue(item.name)}>
                        <CheckboxNew
                            key={i}
                            label={item.name}
                            checked={item.checked}
                            removeChoice={item[3]}
                        />
                    </div>
                </CheckboxContainerSt>
            )
        })


        return (
            <>
                <SelectSt>
                    <WrapperSt>
                        <СhosenSt
                            className={className + ' chosenStyled'}
                            name={name}
                            onClick={() => this.handleOpenSelectClick()}
                            open={this.state.open}
                            selectChoose={this.state.selectChoose}
                            action={action}
                            primary={primary}
                        >
                            <MediumSt>
                                {label}
                            </MediumSt>
                            <ArrowSt open={this.state.open} >
                                <Svg
                                    className={this.state.open ? "keyboard_arrow_top" : ""}
                                    type="keyboard_arrow" />
                            </ArrowSt>
                        </СhosenSt>
                        <ErrorWrapperSt>
                            <ErrorSt>{error}</ErrorSt>
                        </ErrorWrapperSt>
                    </WrapperSt>
                    <ListSt position={position} viewType={viewType} open={this.state.open}>
                        {(tags.length !== 0)
                            ? <SelectedLabelsSt>
                                {tags.map((tag, i) => (
                                    <Tag
                                        key={i}
                                        removeTag={this.removeTag}
                                        name={tag}
                                        value={tag}
                                    />
                                ))}
                            </SelectedLabelsSt>
                            : ""
                        }
                        <UlWrapSt>
                            <UlSt>

                                <CheckboxContainerSt >
                                    <div
                                        className="CheckboxContainerSt"
                                        onClick={() => this.handleSelectAll()}>
                                        <CheckboxNew
                                            label={labelSelectAll}
                                            checked={this.state.checkedAll}
                                        />
                                    </div>
                                </CheckboxContainerSt>
                                {li}
                            </UlSt>
                        </UlWrapSt>
                        <ButtonWrapSt
                            onClick={() => { this.props.updateData(this.state.values, name) }}
                            open={this.state.open}
                        >
                            <Button label="ок" size="medium" width="340px" name={name} />
                        </ButtonWrapSt>
                    </ListSt>
                    <input
                        onChange={this.props.handleFieldChange}
                        type="hidden"
                        name={name}
                        value={this.state.idsTagForSelect}>
                    </input>
                </SelectSt>
            </>
        )
    }
}
