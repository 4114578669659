import React from 'react';
import {withRouter} from "react-router-dom";

import { HOST } from "../../store/types";

import GetCards from "../../components/GetCards/GetCards";
import {Container} from "../../components/Container/Container";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import Filter from "../../components/Filter/Filter";

import { ContainerCatalogStyled } from "../../components/Container/style";

import i18n from "i18next";
import { connect } from 'react-redux'
import { switchViewInCatalogAction } from '../../store/switchView/actions'

import {ContainerCatalogSt} from './style';

class Channel extends React.Component {

    state= {
        history: this.props.history.location.pathname,
        hiddenContentPage: false, // скрываем основной контент страницы и показываем результаты фильтрации
        contentPage: [],
        slug: this.props.history.location.pathname.substr(this.props.history.location.pathname.lastIndexOf('/',)+1).toLowerCase(),
        channel: false,
        adr: "productions",
    }

    //Получаем содержимое страницы
    _onContentPage(slug) {
         fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/channels`)
            .then(res => {
                return res.json();
            })
            .then(body => {
                this.setState(() => {
                    return{channel: body.data};
                });
            }).then(() => {
                const testChannel = this.state.channel.reduce((sum, item) => {
                    return item.slug === slug ? sum + 1 : sum;
                }, 0);
                return testChannel;
            }).then(testChannel => {
                this.setState(()=>{
                    return{adr: testChannel ? "channels" : "productions"};
                });
            }).then(() => {
                fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/${this.state.adr}/${slug}`)
                .then(res => {
                    return res.json();
                })
                .then(body => {
                    this.setState(() => {
                        return{contentPage: body.data};
                    });
                });
            });
    }

    componentDidMount() {
        // Вызов функции получающий содержимое страницы
        this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.lastIndexOf('/',)+1).toLowerCase());
        this.props._switchViewInCatalog(false);
    }

    componentDidUpdate(prevProps) {
        //переход на другой проект
        if (this.props.history.location.pathname !== this.state.history) {
            this.props._switchViewInCatalog(false);
            this.setState(() => {
                return{contentPage: []}
            })
            this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.lastIndexOf('/',)+1));
            this.setState(() => {
                return{history: this.props.history.location.pathname}
            })
        }
    }

    _hiddenContentPage(x) { // скрываем основной контент страницы и показываем результаты фильтрации
        this.setState(() => {
            return { hiddenContentPage: x }
        })
    }

    render() {
        const { contentPage, hiddenContentPage, adr, channel } = this.state;

        let logoIMg = "";
        (i18n.language === 'en') ? logoIMg = contentPage.image_en : logoIMg = contentPage.image_ru
        if(logoIMg==="") {
            logoIMg = (i18n.language === 'en') ? '/images/default-top-img-en.png' : '/images/default-top-img-ru.png'
        }
        return(
            <>
                <HeaderPage
                    Title={i18n.t(`channel.about_the_channel`)}
                    Leftdiv={contentPage.description}
                    Rightdiv={logoIMg}
                    Rightvideo={contentPage.video_embed}/>
            <ContainerCatalogStyled>
                <Filter _hiddenContentPage={(x)=>this._hiddenContentPage(x)} />
                <Container>
                    <div style={ !hiddenContentPage ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                        {(adr === "channels" && channel)
                           // если каналы
                            &&
                              <>
                                  <div style={!this.props.switchView ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                                      {contentPage.id && <GetCards sourceCards={`channels/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[0]}/>}
                                      {contentPage.id && <GetCards sourceCards={`channels/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[1]}/>}
                                      {contentPage.id && <GetCards sourceCards={`channels/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[2]}/>}
                                  </div>
                                  {this.props.switchView && <ContainerCatalogSt>
                                      <GetCards
                                        sourceCards={`projects?sort[column][]=year_start&sort[column][]=title.ru&sort[order][]=desc&sort[order][]=asc&filter%5Bchannel_id%5D=${contentPage.id}`}
                                        type="catalog"
                                        cardDisplayType="grid"
                                        tile={!this.state.dashboard}
                                      />
                                  </ContainerCatalogSt>}
                              </>
                         }
                        {(adr === "productions" && channel)
                            // если продакшн
                            &&
                            <>
                                <div style={!this.props.switchView ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                                    {contentPage.id && <GetCards sourceCards={`productions/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[0]}/>}
                                    {contentPage.id && <GetCards sourceCards={`productions/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[1]}/>}
                                    {contentPage.id && <GetCards sourceCards={`productions/${+contentPage.id}/projects`} type="catalog" cardDisplayType="carousel" getChannelContent={[2]}/>}
                                </div>
                                {this.props.switchView && <ContainerCatalogSt>
                                    <GetCards
                                      sourceCards={`projects?sort[column][]=year_start&sort[column][]=title.ru&sort[order][]=desc&sort[order][]=asc&filter%5Bproduction_id%5D=${contentPage.id}`}
                                      type="catalog"
                                      cardDisplayType="grid"
                                      tile={!this.state.dashboard}
                                    />
                                </ContainerCatalogSt>}
                            </>
                        }
                    </div>
                </Container>
            </ContainerCatalogStyled>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        switchView: store.switchViewInCatalog.switchViewInCatalog
    };
}

const mapDispatchToProps = dispatch => {
    return {
        _switchViewInCatalog: (boolen) => dispatch(switchViewInCatalogAction(boolen))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Channel));