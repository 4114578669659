import styled from "styled-components"
import { colors } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"
import { 
  PprojectImageStyled,
  PprojectWrapEmailStyled,
  PprojectEmailStyled,
} from '../../components/Tabs/style.js';


export const LineStyled  = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.grey_light};
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    border-top: 0;
    &.visible{
      border-top: 2px solid ${colors.grey_light};
    }
  }
`;
export const TooltipSt = styled.div`
  position: absolute;
  left: 80%;
  top: -35px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 5;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
  &.last {
    right: 25px;
    top: -40px;
  }
  &.last-dop {
    right: 25px;
    top: -73px;
  }
`;

export const TooltipItemSt = styled.a`
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding: 10px 10px;
  border-bottom: 1px solid ${colors.grey_light};
  color: ${colors.black_text};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.blue_highlight};
  }
  &:last-child  {
    border-bottom: 1px solid transparent;
  }
`;
export const PprojectWrapButton = styled.div`
  display: none;
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
    margin-top: -29px;
    text-align: center;
    ${PprojectWrapEmailStyled} {
      position: relative;
      display: inline-block;
      svg {
        top: 54%
      }
    }
  }
`;

