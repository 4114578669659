import React from 'react';

import Svg from "../../../src/assets/icons/icons.js"

import {
    WrapAboutSt,
    ItemSt,
    WrapTopSt,
    HeaderSt,
    TextSt,
    LogoSt,
    IconSt,
    WrapMobSt
} from './style.js'

import i18n from "i18next";

class AccountProject extends React.Component {

    state = {
      textIsOpen: false
    }

    handleClickOpen() {
        this.setState((prevState) => ({ textIsOpen: !prevState.textIsOpen }));
    }

    render() {
        const {logoCompany, aboutCompany} = this.props

        return (
          <WrapAboutSt>
            <ItemSt>
              <WrapTopSt onClick={() => this.handleClickOpen()}>
                <HeaderSt>{i18n.t(`profile.about_company_b`)}</HeaderSt>
                <IconSt className={this.state.textIsOpen ? "close" : null}>
                  <Svg type="keyboard_arrow" width="24" height="24"/>
                </IconSt>
              </WrapTopSt>
              <WrapMobSt className={this.state.textIsOpen ? null : "close"}>
                <TextSt >{aboutCompany}</TextSt>
              </WrapMobSt>
            </ItemSt>

            <ItemSt>
                {logoCompany &&
                  <LogoSt>
                      <img src={logoCompany} alt="Логотип компании"/>
                  </LogoSt>
                }
            </ItemSt>
          </WrapAboutSt>
        );
    }
}

export default AccountProject;
