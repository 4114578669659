/* Variables */
// colors
export const colors = {
    'white': '#FFFFFF',
    'black': '#31394E',
    'black_text': '#272D3D',
    'grey_text': '#9E9E9E',
    'grey_light': '#EEEEEE',
    'blue': '#42A5F5',
    'grey_passive': '#BDBDBD',
    'grey_super_light': '#F9F9F9',
    'blue_active': '#42A5F5',
    'blue_visited': '#1565C0',
    'blue_highlight': 'rgba(66, 165, 245, 0.1)',
    'night': '#2E3D72',
    'rose': '#E11C71',
    'orange': '#F1A925',
    'yellow': '#FBD986',
    'aqua': '#2AA0BD',
    'aqua_light': '#66B8B7',
    'magenta': '#E11C71',
    'error': '#FF0C3E'
}

// fonts
export const fonts = {
    'font_family': 'Raleway, sans-serif, Arial',
    'regular': '400',
    'medium': '500',
    'bold': '700',
    'extra_bold': '800'
}
//media
const size = {
    // mobile: '320px',
    mobile: '767px',
    tablet: '768px',
    laptop: '1024px',
    // desktop: '1260px'
    laptopMax: '1224px',
    desktop: '1380px'
}
export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopMax: `(min-width: ${size.laptopMax})`,
    desktop: `(min-width: ${size.desktop})`
};

export const menuList = [
    {
        "title": "Каталог",
        "to": "/catalog"
    },
    {
        "title": "Коллекции",
        "to": "/collections"
    },
    {
        "title": "О нас",
        "to": "/about"
    }
]

    // 'retina-dpi': '144dpi',
    // 'retina-dppx': '1.5dppx'// для ретины пример испрльзования  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {}


