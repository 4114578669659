import React from 'react';
import {
  PopupSt,
  PopupWrapSt,
  PopupCloseSt,
} from './style.js';

class PopupNew extends React.Component {

  render() {
    const { children, handleOpenPopupClick } = this.props;
    return (
      <PopupSt>
        <PopupWrapSt>
          <PopupCloseSt onClick={(e) => handleOpenPopupClick(e)} />
          {children}
        </PopupWrapSt>
      </PopupSt>
    );
  }
}

export default PopupNew;

