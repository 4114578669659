import {
    CUSTOMER_INFO_REQUEST,
    CUSTOMER_INFO_FAIL,
    CUSTOMER_INFO_SUCCESS,
    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_FAIL,
    CUSTOMER_UPDATE_PHOTO_REQUEST,
    CUSTOMER_UPDATE_PHOTO_SUCCESS,
    CUSTOMER_UPDATE_PHOTO_FAIL
} from "../types"

const initialState = {
    isLoading: false,
    message: '',
    errors: [],
    customer: [{
        id: 0,
        email: "",
        full_name: "",
        position: "",
        description: "",
        phone: "",
        photo: "",
        company: {
            name: "",
            description: "",
            website: "",
            address: "",
            logo_ru: "",
            logo_en: "",
            country: {},
            projects: [
                null
            ]
        },
        managers: [{
            id: 0,
            email: "",
            full_name: "",
            position: "",
            description: "",
            genres: [{
                id: 0,
                title: "",
                slug: ""
            }],
            countries: [{
                id: 0,
                title: ""
            }],
            rights: [{
                id: 0,
                name: ""
            }]
        }],
        genres: [{
            id: 0,
            title: "",
            slug: ""
        }],
        countries: [{
            id: 0,
            title: ""
        }],
        rights: [{
            id: 0,
            name: ""
        }],
        favourites: {
            project: [0],
            event: [0],
            collection: [0]
        }
    }]
}

export const customerReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case CUSTOMER_INFO_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case CUSTOMER_INFO_FAIL:
            return {
                ...state,
                message: payload,
                isLoading: false
            };
        case CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                customer: payload,
                isLoading: true
            };
        case CUSTOMER_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case CUSTOMER_UPDATE_SUCCESS:
            return {
                ...state,
                customer: { ...state.customer, ...payload },
                // state.event.filter(event => !payload.includes(event.id))
                isLoading: true
            };
        case CUSTOMER_UPDATE_FAIL:
            return {
                ...state,
                message: payload.message,
                errors: payload.errors,
                isLoading: true
            };
        case CUSTOMER_UPDATE_PHOTO_SUCCESS:
            return {
                ...state,
                customer: { ...state.customer, photo: payload.url }
            };
        case CUSTOMER_UPDATE_PHOTO_FAIL:
            return {
                ...state,
                message: payload.message,
                errors: payload.errors
            };
        default:
            return state;
    }
}