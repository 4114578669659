import { combineReducers } from 'redux'
import { authReducer as auth } from './auth/reducer'
import { customerReducer as customer } from './customer/reducer'
import { feedbackReducer as feedback } from './feedback/reducer'
import { favoritesReducer as favorites } from './favorites/reducer'
import { recommendationsReducer as recommendations } from './profile/recommendations/reducer'
import { projectsReducer as projects } from './profile/projects/reducer'
import { dopReducer as dop } from './profile/dop/reducer'
import { queryReducer as query } from './profile/profileQuery/reducer'
import { channelContentReducer as channelContent } from "./channel/reducer";
import {managersContentReducer as managersContent} from "./managers/reducer";
import {filterSaveReducer as filterSave} from "./filterSave/reducer";
import {allProjectsReducer as allProjects} from "./projects/reducer";
import {switchViewInCatalogReducer as switchViewInCatalog} from './switchView/reducer'

export const rootReducer = combineReducers({
    auth,
    customer,
    favorites,
    recommendations,
    projects,
    dop,
    query,
    feedback,
    channelContent,
    managersContent,
    filterSave,
    allProjects,
    switchViewInCatalog
})