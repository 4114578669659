import React from "react"
import i18n from "i18next";
import { Medium } from "../../styles/typography/typography"
import {
    ToggleLangSt,
    ArrowSt,
    SecondLevelSt,
    SecondLevelItemSt

} from "../Header/style";

class ToggleLanguage extends React.Component {

    handleSwitchLangClick = lang => {
        const currentLocation = window.location.href
        const baseLang = (i18n.language === 'en') ? '.com' : '.ru'
        const newLang = lang === 'en' ? '.com' : '.ru'
        window.location.assign((currentLocation.replace(baseLang, newLang)))
    };
    render() {
        const langList = i18n.languages ? i18n.languages : ["ru", "en"]; // проверка для тестов 
        const langActive = i18n.language ? i18n.language : "ru"; // проверка для тестов
        const switchLangIsOpen = this.props.switchLangIsOpen;
        return (
            <>
                {
                    (window.innerWidth < 768 || this.props.innerWidthForTest < 768) ?
                        <ToggleLangSt onClick={this.props.handleOpenSwitchLangClick} className="toggle-lang">
                            <SecondLevelSt className="switch-lang">
                                {langList.map((lang) => (
                                    <SecondLevelItemSt key={lang}>
                                        <Medium
                                            onClick={() => this.handleSwitchLangClick(lang)}
                                            className={langActive === lang ? "active lang-item" : "lang-item"} uppercase>{lang}</Medium>
                                    </SecondLevelItemSt>
                                ))}
                            </SecondLevelSt>
                        </ToggleLangSt>
                        :
                        <ToggleLangSt className="toggle-lang"
                            onClick={() => this.props.handleOpenSwitchLangClick()}>
                            <Medium uppercase>{langActive}</Medium>
                            <ArrowSt className={switchLangIsOpen ? "opened" : ""}> </ArrowSt>
                            <SecondLevelSt className={switchLangIsOpen ? "switch-lang opened" : "switch-lang"}>
                                {langList.filter(lang => lang !== langActive)
                                    .map(lang => (
                                        <SecondLevelItemSt 
                                            key={lang}
                                            className="lang-item"
                                            onClick={() => this.handleSwitchLangClick(lang)}>
                                            <Medium uppercase className="lang">{lang}</Medium>
                                        </SecondLevelItemSt>
                                    ))}
                            </SecondLevelSt>
                        </ToggleLangSt>
                }
                <input name="lang" type="hidden" value={langActive}></input>
            </>
        );
    }
};

export default ToggleLanguage;