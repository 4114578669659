import {CHANNEL_CONTENT, PRODUCTION_CONTENT, PAGE_CONTENT} from "../types";

const initialState = {
    channelContent: false,
    productionsContent: false,
    pageContent: false,
}

export const channelContentReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CHANNEL_CONTENT:
            return{
                ...state,
                channelContent: payload,
            };
        case PRODUCTION_CONTENT:
            return{
                ...state,
                productionsContent: payload,
            };
        case PAGE_CONTENT:
            return{
                ...state,
                pageContent: payload,
            };
        default:
            return state;
    }
}
