import styled from "styled-components"
import { colors, device} from "../../styles/variables.js"
import { Link } from "react-router-dom";

export const DescriptionHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TypeStyled = styled.div`
  display: flex;
`;

export const LinkStyled = styled(Link)`
   
`;

export const TypeItemStyled = styled.div`
  display: flex;
  background-color: ${colors.blue_highlight};
  border-radius: 21px;
  padding: 8px 10px;
  margin-right: 10px;
  margin-top: 18px;
  font-size: 12px;

  @media ${device.mobile} {
  }
`;
export const WrapperStyled = styled.div`
  position: relative;
`;

export const StarStyled = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  cursor: pointer;
`;

export const TooltipWrapStyled = styled.div`
  position: absolute;
  top: 30px;
  left: 103%;
  min-width: 220px;
  z-index: 1;

  @media ${device.mobile} {
    top: 35px;
    left: 60%;
  }
`;

export const SpanStyled = styled.div`
  background-color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 15px 25px;
`;

