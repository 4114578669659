import styled from "styled-components"
import { colors, fonts, device } from "../../styles/variables.js"
import {
  PprojectWrapEmailStyled
} from '../Tabs/style.js';

export const PprojectSt = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  h4.profile {
    margin-top: 50px;
    margin-bottom: 20px;
    color: ${colors.grey_passive};

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      color: #000;
      margin-top: 30px;
      margin-bottom: 0px;
      width: 100%;
    }
    img.profile  {
      padding-top: 40px;
    }
  }
`;

export const WpapImgSt = styled.div`
  position: relative;
  &.profile {
    ${PprojectWrapEmailStyled} {
      position: absolute;
      left: 8px;
      bottom: 8px;
      height: 30px;
      width: 31px;
      padding: 0;
      margin: 0;
      background-color: rgba(49,57,78, .6);
      text-align: center;
      z-index: 5;
      svg {
        left: 2px;
      }
    }
  }
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
  &.profile-mob {
    display: none;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: block !important;
    }
  }
`;

export const ProfileFotoInputSt = styled.input`
  position: absolute;
  z-index: 1;
  opacity: 0;
  height: 30px;
  width: 31px;
  left: 0;
  bottom: 0;
  font-size: 0;
  cursor: pointer;
`;



export const LeftProfileProjectSt = styled.div`
  padding-top: 76px;
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
`;
export const DateSt = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${colors.grey_text};
`;
export const ItemProjectSt = styled.div`
  color: ${props => props.colors ? props.colors : ""};
  font-size: 14px;
  line-height: 14px;
  font-family: ${fonts.font_family};
  &.all {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const IconWrapFavoritesSt = styled.div`
  position: absolute;
  top: 85px;
  left: 10px;
  cursor: pointer;
`;
export const IconFavoritesSt = styled.div`
  background-color: rgba(49, 57, 78, 0.6);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  svg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
`;

export const TooltipSt = styled.div`
  position: absolute;
  left: 4px;
  top: -57px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 5;
  border-radius: 10px;
  box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
  &.last {
    right: 25px;
    top: -40px;
  }
  &.last-dop {
    right: 25px;
    top: -73px;
  }
`;

export const TooltipItemSt = styled.a`
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding: 10px 10px;
  border-bottom: 1px solid ${colors.grey_light};
  color: ${colors.black_text};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${colors.blue_highlight};
  }
  &:last-child  {
    border-bottom: 1px solid transparent;
  }
`;