import React from 'react';

import TableRow from '../TableRow/TableRow';

import Svg from "../../../src/assets/icons/icons.js"

import {
  TableSt,
  LineSt,
  LineItemSt,
  WrapTableSt,
} from './style.js'

import i18n from "i18next";

class Table extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     dataProjectChange: "",
     tooltipe: false,
     tooltipeRequest: false,
     bottom: false,
    };
    this._sortOfData = this._sortOfData.bind(this);
    this.tooltipeOpen = this.tooltipeOpen.bind(this);
    this.tooltipeOutside = this.tooltipeOutside.bind(this);
  }

  tooltipeOpen(id) {
    this.setState(() => ( {tooltipe: id} ));
    this.setState(() => ( {tooltipeRequest: id} ));
    if (this.state.tooltipe === id) {
      this.setState(() => ( {tooltipe: false} ));
      this.setState(() => ( {tooltipeRequest: false} ));
    }
    if (this.state.tooltipeRequest === id) {
      this.setState(() => ( {tooltipeRequest: false} ));
    }
  }

  tooltipeOutside() {
    this.setState(() => ({tooltipe: false} ));
  }

  componentDidUpdate() {
    if (this.state.tooltipe){
      document.addEventListener('click', this.tooltipeOutside);
    } else  {
      document.removeEventListener('click', this.tooltipeOutside);
    }
  }

  //сортировка
  _sortOfData = (type) => {
    switch (type) {
      case "project":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all.sort((a, b) => {
            if (a.project.title > b.project.title) {
              return -1;
            }
          })
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all.sort((a, b) => {
            if (a.project.title < b.project.title) {
              return -1;
            }
          })
        }
        break;

      case "status":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.status > b.contract.status) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.status < b.contract.status) {
              return -1;
            }
          });
        }
        break;

      case "start":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            const strA = a.contract.date_start;
            const strB = b.contract.date_start;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x = new Date(dateA);
            const y = new Date(dateB);
            if (x > y) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            const strA = a.contract.date_start;
            const strB = b.contract.date_start;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x = new Date(dateA);
            const y = new Date(dateB);
            if (x < y) {
              return -1;
            }
          });
        }
        break;

      case "end":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            const strA = a.contract.date_end;
            const strB = b.contract.date_end;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x = new Date(dateA);
            const y = new Date(dateB);
            if (x > y) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            const strA = a.contract.date_end;
            const strB = b.contract.date_end;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x =  Date.parse(dateA)
            const y = Date.parse(dateB)
            if (x < y) {
              return -1;
            }
          });
        }
        break;

      case "contract":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.number > b.contract.number) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.number < b.contract.number) {
              return -1;
            }
          });
        }
        break;
        
      case "specification":
        if(this.state.dataProjectChange === type) {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.specification > b.contract.specification) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dataProject.all && this.props.dataProject.all.sort((a, b) => {
            if (a.contract.specification < b.contract.specification) {
              return -1;
            }
          });
        }
        break;
        
      case "dopStatus":
        if(this.state.dataProjectChange === type) {
          this.props.dopMaterials.all && this.props.dopMaterials.all.sort((a, b) => {
            if (a.status > b.status) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dopMaterials.all && this.props.dopMaterials.all.sort((a, b) => {
            if (a.status < b.status) {
              return -1;
            }
          });
        }
        break;

      case "dop":
        if(this.state.dataProjectChange === type) {
          this.props.dopMaterials.all && this.props.dopMaterials.all.sort((a, b) => {
            if (a.project.title > b.project.title) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.dopMaterials.all && this.props.dopMaterials.all.sort((a, b) => {
            if (a.project.title < b.project.title) {
              return -1;
            }
          });
        }
        break;

      case "request":
        if(this.state.dataProjectChange === type) {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            if (a.project.title > b.project.title) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            if (a.project.title < b.project.title) {
              return -1;
            }
          });
        }
        break;

      case "requestStatus":
        if(this.state.dataProjectChange === type) {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            if (a.status > b.status) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            if (a.status < b.status) {
              return -1;
            }
          });
        }
        break;
      case "requestDate":
        if(this.state.dataProjectChange === type) {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            const strA = a.date;
            const strB = b.date;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x =  Date.parse(dateA)
            const y = Date.parse(dateB)
            if (x > y ) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.query.all && this.props.query.all.sort((a, b) => {
            const strA = a.date;
            const strB = b.date;
            const dateA = strA.slice(3, 6) + strA.slice(0, 3) + strA.slice(6, 10)
            const dateB = strB.slice(3, 6) + strB.slice(0, 3) + strB.slice(6, 10)
            const x =  Date.parse(dateA)
            const y = Date.parse(dateB)
            if (x < y) {
              return -1;
            }
          });
        }
        break;

      case "recommend":
        if(this.state.dataProjectChange === type) {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            if (a.title > b.title) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
          });
        }
      break;

      case "recommend-start":
        if(this.state.dataProjectChange === type) {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            const x = new Date(a.publish_at)
            const y = new Date(b.publish_at)
            if (x > y ) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            const x = new Date(a.publish_at)
            const y = new Date(b.publish_at)
            if (x < y) {
              return -1;
            }
          });
        }
      break;

      case "recommend-end":
        if(this.state.dataProjectChange === type) {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            const x = new Date(a.expires_at)
            const y = new Date(b.expires_at)
            if (x > y) {
              return -1;
            }
          });
          this.setState(() => ({ bottom: type}));
        } else {
          this.props.recommendations && this.props.recommendations.sort((a, b) => {
            const x = new Date(a.expires_at)
            const y = new Date(b.expires_at)
            if (x < y) {
              return -1;
            }
          });
        }
      break;

      default:
    }
    if(this.state.dataProjectChange === type) {
      this.setState(() => ({ dataProjectChange: ""}));
    }
    if(this.state.dataProjectChange !== type) {
      this.setState(() => ({ dataProjectChange: type}));
      this.setState(() => ({ bottom: ""}));
    }
  };

  render() {
    const {type, dataProject, recommendations, dopMaterials, query} = this.props;
    return (
      <WrapTableSt>
        <TableSt className={type === "profileRecommend" ? "recommend" : ""}>
          {/*таблица для вкладки проекты*/}
          {type === "profileProject" &&
            <>
              {/*Заголовок*/}
              <LineSt className="header">
                <LineItemSt widthSt="24" className="profile-project" >
                </LineItemSt>
                <LineItemSt widthSt="246" className={this.state.dataProjectChange === "project" ? "profile-project active" : "profile-project"} bottom ={this.state.bottom === "project" ? "90" : ""}onClick={()=>this._sortOfData("project")}>
                  <Svg className={this.state.bottom === "project" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.project`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="100" className={this.state.dataProjectChange === "status" ? "profile-project active" : "profile-project"} onClick={()=>this._sortOfData("status")}>
                  <Svg className={this.state.bottom === "status" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.status`)}</span>
                </LineItemSt>
                <LineItemSt  widthSt="105" className={this.state.dataProjectChange === "start" ? "profile-project active" : "profile-project"} onClick={()=>this._sortOfData("start")}>
                  <Svg className={this.state.bottom === "start" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.period_s`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="105" className={this.state.dataProjectChange === "end" ? "profile-project active" : "profile-project"} onClick={()=>this._sortOfData("end")}>
                  <Svg className={this.state.bottom === "end" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.period_until`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="120" className={this.state.dataProjectChange === "contract" ? "profile-project active" : "profile-project"} onClick={()=>this._sortOfData("contract")}>
                  <Svg className={this.state.bottom === "contract" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.contract`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="100" className={this.state.dataProjectChange === "specification" ? "profile-project active" : "profile-project"} onClick={()=>this._sortOfData("specification")}>
                  <Svg className={this.state.bottom === "specification" ? "arrow-rotate" : ""}  type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.specification`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="30" className="profile-project points">
                  <span>...</span>
                </LineItemSt>
              </LineSt>
              {/*Строки*/}
              {dataProject.all.map((item, i)=> {
                return (
                    <TableRow 
                      type="profileProject" 
                      data={item} 
                      key={i}
                      tooltipeOpen={(id) => this.tooltipeOpen(id)} 
                      tooltipe={this.state.tooltipe}
                      tooltipeRequest={this.state.tooltipeRequest}
                      lenght={Object.keys(dataProject.all).length}
                      number={i}
                    />
                )})
              }
            </>
          }

          {/*таблица для вкладки рекомендации*/}
          {type === "profileRecommend" &&
            <>
              {/*Заголовок*/}
              <LineSt className="header">
                <LineItemSt className="recommend-arrow">
                </LineItemSt>
                <LineItemSt className={this.state.dataProjectChange === "recommend" ? " selection active" : "selection"} onClick={()=>this._sortOfData("recommend")}>
                  <Svg className={this.state.bottom === "recommend" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.selection`)}</span>
                </LineItemSt>
                <LineItemSt className={this.state.dataProjectChange === "recommend-start" ? " recommend-start active" : "recommend-start"} onClick={()=>this._sortOfData("recommend-start")}>
                  <Svg className={this.state.bottom === "recommend-start" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.start`)}</span>
                </LineItemSt>
                <LineItemSt className={this.state.dataProjectChange === "recommend-end" ? " recommend-end active" : "recommend-end"} onClick={()=>this._sortOfData("recommend-end")}>
                  <Svg className={this.state.bottom === "recommend-end" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.completion`)}</span>
                </LineItemSt>
                <LineItemSt className="recommend-mail">
                  <Svg type="mail_outline" width="24" height="24"/>
                </LineItemSt>
              </LineSt>
              {/*Строки*/}
              {recommendations.map((item, i)=> {
                return (
                  <TableRow type="profileRecommend" data={item} key={item.id} />
                )})
              }
            </>
          }

          {/*таблица для вкладки запросы*/}
          {type === "profileRequest" &&
            <>
              {/*Заголовок*/}
              <LineSt className="header">
                <LineItemSt className="profile-project" widthSt="24">
                </LineItemSt>
                <LineItemSt widthSt="590" className={this.state.dataProjectChange === "request" ? "active profile-project" : "profile-project"} onClick={()=>this._sortOfData("request")}>
                  <Svg className={this.state.bottom === "request" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.project`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="90" className={this.state.dataProjectChange === "requestDate" ? "active profile-project" : "profile-project"} onClick={()=>this._sortOfData("requestDate")}>
                  <Svg className={this.state.bottom === "requestDate" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.date`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="90" className={this.state.dataProjectChange === "requestStatus" ? "active profile-project" : "profile-project"} onClick={()=>this._sortOfData("requestStatus")}>
                  <Svg className={this.state.bottom === "requestStatus" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.status`)}</span>
                </LineItemSt>
                <LineItemSt className="recommend-mail" widthSt="30">
                  {/*<span>...</span>*/}
                  <Svg type="mail_outline" width="24" height="24"/>
                </LineItemSt>
              </LineSt>
              {/*Строки*/}
              {query?.all.map((item, i)=> {
                return (
                  <TableRow
                    type="profileRequest"
                    tooltipeOpen={(id) => this.tooltipeOpen(id)} 
                    tooltipe={this.state.tooltipe}
                    dop={item}
                    key={i}
                    number={i}
                    lenght={Object.keys(query?.all).length}
                  />
                )})
              }
            </>
          }

          {/*таблица для вкладки доп.материалы*/}
          {type === "profileDop" &&
            <>
              {/*Заголовок*/}
              <LineSt className="header">
                <LineItemSt className="profile-project" widthSt="24">
                </LineItemSt>
                <LineItemSt widthSt="680" className={this.state.dataProjectChange === "dop" ? "active profile-project" : "profile-project"} onClick={()=>this._sortOfData("dop")}>
                  <Svg className={this.state.bottom === "dop" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.project`)}</span>
                </LineItemSt>
                <LineItemSt widthSt="90" className={this.state.dataProjectChange === "dopStatus" ? "active profile-project" : "profile-project"} onClick={()=>this._sortOfData("dopStatus")}>
                  <Svg className={this.state.bottom === "dopStatus" ? "arrow-rotate" : ""} type="arrow_downward" width="24" height="24"/>
                  <span>{i18n.t(`profile.status`)}</span>
                </LineItemSt>
                <LineItemSt className="recommend-mail" widthSt="30">
                  <Svg type="mail_outline" width="24" height="24"/>
                </LineItemSt>
              </LineSt>
              {/*Строки*/}
              {dopMaterials.all && dopMaterials.all.map((item, i)=> {
                return (
                  <TableRow
                    type="profileDop"
                    tooltipeOpen={(id) => this.tooltipeOpen(id)} 
                    tooltipe={this.state.tooltipe}
                    dop={item}
                    key={i}
                    number={i}
                    lenght={Object.keys(dopMaterials.all).length}
                  />
                )})
              }
            </>
          }

          </TableSt>
      </WrapTableSt>
    );
  }
}

export default Table;
