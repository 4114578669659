import React from 'react';
import { connect } from "react-redux";

import AccountChangeFoto from '../AccountChangeFoto/AccountChangeFoto';

import { addToFavorites, deleteFromFavorites, requestFromManager, requestFromManagerbyContent } from '../../store/favorites/actions';

import Svg from "../../assets/icons/icons";
import { colors } from "../../styles/variables.js"

import {
  PprojectImageStyled,
  PprojectWrapEmailStyled,
  PprojectEmailStyled
} from '../Tabs/style.js';

import {
  CardStyled,
  CardImageStyled,
  CardWrapStyled,
  CardTextStyled,
  CardsmallTextStyled
} from '../TabsCardEvents/style.js';

import {
  PprojectSt,
  WpapImgSt,
  DateSt,
  LeftProfileProjectSt,
  ItemProjectSt,
  IconFavoritesSt,
  IconWrapFavoritesSt,
  TooltipSt,
  TooltipItemSt
} from './style.js';

import i18n from "i18next";

class TabsLeft extends React.Component {

    state = {
      bookmarkFavorites: false,
      bookmarkCheck: "not",
      sendRequest: false
    };

  componentDidUpdate(prevProps) {
    //всплывающее окно для кнопок
    if(this.props.favoritesMessage !== prevProps.favoritesMessage){
      if(this.props.favoritesMessage[0] === true ) {
        this.setState(() => {
            return { sendRequest: "Запрос отправлен" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      } else {
        this.setState(() => {
          return { sendRequest: "Запрос уже существует" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      }
    }
}

  // обработка клика на закладку
  _bookmark(id, type) {
    if (this.state.bookmarkCheck === "not") {
      this.setState({ bookmarkFavorites: this.props.isInFavourites }, () => {
        if (this.state.bookmarkFavorites) {
          this.props.deleteFromFavorites(id, type, this.props.token)
        } else {
          this.props.addToFavorites(id, type, this.props.token);
        }
        this.setState((prevState) => (
          {bookmarkFavorites: !prevState.bookmarkFavorites}
        ));
      });
      this.setState(() => ({bookmarkCheck: "check"}));
    }

    if (this.state.bookmarkCheck === "check") {
      if (this.state.bookmarkFavorites) {
        this.props.deleteFromFavorites(id, type, this.props.token)
      } else {
        this.props.addToFavorites(id, type, this.props.token);
      }
      this.setState((prevState) => (
        {bookmarkFavorites: !prevState.bookmarkFavorites}
      ));
    }
  }

  render() {
    const { managers, textSend, type, tabs, imgPoster, id, typeBookmark, isInFavourites, requestFromManager, requestFromManagerbyContent, isLoggedIn} = this.props

    const favorietsColor = this.state.bookmarkCheck === "check" ? this.state.bookmarkFavorites : isInFavourites

    //для "запросы"
    const allQuery = this.props?.query?.all_count;
    const providedQuery = this.props?.query?.provided_count;
    const inProgressQuery = this.props?.query?.in_progress_count;
    const requestQuery = this.props?.query?.request_count;

    //для доп.материалы
    const allDop = this.props?.dop?.all_count;
    const provided = this.props?.dop?.provided_count;
    const request = this.props?.dop?.request_count;

    const date = new Date();
    const today = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();

    return (
      <>
        {type !== "profile" &&
          <WpapImgSt>
            <PprojectImageStyled src={imgPoster ? imgPoster : this.props.photo} width="300" height="425" className={type === "profile" && "profile"}></PprojectImageStyled>
            {type !== "event" && isLoggedIn &&
              //кнопки для запроса контента
              <>
                <PprojectWrapEmailStyled>
                  <Svg width="24" height="24" type="mail_outline" color={colors.blue_active}></Svg>
                  <PprojectEmailStyled onClick={()=>requestFromManagerbyContent([id], this.props.token)}>запросить контент</PprojectEmailStyled>
                    {/*всплывающее окно*/}
                  {this.state.sendRequest && 
                    <TooltipSt className="bottom">
                      <TooltipItemSt>{this.state.sendRequest}</TooltipItemSt>
                    </TooltipSt>
                  }
                </PprojectWrapEmailStyled>
                <PprojectWrapEmailStyled>
                  <Svg width="24" height="24" type="add" color={colors.blue_active}></Svg>
                  <PprojectEmailStyled onClick={()=>requestFromManager([id], this.props.token)}>запросить доп. материалы</PprojectEmailStyled>
                </PprojectWrapEmailStyled>
              </>
            }
            {type === "event" &&
              <PprojectWrapEmailStyled>
                <Svg width="24" height="24" type="mail_outline" color={colors.blue_active}></Svg>
                <PprojectEmailStyled href="mailto:content@sales.gpm-rtv.ru">{textSend}</PprojectEmailStyled>
              </PprojectWrapEmailStyled>
            }
            {isLoggedIn && (
            <IconWrapFavoritesSt onClick={() => this._bookmark([id], typeBookmark)} >
              <IconFavoritesSt>
                <Svg width="24" height="24" type={favorietsColor ? "bookmark" : "bookmark_border"} color={colors.white} fill="white"></Svg>
              </IconFavoritesSt>
            </IconWrapFavoritesSt>
            )}
          </WpapImgSt>
        }

        {type === "profile" && tabs === 0 &&
          <>
            <AccountChangeFoto imgPoster={imgPoster}></AccountChangeFoto>
            
            <PprojectSt>
              {managers.length !== 0 && <h4 className="profile"> {i18n.t(`profile.your_manager`)}</h4>}
              {managers.map((item, i) => {
                return (
                    <CardStyled className="profile" key={i}>
                      {item.photo && <CardImageStyled src={item.photo} width="200" height="283" className="profile"></CardImageStyled>}
                      <CardWrapStyled>
                        <CardTextStyled><h4>{item.full_name}</h4></CardTextStyled>
                        <CardsmallTextStyled>{item.position}</CardsmallTextStyled>
                        <PprojectWrapEmailStyled>
                          <Svg width="24" height="24" type="mail_outline" color={colors.blue_active}></Svg>
                          <PprojectEmailStyled href={"mailto:" + item.email}>{item.email}</PprojectEmailStyled>
                        </PprojectWrapEmailStyled>
                      </CardWrapStyled>
                    </CardStyled>
                )
              })}
            </PprojectSt>
          </>
        }

        {type === "profile" && tabs === 1 &&
          <LeftProfileProjectSt>
            <DateSt>
              <span>{today}</span><br /><br />
            </DateSt>
            <ItemProjectSt colors={colors.black_text} className="all">
              <h4>{i18n.t(`profile.total_projects`)}: {this.props.projects.all_count ? this.props.projects.all_count : 0}</h4><br />
            </ItemProjectSt>
            {this.props.projects.active_count && <ItemProjectSt colors={colors.black_text}>
              <span>{i18n.t(`profile.active`)}: {this.props.projects.active_count ? this.props.projects.active_count : 0}</span><br /><br />
            </ItemProjectSt>}
            
            {this.props.projects.renewal_count && <ItemProjectSt colors={colors.magenta}>
              <span>{i18n.t(`profile.on_extension`)}: {this.props.projects.renewal_count ? this.props.projects.renewal_count : 0}</span><br /><br />
            </ItemProjectSt>}

            {this.props.projects.expired_count && <ItemProjectSt colors={colors.grey_text}>
              <span>{i18n.t(`profile.license_expired`)}: {this.props.projects.expired_count ? this.props.projects.expired_count : 0}</span><br /><br />
            </ItemProjectSt>}
          </LeftProfileProjectSt>
        }

        {type === "profile" && tabs === 2 &&
          <LeftProfileProjectSt>
            <DateSt>
              <span>{today}</span><br /><br />
            </DateSt>
            
             <ItemProjectSt colors={colors.black_text} className="all">
              <h4>{i18n.t(`profile.total_projects`)}: {allQuery ? allQuery : 0} </h4><br />
            </ItemProjectSt>
            
            <ItemProjectSt colors={colors.black_text}>
              <span>{i18n.t(`profile.received`)}: {providedQuery ? providedQuery : 0}</span><br /><br />
            </ItemProjectSt>
            
            <ItemProjectSt colors={colors.aqua}>
              <span>{i18n.t(`profile.In_work`)}: {inProgressQuery ? inProgressQuery : 0}</span><br /><br />
            </ItemProjectSt>

            <ItemProjectSt colors={colors.magenta}>
              <span>{i18n.t(`profile.in_processing`)}: {requestQuery ? requestQuery : 0}</span><br /><br />
            </ItemProjectSt>

          </LeftProfileProjectSt>
        }

        {type === "profile" && tabs === 3 &&
          <LeftProfileProjectSt>
            <DateSt>
              <span>{today}</span><br /><br />
            </DateSt>
            
             <ItemProjectSt colors={colors.black_text} className="all">
              <h4>{i18n.t(`profile.total_projects`)}: {allDop ? allDop : 0} </h4><br />
            </ItemProjectSt>
            
            <ItemProjectSt colors={colors.black_text}>
              <span>{i18n.t(`profile.received`)}: {provided ? provided : 0}</span><br /><br />
            </ItemProjectSt>

            <ItemProjectSt colors={colors.magenta}>
              <span>{i18n.t(`profile.in_processing`)}: {request ? request : 0}</span><br /><br />
            </ItemProjectSt>

          </LeftProfileProjectSt>
        }
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isLoading: store.projects.isLoading,
    projects: store.projects.projects,
    isLoggedIn: store.auth.isLoggedIn,
    token: store.auth.dataToken.token,
    photo: store.customer.customer.photo,
    dop: store.dop.projects,
    query: store.query.projects,
    favoritesMessage: store.favorites.message,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    addToFavorites: (id, type, token) => dispatch(addToFavorites(id, type, token)),
    deleteFromFavorites: (id, type, token) => dispatch(deleteFromFavorites(id, type, token)),
    requestFromManager: (ids, token) => dispatch(requestFromManager(ids, token)),
    requestFromManagerbyContent: (ids, token) => dispatch(requestFromManagerbyContent(ids, token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabsLeft);

