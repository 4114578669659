import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import Channel from './Channel'
import {Container} from '../../components/Container/Container'
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import i18n from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import {getPageContent} from '../../store/channel/actions'
import {getManagersContent} from '../../store/managers/actions'
import NotFound from '../404/404'

/**
 *  Страница о нас. На которой есть табы каналов и проектов (проекты на второй строке)
 *  Первая страница особенная, остальные проходят по дефолту и не требуют особых параметров
 *  Новые свойства для страницы стоит добавлять в channelParameters() и в let dP
 *  Новые каналы должны добавляться и работать автоматически (не проверял)
 *  В рендере используется условие Object.keys(store).length &&  если стейт пустой страница не загрузится
 *  Страница так же ссылается на Channel, который отвечает за вывод иконок и контента канала
 */
const About = () => {
    // возвращаем channelContent pageContent productionsContent
    const channel = useSelector((state) => state.channelContent) //каналы, продукты, о нас
    const managersContent = useSelector((state) => state.managersContent.managersContent) //менеджеры
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPageContent('about_us')) // диспатчим каналы, продукты, о нас
        dispatch(getManagersContent()) // диспатчим менеджеров
    }, [])

    const channelContent = channel.channelContent // каналы
    const productionsContent = channel.productionsContent // продукты
    const pageContent = channel.pageContent // о нас

    let store = {}
    /**
     * Формируем store
     * Запускаем ниже когда productionsContent и channelContent будут не пустыми     *
     */
    const addStore = () => {

        /**
         * Возвращаем нужные параметры для stora
         * Новые параметры которые будут приходить с сервера заносить сюда и в переменную dP
         * @param parameters
         * @returns {[{site: boolean, img: *, gpm: (*|null), text: *, title: *, сhannel_name: *, content: *}]}
         */
        const channelParameters = parameters => {
            return [{
                title: parameters.title,
                channelName: parameters.channelName,
                text: parameters.text,
                site: parameters.site,
                content: parameters.channelName,
                img: parameters.img,
                titleGpm: parameters.titleGpm,
                logo_ru: parameters.logo_ru,
                logo_en: parameters.logo_en
            }]
        }

        // объединяем 2 массива с контентом и продуктами
        const channelProductArr = channelContent.data.concat(productionsContent.data)

        /*
         * Проходимся по объединенному массиву добавляя параметры в stor через функцию channelParameters
         * Ключи стора по умолчанию slug можно у каждого поменять через case
         */
        channelProductArr.map((item => {

            // параметры по умолчанию default property
            let dP = {
                title: item.title,
                channelName: item.slug,
                text: item.text,
                site: item.website,
                content: item.slug,
                img: i18n.language === 'en' ? item.logo_en : item.logo_ru,
                titleGpm: item.titleGpm,
                logo_ru: item.logo_icon_ru,
                logo_en: item.logo_icon_en
            }

            // каналы которым не нужны доп. параметры, пройдут по дефолту
            switch (item.slug) {
                case 'gpm-etv':
                    // добавляем новые параметры
                    dP.titleGpm = pageContent.data && pageContent.data.description.substr(-pageContent.data.description.length, pageContent.data.description.indexOf('.') + 1)
                    // перезаписываем дефолтные
                    dP.text = pageContent.data && pageContent.data.content
                    dP.img = i18n.t('about.img')
                    store['gpm_etv'] = channelParameters(dP)
                    break;
                default :
                    store[item.slug] = channelParameters(dP)
                    break;
            }
        }))
    }

    // ждем когда придут продукты И каналы, иначе могут сыпаться ошибки
    if (productionsContent.data && channelContent.data) {
        addStore() // формируем стор
    }

    /**
     * Функция формирования роутов для переходов по вкладкам каналов
     *
     * @param channel массив каналов ['tnt', '2x2'] берем из ключей store
     * @returns возвращаем роутинг для каналов
     */
    const routeChannel = (channel = Object.keys(store)) => {
        if (Object.keys(store).length) {
            return channel.map(c => {
                return (
                    <Route key={ `${ c }` } path={`/about/${c}`}>
                        {Object.keys(store).length && (
                            <Channel
                                storeChannel={store}
                                store={store[c][0]}
                            />)}
                    </Route>
                )
            })
        }
    }

    return (
        <>
            <div>
                <HeaderPage
                    Title={pageContent.data && pageContent.data.title}
                    Leftdiv={pageContent.data && pageContent.data.description}
                    Rightdiv={(i18n.language === 'en') ? '/images/default-top-img-en.png' : '/images/default-top-img-ru.png'}
                    Rightvideo={pageContent.data && pageContent.data.embed_url}
                />
            </div>
            {Object.keys(store).length && (<Container>
                <Switch>
                    <Route exact path="/about">
                        <Channel store={store.gpm_etv[0]} storeChannel={store}/>
                    </Route>

                    {
                        routeChannel() // формируем Route
                    }

                    <Route path="/about/*" component={NotFound}/>

                </Switch>
            </Container>)}
        </>
    )
}

export default About