

import styled from "styled-components"
import {Big}  from "../../styles/typography/typography"
import { colors as c } from "../../styles/variables.js"
import {device as d} from "../../styles/variables";

export const ContainerStyled = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    @media ${d.tablet} {
        width: auto;
        display: inline-flex;
        /* position: absolute;
        right: 0;
        top: 40px; */
    }

`;

export const SvgSlyled = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    cursor: pointer;
    &:first-of-type {
        margin-right: 30px;
    }
    @media ${d.tablet} {
        &:first-of-type {
            margin-right: 10px;
        }
    }
  
    &.selected, 
    &:hover {
        border: 1px solid ${c.blue};
        box-sizing: border-box;
        border-radius: 2px;
    }

    svg {
        transform: scale(.9);
    }
    
`;

export const LabelStyled = styled(Big)`
    margin-right: auto;
    font-weight: 400;
`;