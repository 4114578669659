import React from 'react';
import SlideDown from 'react-slidedown';
import OutsideClickHandler from 'react-outside-click-handler';

import { WrapperSt, ErrorWrapperSt, ErrorSt } from "../TextField/style";

import Svg from "../../../src/assets/icons/icons.js"

import {
    SelectSt,
    ChosenSt,
    MediumSt,
    ArrowSt,
    ListSt,
    UlSt
} from './style.js';

export default class Select extends React.Component {

    state = {
        open: false,
        selectValue: this.props.content[0],
        selectChoose: this.props.selectChooseFromLink ? true : false,
    };

    componentDidMount() {
        if(this.props.filterSave){
            this.setState(() => {
                return {
                    selectValue: this.props.filterSave
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        // при открытии второго селектора закрываем первый
        if (this.props.closeAllSelect !== prevProps.closeAllSelect && this.state.open === true) {
            this.setState(() => {
                return { open: false }
            })
        }
        // снять все выборы
        if (this.props.removeAllChoice !== prevProps.removeAllChoice) {
            this.setState(() => {
                return {
                    selectChoose: false,
                    selectValue: this.props.content[0],
                }
            })
        }
        // при клике на тег снимается выбор селекта
        if ((this.props.removeChoice !== prevProps.removeChoice) && this.props.removeChoice ==="0") {
            this.setState(() => {
                return {
                    selectChoose: false,
                    selectValue: this.props.content[0],
                }
            })
        }
        // выделяем нужный селект при переходе через "смотреть все"
        if(this.props.selectChooseFromLink){
            if (this.props.selectChooseFromLink !== prevProps.selectChooseFromLink) {
                if(this.props.selectChooseFromLink===this.props.selectName){
                    this.setState(() => {
                        return {
                            selectChoose: true,
                            selectValue: this.props.selectValueFromLink
                        }
                    })
                }
            }
        }
    }

    // открываем селект
    _isSelectOpen() {
        if (this.props._closeAllSelect && this.state.open === false) this.props._closeAllSelect(); // при открытии второго селектора закрываем первый
        setTimeout(() => {
            this.setState(({ open }) => {
                return { open: !this.state.open }
            })
        }, 100)
    }

    // меняем значение селекта
    _isSelectOptionChoose(x, y) {
        this.props._showSearchingResults(x);
        if (this.props.content[0] !== x) {
            this.setState(() => {
                return {
                    selectValue: y ? y : x,
                    open: false,
                }
            })
            //из дефолтного вида при клике на выпадающее меню и выборе названия селекта
            if(x!=="0"){
                this.setState(() => {
                    return {
                        selectChoose: true
                    }
                })
            }
        }
        else {
            this.setState(() => {
                return {
                    selectValue: x,
                    open: false,
                    selectChoose: false
                }
            })
        }
    }

    render() {
        const { primary, error, className, content, selectWidth } = this.props;

        const li = content[1].map((item, i) => {
            if (item.id) {
                return (<li key={i} onClick={() => this._isSelectOptionChoose(item.id, item.name)}>{item.name}</li>)
            }
            else {
                return (<li key={i} onClick={() => this._isSelectOptionChoose(item)}>{item}</li>)
            }
        })

        return (
            <>
                <SelectSt>
                    <OutsideClickHandler  onOutsideClick={() => {this.setState ({ open: false})}}>

                    <WrapperSt>
                        <ChosenSt id = "chosenStyled" className={className} onClick={() => this._isSelectOpen()} open={this.state.open} selectChoose={this.state.selectChoose} primary={primary}>
                            <MediumSt>
                                {this.state.selectValue}
                            </MediumSt>
                            <ArrowSt open={this.state.open} >
                                <Svg
                                    className={this.state.open ? "keyboard_arrow_top" : ""}
                                    type="keyboard_arrow" />

                            </ArrowSt>
                        </ChosenSt>
                        <ErrorWrapperSt>
                            <ErrorSt>{error}</ErrorSt>
                        </ErrorWrapperSt>
                    </WrapperSt>

                    { primary 
                    ?
                        this.state.open &&
                        <ListSt id={"test"} selectWidth={selectWidth} >
                            <UlSt>
                                {li}
                            </UlSt>
                        </ListSt>
                    : 
                        <ListSt id={"test"} selectWidth={selectWidth}>
                            <SlideDown>
                                {this.state.open ? li : null}
                            </SlideDown>
                        </ListSt>
                    }
                    </OutsideClickHandler>
                </SelectSt>
            </>
        )
    }
}