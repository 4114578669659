import {
    HOST,
    DOP_PROFILE_REQUEST,
    DOP_PROFILE_FAIL,
    DOP_PROFILE_SUCCESS
} from '../../types'
import i18n from "i18next"

export const getDopProjects = (token) => { 
    return dispatch => {
        try {
            dispatch({
                type:  DOP_PROFILE_REQUEST,
            })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/my-extra-data`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.all) {
                        throw new Error(data.message)
                    }
                    dispatch({
                        type:  DOP_PROFILE_SUCCESS,
                        payload: data
                    })
                    return data;
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    dispatch({
                        type:  DOP_PROFILE_FAIL,
                        payload: message
                    })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type:  DOP_PROFILE_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}