import React, {useState} from 'react'
import Svg from '../../assets/icons/icons'
import {
    DivStHeaderPageTop,
    DivStRightBlock,
    DivStLeftBlock,
    DivStRightCenter,
    DivStHeaderCenter,
    DivStRightCircle,
    DivStRightText,
    DivStLeftImg,
} from './style'
import i18n from 'i18next'



//картинка по умолчанию для правого блока. Зависит от языка
const rightDivImgDefault = (i18n.language === 'en') ? '/images/default-top-img-en.png' : '/images/default-top-img-ru.png'

/**
 * Должен быть указан параметр leftImgProps(для картинки) или leftDivProps, title (для текста)
 * @param leftImgProps - картинка левого блока
 * @param leftDivProps - текст левого блока, для страниц где нет картинки, например about
 * @param title - заголовок для левого блока, для страниц где нет картинки, например about
 *
 * @param rightDivProps - картинка правого блока, по умолчанию зависит от языка сайта
 * @param rightVideoProps - ссылка на видео, подставляется в iframe src
 * @returns Возвращает компонент в топе страницы, на всю ширину состоящий из двух блоков
 *
 */
//переназываем пропсы, иначе конфликтуют названия с классами styled-components
const HeaderPage = (
    {
        Leftimg: leftImgProps,
        Leftdiv: leftDivProps,
        Title: title,
        Rightdiv: rightDivProps = rightDivImgDefault,
        Rightvideo: rightVideoProps
    }) => {

    const [clicked, setClicked] = useState(false) //локальный стейт, по умолчанию click = false
    const leftIMagesCLassNAme = leftImgProps ? "leftIMages" : "x" //если приходит leftImgProps то добавляем класс
    let leftTextTrue = ""  // переменная для проверки текста в левом блоке, если она существует, то добавляем класс для мобильной версии
    
    leftDivProps = leftDivProps ? leftDivProps.replace(/\u2028/g, ' ') : undefined
    
    if(leftImgProps) {
        leftTextTrue = "hide-mobile"
    }
    
    // не скрываем картинку на мобильной версии на странице about
    let rightDivPropsImpartant
    if(document.location.pathname === "/about") {
        rightDivPropsImpartant = "!important"
    }
    
    return (
        <DivStHeaderPageTop className={`${leftIMagesCLassNAme}`}>
            <DivStHeaderCenter className={`${leftIMagesCLassNAme}`}>
                <DivStLeftBlock className={`${leftIMagesCLassNAme, leftTextTrue}`}>
                    {leftImgProps ? (// Если приходит картинка выводим блок с картинкой, если нет, блок с текстом
                        <DivStLeftImg style={{backgroundImage: `url(${leftImgProps})`}}/>
                    ) : (
                        <DivStRightText>
                            <p>{title}</p>
                            {leftDivProps}
                        </DivStRightText>
                    )}
                </DivStLeftBlock>
                <DivStRightBlock
                    onClick={() => setClicked({clicked: false})}
                    style={{backgroundImage: `url(${rightDivProps})`}}
                    >
                    {rightVideoProps && (
                        <iframe
                            className={`video_page_player ${clicked ? 'visible' : ''}`} //при клике становится видимым
                            src={`${rightVideoProps}?autoplay=1&mute=1&rel=0`}
                            frameBorder="0"
                            webkitallowfullscreen=""
                            mozallowfullscreen=""
                            allowFullScreen=""
                            allow="fullscreen; encrypted-media; gyroscope; picture-in-picture"/>
                    )}
                    {rightVideoProps && ( //если это видео, показываем иконку видео поверх картинки
                        <DivStRightCenter className={`${leftIMagesCLassNAme}`}>
                            <DivStRightCircle>
                                <Svg
                                    width="143"
                                    height="143"
                                    type="play_circle_outline"
                                />
                            </DivStRightCircle>
                        </DivStRightCenter>
                    )}
                </DivStRightBlock>
            </DivStHeaderCenter>
        </DivStHeaderPageTop>
    )
}

export default HeaderPage