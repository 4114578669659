import {
    FAVORITES_INFO_REQUEST,
    FAVORITES_INFO_SUCCESS,
    FAVORITES_INFO_FAIL,
    FAVORITES_ADD_EVENT,
    FAVORITES_ADD_PROJECT,
    FAVORITES_ADD_COLLECTION,
    FAVORITES_DELETE_EVENT,
    FAVORITES_DELETE_PROJECT,
    FAVORITES_DELETE_COLLECTION,
    FAVORITES_INFO_ALL_ADDED
} from "../types"

// const user = JSON.parse(localStorage.getItem(STORAGE_KEY))

const initialState = {
    isLoading: false,
    // count: user ? user.favourites_count : 0,
    count: 0,
    message: '',
    messageallProjectsAdded: '',
    // collection: user ? user.favourites.collection : [],
    // event: user ? user.favourites.event : [],
    // project: user ? user.favourites.project : []
    collection: [],
    event: [],
    project: []
}

export const favoritesReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case FAVORITES_INFO_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case FAVORITES_INFO_SUCCESS:
            return {
                ...state,
                collection: payload.collection,
                event: payload.event,
                project: payload.project,
                count: payload.collection.length + payload.event.length + payload.project.length,
                isLoading: true
            };
        case FAVORITES_INFO_FAIL:
            return {
                ...state,
                message: payload,
                isLoading: false
            };
        case FAVORITES_INFO_ALL_ADDED:
            return {
                ...state,
                messageallProjectsAdded: payload,
            }
        case FAVORITES_ADD_EVENT:
            return {
                ...state,
                event: state.event.concat(payload),
                count: state.count + payload.length
            }
        case FAVORITES_ADD_PROJECT:
            return {
                ...state,
                project: state.project.concat(payload),
                count: state.count + payload.length
            }
        case FAVORITES_ADD_COLLECTION:
            return {
                ...state,
                collection: state.collection.concat(payload),
                count: state.count + payload.length
            }
        case FAVORITES_DELETE_EVENT:
            return {
                ...state,
                event: state.event.filter(event => !payload.includes(event.id)),
                count: state.count - payload.length
            }
        case FAVORITES_DELETE_PROJECT:
            return {
                ...state,
                project: state.project.filter(project => !payload.includes(project.id)),
                count: state.count - payload.length
            }
        case FAVORITES_DELETE_COLLECTION:
            return {
                ...state,
                collection: state.collection.filter(collection => !payload.includes(collection.id)),
                count: state.count - payload.length
            }
        default:
            return state;
    }
}