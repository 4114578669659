import styled from "styled-components"
import { Link } from "react-router-dom";

import { colors as c } from "../../styles/variables.js"
import { fonts as f } from "../../styles/variables.js"
import { device as d } from "../../styles/variables.js"

const backgroundColor = ["#E11C71;", "#66B8B7;", "#1565C0;", "#F1A925;", "#42A5F5;"]

function backgroundColorGet(color) {
    return backgroundColor[(color - 1) % 5];
}

export const CardSt = styled.div`
    z-index: ${props => props.zIndexCard};
    ${props => props.exceptionFromLastCard && "z-index: 998;"}
    position: relative;
    padding-bottom: 20px;
    width: 50%;
    @media ${d.tablet} {
       width: ${props => props.widthCard ? props.widthCard : "25%"};
    }
    @media ${d.laptop} {
      width: ${props => props.widthCard ? props.widthCard : "20%"};
    }
`;

export const CardMarginRightSt = styled.div`
   height: 100%;
   margin-right: 4%;
   position: relative;
   @media ${d.laptop} {
       margin-right: 14.96%;
   }
`;

export const LinkSt = styled(Link)`
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
    transition: box-shadow .3s ease;
    &:hover {
        box-shadow: 0px 6px 7px rgba(66,165,245,0.12), 0px 1px 10px rgba(66,165,245,0.2);
    }
`;

export const LinkTitleSt = styled(Link)`
    text-decoration: none;
    color: #fff;
    &:hover{
      text-decoration: underline !important;
    }
`;

export const ImgSt = styled.img`
   width: 100%;
   height: auto;
   display: block;
`;

export const LabelWrapperSt = styled.div`
   position: absolute;
   bottom: 0;
   width: 100%;
   height: ${props => props.type === "collections" && "24%;"}
           ${props => props.type === "events" && "52%;"}
   background: ${props => props.type === "collections" && backgroundColorGet(props.backgroundColor)}
               ${props => props.type === "events" && `${c.black};`}
   @media ${d.tablet} {
        height: ${props => props.type === "collections" && "18%;"}
           ${props => props.type === "events" && "36%;"}
   }
`;

export const LabelSt = styled.p`
   width: 100%;
   position: absolute;
   top: 0;
   font-size: 12px;
   color: ${c.white};
   font-weight: ${f.bold};
   padding: 0 5px;
   height: ${props => props.type === "collections" && "100%;"}
           ${props => props.type === "events" && "70%;"}
   ${props => props.type === "collections" && "text-align: center; display: table;"}
   ${props => props.type === "events" && "padding-left: 15px; padding-top: 10px;"} 
   @media ${d.tablet} {
        text-transform: uppercase;
   }
`;

export const DateSt = styled.p`
   width: 100%;
   height: 30%;
   position: absolute;
   bottom: 0;
   text-transform: uppercase;
   font-size: 10px;
   color: ${c.white};
   font-weight: ${f.normal};
   padding: 0 15px;
`;

export const TextSt = styled.span`
    display:table-cell!important;
    vertical-align:middle;
`;

export const ButtonSt = styled.div`
    ${props => props.chooseCard && "opacity: .7;"}
    width: 20%;
    height: 14%;
    background: rgba(66, 165, 245, .6);
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    display: none;
    transition: background .3s;
    &:hover {
        background: rgba(66, 165, 245, 1);
        svg {
            transform: scale(1.15);
        }
    }
    @media ${d.laptop} {
       display: block;
       z-index: 10;
    }
`;

export const ContainerFormSt = styled.div`
    width: 50%;
    height: 24px;
    cursor: pointer;
    background: ${props => props.chooseCard ? "rgb(49,57,78,1)" : "rgb(49,57,78,.6)"};
    padding-right: 4px;
`;

export const СhoosePictureContainerSt = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const FormСhooseSt = styled.form`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    &>input{
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`;

export const ButtonStInsideLink = styled.div`
    display: none;
    width: 20%;
    height: 14%;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;
    @media ${d.laptop} {
       display: block;
    }
`;

export const WrapperBookmarkSt = styled.div`
    max-width: 50%;
    max-height: 50%;
    width: ${props=>props.chooseCard ? "56px" : "24px"};
    height: 24px;
    border-radius: 12px;
    overflow: hidden;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
    display: flex;
`;

export const BookmarkSt = styled.div`
    position: relative;
    width: ${props=>props.chooseCard ? "50%" : "100%"};
    height: 24px;
    background: rgb(49, 57, 78, ${props=>props.isOpacity ? "1" : ".6"});
    cursor: pointer;
`;

export const OpenDiscriptionSt = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
`;

export const DiscriptionSt = styled.div`
    display: none;
    position: absolute;
    overflow: hidden;
    height: 100%;
    bottom: 0;
    background: ${c.blue};
    @media ${d.laptop} {
       display: block;
       ${props => props.openCardControl[0]}
       ${props => props.discriptionOpenLeft ? "right: 0;" : "left: 80%;"}
       transition: .5s;
    }
`;

export const DiscriptionContainerSt = styled.div`
    padding: 12px 10px 12px 20px;
    position: relative;
    height: 100%;
    ${props => props.openCardControl[1]}
`;

export const DiscriptionContentSt = styled.div`
    height: 85%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
    }
`;

export const TitleSt = styled.p`
    font-size: 20px;
    color: ${c.white};
    font-weight: ${f.bold};
    min-height: 50px;
    padding-bottom: 5px;
    padding-right: 13%;
`;

export const ProjectTextSt = styled.p`
    text-transform: uppercase;
    color: ${c.black};
    font-size: 10px;
    margin-bottom: 7px;
`;

export const HrSt = styled.hr`
     border: none;
     border-top: 1px solid #ffffff;
     opacity: .2;
     margin: 10px 0;
`;

export const ProjectDiscriptionSt = styled.p`
     font-size: 12px;
     color: ${c.white};
`;

export const FooterSt = styled.div`
     display: flex;
     flex-wrap: wrap;
     position: absolute;
     width: 80%;
     bottom: 10px;
     justify-content: space-between;
     color: ${c.white};
     font-size: 10px;
`;

export const FooterContainerSt = styled.div`
     display: table;
     height: 30px;
`;

export const FooterContentSt = styled.div`
     display: table-cell;
     vertical-align: middle;
     ${props => props.paddingRight && "padding-right: 10px;"}
`;

export const FooterContentASt = styled.a`
    text-decoration: none;
    color: ${c.white};
    display: table-cell;
    vertical-align: middle;
    ${props => props.paddingRight && "padding-right: 10px;"}
`;

export const CrossSt = styled.div`
       width: 40px;
       height: 40px;
       position: absolute;
       top: 0;
       right: 0;
       background: ${c.white};
       opacity: .7;
       cursor: pointer;
       ${props => props.openCardControl[2]}
`;

export const CenterSvgSt = styled.svg`
       position: absolute;
       margin: auto;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       transition: transform .3s ease;
`;

export const ChooseCardTopSt = styled.div`
    position: absolute; 
    top: 0; 
    width: 100%; 
    height: 100%; 
    background: #ffffff; 
    opacity: .7; 
    z-index: 9;
`;

export const DiscriptionChannelSVGSt = styled.svg`
   transform: scale(.8);
   marginLeft: -12px;
   fill: #ffffff;
`;

