import React from "react";
import {Container} from "../../components/Container/Container";
import i18n from "i18next";
import GetCards from "../../components/GetCards/GetCards";
import { H3 } from "../../styles/typography/typography"

import {HeaderStyled} from "./style";

import { ContainerCatalogStyled } from "../../components/Container/style";
export default class SearchResult extends React.Component {

    state={
        searchShow: true,
        countCards: 0
    }

    // проверяем есть ли хотя бы одна найденная карта. В зависимости от результата выводим нужный текст
    _countCards(x){
        const count = this.state.countCards+x;
        this.setState(()=>{
            return{countCards: count}
        })
    }

    componentDidUpdate(prevProps) {
        //
        if(this.props.searchWorld !== prevProps.searchWorld){
            this.setState(()=>{
                return{countCards: 0}
            })
            this.setState(()=>{
                return{searchShow: false}
            })
            setTimeout(()=>{
                this.setState(()=>{
                    return{searchShow: true}
                })
            }, 30)
        }
    }

    render() {
        return(
            <ContainerCatalogStyled>
                <Container>
                    {this.state.countCards>0 && <HeaderStyled>
                        <H3 as= "h1">{i18n.t(`search.searching_results`)} «{this.props.searchWorld}»</H3>
                    </HeaderStyled>}
                    {this.state.countCards===0 && <HeaderStyled>
                       <H3 as= "h1">{i18n.t(`search.no_results`)}</H3>
                    </HeaderStyled>}
                </Container>
                <Container>
                    {(this.state.searchShow && this.props.searchWorld !== "") && <>
                        <GetCards sourceCards={`projects?filter%5Btitle%5D=${this.props.searchWorld}`} title={i18n.t(`filter.search_by_name`)} type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                    </>}
                </Container>
                <Container> 
                    {(this.state.searchShow && this.props.searchWorld !== "") && <>
                        <GetCards sourceCards={`projects?filter%5Bcategory_id%5D=1&filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`filter.category.${0}.animation`)} link="/catalog" type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`projects?filter%5Bcategory_id%5D=2&filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`filter.category.${0}.documentaries`)} link="/catalog" type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`projects?filter%5Bcategory_id%5D=4&filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`filter.category.${0}.programs`)} link="/catalog" type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`projects?filter%5Bcategory_id%5D=5&filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`filter.category.${0}.series`)} link="/catalog" type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`projects?filter%5Bcategory_id%5D=3&filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`filter.category.${0}.films`)} link="/catalog" type="catalog" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`events?filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`header.menuList.${1}.title`)} link="/events" type="events" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                        <GetCards sourceCards={`collections?filter%5Bsearch%5D=${this.props.searchWorld}`} title={i18n.t(`header.menuList.${2}.title`)} link="/collections" type="collections" cardDisplayType="carousel" _countCards={(x)=>this._countCards(x)}/>
                    </>}
                </Container>
            </ContainerCatalogStyled>
        )
    }
}