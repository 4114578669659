import React from 'react';
import { H4, H3 } from "../../styles/typography/typography.js";
import Popup from '../../components/Popup/Popup.jsx'
import VideoGallery from '../../components/VideoGallery/VideoGallery.jsx';


import {
  AdditionalDownloadWrap }
from '../Tabs-additional/style.js';

import {
  FotoStyled,
  FotoLeftStyled,
  FotoRightStyled,
  FotoLinkStyled, 
  PostertStyled,
  PostertItemStyled,
} from './style.js';

import {
  PopupImageStyled,
} from '../Popup/style.js';
import {
  TextWrapStyled,
} from '../Tabs-description/style.js';

import TabsCardEvents from "../TabsCardEvents/TabsCardEvents";
import i18n from "i18next";

class TabsFoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 0,
      fotoCount: 11
    };
    this.handleFotoAllClick = this.handleFotoAllClick.bind(this); 
  }

  handleFotoAllClick = (fotosLength) => {
    this.setState(() => ({fotoCount: fotosLength}));
  }
  
  render() {
    const {cadresSmall, cadre, posters, handleClick, popupIsOpen, fotosIndex, handleClickPlus, handleClickMinus, project, manager, location, videos, event} = this.props
    {/*уточнить как будут передаваться фото */}
    
    return (
      <React.Fragment>
        {cadre.length > 0 && <>
        {project &&  <H4 color="black_text">{i18n.t(`catalog.project.${0}.personnel`)}</H4>}
        <FotoStyled className={!project && "notline"}>
          <FotoLeftStyled>
            {
              cadresSmall.map((fotos, index) =>
                index  > this.state.fotoCount
                ? ""
                : <AdditionalDownloadWrap  onClick={() => handleClick(index)}>
                    <img src={fotos} key={index} width="160" height="90" alt="Постер"/>
                  </AdditionalDownloadWrap>
              )
            }
          </FotoLeftStyled>
          <FotoRightStyled>
            {
              this.state.fotoCount > 11
                ? <FotoLinkStyled onClick={() => this.handleFotoAllClick(11)}>{
                    cadre.length > 11 && 
                      i18n.t(`catalog.project.${0}.reduce`)}
                  </FotoLinkStyled>
                : <FotoLinkStyled onClick={() => this.handleFotoAllClick(cadre.length)}>
                    {cadre.length > 11 && 
                      i18n.t(`catalog.project.${0}.see_all.${0}.text1`) + " " + i18n.t(`catalog.project.${0}.see_all.${0}.text2`) + " " + (cadre.length+"+")}
                </FotoLinkStyled>
            }
          </FotoRightStyled>
        </FotoStyled>
        </>}

        {(project && posters.length > 0) &&
          <>
            <H4 color="black_text">{i18n.t(`catalog.project.${0}.posters`)}</H4>
            <PostertStyled>
              {posters.map((posters, index) =>
                  <PostertItemStyled src={posters} width="160" height="90" key={index} />
                )}
            </PostertStyled>
          </>
        }
        {/*галлерея с видео для страницы мероприятия(вкладка фото/видео)*/}
        {event && 
          <VideoGallery
            trailerBlock={videos}
          />
        }
        {manager && 
          <>
            <TextWrapStyled>
              <H3>{i18n.t(`catalog.project.${0}.managers`)}</H3>
              <TabsCardEvents manager={manager} location={location} />
            </TextWrapStyled>
          </>
        }

        {popupIsOpen
          ? 
            <Popup
              arrows={true}
              foto={true}
              fotosIndex={fotosIndex}
              fotosAll={cadre.length}
              handleClick={handleClick}
              handleClickMinus={handleClickMinus}
              handleClickPlus={handleClickPlus} >
                <PopupImageStyled src={cadre[fotosIndex]} />
            </Popup>
          : null
        }
      </React.Fragment>
    );
  }
}

export default TabsFoto;
