import styled from "styled-components"
import { colors, fonts, device} from "../../styles/variables.js"

export const SpanStyled = styled.span`
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  padding-bottom: 30px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 14px;
    padding-bottom: 30px;
  }
`;