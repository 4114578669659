import React from 'react';
import BannerNew from "../BannerNew/BannerNew";

import {
    BannerFonSt,
    ContainerTopBannerSt,
    AboutUsMoreDetailsSt,
    LeftSt,
    AboutUsTextSt,
    AboutUsLinkSt,
    AboutUsTitleSt,
    ContainerSt,
    ButtonSt,
    ButtonPaddingSt,
    ArrowSt,
    BannersContainerSt,
    CarouselSt,
    PlugSt
} from './style.js';

import i18n from "i18next";

export default class TopBannerNew extends React.Component {

    state ={
        maxRight: 0,
        marginCount: 0, // margin-left у CarouselSt - за чет которого осуществляется листание карусели
        rightButtonDisplay: false,
        leftButtonDisplay: false,
        lastCardLeftMargin: true,
    };

    // ref - для получения ширины контейнара
    BannerFonSt = React.createRef();

    carouselMoveRight(){
        let newMarginCount = this.state.marginCount + 1;
        if(this.props.banners.length - newMarginCount === this.state.maxRight){
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    rightButtonDisplay: false,
                    leftButtonDisplay: true,
                }
            })
        }
        else{
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    leftButtonDisplay: true
                }
            })
        }
    }

    carouselMoveLeft(){
        let newMarginCount = this.state.marginCount - 1;
        if(newMarginCount === 0){
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    leftButtonDisplay: false,
                    rightButtonDisplay: true
                }
            })
        }
        else{
            this.setState(() => {
                return {
                    marginCount: newMarginCount,
                    rightButtonDisplay: true
                }
            })
        }
    }

    // Движение карусели СВАЙПОМ
    swipeZeroDot = 0;
    swipeBreak = true;
    // получение первой точки свайпа
    swipeSlide(e) {
        this.swipeZeroDot = e.changedTouches[0].pageX;
    }
    //отработка горизонтального свайпа
    swipeSlideEnd(e){
        if(this.swipeBreak){
            this.swipeBreak = false;
            let finalDot = this.swipeZeroDot - e.changedTouches[0].pageX;
            if(finalDot>1 && this.state.rightButtonDisplay){
                this.carouselMoveRight();
            }
            else if(finalDot<-1 && this.state.leftButtonDisplay){
                this.carouselMoveLeft();
            }
            setTimeout(() => this.swipeBreak = true, 300);
        }
    }

    _maxRightCount(){
        setTimeout(() => {
            if(this.BannerFonSt.current.offsetWidth < 768 && this.props.banners.length>2){
                this.setState(() => {
                    return {
                        rightButtonDisplay: true,
                        maxRight: 2,
                        lastCardLeftMargin: false,
                    }
                })
            }
            else if(this.BannerFonSt.current.offsetWidth < 1024  && this.props.banners.length>3){
                this.setState(() => {
                    return {
                        rightButtonDisplay: true,
                        maxRight: 3,
                        lastCardLeftMargin: false,
                    }
                })
            }
            else if(this.BannerFonSt.current.offsetWidth < 1420  && this.props.banners.length>4){
                this.setState(() => {
                    return {
                        rightButtonDisplay: true,
                        maxRight: 4,
                        lastCardLeftMargin: false,
                    }
                })
            }
            else if(this.BannerFonSt.current.offsetWidth < 1820  && this.props.banners.length>5){
                this.setState(() => {
                    return {
                        rightButtonDisplay: true,
                        maxRight: 5,
                        lastCardLeftMargin: false,
                    }
                })
            }
            else if(this.BannerFonSt.current.offsetWidth >= 1820  && this.props.banners.length>6){
                this.setState(() => {
                    return {
                        rightButtonDisplay: true,
                        maxRight: 6,
                        lastCardLeftMargin: false,
                    }
                })
            }
        }, 300)
    }


    componentDidMount() {
        this._maxRightCount();
    }

    componentDidUpdate(prevProps) {
        if(this.props.banners.length !== prevProps.banners.length){
            this._maxRightCount();
        }
        if(this.props.isMobile !== prevProps.isMobile){
            this.setState(() => {
                return {
                    maxRight: 0,
                    marginCount: 0, // margin-left у CarouselSt - за чет которого осуществляется листание карусели
                    rightButtonDisplay: false,
                    leftButtonDisplay: false,
                }
            })
            this._maxRightCount();
        }
    }

    render(){
        const { banners, contentType, plug } = this.props;

        let PlugImg = (i18n.language === 'en') ? '/images/cover_event_null_EN.jpg' : '/images/cover_event_null_RU.jpg'

        const allBanners = banners.map((item, i) => {
            if(1===1){
                if(i === banners.length-1){
                    return(
                        <BannerNew { ...item } key={i+1} index={i} widthEl={banners.length} marginCount={this.state.marginCount}  discriptionOpenLeft={true} contentType={contentType} lastCardLeftMargin={item.type}/>
                    )
                }
                else{
                    return(
                        <BannerNew { ...item } key={i+1} index={i} widthEl={banners.length} marginCount={this.state.marginCount} contentType={item.type}/>
                    )
                }
            }
        })

        return (
            <BannerFonSt ref={this.BannerFonSt}>
                <ContainerTopBannerSt>
                    <LeftSt>
                        {(contentType === "проект" || contentType === "project") && <>
                            <AboutUsTitleSt>{i18n.t(`topBanner.description.${0}.title`)}</AboutUsTitleSt>
                            <AboutUsTextSt>
                                {i18n.t(`topBanner.description.${0}.text1`)}
                                <AboutUsLinkSt href='/channel/tnt'>{i18n.t(`topBanner.description.${0}.text2`)}</AboutUsLinkSt>,
                                <AboutUsLinkSt href='/channel/tv3'> {i18n.t(`topBanner.description.${0}.text3`)}</AboutUsLinkSt>,
                                <AboutUsLinkSt href='/channel/friday'> «{i18n.t(`topBanner.description.${0}.text4`)}»</AboutUsLinkSt>,&nbsp;
                                <AboutUsLinkSt href='/channel/tnt4' >{i18n.t(`topBanner.description.${0}.text5`)}</AboutUsLinkSt>,&nbsp;
                                <AboutUsLinkSt href='/channel/2x2' >2х2</AboutUsLinkSt>,&nbsp;
                                <AboutUsLinkSt href='/channel/subbota' > «{i18n.t(`topBanner.description.${0}.text6`)}»</AboutUsLinkSt>,&nbsp;
                                {i18n.t(`topBanner.description.${0}.text7`)}&nbsp;«{i18n.t(`topBanner.description.${0}.text8`)}»,&nbsp;
                                {i18n.t(`topBanner.description.${0}.text9`)} &nbsp;
                                <AboutUsLinkSt href='/channel/comedy-production' >{i18n.t(`topBanner.description.${0}.text10`)}</AboutUsLinkSt>&nbsp;
                                {i18n.t(`topBanner.description.${0}.text11`)}&nbsp;
                                <AboutUsLinkSt href='/channel/good-story' >{i18n.t(`topBanner.description.${0}.text12`)}</AboutUsLinkSt>.&nbsp;
                                {i18n.t(`topBanner.description.${0}.text13`)}&nbsp;«{i18n.t(`topBanner.description.${0}.text14`)}», <AboutUsLinkSt href='/channel/2x2prod' >«{i18n.t(`topBanner.description.${0}.text15`)}»</AboutUsLinkSt>&nbsp;
                                {i18n.t(`topBanner.description.${0}.text16`)}&nbsp;
                                <AboutUsLinkSt href='/channel/123-production' >«{i18n.t(`topBanner.description.${0}.text17`)}»</AboutUsLinkSt>,&nbsp;
                                {i18n.t(`topBanner.description.${0}.text18`)}.</AboutUsTextSt>
                            <AboutUsMoreDetailsSt to={'/about'}>{i18n.t(`topBanner.detail`)}</AboutUsMoreDetailsSt>

                        </>}
                        {(contentType === "мероприятие" || contentType === "event") && <>
                            <AboutUsTitleSt>{i18n.t(`topBanner.description.${1}.title`)}</AboutUsTitleSt>
                            <AboutUsTextSt>{i18n.t(`topBanner.description.${1}.text1`)}</AboutUsTextSt>
                        </>}
                    </LeftSt>
                    <ContainerSt>

                        {(plug==='true') ? <PlugSt style={{ backgroundImage: `url(` + PlugImg + `)` }} ></PlugSt>
                            : <>
                            <BannersContainerSt>
                                <CarouselSt widthEl={banners.length} marginCount={this.state.marginCount} onTouchStart={(e) => this.swipeSlide(e)} onTouchMove={(e) => this.swipeSlideEnd(e)}>
                                    {allBanners}
                                </CarouselSt>
                            </BannersContainerSt>
                            {this.state.leftButtonDisplay && <ButtonSt side="left" onClick={() => this.carouselMoveLeft()}>
                                <ButtonPaddingSt></ButtonPaddingSt>
                                <ArrowSt width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.668 3.79967L6.36658 13.9997L16.668 24.1997L13.4966 27.333L0.00131342 13.9997L13.4966 0.66634L16.668 3.79967Z" fill="white"/>
                                </ArrowSt>
                            </ButtonSt>}
                            {this.state.rightButtonDisplay && <ButtonSt side="right" onClick={() => this.carouselMoveRight()}>
                                <ButtonPaddingSt></ButtonPaddingSt>
                                <ArrowSt width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.332027 24.2003L10.6334 14.0003L0.332031 3.80032L3.50342 0.666993L16.9987 14.0003L3.50341 27.3337L0.332027 24.2003Z" fill="white"/>
                                </ArrowSt>
                            </ButtonSt>}
                        </>
                        }

                    </ContainerSt>
                </ContainerTopBannerSt>
            </BannerFonSt>
        );
    }
}
