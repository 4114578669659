import React from 'react';
import { HOST } from "../../store/types";
import GetCards from "../../components/GetCards/GetCards";
import ToggleView from "../../components/ToggleView/ToggleView";
import {
    ContainerForToggleViewStyled,
    HeaderStyled,
    NumberPassedStyled,
    ContainerStyled
} from './style.js';
import i18n from "i18next";

import {Container} from "../../components/Container/Container";
import { ContainerCatalogStyled } from "../../components/Container/style";
export default class Events extends React.Component {

    state ={
        dashboard: true,
        total: 0
    };

    // переключение режима показа карточек на странице
    _onToggleViewClick(x) {
        this.setState({
            dashboard: x
        });
    }

    // Получаем категории и жанры с сервера
    _onGetTotal(){
        fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/events?group=1`)
            .then((res) => {
                return res.json();
            })
            .then((body) => {
                this.setState(() => {
                    return {total: body.data.ended.total};
                });
            });
    }

    componentDidMount() {
        //Вызов функции получающий категории и жанры с сервера
        this._onGetTotal();
    }

    render() {

        const now = new Date();
        const year = now.getFullYear();
        return (
            <ContainerCatalogStyled>
                <GetCards sourceCards="slides" contentType={(i18n.language === 'en') ? 'event' : 'мероприятие'} plug="true" cardDisplayType="topbanner" isMobile={this.props.isMobile}/>
                <Container padding ="true">
                    <ContainerStyled>
                        <ContainerForToggleViewStyled>
                            <ToggleView selected = {this.state.dashboard  ? "dashboard" : "list" }  toggleViewChange={(x)=>this._onToggleViewClick(x)}  />
                        </ContainerForToggleViewStyled>
                        <GetCards sourceCards="events?sort%5Bcolumn%5D=date_end&sort%5Border%5D=asc" type="events" title={year+1} cardDisplayType="grid" tile={this.state.dashboard}/>
                        <GetCards sourceCards="events?sort%5Bcolumn%5D=date_end&sort%5Border%5D=asc" type="events" title={year} cardDisplayType="grid" tile={this.state.dashboard}/>
                        {this.state.total>0 && <HeaderStyled>{i18n.t(`events.past`)}<NumberPassedStyled> ({this.state.total})</NumberPassedStyled></HeaderStyled>}
                        <GetCards sourceCards="events?sort%5Bcolumn%5D=date_end&sort%5Border%5D=asc" type="events" title={year} past={true} cardDisplayType="grid" tile={this.state.dashboard}/>
                        <GetCards sourceCards="events?sort%5Bcolumn%5D=date_end&sort%5Border%5D=asc" type="events" title={year-1} cardDisplayType="grid" tile={this.state.dashboard}/>
                    </ContainerStyled>
                </Container>
            </ContainerCatalogStyled>
        );
    }
}

