import { store } from '../store/configureStore'
import { logout } from '../store/auth/actions';
import i18n from "i18next";

const getIndex = (array, item) => {
  return array.findIndex((it) => it.id === item.id);
};

export const replaceItem = (array, item) => {
  const itemIndex = getIndex(array, item);
  return [
    ...array.slice(0, itemIndex),
    item,
    ...array.slice(itemIndex + 1)
  ];
};

// валидация email
export const validateEmail = (str) => {
  const regExpEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regExpEmail.test(String(str).toLowerCase());
}

// валидация пароля (не менее 8 символов и не менее одной цифры)
export const validatePass = (str) => {
  const regExpPass = /^(?=.*\d)(?=.*[a-zA-Zа-яА-Я])(?=.*[0-9]).{8,}$/;
  return regExpPass.test(str);
}

// валидация на пустое поле
export const emptyField = (str) => {
  if (str === "") {
    return i18n.t(`login.fill_in_the_field`) //Заполните поле
  } else {
    return ""
  }
}

/* Check status for request from server */
export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.json()
  }
  else if (response.status === 401) { 
    store.dispatch(logout())
  }
  else if (response.status >= 400 && response.status < 500) {
    return response.json().then(err => Promise.reject(err));
  }
  return Promise.reject(new Error(response.status))
}

const getGenresFromProject = (item) => {
        const arr = [];
        item.genres.map((item) => {
            const id =  item.id
            const title =  item.title
            arr.push({id, title})
        })
        return arr
    };

// получение списка жанров, если у жанра есть привязка хотя бы к одному проекту
export const getGenresList = (response) => {
  const genresFromProject = response.map((item) => getGenresFromProject(item)).flat(Infinity);
  let used = {};
  const filtered = genresFromProject.filter(function(obj) {
        return obj.id in used ? 0:(used[obj.id]=1);
  });
  return filtered
}

// обработка строки с датой
export function dateFormation (date_start, date_end) {
    let date = "";
    let dateFinish = "";
    let dateMonthStart = [];
    let dateMonthFinish = "";
    let giveDate = "";
    if (date_start) {
        date = date_start;
        if (date_end) dateFinish = date_end;
        switch (date.substr(5, 2)) {
            case "01": dateMonthStart = ["January", "Jan."]; break;
            case "02": dateMonthStart = ["February", "Feb."]; break;
            case "03": dateMonthStart = ["March", "Mar."]; break;
            case "04": dateMonthStart = ["April", "Apr."]; break;
            case "05": dateMonthStart = ["May", "May"]; break;
            case "06": dateMonthStart = ["June", "June"]; break;
            case "07": dateMonthStart = ["July", "July"]; break;
            case "08": dateMonthStart = ["August", "Aug."]; break;
            case "09": dateMonthStart = ["September", "Sept."]; break;
            case "10": dateMonthStart = ["October", "Oct."]; break;
            case "11": dateMonthStart = ["November", "Nov."]; break;
            case "12": dateMonthStart = ["December", "Dec."]; break;
        }
        switch (dateFinish.substr(5, 2)) {
            case "01": dateMonthFinish = "Jan."; break;
            case "02": dateMonthFinish = "Feb."; break;
            case "03": dateMonthFinish = "Mar."; break;
            case "04": dateMonthFinish = "Apr."; break;
            case "05": dateMonthFinish = "May"; break;
            case "06": dateMonthFinish = "June"; break;
            case "07": dateMonthFinish = "July"; break;
            case "08": dateMonthFinish = "Aug."; break;
            case "09": dateMonthFinish = "Sept."; break;
            case "10": dateMonthFinish = "Oct."; break;
            case "11": dateMonthFinish = "Nov."; break;
            case "12": dateMonthFinish = "Dec."; break;
        }
        giveDate = dateMonthStart[1] === dateMonthFinish ? ` ${dateMonthStart[0]} ` : `${dateMonthStart[1]} `; // ставим месяц начала мероприятия
        giveDate += date.substr(8, 2); // ставим день начала мероприятия
        giveDate += (date.substr(5, 5) !== dateFinish.substr(5, 5) ? `—` : ``); // если месяц и день начала мероприятия отличаются от дня и месяца конца мероприятия, то ставим "—"
        giveDate += (dateMonthStart[1] !== dateMonthFinish ? ` ${dateMonthFinish} ` : ``); // если месяц начала мероприятия отличается от месяца конца мероприятия, то ставим месяц оканчания
        giveDate += (date.substr(5, 5) !== dateFinish.substr(5, 5) ? `${dateFinish.substr(8, 2)}` : ``); // если день начала мероприятия отличается от дня конца мероприятия, то ставим день оканчания
        giveDate += `, ${date.substr(0, 4)} `; // год прохождения мероприятия
        return giveDate;
    }
}

// получение массива с выбранными тегами для селекта
export const getSelectedTags = (arr) => {
  if (arr === undefined) {
      return []
  } else
      return arr.filter(val => val.checked === true)
          .map((tag, i) => (tag.name))
}

// получение массива id с выбранными тегами для селекта
export const getSelectedTagsId = (arr) => {
  if (arr === undefined) {
      return []
  }  
  else {
      let newArr = [];
      arr.filter(val => val.checked === true)
          .map((tag, i) => newArr.push(tag.id))
          return newArr
  }  
}