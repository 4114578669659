import React from 'react';
import i18n from "i18next";
import { H4 } from "../../styles/typography/typography.js";
import Popup from '../../components/Popup/Popup.jsx';
import {
  AdditionalContainerStyled,
  VideoWrapStyled,
  VideoPreviewStlyed,
  VideoPlayStlyed,
  VideoTextStlyed,
} from '../Tabs-additional/style.js';

import {
  PopupFrameStyled
} from '../Popup/style.js';

class VideoGallery extends React.Component {  constructor(props) {
  super(props);
  this.state = {
    popupIsOpen: false,
    fotosIndex: 0,
    trailer: false,
  };
  this.handleClick = this.handleClick.bind(this);
  this.handleClickPlus = this.handleClickPlus.bind(this);
  this.handleClickMinus = this.handleClickMinus.bind(this);
  this.onToggleViewClick = this.onToggleViewClick.bind(this);
}

onToggleViewClick(x) {
  this.setState({dashboard: x});
}

handleClick = (id, statusTreiler) => {
  this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
  this.setState(() => ({ fotosIndex: id }));
  this.setState(() => ({ trailer: statusTreiler }));
}

handleClickPlus = () => {
  this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex + 1}));
}

handleClickMinus = () => {
  this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex - 1}));
}

  render() {
    const {trailerBlock} = this.props;

   
    return (
      <>
        {trailerBlock.length > 0 && 
          <>
            <H4 color="black_text">Тестовове видео для верстки</H4><br />
            <AdditionalContainerStyled>
              {trailerBlock.map((item, index) =>
                <VideoWrapStyled key={index}>
                  <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                  <VideoPlayStlyed width="60" height="60" viewBox="0 0 80 79" fill="none" onClick={() => this.handleClick(index, true)}>
                    <g opacity="0.6"><circle cx="40.2303" cy="39.2323" r="32.6932" fill="white"/></g>
                    <path d="M51.6728 40.0491L35.3262 50.6744L35.3262 29.4238L51.6728 40.0491Z"/>
                  </VideoPlayStlyed>
                  <VideoTextStlyed>
                    {item.title} | {item.duration_text}
                  </VideoTextStlyed>
                </VideoWrapStyled>
              )}
            </AdditionalContainerStyled>
          </>
        }

      {
        this.state.popupIsOpen
          ? <Popup
            arrows={true}
            handleClickMinus={this.handleClickMinus}
            handleClick={ this.handleClick}
            handleClickPlus={ this.handleClickPlus}  
            fotosAll={trailerBlock.length} 
            fotosIndex={this.state.fotosIndex}>
              <PopupFrameStyled src={trailerBlock[this.state.fotosIndex].embed_url}/>
            </Popup>
          : null
      }
      </>
    );
  }
};

export default VideoGallery;
