import {
    HOST,
    CUSTOMER_INFO_REQUEST,
    CUSTOMER_INFO_FAIL,
    CUSTOMER_INFO_SUCCESS,
    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_FAIL,
    CUSTOMER_UPDATE_PHOTO_SUCCESS,
    CUSTOMER_UPDATE_PHOTO_FAIL
} from '../types'

import { checkStatus } from '../../services/utils';
import i18n from "i18next"

export const getCustomerInfo = (token) => {
    return dispatch => {
        try {
            dispatch({
                type: CUSTOMER_INFO_REQUEST,
            })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/info`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(checkStatus)
                .then(data => {
                    dispatch({
                        type: CUSTOMER_INFO_SUCCESS,
                        payload: data.data
                    })
                    return data.data;
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    dispatch({
                        type: CUSTOMER_INFO_FAIL,
                        payload: message
                    })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CUSTOMER_INFO_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const updateCustomerInfo = (info, token) => {
    return (dispatch, getState) => {
        try {
            dispatch({
                type: CUSTOMER_UPDATE_REQUEST,
            })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/update`, {
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(info),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.message) {
                        let err = new Error(data.message);
                        err.errors = data.errors;
                        throw err;
                    }
                    // All list of data for multiselect
                    const multiselectInfo = getState().auth.register_info;
                    // For multiselect
                    let selectedInfo;
                    if (Object.keys(info).includes('countries') || Object.keys(info).includes('genres') || Object.keys(info).includes('rights')) {
                        const options = Object.keys(info)[0]
                        selectedInfo = multiselectInfo[options].filter(option => info[options].includes(option.id))
                        // selectedInfo = multiselectInfo[options].filter(country => info.countries.includes(country.id))
                        dispatch({
                            type: CUSTOMER_UPDATE_SUCCESS,
                            payload: { [options]: selectedInfo }
                        })
                    } else {
                        dispatch({
                            type: CUSTOMER_UPDATE_SUCCESS,
                            payload: info
                        })
                    }
                    return data;
                })
                .catch((dataErr) => {
                    let message;
                    if (dataErr.errors && dataErr.message) {
                        message = { message: dataErr.message, errors: dataErr.errors }
                    } else if (dataErr.message) {
                        message = { message: dataErr.message, errors: {} }
                    } else {
                        message = {
                            message: (message.response &&
                                message.response.data &&
                                message.response.data.message) ||
                                message.message ||
                                message.toString(),
                            errors: {}
                        }
                    }

                    dispatch({
                        type: CUSTOMER_UPDATE_FAIL,
                        payload: message
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CUSTOMER_UPDATE_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const updatePhoto = (file, token) => {
    const formData = new FormData()
    formData.append('photo', file)
    return (dispatch) => {
        try {
            // dispatch({
            //     type: CUSTOMER_UPDATE_PHOTO_REQUEST,
            // })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/customer/upload-photo`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json'
                },
                body: formData
            })
                .then(checkStatus)
                .then(data => {
                    dispatch({
                        type: CUSTOMER_UPDATE_PHOTO_SUCCESS, 
                        payload: data
                    })
                    return data;
                })
                .catch((err) => {
                    let errors;
                    if (err.errors && err.message) {
                        errors = { message: err.message, errors: err.errors }
                    } else if (err.message) {
                        errors = { message: err.message, errors: {} }
                    } else {
                        errors = {
                            message: (err.response &&
                                err.response.data &&
                                err.response.data.message) ||
                                err.message ||
                                err.toString(),
                            errors: {}
                        }
                    }
                    dispatch({
                        type: CUSTOMER_UPDATE_PHOTO_FAIL,
                        payload: errors
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CUSTOMER_UPDATE_PHOTO_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}