import React from 'react';
import { connect } from "react-redux";

import SelectMultiple from '../SelectMultiple/SelectMultiple';
import Select from '../Select/Select'

import { updateCustomerInfo } from '../../store/customer/actions';

import { colors } from "../../styles/variables.js"
import Svg from "../../../src/assets/icons/icons.js"

import { UlSt } from '../Select/style.js';
import {
  AreaSt,
  EditSt,
  ContainerAreaSt,
  WrapHeaderSt,
  WrapContentSt,
  VerifedSt,
  VerifedTextSt,
  WrapTooltip,
  ButtonSaveSt,
  WrapSelectSt,
  WrapTopSt,
  WrapLogoSt,
  LineSt,
  LineLeftSt,
  LineRightSt,
  TooltipWrapSt,
  SpanSt
} from './style.js'

import i18n from "i18next";

class AccountProfile extends React.Component {

    state = {
        tooltipIsOpen: false,
        full_name: this.props.managerName,
        fullPosition: this.props.fullPosition,
        email: this.props.mail,
        company_name: this.props.company,
        position: this.props.position,
        password: "",
        /*селекты*/
        country: this.props.country,
        countries: this.props.territory,
        genres: this.props.genres,
        typeOfRights: this.props.typeOfRights,
        /*end селекты*/
        full_nameActive: false,
        fullPositionActive: false,
        emailActive: false,
        company_nameActive: false,
        positionActive: false,
        passwordActive: false,
        /*ошибки*/
        errorsFullName: false,
        errorsMail: false,
        errorsCompany: false,
        errorsPassword: true,
        formErrors: { password:'', email:'', fullName: '', newEmail: '', confirmPassword: '', country: '', newPassword: '', company: '', position:'', genresChosen:'', rightChosen:'', territoryChosen:'',},
    };

  _handleChange(evt) {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    if (name === "full_name") {
      this._validFullName(value)
    }
    if (name === "company_name" || name === "position") {
      this._validCompany(value)
    }
    if (name === "password") {
      this._validPassword(value)
    }
    if (name === "email") {
      this._validEmail(value)
    }
  }

  _handleFocus(name) {
    this.setState({ [name + 'Active']: "active" });
  }

  _handleNotFocus(name, type) {
    if(type === "not") {
      this.setState({ [name + 'Active']: false });
    } else {
      this.setState({ [name + 'Active']: false});
      this.props.updateCustomerInfoAction({ [name]: this.state[name] }, this.props.token)
    }
  }

  _handleTooltipEnter = () => {
    this.setState((prevState) => ({ tooltipIsOpen: true }));
  }

  _handleTooltipLeave = () => {
    this.setState((prevState) => ({ tooltipIsOpen: false}));
  }

  _showSearchingResults(x) {
    this.setState(() => {
      return {
        country: x,
      }
    });
    this.props.updateCustomerInfoAction({ ["country_id"]: x }, this.props.token)
  }

  sortingData = (data) => {
    if (data === undefined) {
      return []
    } else
      return data.filter(item => item.checked === true)
        .map(item => item.id)
  }

  _updateSelectData = (data, selectName) => {
    const dataSort = this.sortingData(data);
    this.props.updateCustomerInfoAction({ [selectName]: dataSort }, this.props.token)
  }

  _validFullName = (value) => {
    if (value.length > 35 || value.length === 0 || value.length <= 3){
      this.setState({ errorsFullName: true });
    } else {
      this.setState({ errorsFullName: false });
    }
  }

  _validCompany = (value) => {
    if (value.length > 35 || value.length === 0){
      this.setState({ errorsCompany: true });
    } else {
      this.setState({ errorsCompany: false });
    }
  }

  _validPassword = (value) => {
    if (value.length > 35 || value.length === 0 || value.length < 8){
      this.setState({ errorsPassword: true });
    } else {
      this.setState({ errorsPassword: false });
    }
  }

  _validEmail = (value) => {
    const pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
    if (pattern.test(value)){
      this.setState({ errorsMail: false});
    } else {
      this.setState({ errorsMail: true });
    }
  }

  render() {
    const { verifed, country, territory, genres, typeOfRights, logoCompany, mail, company, customer, managerName, position } = this.props;

    return (
      <form>
        <WrapTopSt>
          <WrapHeaderSt>
            {verifed === "PRO"
              ? <VerifedSt >
                <WrapTooltip onMouseEnter={this._handleTooltipEnter} onMouseLeave={this._handleTooltipLeave} >
                  <Svg width="24" height="24" type="verified"></Svg>
                  <VerifedTextSt className="line">Pro</VerifedTextSt>
                  {(this.state.tooltipIsOpen)
                    ? <TooltipWrapSt>
                      <UlSt>
                        <SpanSt>{i18n.t(`profile.concluding`)}</SpanSt>
                      </UlSt>
                    </TooltipWrapSt>
                    : null}
                </WrapTooltip>
              </VerifedSt>
              : <VerifedSt>
                <WrapTooltip>
                  <Svg width="24" height="24" type="work_outline"></Svg>
                  <VerifedTextSt>PARTNER</VerifedTextSt>
                </WrapTooltip>
              </VerifedSt>
            }

            <EditSt className={this.state.full_nameActive === "active" && "active focus"}>
              <ContainerAreaSt className={this.state.full_nameActive === "active" ? "header focus" : "header"}>
                {!this.state.full_nameActive && <span>{managerName}</span>} {/*нужно для ширины и высоты в зависисмости от количества текста*/}
                {this.state.full_nameActive && <AreaSt
                  className={this.state.full_nameActive === "active" ? "header" : "header"}
                  value={this.state.full_name}
                  name="full_name"
                  autoFocus={this.state.full_nameActive === "active" ? true : false}
                  onChange={(e) => this._handleChange(e)}
                  readOnly={this.state.full_nameActive === "active" ? false : true}
                  maxLength="36"
                />}
              </ContainerAreaSt>
              {this.state.full_nameActive === "active"
                ? <>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('full_name')} type="submit"  disabled={this.state.errorsFullName ? "disabled" : false} >
                      <Svg type="check" color={!this.state.errorsFullName ? `${colors.aqua_light}`: `${colors.grey_passive}` } width="24" height="24" />
                    </ButtonSaveSt>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('full_name', 'not')} type="submit">
                      <Svg type="close" color={colors.black_text} width="24" height="24" />
                    </ButtonSaveSt>
                  </>
                : <span onClick={() => this._handleFocus('full_name')}>
                    <Svg type="edit" color={colors.grey_passive} width="24" height="24" />
                  </span>
              }
            </EditSt>

          </WrapHeaderSt>

          <WrapLogoSt>
            <img src={logoCompany} alt="" />
          </WrapLogoSt>

        </WrapTopSt>

        <EditSt>
          <ContainerAreaSt className="header-small visible">
            {this.state.fullPosition} {/*нужно для ширины и высоты в зависисмости от количества текста*/}
          </ContainerAreaSt>
        </EditSt>

        <WrapContentSt>

          {/*поле для отображения/замены email*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>E-mail</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <EditSt className={this.state.emailActive === "active" ? "focus" : ""}>
                <ContainerAreaSt className={this.state.emailActive === "active" ? "focus" : ""}>
                  {!this.state.emailActive &&<span>{mail}</span>} {/*нужно для ширины и высоты в зависисмости от количества текста*/}
                  {this.state.emailActive && <AreaSt
                    value={this.state.email}
                    className={this.state.emailActive === "active" ? "" : ""}
                    name="email"
                    autoFocus={this.state.emailActive === "active" ? true : false}
                    onChange={(e) => this._handleChange(e)}
                    readOnly={this.state.emailActive === "active" ? false : true}
                  />}
                </ContainerAreaSt>
                {this.state.emailActive === "active"
                  ? <>
                      <ButtonSaveSt onClick={() => this._handleNotFocus('email')} type="submit" disabled={this.state.errorsMail ? "disabled" : false}>
                        <Svg type="check" color={!this.state.errorsMail ? `${colors.aqua_light}`: `${colors.grey_passive}` } width="24" height="24" />
                      </ButtonSaveSt>
                      <ButtonSaveSt onClick={() => this._handleNotFocus('email', 'not')} type="submit">
                        <Svg type="close" color={colors.black_text} width="24" height="24" />
                      </ButtonSaveSt>
                    </>
                  : <span onClick={() => this._handleFocus('email')}>
                    <Svg type="edit" color={colors.grey_passive} width="24" height="24" />
                  </span>
                }
              </EditSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены названия компании*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.company`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <EditSt className={this.state.company_nameActive === "active" ? "focus" : ""}>
                <ContainerAreaSt className={this.state.company_nameActive === "active" ? "focus" : ""}>
                  {!this.state.company_nameActive && <span>{company}</span>} {/*нужно для ширины и высоты в зависисмости от количества текста*/}
                  {this.state.company_nameActive === "active" &&
                    <AreaSt
                      value={this.state.company_name}
                      className={this.state.company_nameActive === "active" ? "" : ""}
                      name="company_name"
                      autoFocus={this.state.company_nameActive === "active" ? true : false}
                      onChange={(e) => this._handleChange(e)}
                      readOnly={this.state.company_nameActive === "active" ? false : true}
                    />
                  }
                </ContainerAreaSt>
                {this.state.company_nameActive === "active"
                  ? <>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('company_name')} type="submit"  disabled={this.state.errorsCompany ? "disabled" : false} >
                      <Svg type="check" color={!this.state.errorsCompany ? `${colors.aqua_light}`: `${colors.grey_passive}` } width="24" height="24" />
                    </ButtonSaveSt>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('company_name', 'not')} type="submit">
                      <Svg type="close" color={colors.black_text} width="24" height="24" />
                    </ButtonSaveSt>
                    </>
                  : <span onClick={() => this._handleFocus('company_name')}>
                    <Svg type="edit" color={colors.grey_passive} width="24" height="24" />
                  </span>
                }
              </EditSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены должности*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.position`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <EditSt className={this.state.positionActive === "active" ? "focus" : ""}>
                <ContainerAreaSt className={this.state.positionActive === "active" ? "focus" : ""}>
                  {!this.state.positionActive && <span>{position}</span>}{/*нужно для ширины и высоты в зависисмости от количества текста*/}
                  {this.state.positionActive === "active" &&
                    <AreaSt
                      value={this.state.position}
                      className={this.state.positionActive === "active" ? "" : ""}
                      value={this.state.position}
                      name="position"
                      autoFocus={this.state.positionActive === "active" ? true : false}
                      onChange={(e) => this._handleChange(e)}
                      readOnly={this.state.positionActive === "active" ? false : true}
                    />
                  }
                </ContainerAreaSt>
                {this.state.positionActive === "active"
                  ? <>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('position')} type="submit"  disabled={this.state.errorsCompany ? "disabled" : false} >
                      <Svg type="check" color={!this.state.errorsCompany ? `${colors.aqua_light}`: `${colors.grey_passive}` } width="24" height="24" />
                    </ButtonSaveSt>
                    <ButtonSaveSt onClick={() => this._handleNotFocus('position', 'not')} type="submit">
                      <Svg type="close" color={colors.black_text} width="24" height="24" />
                    </ButtonSaveSt>
                    </>
                  : <span onClick={() => this._handleFocus('position')}>
                    <Svg type="edit" color={colors.grey_passive} width="24" height="24" />
                  </span>
                }
              </EditSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены страны*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.country`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <WrapSelectSt>
                <Select
                  primary
                  content={country}
                  className={this.state.formErrors.country !== '' ? "invalid" : ""}
                  error={this.state.formErrors.country} 
                  name="country"
                  selectWidth="true"
                  _showSearchingResults={(x) => this._showSearchingResults(x)}
                />
              </WrapSelectSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены пароля*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.pass`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <EditSt className={this.state.passwordActive === "active" ? "password focus" : "password"} disabled={this.state.errorsPassword ? "disabled" : false} >
                <ContainerAreaSt className={this.state.passwordActive === "active" ? "focus" : ""}>
                    {!this.state.passwordActive && <span className="password">&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</span>}
                    {this.state.passwordActive &&
                      <AreaSt
                        value={this.state.password}
                        className={this.state.passwordActive === "active" ? "" : ""}
                        name="password"
                        autoFocus={this.state.passwordActive === "active" ? true : false}
                        onChange={(e) => this._handleChange(e)}
                        readOnly={this.state.passwordActive === "active" ? false : true}
                        placeholder="введите новый пароль"
                      />
                    }
                </ContainerAreaSt>
                {this.state.passwordActive === "active"
                  ? <>
                      <ButtonSaveSt onClick={() => this._handleNotFocus('password')} type="submit">
                        <Svg type="check" color={!this.state.errorsPassword ? `${colors.aqua_light}`: `${colors.grey_passive}` }  width="24" height="24" />
                      </ButtonSaveSt>
                      <ButtonSaveSt onClick={() => this._handleNotFocus('password', 'not')} type="submit">
                        <Svg type="close"  color={colors.black_text} width="24" height="24" />
                      </ButtonSaveSt>
                    </>
                  : <span onClick={() => this._handleFocus('password')}>
                    <Svg type="edit" color={colors.grey_passive} width="24" height="24" />
                  </span>
                }
              </EditSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены территории интересующих прав*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.territory`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <WrapSelectSt>
                <SelectMultiple
                  primary
                  name="countries"
                  content={territory}
                  action="edit"
                  updateData={this._updateSelectData}
                />
              </WrapSelectSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены вида прав*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.type_of_rights`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <WrapSelectSt>
                <SelectMultiple
                  primary
                  name="rights"
                  content={typeOfRights}
                  action="edit"
                  updateData={this._updateSelectData}
                />
              </WrapSelectSt>
            </LineRightSt>
          </LineSt>

          {/*поле для отображения/замены интересующих прав*/}
          <LineSt className="profile">
            <LineLeftSt className="profile">
              <span>{i18n.t(`profile.genres_of_interest`)}</span>
            </LineLeftSt>
            <LineRightSt className="profile">
              <WrapSelectSt>
                <SelectMultiple
                  primary
                  content={genres}
                  name="genres"
                  position="top"
                  action="edit"
                  viewType="popup"
                  updateData={this._updateSelectData}
                />
              </WrapSelectSt>
            </LineRightSt>
          </LineSt>

        </WrapContentSt>
        {(Object.keys(customer.errors).length !== 0) && (
          Object.values(customer.errors).map((error, index) => {
            return (
              <div key={index}>{error}</div>
            )
          })
        )}

      </form>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    isLoading: store.customer.isLoading,
    customer: store.customer,
    token: store.auth.dataToken.token
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerInfoAction: (customer, token) => dispatch(updateCustomerInfo(customer, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
