import React from "react";
import {
    ContainerSt,
    NameSt,
    ButtonWrapSt
} from "./style";

const Tag = ({ name, removeTag }) => {
    return (
        <ContainerSt data-value={name}>
            <NameSt data-value={name}>
                {name}
            </NameSt>
            <ButtonWrapSt
                data-value={name}
                onClick={removeTag}
            >
                <svg data-value={name} width="24" height="24" viewBox="0 0 24 24">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5229 22 12C22 6.47708 17.5228 2 12 2C6.47716 2 2 6.47708 2 12C2 17.5229 6.47716 22 12 22ZM6.96208 8.28799L10.6742 12L6.96208 15.7122L8.28792 17.038L12 13.3258L15.7121 17.038L17.0379 15.7122L13.3258 12L17.0379 8.28799L15.7121 6.96216L12 10.6743L8.28792 6.96216L6.96208 8.28799Z" fill="#42A5F5" />
                </svg>
            </ButtonWrapSt>
        </ContainerSt>
    )
}

export default Tag;