import React from 'react';
import { ButtonStyled } from './style.js';
import {
    SmallStyled
} from "./style";


const Button = ({ name = "", primary = false, disabled = false, size = 'medium', label='set label', type ='button', width, _click=() => {}, ...props }) => {
            return ( 
                <ButtonStyled type={type} primary={primary} disabled={disabled} size = {size} width= {width} name= {name} onClick={()=>_click()}>
                    <SmallStyled size="14px" extra_bold uppercase> {label} </SmallStyled>
                </ButtonStyled>  
            );
};

export default Button;