import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { HOST } from "../../store/types";

import GetCards from "../../components/GetCards/GetCards";
import Tabs from '../../components/Tabs/Tabs.jsx'
import TabsDescription from '../../components/Tabs-description/Tabs-description.jsx'
import TabsFoto from '../../components/Tabs-foto/Tabs-foto.jsx'
import TabsVideo from '../../components/Tabs-video/Tabs-video.jsx'
import TabsEvent from "../../components/TabsEvent/TabsEvent";
import {
  LineOneStyled
} from './style.js';
import { Container } from "../../components/Container/Container";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import i18n from "i18next";
import {connect} from "react-redux";
import { ContainerCatalogStyled } from "../../components/Container/style";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupIsOpen: false,
      fotosIndex: 0,
      trailer: false,
       //для компонента Grid 
      dashboard: true,
      chooseCard: false,
      //контент
      contentPage: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickPlus = this.handleClickPlus.bind(this);
    this.handleClickMinus = this.handleClickMinus.bind(this);
    this.onToggleViewClick = this.onToggleViewClick.bind(this);
  }

  onToggleViewClick(x) {
    this.setState({dashboard: x});
  }

  handleClick = (id, statusTreiler) => {
    this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
    this.setState(() => ({ fotosIndex: id }));
    this.setState(() => ({ trailer: statusTreiler }));
  }

  handleClickPlus = () => {
    this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex + 1}));
  }

  handleClickMinus = () => {
    this.setState((prevState) => ({ fotosIndex: prevState.fotosIndex - 1}));
  }

  //Получаем содержимое страницы
  _onContentPage(slug, token){
    if (token) {
      var bearer = 'Bearer ' + token
    }
    fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/events/${slug}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Authorization': bearer,
        'Content-Type': 'application/json'
      }
    })
        .then((res)=>{
          return res.json();
        })
        .then((body)=>{

          if(body.message) {
            this.setState({ redirect: true })
          } else {
            this.setState(() => {
              return { contentPage: body.data };
            });
          }

        });
  }

  componentDidMount() {
    // Вызов функции получающий содержимое страницы
    this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.indexOf('/',1)+1), this.props.token);
  }

  componentDidUpdate(prevProps) {
    //переход на другой проект
    if (this.props.history.location.pathname !== this.state.history) {
      this._onContentPage(this.props.history.location.pathname.substr(this.props.history.location.pathname.indexOf('/',1)+1), this.props.token);
      this.setState(()=>{
        return{history: this.props.history.location.pathname}
      })
    }
  }

  render() {

    //редирект на 404
    const { redirect } = this.state;
    if (redirect) { return <Redirect to='/404'/>; }

    // обработка даты мероприятия
    let date = "";
    let getDateS = String(this.state.contentPage.date_start);
    let getDateE = String(this.state.contentPage.date_end);
    const dateMonth = [i18n.t(`event.january`), i18n.t(`event.february`), i18n.t(`event.march`), i18n.t(`event.april`), i18n.t(`event.may`), i18n.t(`event.june`), i18n.t(`event.july`), i18n.t(`event.august`), i18n.t(`event.september`), i18n.t(`event.october`), i18n.t(`event.november`), i18n.t(`event.december`)];

    let daysCoincide = getDateS.substr(8,2) !== getDateE.substr(8,2); // совпадает день начала и окончания
    let monthsCoincide = getDateS.substr(5,2) !== getDateE.substr(5,2); // совпадает месяц начала и окончания
    let yearsCoincide = getDateS.substr(0,4) !== getDateE.substr(0,4); // совпадает год начала и окончания

    if(this.state.contentPage.date_end){
      date = +getDateS.substr(8,2); // добавляем число начала
      date += monthsCoincide ? ` ${dateMonth[+getDateS.substr(5,2)-1]}` : ""; // если месяц начала отличается от месяца окончания, то добавляем месяц начала
      date += yearsCoincide ? ` ${getDateS.substr(0,4)}` : ""; // если год начала отличается от года окончания, то добавляем год начала
      date += (daysCoincide || monthsCoincide || yearsCoincide) ? " - " : ""; // если дата начала и окончания отличаются, то ставим тере
      date += (daysCoincide || monthsCoincide || yearsCoincide) ? `${+getDateE.substr(8,2)}` : ""; // если дата начала и окончания отличаются, то ставим день окончания
      date += ` ${dateMonth[+getDateE.substr(5,2)-1]}`; // добавляем месяц окончания
      date += ` ${getDateE.substr(0,4)}` // добавляем год окончания
    }

    // менеджеры
    let managers = [];
    this.state.contentPage.managers && this.state.contentPage.managers.map((item)=>{return(managers.push(item))});
    // кадры
    let photos = [];
    this.state.contentPage.photos && this.state.contentPage.photos.map((item) => { return (photos.push(item.media)) });
    let photosSmall = [];
    this.state.contentPage.photos && this.state.contentPage.photos.map((item) => { return (photosSmall.push(item.media_preview)) });
    let videos = [];
    this.state.contentPage.videos && this.state.contentPage.videos.map((item) => { return (videos.push(item.media)) });

    const testProject = {
      cadre: ["../img-test/video-prev.png",  "../img-test/video-prev2.png", "../img-test/video-prev.png", "../img-test/video-prev3.png", "../img-test/video-prev.png", "../img-test/video-prev.png", "../img-test/video-prev3.png"],
      posters: ["video-poster.png",  "video-poster.png"],
      ///Вкладка видео, галерея "трейлеры"
      trailerBlock: [{ 
        title: i18n.t(`event.trailer`),
        embed_url: "https://uma.media/play/embed/0d66ac0a6d2d9821621396c7fd718a02?wmode=opaque&amp;fakeFullscreen=1",
        duration_text: "03:11", 
        preview_ru: "../img-test/video-prev.png",
      },
      {
        title: i18n.t(`event.trailer`),
        embed_url: "https://www.youtube.com/embed/ZlDiZO5x1o4",
        duration_text: "03:11", 
        preview_ru: "../img-test/video-prev.png",
      }],
      //Вкладка видео, галерея "эпизоды"
      season: [
        [
          {video: "https://uma.media/play/embed/0d66ac0a6d2d9821621396c7fd718a02", time: "03:11", previewVideo: "../img-test/video-prev.png"},
          {video: "https://uma.media/play/embed/af0d250d98bc9966cdbdbbc6fec555b9", time: "03:11", previewVideo: "../img-test/video-prev.png",},
          {video: "https://uma.media/play/embed/0d66ac0a6d2d9821621396c7fd718a02", time: "03:11", previewVideo: "../img-test/video-prev.png",},
          {video: "https://uma.media/play/embed/af0d250d98bc9966cdbdbbc6fec555b9", time: "03:11", previewVideo: "../img-test/video-prev.png",}
        ],
        [
          {video: "https://uma.media/play/embed/0d66ac0a6d2d9821621396c7fd718a02", time: "03:11", previewVideo: "../img-test/video-prev.png"},
          {video: "https://uma.media/play/embed/af0d250d98bc9966cdbdbbc6fec555b9", time: "03:11", previewVideo: "../img-test/video-prev.png",},
        ]
      ],
      description: [ 
        {name: i18n.t(`event.dates`), text: date},
        {name: i18n.t(`event.location`), text: this.state.contentPage.location ? this.state.contentPage.location : ""},
        {name: i18n.t(`event.format`), text: this.state.contentPage.format ? this.state.contentPage.format : ""},
        {name: i18n.t(`event.website`), text: this.state.contentPage.website ? <a href={this.state.contentPage.website} target="_blank">{this.state.contentPage.website}</a> : ""},
        {name: i18n.t(`event.program`), text: this.state.contentPage.schedule ? <a href={this.state.contentPage.schedule} target="_blank">{this.state.contentPage.schedule.substr(1+this.state.contentPage.schedule.lastIndexOf("/"))}</a>: ""}
      ],
     location: {
        img: this.state.contentPage.location_image,
        pdf: this.state.contentPage.location_info,
      }
    }
    const tabsMenu = [
      {tabsName: i18n.t(`events.synopsis`)},
      {tabsName: (this.state.contentPage.selection && i18n.t(`events.our_materials`))},
      /*{tabsName: "Видео"},*/
      {tabsName: ((photos.length>0 || videos.length>0) && i18n.t(`events.photo_video`))},
      //для отображение переключателя карточек во вкладке "Наши материалы"
      {events: true},
    ]
    return (
      <ContainerCatalogStyled>
{/*        <GetCards
          sourceCards="banners" 
          contentType="мероприятие" 
          cardDisplayType="topbanner"
        />*/}
        <HeaderPage
            /* Leftimg={'/img-test/c710x400.png'}*/
            Title={i18n.t(`events.about_the_event`)}
            Leftdiv={this.state.contentPage.top_description}
            Rightdiv={(i18n.language === 'en') ? this.state.contentPage.image_en : this.state.contentPage.image_ru}
            Rightvideo={this.state.contentPage.video_embed}/>

        <Container>
          <Tabs
            tabsMenu={tabsMenu}
            imgPoster={(i18n.language === 'en') ? (this.state.contentPage.poster_en === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.poster_en) : (this.state.contentPage.poster_ru === "" ? "../img-test/default-card-img.jpg" : this.state.contentPage.poster_ru)}
            textSend={i18n.t(`events.make_appointment`)}
            type="event"
            //для пререключения вкладок
            onToggleViewClick={(x)=>this.onToggleViewClick(x)}
            dashboard={this.state.dashboard}
            id={this.state.contentPage && this.state.contentPage.id}
            isInFavourites={this.state.contentPage.isInFavourites && this.state.contentPage.isInFavourites}
            typeBookmark="events"

            firstTabs={<TabsDescription
              description={testProject.description}
              bigDescription={this.state.contentPage.short_description ? this.state.contentPage.short_description : ""}
              name={this.state.contentPage.title ? this.state.contentPage.title : ""}
              moreDetails={this.state.contentPage.description ? this.state.contentPage.description : ""}
              manager={managers[0] ? managers : false}
              location={testProject.location}
            />}

            secondTabs={<TabsEvent
              dashboard={this.state.dashboard}
              manager={managers[0] ? managers : false}
              getSelections={this.state.contentPage.selection && this.state.contentPage.selection.id}
              location={testProject.location}
            />}

            /*thirdTabs={<TabsVideo
              season={testProject.season} 
              trailerBlock={testProject.trailerBlock} 
              handleClickMinus={this.handleClickMinus} 
              handleClickPlus={this.handleClickPlus} 
              handleClick={this.handleClick} 
              popupIsOpen={this.state.popupIsOpen} 
              fotosIndex={this.state.fotosIndex} 
              trailer={this.state.trailer}
              manager={testProject.manager}
              location={testProject.location}
            />}*/

            thirdTabs={<TabsFoto
              posters={testProject.posters} 
              cadresSmall={photosSmall}
              cadre={photos}
              handleClickMinus={this.handleClickMinus} 
              handleClickPlus={this.handleClickPlus} 
              handleClick={this.handleClick} 
              popupIsOpen={this.state.popupIsOpen} 
              fotosIndex={this.state.fotosIndex}
              manager={managers[0] ? managers : false}
              location={testProject.location}
              videos = {testProject.trailerBlock}
              event = {true}
            />}

          />
          <LineOneStyled></LineOneStyled>
          <GetCards sourceCards="events" link="/events" type="events" title={i18n.t(`events.we_also_recommend`)} cardDisplayType="carousel" />
        </Container>
      </ContainerCatalogStyled>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.auth.dataToken.token,
    isLoggedIn: store.auth.isLoggedIn
  };
}


export default withRouter(connect(mapStateToProps)(Event));
