import React from 'react';
import {colors} from "../../styles/variables";
import i18n from "i18next";
import { H4 } from "../../styles/typography/typography.js";

import {
  AdditionalTextStyled, 
  AdditionalLogo,
} from '../Tabs-additional/style.js';

class Materials extends React.Component {

  render() {
    const {materials} = this.props;

    return (
      <>
        <H4 color="black_text">{i18n.t(`catalog.project.${0}.materials`)}</H4>
        {materials.map((materials, index) =>
            <AdditionalTextStyled href={materials.download_url} key={index}>
              <AdditionalLogo width="24" height="24" viewBox="0 0 24 24">
                <use xlinkHref="../images/sprite.svg#download"  fill={colors.blue_active}></use>
              </AdditionalLogo>
              {materials.name}
            </AdditionalTextStyled>
          )
        }
      </>
    );
  }
}


export default Materials;