import React from 'react';
import {
  PopupStyled,
  PopupWrapStyled,
  PopupGalleryWrap,
  PopupCloseStyled,
  ButtonStyled,
  ArrowStyled,
} from './style.js';

//handleClick - обязательный параметр, передавать функцию для закрытия попапа

class Popup extends React.Component {
  
  render() {
    const {handleClick, handleClickPlus, handleClickMinus, fotosAll, fotosIndex, children, foto, arrows} = this.props;
    return (
      <PopupStyled>
        {foto 
          ? <PopupGalleryWrap>
                <PopupCloseStyled onClick={handleClick} />
                {children}
              {/* стрелки */}        
              {fotosIndex === 0 || !arrows
                ? null
                :<ButtonStyled oppositeSide="right" onClick={handleClickMinus}>
                  <ArrowStyled width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.668 3.79967L6.36658 13.9997L16.668 24.1997L13.4966 27.333L0.00131342 13.9997L13.4966 0.66634L16.668 3.79967Z" fill="white"/>
                  </ArrowStyled>
                </ButtonStyled>
              }
              {fotosIndex === (fotosAll - 1)  || !arrows
                ? null
                :<ButtonStyled oppositeSide="left" onClick={handleClickPlus}>
                  <ArrowStyled width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.332027 24.2003L10.6334 14.0003L0.332031 3.80032L3.50342 0.666993L16.9987 14.0003L3.50341 27.3337L0.332027 24.2003Z" fill="white"/>
                  </ArrowStyled>
                </ButtonStyled>
              }
            </PopupGalleryWrap>
          :
            <PopupWrapStyled>
              <PopupCloseStyled onClick={handleClick} />
              {children}
              {/* стрелки */}        
              {fotosIndex === 0 || !arrows
                ? null
                :<ButtonStyled oppositeSide="right" onClick={handleClickMinus}>
                  <ArrowStyled width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.668 3.79967L6.36658 13.9997L16.668 24.1997L13.4966 27.333L0.00131342 13.9997L13.4966 0.66634L16.668 3.79967Z" fill="white"/>
                  </ArrowStyled>
                </ButtonStyled>
              }
              {fotosIndex === (fotosAll - 1) || !arrows
                ? null
                :<ButtonStyled oppositeSide="left" onClick={handleClickPlus}>
                  <ArrowStyled width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.332027 24.2003L10.6334 14.0003L0.332031 3.80032L3.50342 0.666993L16.9987 14.0003L3.50341 27.3337L0.332027 24.2003Z" fill="white"/>
                  </ArrowStyled>
                </ButtonStyled>
              }
            </PopupWrapStyled>
        }

      </PopupStyled>
    );
  }

}

export default Popup;

