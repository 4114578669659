import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import { Small, H3 } from "../../styles/typography/typography"
import { device } from "../../styles/variables.js"
import {Link} from "react-router-dom";
export const ContainerCollectionSt = styled.div`
    padding-top: 60px;

    flex: 1 0 auto;
    @media ${device.tablet} {
        padding-top: 0;
    }
`;

export const ContainerSt = styled.div`
    position: relative;
    &:first-child {
        padding: 35px 0 0 0;
    }
`;
export const ToggleViewSt = styled.div`
    padding: 20px 0;
    
    @media ${device.tablet} {
        padding: 0;
    }
`;
export const SettingsMenuSt = styled.div`
    @media ${device.tablet} {
        margin-top: 10px;
    }
`;

export const SettingsToggleSt = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    span.visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        -webkit-clip: rect(0,0,0,0);
        clip: rect(0,0,0,0);
        overflow: hidden;
    }
    .toggle{
        margin-left: 30px;
        @media ${device.laptop} {
            margin-left: 0;
        }
    }
   
`;

export const SettingsMenuWrapperSt = styled.div`
    display: none;
    justify-content: space-between; 
    padding: 20px 0 30px 0 ;
    border-bottom: 1px solid ${c.grey_light};
    flex-direction: column;
    margin-bottom: 10px;
   &.opened  {
    display: flex;
    position: absolute;
    background-color: ${c.white};
    width: 100%;
    z-index: 96;
    box-shadow:  0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
    width: 100vw;
    margin-left: -20px;
    padding: 0 20px 10px 20px;
    margin-top: 12px;
   }

   > div {
    @media ${device.tablet} {
        display: flex;
        flex-direction: initial;
        width: 100%;
        justify-content: space-between;
    }
   }
   
    @media ${device.tablet} {
        display: flex;
        flex-direction: initial;
    }
`;

export const SettingsLeftSt = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0;
   
    @media ${device.tablet} {
        flex-direction: initial;
        padding: 0;
    }
`;

export const H3St = styled(H3)`
  padding: 0!important;
  border-bottom: none!important;
  margin-bottom: 0!important;
`;

export const SettingsRigthSt = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media ${device.tablet} {
        flex-direction: initial;
    } 

`;

export const ItemSettingsSt = styled(SettingsRigthSt)` 
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 20px 0 20px 20px;
    border-top: 1px solid ${c.grey_light};
    border-bottom: 1px solid ${c.grey_light};
    cursor: pointer;
    &:first-child {
        border-bottom: none;
    }

    @media ${device.tablet} {
        width: auto;
        border: none;
        padding: 0;
        &:last-child {
            margin-left: 30px;
        }
    }

`;

export const WrapItemSt =  styled.div` 
    display: flex;
    align-items: center;
`;
export const SpanSt = styled(Small)`
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 5px;
    line-height: initial;
`;

export const HeaderSt = styled.div`
        position: relative;
        margin-top: 40px;
       
        h1{
            padding: 0 0 35px 0;
            border-bottom: 1px solid ${c.grey_light};
            margin-bottom: 10px;
            
        }
`;

export const SecondLevelSt = styled.ul` 
    @media ${device.mobile} {
        width: 100vw;
        padding: 0px 20px 0;
        overflow: initial;
        margin-right: -20px;
        box-shadow: 0px 4px 5px rgba(66,165,245,0.12), 0px 1px 10px rgba(66,165,245,0.2);
    }

    position: absolute;
    top: 100%;
    
    margin-bottom: 0;
    background-color: ${c.white};
    z-index: 99;
    padding:10px 0;
    list-style: none;
    a:hover {
        text-decoration: none;
    }
    @media ${device.tablet} {
        padding: 10px 0;
        margin-right: initial;
        box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
        min-width: 200px;
        white-space: nowrap;
        border-radius: 10px;
        &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        right: 10px;
        height: 25px;
        width:105%;
        background-color:transparent;
        }
    }
    @media ${device.laptopMax} {
        min-width: 200px;
    }
`;

export const SecondLevelItemSt = styled.li` 
    padding: 20px 0 20px 40px;
    cursor: pointer; 
    transition: background-color .3s; 
    &:active:not(.profile-name), 
    &:hover:not(.profile-name) {
        background-color: ${c.blue_highlight};
    }
    &.profile-name {
        cursor: initial;  
        padding: 30px 0 30px 40px;
      
    }
    &:not(.profile-name)
    {
        border-top: 1px solid #EEEEEE; 
    }
    @media ${device.tablet} {
        span {
            text-transform: uppercase;
            font-weight: 600;
            display: flex;
        }
      
        padding: 10px 0 10px 30px;
        display: flex;
        align-items: center;
        &:not(.profile-name)
        {
            border-top: none; 
        }
       
    }
`;

export const  HeaderTopSt = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 35px 0;
    border-bottom: 1px solid #EEEEEE;
`;

export const LinkSt = styled(Link)`
   padding-left: 5px;
   text-decoration: none;
   &:hover {
        text-decoration: underline;
        color: ${c.blue}; 
   }
`;

export const TitleBlockSt =  styled.div`
    display: inline-flex;
    align-items: baseline;
`;