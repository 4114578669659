import styled from "styled-components"
import { device } from "../../styles/variables.js"
export const ContainerCatalogStyled = styled.div`
    padding-top: 100px;
    padding-bottom: 150px;
    flex: 1 0 auto;
    @media ${device.tablet} {
        padding-bottom: 200px;
    } 
`;
