import React from 'react';
import { connect } from "react-redux";

import CardList from "../CardList/CardList";
import Cadre from "../Cadre/Cadre";
import Video from "../Video/Video";
import Materials from "../Materials/Materials";

import {requestFromManager} from "../../store/favorites/actions";

import Svg from "../../../src/assets/icons/icons.js"
import {colors} from "../../styles/variables";

import {
  LineSt,
  LineItemSt,
} from '../Table/style.js';
import {
  WrapContentStyled,
  AnonsStyled,
  BlockLeftStyled,
  BlockRightStyled,
  ContentStyled,
  ItemStyled,
  TooltipProfileSt,
  TooltipItemProfileSt,
  WrapperSt,
  ClickSt,
  ClickRecommendSt,
  TextRequestSt,
  WrapSt
} from './style.js';

import i18n from "i18next";

class TableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineIsopen: false,
      sendRequest: false
    };
    this._handleIsOpen = this._handleIsOpen.bind(this);
    this._colotype = this._colotype.bind(this);
    this._sendRequest = this._sendRequest.bind(this);
  }
  
  _handleIsOpen() {
    this.setState((prevState) => (
      {lineIsopen: !prevState.lineIsopen}
    ));
  }
  
  _colotype(status) {
    if(status === "на продлении" || status === "renewal") {
      return colors.magenta
    }
    if(status === "истек" || status === "expired") {
      return colors.grey_text
    }
    if(status === "request" || status === "запрос"  ) {
      return colors.magenta
    }
    if(status === "in_progress" || status === "в работе"  ) {
      return colors.aqua
    }
  }
  
  _sendRequest(id) {
    this.props.requestFromManager([id], this.props.token);
  }

  componentDidUpdate(prevProps) {
    if(this.props.favoritesMessage !== prevProps.favoritesMessage){
      if(this.props.favoritesMessage[0] === true ) {
        this.setState(() => {
            return { sendRequest: "Запрос отправлен" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      } else {
        this.setState(() => {
          return { sendRequest: "Запрос уже существует" };
        });
        setTimeout(()=>{
            this.setState(() => {
                return { sendRequest: false };
            });
        }, 1500)
      }
    }
}

  render() {
    const {type, data, tooltipeOpen, tooltipe, customer, dop, tooltipeRequest} = this.props;
    const manager = customer?.managers[0]?.email;

    return (
      <>
        {/*строка таблицы для вкладки проекты*/}
        
        {type === "profileProject" &&
          <>
            <WrapperSt>
              <LineSt onClick={this._handleIsOpen} className={this.state.lineIsopen || tooltipe === data.project.id ? "open" : ""}>
                <LineItemSt className="profile-project" widthSt="24">
                  <Svg type="keyboard_arrow" className={this.state.lineIsopen ? "arrow-up" : ""} width="24" height="24"/>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="246" colorSt={colors.blue_active}>
                  <span>{data && (i18n.language === 'en' ? data.project.slug : data.project.title)}</span>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="100" colorSt={()=>this._colotype(data.contract.status)}>
                  <span>{data.contract.status} </span>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="105">
                  <span>{data.contract.date_start} </span>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="105">
                  <span>{data.contract.date_end}</span>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="120">
                  <span>{data.contract.number}</span>
                </LineItemSt>
                <LineItemSt className="profile-project" widthSt="100">
                  <span>{data.contract.specification ? data.contract.specification : "-"}</span>
                </LineItemSt>
                <LineItemSt className={tooltipe === data.project.id ? "profile-project points points-blue" : "profile-project points"} widthSt="30">
                  <span>...</span>
                </LineItemSt>
              </LineSt>
              <ClickSt onClick={()=>tooltipeOpen(data.project.id)} className="click"/>
              {tooltipe === data.project.id &&
                <TooltipProfileSt className="bottom">
                  <TooltipItemProfileSt href={"mailto:" + manager + "?subject=Запрос на проект " + `${data && (i18n.language === 'en' ? data.project.slug : data.project.title)}` +"&body=Запрос на проект " + `${data && (i18n.language === 'en' ? data.project.slug : data.project.title)}`}>{i18n.t(`profile.write_to_the_manager`)}</TooltipItemProfileSt>
                  <TooltipItemProfileSt onClick={()=>this._sendRequest(data.project.id)}>{i18n.t(`profile.request_promo_materials`)}</TooltipItemProfileSt>
                </TooltipProfileSt>
              }
              {tooltipeRequest === data.project.id && this.state.sendRequest && 
                <TooltipProfileSt className="bottom">
                  <TooltipItemProfileSt>{this.state.sendRequest}</TooltipItemProfileSt>
                </TooltipProfileSt>
              }
              
            </WrapperSt>

            {this.state.lineIsopen &&
              <WrapContentStyled className="project">
                <BlockLeftStyled>
                  <CardList { ...data.project } key={data.project.title} type="catalog" page="profile" profileProject={true} />
                </BlockLeftStyled>
                <BlockRightStyled>
                  <ContentStyled>
                    {data.contract.countries && 
                      data.contract.countries.map((item, i)=> {
                        return (
                          <ItemStyled key={i}>{item.title}</ItemStyled>
                        )}
                      )
                    }
                  </ContentStyled>
                  <ContentStyled>
                    {data.contract.rights && 
                      data.contract.rights.map((item, i)=> {
                        return (
                          <ItemStyled key={i}>{item.name}</ItemStyled>
                        )}
                      )
                    }
                    
                  </ContentStyled>
                </BlockRightStyled>
              </WrapContentStyled>
            }
          </>
        }
        
        {/*строка таблицы для вкладки рекомендации*/}
        {type === "profileRecommend" &&
          <>
            {/*Таблица*/}
            <WrapperSt>
            <LineSt onClick={this._handleIsOpen} className={this.state.lineIsopen ? "open" : ""}>
              <LineItemSt className="recommend-arrow">
                <Svg type="keyboard_arrow" className={this.state.lineIsopen ? "arrow-up" : ""} width="24" height="24"/>
              </LineItemSt>
              <LineItemSt className="selection">
                <span>{data.title && data.title}</span>
              </LineItemSt>
              <LineItemSt className="recommend-start">
                <span>{data.publish_at && data.publish_at.substring(0,10).replace(/-/g,'.')}</span>
              </LineItemSt>
              <LineItemSt className="recommend-end">
                <span>{data.expires_at && data.expires_at.substring(0,10).replace(/-/g,'.')}</span>
              </LineItemSt>
              <LineItemSt className="recommend-mail">
                <Svg type="mail_outline" width="24" height="24"/>
              </LineItemSt>
            </LineSt>
            <ClickRecommendSt href={"mailto:" + manager + "?subject=Запрос на подборку - " + data.title + "&body=Проекты:" + `\n` + encodeURIComponent(`${data && data.projects.map((item) => {return `\n ${item.title}, id - ${item.id}`})}`)} />
            </WrapperSt>
            {this.state.lineIsopen &&
              <WrapContentStyled> 
                <AnonsStyled>{data.description && data.description}</AnonsStyled>
                {data.projects && 
                  data.projects.map((item, i)=> {
                    return (
                      <CardList { ...item } key={item.title} type="catalog" page="profile" />
                    )}
                  )
                }
              </WrapContentStyled>
            }
          </>
        }
        {/*строка таблицы для вкладки Запросы*/}
        {type === "profileRequest" &&
          <>
            {/*Таблица*/}
            <WrapperSt>
            <LineSt onClick={this._handleIsOpen} className={this.state.lineIsopen || tooltipe === dop.project.id ? "open" : ""}>
              <LineItemSt className="profile-project" widthSt="24">
                <Svg type="keyboard_arrow" className={this.state.lineIsopen ? "arrow-up" : ""} width="24" height="24"/>
              </LineItemSt>
              <LineItemSt className="profile-project" widthSt="590" colorSt={colors.blue_active}>
                <span>{dop && (i18n.language === 'en' ? dop.project.slug : dop.project.title)}</span>
              </LineItemSt>
              <LineItemSt className="profile-project" widthSt="90" colorSt={colors.black_text}>
                <span>{dop.date}</span>
              </LineItemSt>
              <LineItemSt className="profile-project" widthSt="90" colorSt={()=>this._colotype(dop.status)}>
                <span>{dop.status}</span>
              </LineItemSt>
              <LineItemSt className="recommend-mail">
                <Svg type="mail_outline" width="24" height="24"/>
              </LineItemSt>
            </LineSt>
            <ClickRecommendSt href={"mailto:" + manager + "?subject=Запрос на проект " + dop.project.title + "&body=Запрос на проект - " + dop.project.title} />

            </WrapperSt>
            {this.state.lineIsopen &&
              <WrapContentStyled className="project"> 
              {dop.status === "request" || dop.status === "запрос" || dop.status ===  "в работе" || dop.status === "in_progress"
                ? <TextRequestSt>
                    {i18n.t(`profile.your_request_for_additional_materials.${0}.text1`)}
                      <br />
                    {i18n.t(`profile.your_request_for_additional_materials.${0}.text2`)}
                  </TextRequestSt>
                :<WrapSt className="profile-cadre">
                 <CardList { ... dop.project } key={dop.project.title} type="catalog" page="profile" profileProject={true}/>
                 {dop.project.data_extra && dop.project.data_extra.cadres && Object.keys(dop.project.data_extra.cadres).length > 0 && <Cadre extraImg={dop.project.data_extra.cadres} />}
                 {dop.project.data_extra && dop.project.data_extra.other_videos && Object.keys(dop.project.data_extra.other_videos).length > 0 && <Video extraVideos={dop.project.data_extra.other_videos} />}
                 {dop.project.data_extra && dop.project.data_extra.files && Object.keys(dop.project.data_extra.files).length > 0 && <Materials materials={dop.project.data_extra.files} />}
                </WrapSt>
              }
              </WrapContentStyled> 
            }
          </>
        }
        {/*строка таблицы для вкладки доп.материалы*/}
        {type === "profileDop" &&
          <>
            {/*Таблица*/}
            <WrapperSt>
            <LineSt onClick={this._handleIsOpen} className={this.state.lineIsopen || tooltipe === dop.project.id ? "open" : ""}>
              <LineItemSt className="profile-project" widthSt="24">
                <Svg type="keyboard_arrow" className={this.state.lineIsopen ? "arrow-up" : ""} width="24" height="24"/>
              </LineItemSt>
              <LineItemSt className="profile-project" widthSt="680" colorSt={colors.blue_active}>
                <span>{dop && (i18n.language === 'en' ? dop.project.slug : dop.project.title)}</span>
              </LineItemSt>
              <LineItemSt className="profile-project" widthSt="90" colorSt={()=>this._colotype(dop.status)}>
                <span>{dop.status}</span>
              </LineItemSt>
              <LineItemSt className="recommend-mail" widthSt="30">
                <Svg type="mail_outline" width="24" height="24"/>
              </LineItemSt>
            </LineSt>
            <ClickRecommendSt href={"mailto:" + manager + "?subject=Запрос на проект " + dop.project.title + "&body=Запрос на проект - " + dop.project.title} />

            </WrapperSt>
            {this.state.lineIsopen &&
              <WrapContentStyled className="project"> 
              {dop.status === "request" || dop.status === "запрос"
                ? <TextRequestSt>
                    {i18n.t(`profile.your_request_for_additional_materials.${0}.text1`)}
                      <br /><br />
                    {i18n.t(`profile.your_request_for_additional_materials.${0}.text2`)}
                  </TextRequestSt>
                :<WrapSt className="profile-cadre">
                 <CardList { ... dop.project } key={dop.project.title} type="catalog" page="profile" profileProject={true}/>
                 {dop.project.data_extra && dop.project.data_extra.cadres && Object.keys(dop.project.data_extra.cadres).length > 0 && <Cadre extraImg={dop.project.data_extra.cadres} />}
                 {dop.project.data_extra && dop.project.data_extra.other_videos && Object.keys(dop.project.data_extra.other_videos).length > 0 && <Video extraVideos={dop.project.data_extra.other_videos} />}
                 {dop.project.data_extra && dop.project.data_extra.files && Object.keys(dop.project.data_extra.files).length > 0 && <Materials materials={dop.project.data_extra.files} />}
                </WrapSt>
              }
              </WrapContentStyled> 
            }
          </>
        }
      </>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    token: store.auth.dataToken.token,
    customer: store.customer.customer,
    favorites: store.favorites,
    favoritesMessage: store.favorites.message,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    requestFromManager: (id, token) => dispatch(requestFromManager(id, token))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TableRow);


