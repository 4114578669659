import { createGlobalStyle } from 'styled-components';
import { colors as c} from "../styles/variables.js"

export const GlobalStyle = createGlobalStyle`
     body {
        font-family: 'Raleway';
        height: 100%;
     }

     body #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
     }
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    /* Remove outline on the forms and links */
    :active, :hover, :focus {
        outline: 0;
        outline-offset: 0;
    }
    input {
        outline: none;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px #fff !important;
    }
    p {
        white-space: pre-line;
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
        color: ${c.black};
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: ${c.black};
    }
    
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: ${c.black};
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: ${c.black};
    }
    textarea,
    input:matches(
    [type="email"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="tel"],
    [type="text"],
    [type="url"]
    ) {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
`