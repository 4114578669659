import React from 'react';
import GetCards from "../../components/GetCards/GetCards";
import { H3 } from "../../styles/typography/typography.js";
import Button from "../../components/Button/Button";
import {
    ContainerStyled,
    WrapperStyled,
    ImageWrapperStyled,
} from "./style";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { Container } from "../../components/Container/Container";
import Filter from "../../components/Filter/Filter";

import { ContainerCatalogStyled } from "../../components/Container/style";
class Home extends React.Component {
    state = {
        hiddenContentPage: false, // скрываем основной контент страницы и показываем результаты фильтрации
    }

    _hiddenContentPage(x) {
        this.setState(() => {
            return { hiddenContentPage: x }
        })
    }

    render() {
        return (
            <>
                <div className="toast toast-primary">
                    {/* Current window width: {windowWidth} */}
                </div>
                <GetCards sourceCards="slides" contentType={(i18n.language === 'en') ? 'project' : 'проект'} cardDisplayType="topbanner" isMobile={this.props.isMobile} />
                <ContainerCatalogStyled>
                    <Filter _hiddenContentPage={(x) => this._hiddenContentPage(x)} />
                    <div style={!this.state.hiddenContentPage ? { height: "auto", overflow: "" } : { height: "0", overflow: "hidden" }}>
                        <Container>
                            <GetCards sourceCards="collections" link="/collections" type="collections" title={i18n.t(`home.collections`)} cardDisplayType="carousel" />
                            <GetCards sourceCards="events" link="/events" type="events" title={i18n.t(`home.events`)} cardDisplayType="carousel" />
                        </Container>
                        <ContainerStyled>
                            <ImageWrapperStyled>
                                {(i18n.language === 'en') ? <img src="images/banner_gpm_en.png" />
                                                          : <img src="images/banner_gpm.jpg" />}
                            </ImageWrapperStyled>
                            <WrapperStyled>
                                <H3>
                                    {i18n.t(`home.content_collection.${0}.text1`)} <br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text2`)} <br />
                                    {i18n.t(`home.content_collection.${0}.text3`)} <br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text4`)} <br /><br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text5`)} <br /><br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text6`)} <br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text7`)} <br className="mobile" />
                                    {i18n.t(`home.content_collection.${0}.text8`)}
                                </H3>
                                {(i18n.language === 'en') ? 
                                    <a href="documents/catalogue_eng.pdf" download >
                                        <Button as="a" href="/" label={i18n.t(`home.download_catalog`)} size="big" primary />
                                    </a>
                                    : 
                                    <a href="documents/catalogue_rus.pdf" download >
                                        <Button as="a" href="/" label={i18n.t(`home.download_catalog`)} size="big" primary />
                                    </a>
                                }
                            </WrapperStyled>
                        </ContainerStyled>
                    </div>
                </ContainerCatalogStyled>
            </>
        );
    }
}

export default withTranslation()(Home);

