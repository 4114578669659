import React from 'react';
import {
    CheckboxStyled,
    MediumStyled,
    CheckboxSquareStyled,
} from './style.js';

export default class Checkbox extends React.Component {

    state ={
        checked: this.props.filterSave ? true : false,
    };

    _isCheckboxChecked(){
        this.props._showSearchingResults(!this.state.checked);
        this.setState(({checked})=>{
            return { checked: !this.state.checked }
        })
    }

    componentDidUpdate(prevProps) {
        // снять все выборы
        if (this.props.removeAllChoice !== prevProps.removeAllChoice) {
            this.setState(()=>{
                return{
                    checked: false,
                }
            })
        }
        // при клике на тег снимается выбор селекта
        if ((this.props.removeChoice !== prevProps.removeChoice) && this.props.removeChoice ==="0") {
            this.setState(()=>{
                return{
                    checked: false,
                }
            })
        }
    }

    render(){
        return(
            <CheckboxStyled onClick={()=>this._isCheckboxChecked()}>
                <MediumStyled>
                    {this.props.name}
                </MediumStyled>
                <CheckboxSquareStyled checked={this.state.checked}>
                    <svg width="18" height="18" viewBox="0 0 18 18">
                        <path d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"/>
                        {this.state.checked && <path d="M6.00003 12.1698L1.83003 8.99984L0.410034 10.40984L6.00003 15.9998L18 3.99984L16.59 2.589844L6.00003 13.1698Z"/>}
                    </svg>
                </CheckboxSquareStyled>
            </CheckboxStyled>
        )
    }
}