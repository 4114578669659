import React from 'react';
import { H3 } from "../../styles/typography/typography.js";
import {
  SpanStyled,
} from './style.js';

import {
  TextWrapStyled,
} from '../Tabs-description/style.js';
import TabsCardEvents from "../TabsCardEvents/TabsCardEvents";
import GetCards from "../GetCards/GetCards";


class TabsEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //для компонента Grid 
      chooseCard: false,
    };
  }

  
  render() {
    const {dashboard, manager, location} = this.props
    return (
    <>
      <SpanStyled>В рамках данного мероприятия состоится презентация активов ГПМ РТВ и пройдут показы следующих проектов каналов группы:</SpanStyled>
      <GetCards sourceCards={`selections/${this.props.getSelections}`} type="catalog" cardDisplayType="grid" tile={window.innerWidth > 767 ? dashboard : false} chooseCard={this.state.chooseCard} widthCard={"28%"} getSelections={this.props.getSelections} />
      {manager && 
        <>
          <TextWrapStyled>
            <H3>Менеджеры на данном мероприятии</H3>
            <TabsCardEvents manager={manager} location={location} />
          </TextWrapStyled>
        </>
      }
    </>
    );
  }
}

export default TabsEvent;
