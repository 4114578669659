import React from 'react';

import {
    ContainerStyled,
    ItemStyled,
    LinkStyled,
    SVGStyled
} from "./style";
import i18n from "i18next";


class Logos extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    scrollToTop() {
        if (this.props.container === "footer") {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } 
    }

    render () {

        const { productions, container, color, channelContent} = this.props;
        const LINK = `/channel`;


        let channelList = [];
        let img = "";

        if(channelContent !== undefined && channelContent !== false) {
            channelContent.data.map ( (item, index) => {
                (i18n.language === 'en') ? img = item.logo_icon_en : img = item.logo_icon_ru
                channelList = [...channelList, {
                    img: img,
                    link: `${LINK}/`+ item.slug,
                    width: `80`,
                    height: `36`
                }]
            })

        }

     /*   const channelList =
        [
            {
                img: `images/logos-sprite.svg#tnt`,
                link: `${LINK}/tnt`,
                width: `52`,
                height: `18`
            },
            {
                img: i18n.t(`logos.tv3.${0}.img`),
                link: `${LINK}/tv3`,
                width: i18n.t(`logos.tv3.${0}.width`),
                height: i18n.t(`logos.tv3.${0}.height`)
            },
            {
                img: `images/logos-sprite.svg#tnt4`,
                link: `${LINK}/tnt4`,
                width: `24`,
                height: `22`
            },
            {
                img: i18n.t(`logos.friday.${0}.img`),
                link: `${LINK}/friday`,
                width: i18n.t(`logos.friday.${0}.width`),
                height: i18n.t(`logos.friday.${0}.height`)
            },
            {
                img: `images/logos-sprite.svg#2x2`,
                link: `${LINK}/2x2`,
                width: `45`,
                height: `20`
            },
            {
                img: i18n.t(`logos.super.${0}.img`),
                link: `${LINK}/super`,
                width: i18n.t(`logos.super.${0}.width`),
                height: i18n.t(`logos.super.${0}.height`)
            },
        ];*/


        const productionsList =
        [
            {
                img: `images/ComedyProduction.svg`,
                link: `${LINK}/comedy-production`,
                width: `66`,
                height: `15`,

            },
            {
                img: `images/123Production_Studio_white.svg`,
                link: `${LINK}/123-production`,
                width: `48`,
                height: `21`
            },
            {
                img: `images/GoodStoryWhite.svg`,
                link: `${LINK}/good-story`,
                width: `55`,
                height: `22`
            },
            {
                img: `images/2x2AnimStudRus.svg`,
                link: `${LINK}/2x2prod`,
                width: i18n.t(`logos.tv2x2_anim.${0}.width`),
                height: i18n.t(`logos.tv2x2_anim.${0}.height`)
            },
        ];

        const logoList = productions ? [...channelList, ...productionsList]  : channelList;

        return (
                <ContainerStyled container={container}>
                    {logoList.map((logo, i) => (
                        <ItemStyled onClick={() => this.scrollToTop()} key={i} container={container} className = {productions ? "productions" : ""} >
                            <LinkStyled to={logo.link} >
{/*                                <SVGStyled width={logo.width} height={logo.height} color={color} container={container} >
                                    <use xlinkHref={logo.img}></use>
                                </SVGStyled>*/}
                                <div className={productions ? 'opacity' : null} style={{ backgroundImage: `url(${logo.img})`, backgroundPosition: "center"}}></div>
                            </LinkStyled>
                        </ItemStyled>
                    ))}
                </ContainerStyled>
        );
    }
}

export default Logos;