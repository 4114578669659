import styled from "styled-components"
import { colors as c, fonts, device} from "../../styles/variables.js"
import {Small} from "../../styles/typography/typography"

export const WrapperSt = styled.div` 
    position: relative;
  
    width: ${props => props.width ? props.width  : "100%"}; 
`;
export const InputSt = styled.input`
    display: block;
    border: 1px solid ${c.grey_light};
    z-index: 96;
    border-radius: 40px;
    padding: 10px 30px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: ${c.black_text};
    width: 100%;
    font-family: ${fonts.font_family};
    cursor: initial;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        color: ${c.grey_text};
    }

    &:focus {
        border: 1px solid ${c.blue_active}; 
    }
    &:disabled { 
        color: ${c.black_text};
        border: 1px solid ${c.grey_light};
        background-color: ${c.white};
   }
    &.invalid,
    &:invalid {
        border: 1px solid ${c.error} !important;;
    }
    &.valid {
        border: 1px solid  ${c.black_text};
    }
    @media ${device.tablet} {
        font-size: 14px;
        line-height: 16px;
    }

    @media (min-width: 1460px) {
        width: ${props => props.width}; 
        padding:  ${props => props.primary ? "19px 30px" : "10px 30px" }; 
    }
`;
export const ErrorWrapperSt = styled.div`
    position: absolute;
    top: 40px;
    left: 30px;
    color: ${c.error};
    display:flex;

    @media (min-width: 1350px) {
        top: 40px;
    }
    @media (min-width: 1460px) {
        top: 60px;
    }
`;

export const ErrorSt = styled(Small)`
    font-size: 12px;
    line-height: 12px;
    color: ${c.error};
`;