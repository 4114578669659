import React from 'react';
import { connect } from "react-redux";

import GetCards from "../../components/GetCards/GetCards";
import {Container} from "../../components/Container/Container";
import Filter from "../../components/Filter/Filter";

import { ContainerCatalogSt } from "./style";
import { switchViewInCatalogAction } from '../../store/switchView/actions'

class Catalog extends React.Component {

    state= {
        hiddenContentPage: false, // скрываем основной контент страницы и показываем результаты фильтрации
        render: false,
        grid: false
    }

    _hiddenContentPage(x){ // скрываем основной контент страницы и показываем результаты фильтрации
        this.setState(()=>{
            return { hiddenContentPage: x }
        })
    }

    componentDidMount () {
        this.props._switchViewInCatalog(false)
    }

    _render(){ // обновляем состояние фильра при переходе через "смотрет все"
        this.setState(()=>{
            return { render: true }
        })
        setTimeout(()=>{
            this.setState(()=>{
                return { render: false }
            })
        }, 50)
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.switchView !== prevProps.switchView ) {
            this.setState(()=>{
                return { grid: true }
            })
        }
    }

    render() {

        const genres = this.props.genres;

        return(
            <ContainerCatalogSt>

                <Filter _hiddenContentPage={(x)=>this._hiddenContentPage(x)} render={this.state.render}/>

                <div style={ !this.state.hiddenContentPage ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                   <Container>
                        <div style={!this.props.switchView ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                            {genres && genres.map((genres, i) => (
                              <GetCards key={i}
                                        sourceCards= {`projects?filter%5Bgenre_id%5D=${genres.id}`}
                                        title= {genres.title}
                                        link="/catalog"
                                        render={()=>this._render()}
                                        type="catalog"
                                        cardDisplayType="carousel"
                              />
                            ))}
                        </div>
                    </Container>

                    {this.state.grid && <Container padding="true">
                        <div style={this.props.switchView ? {height:"auto", overflow: ""} : {height:"0", overflow: "hidden"}}>
                            <GetCards
                              sourceCards={`projects?sort[column][]=year_start&sort[column][]=title.ru&sort[order][]=desc&sort[order][]=asc`}
                              type="catalog"
                              cardDisplayType="grid"
                              tile={!this.state.dashboard}
                            />
                        </div>
                    </Container>}
                </div>


            </ContainerCatalogSt>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        projects: store.allProjects.allProjects,
        isLoading: store.allProjects.isLoading,
        genres: store.allProjects.genres,
        switchView: store.switchViewInCatalog.switchViewInCatalog
    };
}

const mapDispatchToProps = dispatch => {
    return {
        _switchViewInCatalog: (boolen) => dispatch(switchViewInCatalogAction(boolen))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
  