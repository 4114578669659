import React from 'react';
import {
    SvgStyled,
} from "./style";
/**
 *
 * @param width     - ширина иконки
 * @param height    - высота иконки
 * @param classAdd  - класс (цвет, трансформация)
 * @param nameSvg   - название иконки
 *
 * Цвета - fill_black | fill_grey | fill_blue
 *
 * Названия иконок
 *
 'close', 'add', 'check', 'check_box_outline_blank', 'check_box', 'indeterminate_check_box', 'radio_button_checked',
 'radio_button_unchecked', 'link', 'link-off', 'favorite_border', 'favorite', 'insert_photo', 'collections',
 'video_library', 'collections_bookmark', 'loyalty', 'lock', 'shopping_cart', 'file_catalog', 'delete_outline',
 'picture_as_pdf', 'share', 'folder', 'site', 'send', 'help_outline', 'open_in_new', 'perm_identity', 'info',
 'new_releases', 'account_circle', 'login', 'bookmark_border', 'bookmark', 'star_border', 'star', 'verified',
 'work_outline', 'edit', 'more_horiz', 'pan_tool', 'settings', 'search', 'dashboard', 'list', 'sort_by_alpha', 'tune',
 'menu', 'fullscreen', 'fullscreen_exit', 'visibility_off', 'visibility', 'archive', 'unarchive', 'thumb_up_alt',
 'wifi', 'cloud', 'mail_outline', 'event', 'date_range', 'movie', 'alternate_email', 'flag', 'add_box', 'home',
 'add_circle_outline', 'check_circle', 'download', 'keyboard_arrow', 'arrow_downward', 'play_circle_filled',
 Пример <Svg nameSvg="bookmark" classAdd="fill_grey" width="24" height="24" />

  Для этих картинок нужен доп. класс

 'download_top' => 'download',
 'keyboard_arrow_top' => 'keyboard_arrow',
 'keyboard_arrow_left' => 'keyboard_arrow',
 'keyboard_arrow_right' => 'keyboard_arrow',
 'arrow_townward' => 'arrow_downward',
 Пример <Svg nameSvg="download" classAdd="download_top" width="24" height="24" />
 *
 *
 *
 */
const Svg = ({ width = '24', height = '24', className = '', color = '', type = '', ...props }) => {
    return (
        <SvgStyled width={width} height={height} fill={color} className={className}>
            <use xlinkHref={"images/sprite.svg#"+ type}></use>
        </SvgStyled>
    )
}

export default Svg;
