import styled from "styled-components"

import {colors as c, device as d, fonts as f} from "../../styles/variables.js"
import {H3} from "../../styles/typography/typography";

export const ContainerSt = styled.div`
   position: relative;
    z-index: 1;
   margin: 0 auto;
   padding: 30px 0 0;
   @media ${d.tablet} {
        padding: 30px 0 30px;
   }
`;

export const HeaderSt = styled(H3)`
   margin: 0 auto 30px;
   max-width: 1140px;
   color: ${c.black_text};
   font-weight: ${f.bold};
  
  ${props=>props.page && "margin-right: 30px;"}
   @media ${d.laptop} {
       max-width: 100%;
       margin: 0 auto 30px;
       
   }
`;

export const DescriptionContainerSt = styled.div`
   margin: 0 auto 30px;
   @media ${d.laptop} {
        margin: 0 auto 30px;
        max-width: 100%;
   }
   @media ${d.desktop} {
        margin: 0 auto 30px;
   }
`;

export const ContentSt = styled.div`
   // display: flex;
   // flex-wrap: wrap;
   // position: relative;
   overflow: hidden;
   width:102%;
   @media ${d.tablet} {
       width:100%;
   }
   @media ${d.laptop} {
       width:100%;
       margin: 0 auto;

   }
`;

export const ContentDSt = styled.div`
   display: flex;
   flex-wrap: wrap;
   position: relative;
   @media ${d.laptop}{
       width:103%;
   }
`;

export const ContentDCLSt = styled.div`
   display: flex;
   flex-wrap: wrap;
   position: relative;
   @media ${d.tablet}{
        width:100%;
        &>div{
            width: 45%;
            margin-right: 5%;
        }
   }
`;