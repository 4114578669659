import {
    DOP_PROFILE_REQUEST,
    DOP_PROFILE_FAIL,
    DOP_PROFILE_SUCCESS
} from "../../types"

const initialState = {
    isLoading: false,
    message: '',
    projects: {}
}

export const  dopReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case DOP_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: false
            };
        case DOP_PROFILE_SUCCESS:
            return {
                ...state,
                projects: payload,
                isLoading: true
            };
        case DOP_PROFILE_FAIL:
            return {
                ...state,
                message: payload,
                isLoading: false
            };
        default:
            return state;
    }
}