import styled from 'styled-components'
import {colors as c} from '../../styles/variables.js'
import {fonts as f} from '../../styles/variables.js'
import {device} from '../../styles/variables.js'

//fix desktop text, img
const heightText = {
    'height': '41.1vw'
}

const heightIMg = {
    'height': '28.1vw'
}


export const DivStHeaderPageTop = styled.div`
    width: 100%;
    background: #31394E;    
    height: 28.1vw; 
    display: flex;
    
    @media ${device.mobile}  {
        margin-top: 60px;
        height: 100%;
    }
    
    @media ${device.tablet}  {
       height: 56.2vw;
       height: 100%;
    }
    @media (min-width: 1024px) { 
       height: 28.1vw; 
    }
    @media (min-width: 1024px) and (max-width: 1300px) {         
       height: 28.1vw; 
       
       &.leftIMages {
          ${heightIMg}; 
       }
    }
    
    @media ${device.desktop}  {
       height: 28.1vw; 
    }
    
`;
export const DivStHeaderCenter = styled.div`
    width: 100%;
    margin: 0 auto;     
    height: 28.1vw; 
    display: flex;
    
    @media ${device.mobile}  { 
        height:100%;
        flex-wrap: wrap-reverse;
    }
    
    @media ${device.tablet}  {
       height:100%;
       flex-wrap: wrap-reverse;
    }
    @media (min-width: 1024px) { 
       height: 28.1vw;
       flex-wrap: nowrap;
    }
    @media (min-width: 1024px) and (max-width: 1300px) {          
       height: 28.1vw; 
       
       &.leftIMages {
          ${heightIMg}; 
       }
    }
    @media ${device.desktop}  {
       height: 28.1vw; 
    }
    
`;

export const DivStLeftBlock = styled.div`
    width: 50%;      
    background: #31394E; 
    color: #fff; 
    font-size: 16px;
    line-height: 18,78px;
    font-style: normal; 
    font-weight: ${f.medium}; 
    font-family: ${f.font_family};
    
    & img {       
        display:block;
        width: 100%;
        height: 28.1vw; 
    }
    
    & p {
        padding: 90px;
    }
  
    @media ${device.mobile}  {
        display: block;
        width: 100%;

        &.hide-mobile {
          display: none;
        }
    }    
    
    @media ${device.tablet}  {
        display: block;
        width: 100%;
      
        &.hide-mobile {
          display: none;
        }
    }
    @media (min-width: 1024px) { 
        display: block;
        width: 50%;


        &.hide-mobile {
          display: block;
        }
    }
  
    @media (min-width: 1024px) and (max-width: 1300px) {   
       
       height: 28.1vw; 
       
       &.leftIMages {
          ${heightIMg}; 
       }
    }
    @media ${device.desktop}  {
        display: block;
    }
`;

export const DivStRightBlock = styled.div`
    width: 50%;       
    position: relative;      
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    & img {       
        display:block;
        width: 100%;
        height: 28.1vw; 
    }
    
    & iframe {
        width: 100%;
        height: 100%; 
        z-index: 11;
        position: absolute;
        display: none;
    }
    
    & .visible {
        display: block;
    }
    
    @media ${device.mobile}  {
       width: 100%;
       height: 56.2vw;
       background-image: none;
      
      & iframe {
        display: block;
      }
    }
    
    @media ${device.tablet}  {
       width: 100%;
       height: 56.2vw;  
    }
    @media (min-width: 1024px) { 
       width: 50%;
       height: 28.1vw; 
    }  
    @media (min-width: 1024px) and (max-width: 1300px) {   
       height: 28.1vw; 
       
       &.leftIMages {
          ${heightIMg}; 
       }
    }
    
    @media ${device.desktop}  {
       width: 50%;
       height: 28.1vw; 
    }    
    
`;


export const DivStLeftImg = styled.div`  
    position: relative; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
        
    & img {       
        display:block;
        width: 100%;
        height: 28.1vw; 
    }
    
    @media ${device.mobile}  {
       width: 100%;
       height: 175px; 
    }
    
    @media ${device.tablet}  {
       width: 100%;
       height: 250px; 
    }
    @media (min-width: 1024px) { 
        width: 100%;
        height: 28.1vw; 
    } 
    
    @media ${device.desktop}  {
       width: 100%;
       height: 28.1vw; 
    }    
    
`;


export const DivStRightCenter = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 28.1vw;
        position: absolute;
        
        & svg {            
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        @media ${device.mobile}  { 
             height: 56.2vw; 
             transform: scale(0.35);
        }
        
        @media ${device.tablet}  {
           width: 100%;
           height: 56.2vw; 
        }  
        @media (min-width: 1024px) { 
           height: 28.1vw; 
        }        
        @media (min-width: 1024px) and (max-width: 1300px) {   
           height: 28.1vw; 
           
           &.leftIMages {
              ${heightIMg}; 
           }
        }        
        @media ${device.desktop}  { 
           height: 28.1vw; 
        }           
        
`;


export const DivStRightCircle = styled.div` 
        background: rgba(255,255,255, 0.5);
        border-radius: 100px; 
        cursor: pointer;
        
        & :hover {
            background: rgba(255,255,255, 1);     
            border-radius: 100px;    
        }
`;

export const DivStRightText = styled.div` 
    width: 720px;
    float: right;
    line-height: 20px;
    padding: 60px 90px;

  @media ${device.mobile}  {
    width: 100%;
    padding: 20px;
  }
  
  @media ${device.tablet}  {
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    width: 720px;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
      width: 720px;
      padding: 10px 25px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

    
    }
    @media (min-width: 1024px) and (max-width: 1300px) {
      font-size: 14px !important;
      width: 720px;
      max-width: 100%;
    }
    @media (min-width: 1700px) {
      width: 720px;
    }
    
    & p {       
        color: ${c.blue_active};
        font-weight: ${f.bold};
        margin-bottom: 35px;    
        text-transform: uppercase;
        padding: 0;

        @media (min-width: 1024px) and (max-width: 1200px) {         
         margin-bottom: 15px;
         }
    }
`;
