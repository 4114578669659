import styled from "styled-components";
import {colors as c, device as d} from "../../styles/variables.js"
import {Medium as Medium} from  "../../styles/typography/typography";

export const SelectSt = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ChosenSt = styled.div`
    /* padding: 7px 25px; */

    padding: 9px 25px;
    border-radius: 50px;
    border: 1px solid ${props=>props.open ? c.blue_active :
        props=>props.selectChoose ? c.black_text :c.grey_light};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    &.invalid,
    &:invalid {
        border: 1px solid ${c.error} !important;;
    }
    @media (min-width: 1460px) {
        width: ${props => props.width}; 
        padding:  ${props => props.primary ? "19px 50px 19px  30px" : "10px 30px 10px 20px;" };
    }
`;

export const MediumSt = styled(Medium)`
    display: block;
    flex-grow: 30;
    overflow: hidden;

    @media ${d.tablet} {
        font-size: 14px;
        line-height: 16px;
    }
`;


export const ArrowSt = styled.div`
    position: absolute;
    right: 20px;
    background: ${c.white};
    svg {
            display: flex;
            align-items:center;
        }
`;

export const ListSt = styled.div`
        position: absolute;
        z-index: 99;
        min-width:  ${props => props.selectWidth ? "initial" : "100%" };
        width: ${props => props.selectWidth ? "100%" : "initial" };
        padding-top: 10px;
        @media ${d.tablet} {
                top: 0;
                margin-top: 35px;
        }
        &>div, &>ul{
            max-height: 500px;
            overflow-y: auto;
            background: ${c.white};
            box-shadow: 0 1px 3px ${c.blue_active};
            border-radius: 10px;
            overflow-y: auto;
            transition-duration: .2s;
            transition-timing-function: ease-in-out;
            list-style-type: none;
               
                &>li{
                    white-space: nowrap;
                    cursor: pointer;
                    padding: 15px 27px;
                    &:hover{
                        background: ${c.blue_highlight};
                    }
                    &:first-child{
                        padding-top: 25px;
                    }
                    &:last-child{
                        padding-bottom: 25px;
                    }
                }
        }
`;

export const UlSt = styled.ul`
        max-height: 500px;
        overflow-y: auto;
        background: ${c.white};
        box-shadow: 0 1px 3px ${c.blue_active};
        border-radius: 10px;
`;