import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import { device } from "../../styles/variables.js"


export const ContainerStyled  = styled.div`
    display:flex;
    justify-content: center;
    position: relative;
    max-width: 780px; 
    /* margin: 85px auto 0; */
    margin: 85px auto -60px;
    background: ${c.blue};
    @media ${device.tablet} { 
        margin: 160px auto 120px;
        justify-content: flex-end;
        background: none;
    }
    /* @media ${device.laptopMax} {
        margin: 160px auto 0; 
    } */
`;

export const WrapperStyled = styled.div`
    background: ${c.blue};
    max-width: 100%;
    padding: 270px 40px 50px;
   
    @media ${device.tablet} {
        max-width: 620px;
        padding: 35px 30px;
     
    }
    button {
        margin-top: 20px;
    }
    h3 {
        text-decoration: none;
        max-width: 200px;
        margin: 0 auto;
        color: ${c.white};
        @media ${device.tablet} {
            padding: 0;
            max-width: initial;
            width: 368px;
        }
        @media ${device.desktop} {
            width: 430px;
        }
    }
    @media ${device.tablet} { 
        padding : 35px 50px 40px 150px;
    }

    @media ${device.desktop} { 
        padding: 35px 50px 40px 140px;
    }
    br {
        display:none ;
        &.mobile {
            display:block ;
            @media ${device.tablet} {
                display:none ;
            }
        }
        @media ${device.tablet} {
            display:block ;
        }
    }
   
`;

export const ImageWrapperStyled = styled.div`
    position: absolute;
    top: -45px;
    max-width: 200px;
    max-height: 282px;
    @media ${device.tablet} { 
        top: -60px;
        left: 0px;
        display: block; 
        max-width: initial;
        max-height: initial;
    }
    img {
        width: 100%;
        max-width: 220px;
    }
`;