import React from 'react';
import { connect } from "react-redux";
import { getFavotitesInfo } from '../store/favorites/actions';
import { getChannelContent } from "../store/channel/actions";
import { getGenres} from "../store/projects/actions";
import {getFilterNavigationMenu} from "../store/filterSave/actions";
// import { GlobalFonts } from '../styles/fonts.js';
import { GlobalStyle } from '../styles/styles.js';
import Home from '../pages/home/Home'
import Catalog from '../pages/catalog/Catalog.jsx' 
import Events from '../pages/events/Events.jsx'
import About from '../pages/about/About.jsx'
import Favorites from '../pages/favorites/Favorites.jsx'
import Channel from '../pages/channel/Channel.jsx'
import Collections from "../pages/collections/Collections.jsx";
import Collection from "../pages/collection/Collection";
import Profile from "../pages/profile/Profile.jsx";
import Header from '../components/Header/Header.jsx'
import Footer from '../components/Footer/Footer.jsx'
import Project from '../pages/project/Project.jsx'
import Event from '../pages/event/Event.jsx'
import Auth from '../pages/auth/Auth'
import SearchResult from "../pages/searchresults/SearchResult";
import PasswordReset from "../pages/passwordreset/PasswordReset";
import PrivateRoute from "../components/private-route/private-route";
import FeedbackPage from '../pages/feedbackPage/FeedbackPage';
import NotFound from '../pages/404/404';
import i18n from "i18next";

import {
    BrowserRouter as Router,
    HashRouter,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: undefined,
            windowWidth: window.innerWidth,
            searchWorld: "",
        };
    }


    setWindowWidth = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    };

    _wholeSiteSearch(value) {
        this.setState({
            searchWorld: value
        });
    }

    componentDidMount() {
        if(!this.props.channelContent){
            this.props.getChannelContent('channels');
        }
        if(!this.props.productionsContent){
            this.props.getChannelContent('productions');
        }
        window.addEventListener("resize", this.setWindowWidth);
        this.props.token && this.props.getFavotitesInfo(this.props.token)
        this.props.getGenres()
        
        document.title = i18n.t(`title.all`) //title
        this.props._getFilterNavigationMenu();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.setWindowWidth);
    }



    render() {
        const { windowWidth } = this.state
        const isMobile = windowWidth < 768
        return (
            <>
                {/* Add global styles to pages*/}
                < GlobalStyle />
                <Router>
                    <Header channelContent={this.props.channelContent} isMobile={isMobile} _wholeSiteSearch={(value) => this._wholeSiteSearch(value)} />
                    <Switch>
                        <Route path="/" exact>
                            <Home isMobile={windowWidth} />
                        </Route>
                        <Route path="/catalog" exact>
                            <Catalog />
                        </Route>
                        <Route path="/catalog/:id?" exact>
                            <Project />
                        </Route>
                        <Route path="/events" exact>
                            <NotFound />
                            {/* <Events isMobile={windowWidth} /> */}
                        </Route>
                        <Route path="/events/:id?" exact>
                            {/* <Event /> */} 
                            <NotFound />
                        </Route>
                        <Route path="/collections" exact>
                            <Collections />
                        </Route>
                        <Route path="/collections/:id?" exact>
                            <Collection />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <PrivateRoute
                            exact
                            path="/favorites"
                            render={() => {
                                return (
                                    <Favorites />
                                );
                            }}
                        />
                        <PrivateRoute
                            exact
                            path="/profile"
                            render={() => {
                                return (
                                    <Profile />
                                );
                            }}
                        />
                        <Route path="/channel/:id?" exact>
                            <Channel />
                        </Route>
                        <Route path="/search" exact>
                            <SearchResult searchWorld={this.state.searchWorld} />
                        </Route>
                        <Route path="/feedback" exact>
                            <FeedbackPage />
                        </Route>
                        {
                            (this.props.isLoggedIn)
                                ? <>
                                    <Route path="/password/reset/:id?" exact>
                                        <Redirect to="/profile" />
                                    </Route>
                                    <Route path="/auth" exact>
                                        <Redirect to="/profile" />
                                    </Route>
                                    <Route path="*" component={NotFound} />
                                </>
                                : null
                        }
                        <Route path="/password/reset/:id?" exact>
                            <PasswordReset />
                        </Route>
                        <Route path="/auth" exact>
                            <Auth />
                        </Route>
                        <Route path="*" component={NotFound} />
                    </Switch>
                    <Footer channelContent={this.props.channelContent} />
                </Router>
            </>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        isLoggedIn: store.auth.isLoggedIn,
        token: store.auth.dataToken.token,
        channelContent: store.channelContent.channelContent,
        productionsContent: store.channelContent.productionsContent
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getFavotitesInfo: (token) => dispatch(getFavotitesInfo(token)),
        getGenres: () => dispatch(getGenres()),
        getChannelContent: (typeContent) => dispatch(getChannelContent(typeContent)),
        _getFilterNavigationMenu: () => dispatch(getFilterNavigationMenu()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
