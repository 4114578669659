import { checkStatus, getGenresList} from '../../services/utils';
import i18n from "i18next";

import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAIL,
    GET_GENRES_SUCCESS,
    HOST,
  } from "../types";

export const getProjects = () => {
    return dispatch => {
      try {
        dispatch({
          type: GET_PROJECTS_REQUEST,
        })
        return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/projects`)
          .then(checkStatus)
          .then((data) => {
            dispatch({
              type: GET_PROJECTS_SUCCESS,
              payload: data.data
            })
            return data
          })
          .catch(error => {
              const message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();

              dispatch({
                  type: GET_PROJECTS_FAIL,
                  payload: message
              })
          })
      } catch (error){
          const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            dispatch({
                type: GET_PROJECTS_FAIL,
                payload: message
            })
        return Promise.reject();
      }
    }
}

// список жанров 
export const getGenres = () => {
    return dispatch => {
      try {
        dispatch({
          type: GET_PROJECTS_REQUEST,
        })
        return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/projects`)
          .then(checkStatus)
          .then((data) => {
            const genresList = getGenresList(data.data);
            dispatch({
              type: GET_GENRES_SUCCESS,
              payload: genresList
            })
            return genresList
          })
          .catch(error => {
              const message =
                  (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                  error.message ||
                  error.toString();

              dispatch({
                  type: GET_PROJECTS_FAIL,
                  payload: message
              })
          })
      } catch (error){
          const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            dispatch({
                type: GET_PROJECTS_FAIL,
                payload: message
            })
        return Promise.reject();
      }
    }
}