import React from 'react';
import { connect } from "react-redux";

import { addToFavorites, deleteFromFavorites } from "../../store/favorites/actions";
import {dateFormation} from "../../services/utils";
import {channelListForCards as channelList} from "../../services/constants";

import Popup from '../../components/Popup/Popup.jsx';

import {PopupFrameStyled} from '../Popup/style.js';
import {
    CardSt,
    ContainerSt,
    BookmarkSt,
    ImgContainerSt,
    ImgSt,
    ContentSt,
    TitleSt,
    TrailerSt,
    ArrowSt,
    HiddenContentSt,
    InfoSt,
    DiscriptionSt,
    FooterSt,
    FooterLeftSt,
    TrailerContainerSt,
    DiscriptionChannelSVGSt,
    DiscriptionRARSSt,
    DateSt,
    ToFavoritesContainerSt,
    ToFavoritesSt,
    LineSt,
    LinkSt,
} from "./style";
import {
    CenterSvgSt,
    ChooseCardTopSt,
    ContainerFormSt,
    FormСhooseSt,
    WrapperBookmarkSt,
    СhoosePictureContainerSt
} from "../Card/style";

import i18n from "i18next";

class CardList extends React.Component {
    state = {
        openCard: this.props.profileProject ? true : false,
        bookmarkCheck: (this.props.page === "favorites" || this.props.isInFavourites) ? true : false,
        chooseCard: false,
        popupIsOpen: false,
        trailer: "",
    };

    _bookmark() {
        if (this.state.bookmarkCheck) {
            this.props.deleteFromFavoritesAction([this.props.id], this.props.type, this.props.token)
        } else {
            this.props.addToFavoritesAction([this.props.id], this.props.type, this.props.token)
        }
        this.setState(({ bookmarkCheck }) => {
            return { bookmarkCheck: !this.state.bookmarkCheck }
        })
    }
    handleClick = (evt, id) => {
        evt.preventDefault()
        this.setState((prevState) => ({ popupIsOpen: !prevState.popupIsOpen }));
        this.setState(() => ({ trailer: id }));
    }

    // выбрать карту/снять выбор карты
    chooseCard() {
        this.state.chooseCard ? this.props._getallchooseCard(this.props.id, "remove", this.props.page === "favorites" && this.props.type) : this.props._getallchooseCard(this.props.id, "add", this.props.page === "favorites" && this.props.type);
        this.setState(() => {
            return { chooseCard: !this.state.chooseCard }
        })
    }

    openCard() {
        this.setState(({ openCard }) => {
            return { openCard: !this.state.openCard }
        })
    }

    componentDidUpdate(prevProps) {
        // при выборе "выбрать все" закрываем открытое описание
        if (this.props.chooseCard !== prevProps.chooseCard && prevProps.chooseCard == false) {
            this.props._getallchooseCard(this.props.id, "addAll", this.props.page === "favorites" && this.props.type);
            this.setState(() => {
                return { chooseCard: true }
            })
        }
        else if (this.props.chooseCard !== prevProps.chooseCard && prevProps.chooseCard == true) {
            this.props._getallchooseCard(this.props.id, "removeAll", this.props.page === "favorites" && this.props.type);
            this.setState(() => {
                return { chooseCard: false }
            })
        }
    }

    // после клика по карте, при переходе на страницу проекта, поднимаемся в самый верх экрана
    _clickTop() {
        window.scrollTo(0,0);
    }

    render() {
        const { date_start, date_end, genres, token, page, type, isLoggedIn, id, slug, poster_en, poster_ru, title, category, profileProject, year_start, year_end, duration, location, description, channel, age_restriction, trailer_embed } = this.props;

        // Получение списка с жанрами
        let genresList = "";
        if (genres) {
            genresList = genres.map((item) => {
                return (" " + item.title);
            })
        }

        // проверка необходимости чекбоксов и связанных сними элементов верстки
        const checkbox = token && (page === "collections" || (page === "favorites" && type !== "events")) && isLoggedIn;

        // ссылка на проект, мероприятие или коллекцию в зависимости от значения type
        // если значение type === "collections", то используется id, в противном случае slug
        const link = `/${type}/` + (type === "collections" ? id : slug);

        return (
            <CardSt>
                <ContainerSt>
                    {isLoggedIn && (
                        <WrapperBookmarkSt chooseCard={checkbox}>
                            <BookmarkSt onClick={() => this._bookmark()} chooseCard={checkbox}>
                                <CenterSvgSt width="12" height="14" viewBox="0 -1 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    {this.state.bookmarkCheck ?
                                        (page === "favorites" ? <path d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z" fill="white" /> : <path d="M9.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V13.75L6 11.5L11.25 13.75V1.75C11.25 0.925 10.575 0.25 9.75 0.25Z" fill="white" />) :
                                        <path d="M9.75 0.25H2.25C1.425 0.25 0.75 0.925 0.75 1.75V13.75L6 11.5L11.25 13.75V1.75C11.25 0.925 10.575 0.25 9.75 0.25ZM9.75 11.5L6 9.865L2.25 11.5V1.75H9.75V11.5Z" fill="white" />}
                                </CenterSvgSt>
                            </BookmarkSt>
                            {/* ПРОЕКТЫ - чекбокс "выбрать" */}
                            {token && (page === "collections" || (page === "favorites" && type!=="events")) && isLoggedIn &&
                            <ContainerFormSt chooseCard={this.state.chooseCard}>
                                <СhoosePictureContainerSt>
                                    {!this.state.chooseCard && <CenterSvgSt width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.25 4.25V14.75H3.75V4.25H14.25ZM14.25 2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V4.25C15.75 3.425 15.075 2.75 14.25 2.75Z" fill="white"/>
                                    </CenterSvgSt>}
                                    {this.state.chooseCard && <CenterSvgSt width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.25 2.75H3.75C2.925 2.75 2.25 3.425 2.25 4.25V14.75C2.25 15.575 2.925 16.25 3.75 16.25H14.25C15.075 16.25 15.75 15.575 15.75 14.75V4.25C15.75 3.425 15.075 2.75 14.25 2.75ZM14.25 14.75H3.75V4.25H14.25V14.75ZM13.4925 7.25L12.435 6.185L7.4925 11.1275L5.5575 9.2L4.4925 10.2575L7.4925 13.25L13.4925 7.25Z" fill="white"/>
                                    </CenterSvgSt>}
                                </СhoosePictureContainerSt>
                                <FormСhooseSt>
                                    <input type="checkbox" onChange={() => this.chooseCard()} />
                                </FormСhooseSt>
                            </ContainerFormSt>}
                        </WrapperBookmarkSt>
                    )}
                    <ImgContainerSt openCard={this.state.openCard}>
                        <LinkSt to={link} onClick={() => this._clickTop()} >
                            <ImgSt src={(i18n.language === 'en') ? (poster_en === "" ? "../img-test/default-card-img.jpg" : poster_en) : (poster_ru === "" ? "../img-test/default-card-img.jpg" : poster_ru)} openCard={this.state.openCard}></ImgSt>
                        </LinkSt>
                    </ImgContainerSt>

                    {(this.state.chooseCard && type !== "events") && <ChooseCardTopSt></ChooseCardTopSt>}

                    <ContentSt>
                        <TitleSt><LinkSt to={link} onClick={() => this._clickTop()} >{title}</LinkSt></TitleSt>
                        {!profileProject && <ArrowSt onClick={() => this.openCard()} openCard={this.state.openCard}></ArrowSt>}
                        <HiddenContentSt>
                            <InfoSt>
                                {(category && this.state.openCard) && `${category.title}, ${genresList} | `}
                                {type === "catalog" && i18n.t(`filter.production_year`) + `: ${year_start}${!year_end ? " — ..." : year_end === year_start ? "" : (" — " + year_end)}`}
                                {(duration && this.state.openCard) && ` | ` + i18n.t(`filter.timing`) + `: ${duration}`}
                                {(location && this.state.openCard) && location}
                            </InfoSt>
                            <DiscriptionSt openCard={this.state.openCard} dangerouslySetInnerHTML={{__html:`${description}`}}></DiscriptionSt>
                        </HiddenContentSt>
                        <FooterSt>
                            <FooterLeftSt>
                                {channel && <DiscriptionChannelSVGSt width={channelList[channel.id] && channelList[channel.id].width} height={"22"}>
                                    <use xlinkHref={`images/logos-sprite.svg#${channelList[channel.id] && channelList[channel.id].img}`}></use>
                                </DiscriptionChannelSVGSt>}
                                {age_restriction && <DiscriptionRARSSt>{age_restriction}+</DiscriptionRARSSt>}
                                <DateSt>
                                    {dateFormation(date_start, date_end)}
                                </DateSt>
                            </FooterLeftSt>
                            {trailer_embed && <TrailerContainerSt target="_blank"  onClick={(evt) => this.handleClick(evt, trailer_embed)}>
                                <TrailerSt>
                                    {i18n.t(`filter.trailer`)}
                                </TrailerSt>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="12" stroke="#42A5F5" />
                                    <path d="M17.375 13.3125L11.125 17.375L11.125 9.25L17.375 13.3125Z" fill="#42A5F5" />
                                </svg>
                            </TrailerContainerSt>}
                            {(isLoggedIn && (page !== "profile" || profileProject)) && <ToFavoritesContainerSt onClick={() => this._bookmark()}>
                                <ToFavoritesSt>{this.state.bookmarkCheck ? i18n.t(`filter.remove_from_favorites`) : i18n.t(`filter.to_favorites`)}</ToFavoritesSt>
                            </ToFavoritesContainerSt>}
                        </FooterSt>
                    </ContentSt>
                </ContainerSt>
                { page !== "profile" && <LineSt></LineSt>}
                
                {/*попап с видео*/}
                {
                this.state.popupIsOpen
                    ? <Popup
                        handleClick={this.handleClick}>
                        <PopupFrameStyled src={this.state.trailer}/>
                    </Popup>
                    : null
                }
            </CardSt>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        token: store.auth.dataToken.token,
        isLoggedIn: store.auth.isLoggedIn
    };
}
const mapDispatchToProps = dispatch => {
    return {
        addToFavoritesAction: (id, type, token) => dispatch(addToFavorites(id, type, token)),
        deleteFromFavoritesAction: (id, type, token) => dispatch(deleteFromFavorites(id, type, token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList);