import {
    SWITCH_VIEW_IN_CATALOG
} from "../types";

// Переключения состояния правой колонки сайта
export const switchViewInCatalogAction = (boolen) => {
    return dispatch => {
        dispatch({
            type: SWITCH_VIEW_IN_CATALOG,
            boolen: boolen
        })
    }
}