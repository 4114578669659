import React from 'react';

import { Container } from "../../components/Container/Container";
import Collection from "../collection/Collection";

import { HOST } from "../../store/types";
import {Big} from "../../styles/typography/typography"

import {
    ContainerCollectionSt,
    HeaderSt,
    H3St,
    HeaderTopSt,
    TitleBlockSt
} from "./style";
import {TextSTyled} from "../404/style";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

class Collections extends React.Component {

    state = {
        collectionId: [],
        noCollection: false,
    };

    // Получаем получающий id коллекций
    onGetState() {
        fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/collections`)
            .then((res) => {
                return res.json();
            })
            .then((body) => {
                let newArray = [];
                body.data.map((item) => {
                    return (newArray.push(item.id))
                })
                this.setState(() => {
                    return { collectionId: newArray };
                });
            });
    }

    componentDidMount() {
        //Вызов функции получающий id коллекций
        this.onGetState();
        setTimeout(() => {
            this.setState(() => {
                return { noCollection: true };
            });
        }, 1000)
    }

    render() {
        return (
            <>
                <ContainerCollectionSt>
                    <Container  padding="true">
                        <HeaderSt>
                            <HeaderTopSt>
                                <TitleBlockSt>
                                    <H3St as="h1">{i18n.t(`collections.collections`)}</H3St>
                                </TitleBlockSt>
                            </HeaderTopSt>

                            {this.state.collectionId.length ? null :
                                (this.state.noCollection && <TextSTyled>
                                    <Big>
                                        {i18n.t(`collections.no_collection`)}
                                    </Big>
                                </TextSTyled>)
                            }
                        </HeaderSt>

                        </Container>
                        {this.state.collectionId.map((collection, i) =>(
                            <Collection numberCollection={collection} collectionComponents key={i}/>
                        ))}
                </ContainerCollectionSt >
            </>
        );
    }
}

export default withTranslation()(Collections);