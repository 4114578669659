import React from 'react';
import { H4 } from "../../styles/typography/typography.js";
import i18n from "i18next";

import {
  AdditionalWrapStyled,
  AdditionalContainerStyled,
  AdditionalDownloadStyled,
  AdditionalDownloadWrap,
} from '../Tabs-additional/style.js';


class Cadre extends React.Component {

  render() {
    const {extraImg} = this.props;

    return (
      <>
        {/*кадры*/}
        <H4 color="black_text">{i18n.t(`catalog.project.${0}.personnel`)}</H4>
        <AdditionalWrapStyled>
          <AdditionalContainerStyled>
            {extraImg && extraImg.map((cadre, i) =>
              <AdditionalDownloadWrap key={i}>
                <img src={cadre.media} width="160" height="90" />
                <AdditionalDownloadStyled href={cadre.download_url} />
              </AdditionalDownloadWrap>
            )}
          </AdditionalContainerStyled>
        </AdditionalWrapStyled>
      </>
    );
  }
}


export default Cadre;