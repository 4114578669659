import React from 'react';
import i18n from "i18next";
import { H4 } from "../../styles/typography/typography.js";

import {
  AdditionalDownloadStyled,
  VideoWrapStyled,
  VideoPreviewStlyed,
  VideoTextStlyed,
  AdditionalContainerStyled,
  AdditionalWrapStyled
} from '../Tabs-additional/style.js';

class Video extends React.Component {

  render() {
    const {extraVideos} = this.props;

    return (
      <>
        <H4 color="black_text">{i18n.t(`catalog.project.${0}.video`)}</H4>
        <AdditionalWrapStyled>
          <AdditionalContainerStyled>
            {extraVideos.map((item, i)=>
              <VideoWrapStyled key={i}>
                <VideoPreviewStlyed src={item.preview_ru} width="300" height="168"/>
                <VideoTextStlyed>
                  {item.title} | {item.duration_text}
                </VideoTextStlyed>
                <AdditionalDownloadStyled href={item.download_url} />
              </VideoWrapStyled>
            )}
          </AdditionalContainerStyled>
        </AdditionalWrapStyled>
      </>
      
    );
  }
}

export default Video;