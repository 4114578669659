import styled from "styled-components"
import { colors as c, fonts, device} from "../../styles/variables.js"
export const  SearchWrapSt  = styled.div`
    width: 100%; 
    height: 36px;
    position: relative;
   
    @media ${device.tablet} {
        width: ${props => props.size}; 
    }    
    /* flex-grow: 1fr; */
`;


export const SubmitInputSt  = styled.input` 
    visibility: hidden;
    position: absolute;
`;

export const CloseIconSt  = styled.div`
    cursor: pointer;  
    svg {
        z-index: 99;
        right: 10px;
        position: absolute;
        bottom: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        @media ${device.tablet} {
            display: flex;
            position: relative;
            top: initial;
            transform: none;
            right: 0;
            
        }
    }
    @media ${device.tablet} {
        padding-right: 10px;
        position: relative; 
        right: 0;
        z-index: 1;
        display: flex;
    }
`;

export const SearchIconSt  = styled.div` 
        z-index: 99;
        display: flex;
        cursor: pointer;
        padding-right: 10px;
        @media ${device.tablet} {
            z-index: 1;
        }
        svg {
            z-index: 99;
            position: absolute;
            bottom: 0;
            top: 50%;
            transform: translateY(-50%);
            @media ${device.tablet} {
                z-index: 1;
            }
        }
    /* @media ${device.tablet} { */
        svg {
            right: 10px;
        }
    /* } */
    &.search-header {
        padding-right: 0;
        @media ${device.tablet} {
            padding-right: 10px;
        }
        svg {
            right: initial;
            position: relative;
            top: initial;
            transform: initial;
        }
    }
`;

export const TuneIconSt  = styled.div` 
        z-index: 99;
        display: flex;
        cursor: pointer;
        padding-right: 30px;
        @media ${device.tablet} {
            z-index: 1;
        }
        svg {
            z-index: 99;
            position: absolute;
            bottom: 0;
            top: 50%;
            transform: translateY(-50%);            
                @media ${device.tablet} {
                    z-index: 1;
                }
           
        }
    /* @media ${device.tablet} { */
        svg {
            right: 50px;
        }
    /* } */
    &.search-header {
        padding-right: 0;
        @media ${device.tablet} {
            padding-right: 30px;
        }
        svg {
            right: initial;
            position: relative;
            top: initial;
            transform: initial;
        }
    }
`;

export const SearchInputSt  = styled.input` 
    outline: none;
    background: none;
    position: absolute;
    right: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    box-sizing: border-box;
    border: 1px solid ${c.grey_light};
    width: 38px;
    height: 36px;
    font-size: 0;
    cursor: pointer;
    font-family: ${fonts.font_family};
    opacity: 0;
    transition: width .5s ease , opacity .5s ease;
    &.opened {
        opacity: 1;
        width: calc(100vw - 35px);
        background-color: #fff;
        z-index: 98;
        border-radius: 40px;
        padding: 10px 30px;
        box-sizing: border-box;
        border-radius: 40px;
        font-size:14px;
        line-height: 16px;
        font-weight:400;
        color: ${c.black_text};
        cursor: initial;
        margin-right: -2px;
        @media ${device.tablet} {
            width: 100%;
            margin-right: 0;
            z-index: 0;
        }
        @media ${device.desktop} {
            width: 100%;
        }
        &:focus {
            border: 1px solid ${c.blue_active}; 
        }
        
    }
`;

