import React from "react";
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Container } from '../Container/Container.jsx'
import Logos from '../Logos/Logos'
import Svg from "../../../src/assets/icons/icons.js"
import Search from "../../components/Search/Search";
import ToggleLanguage from "../ToggleLanguage/ToggleLanguage";
import PopupNew from '../../components/PopupNew/PopupNew.jsx'
import LogIn from '../LogIn/LogIn'
import OutsideClickHandler from 'react-outside-click-handler';
import { logout } from '../../store/auth/actions'
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { colors as c } from "../../styles/variables.js"
import { menuList } from "../../styles/variables";
import { Small, Medium } from "../../styles/typography/typography"
import {
    ContainerHeaderSt,
    HeaderSt,
    HeaderSecondRowSt,
    LogoSt,
    MobileBlockSt,
    BookmarkIconSt,
    ArrowSt,
    ToggleSt,
    NavSt,
    WrapperSt,
    LinkSt,
    DownloadLinkSt,
    CountBookmarkSt,
    AdditionalWrapSt,
    SiteListSt,
    MiddleListSt,
    BookmarkListSt,
    FavoritesWrapSt,
    UserListSt,
    UserNameSt,
    ItemSt,
    HeaderWrapperSt,
    LogosWrapSt,
    SecondLevelSt,
    SecondLevelItemSt,
    AccountIconSt,
    WrapSecondRow,

} from "./style";
class Header extends React.Component {

    state = {
        menuIsOpen: false,
        menuProfileIsOpen: false,
        lang: "ru",
        switchLangIsOpen: false,
        searchBoxIsOpen: false,
        popupIsOpen: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.history.location.pathname !== this.state.history && this.props.history.location.pathname !== "/search") {
            this.setState(() => {
                return {
                    searchBoxIsOpen: false,
                }
            });
            this.setState(() => {
                return { history: this.props.history.location.pathname }
            })
        }
        else if (this.props.history.location.pathname !== this.state.history && this.props.history.location.pathname === "/search") {
            this.setState(() => {
                return { history: this.props.history.location.pathname }
            })
        }
    }

    logOut = (e) => {
        this.setState(() => {
            return {
                popupIsOpen: false,
            }
        });
        this.props.logoutActions()
    }

    // попап с login/registration
    handleOpenPopupClick = (e) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                popupIsOpen: !state.popupIsOpen,
                menuIsOpen: false,
            }
        });
    }

    // открыть меню по кнопке бургер (моб версия)
    handleOpenMenuClick = () => {
        this.setState((state) => {
            return {
                menuIsOpen: !state.menuIsOpen,
                menuProfileIsOpen: false,

            }
        });
    }

    // открыть меню профиля
    handleOpenProfileMenuClick = (e) => {
        e.preventDefault()
        this.setState((state) => {
            return {
                menuIsOpen: false,
                switchLangIsOpen: false,
                menuProfileIsOpen: !this.state.menuProfileIsOpen,
                searchBoxIsOpen: false,
            }
        });
    }

    // открыть/закрыть меню с переключением языка
    handleOpenSwitchLangClick = () => {
        this.setState((state) => {
            return {
                switchLangIsOpen: !state.switchLangIsOpen,
                menuProfileIsOpen: false,
                searchBoxIsOpen: false,
            }
        });
    }

    // открыть/закрыть поисковую строку
    handleOpenSearchBoxClick = () => {
        this.setState((state) => {
            return {
                searchBoxIsOpen: !state.searchBoxIsOpen,
                menuProfileIsOpen: false,
                menuIsOpen: false,
            }
        });
    }

    render() {
        const { currentUser, isLoggedIn, channelContent, history } = this.props;
        const favoritesCount = this.props.favoritesCount > 99 ? "99+" : this.props.favoritesCount

        return (
            <>
                <ContainerHeaderSt>
                    <Container>
                        <HeaderSt>
                            <HeaderWrapperSt>
                                {!(this.state.searchBoxIsOpen && (window.innerWidth < 768))
                                    && <LogoSt
                                        to="/"
                                        onClick={() => { this.setState({ menuIsOpen: false, menuProfileIsOpen: false }) }}>
                                        <img width={(window.innerWidth < 768) ? "100" : "120"}  src={i18n.t(`header.logo`)} alt="" />
                                    </LogoSt>
                                }
                                {(window.innerWidth < 768)
                                    && (
                                        <MobileBlockSt>
                                            {isLoggedIn
                                                ?
                                                <>
                                                    <AccountIconSt
                                                        className="account-icon"
                                                        onClick={(e) => this.handleOpenProfileMenuClick(e)}
                                                    >
                                                        <Svg type="account_circle" />
                                                    </AccountIconSt>

                                                    <BookmarkIconSt
                                                        to={"/favorites"}
                                                        onClick={() => { this.setState({ menuIsOpen: false, menuProfileIsOpen: false }) }} >
                                                        <Svg type="bookmark_border" />
                                                        {(isLoggedIn && favoritesCount)
                                                            ?
                                                            <CountBookmarkSt
                                                                className={this.state.searchBoxIsOpen ? "opacity" : ""}
                                                                style={{ right: '20px' }}>
                                                                <Small className="count" size="10px">{favoritesCount}</Small>
                                                            </CountBookmarkSt>
                                                            : null
                                                        }
                                                    </BookmarkIconSt>
                                                </>
                                                :
                                                <AccountIconSt
                                                    className="account-icon with-icon"
                                                    onClick={(e) => this.handleOpenPopupClick(e)}
                                                >
                                                    <NavLink to="/auth" >
                                                        <Svg type="login" />
                                                    </NavLink>
                                                </AccountIconSt>

                                            }
                                            <Search
                                                className="search-header"
                                                isWrap={false}
                                                searchBoxIsOpen={this.state.searchBoxIsOpen}
                                                handleOpenSearchBoxClick={this.handleOpenSearchBoxClick}
                                                _wholeSiteSearch={(value) => this.props._wholeSiteSearch(value)}
                                            />
                                        </MobileBlockSt>
                                    )
                                }

                                <NavSt
                                    className={window.innerWidth < 768 ? this.state.menuIsOpen ? "opened" : "closed" : ""}
                                    left={this.state.menuIsOpen}
                                >
                                    <ToggleSt
                                        onClick={(e) => this.handleOpenMenuClick(e)}
                                        className="toggle"
                                        aria-label="Открыть меню"
                                        type="button">
                                        <span className="visually-hidden">открыть меню</span>
                                    </ToggleSt>
                                    <WrapperSt className="wrapper">
                                        <AdditionalWrapSt>
                                            <SiteListSt className="site-list">
                                                {menuList.map((li, index) => (
                                                    <ItemSt
                                                        key={index}
                                                        className="list-item"
                                                        onClick={(e) => (window.innerWidth < 768) && this.handleOpenMenuClick(e)}
                                                    >
                                                        <LinkSt to={`${li.to}`} activeClassName="selected">
                                                            {i18n.t(`header.menuList.${index}.title`)}
                                                        </LinkSt>
                                                    </ItemSt>
                                                ))}
                                                <BookmarkListSt className="bookmark-list">
                                                    <ItemSt
                                                        className="list-item"
                                                        onClick={(e) => (window.innerWidth < 768) ? this.handleOpenMenuClick(e) : ""}>
                                                        <LinkSt
                                                            className="with-icon"
                                                            to={isLoggedIn ? "/favorites" : ((window.innerWidth < 768) ? "/auth" : "")}
                                                            onClick={(e) => (!isLoggedIn && (window.innerWidth > 768)) ? this.handleOpenPopupClick(e) : null}
                                                            activeClassName={isLoggedIn ? "selected" : ""} >
                                                            <Svg type="bookmark_border" />
                                                            <FavoritesWrapSt>
                                                                <Medium>{i18n.t(`header.favorites`)}</Medium>
                                                                {(isLoggedIn && favoritesCount)
                                                                    ?
                                                                    <CountBookmarkSt>
                                                                        <Small className="count not-hover" size="10px">{favoritesCount}</Small>
                                                                    </CountBookmarkSt>
                                                                    : null
                                                                }
                                                            </FavoritesWrapSt>
                                                        </LinkSt>
                                                    </ItemSt>
                                                </BookmarkListSt>
                                                {(window.innerWidth < 768)
                                                    ? ""
                                                    : <>
                                                        <Search
                                                            className="search-header"
                                                            isWrap={false}
                                                            searchBoxIsOpen={this.state.searchBoxIsOpen}
                                                            handleOpenSearchBoxClick={this.handleOpenSearchBoxClick}
                                                            _wholeSiteSearch={(value) => this.props._wholeSiteSearch(value)}
                                                        />
                                                    </>
                                                }
                                            </SiteListSt>
                                            <MiddleListSt className="middle-list">
                                                <ItemSt className="list-item" onClick={(window.innerWidth < 768) ? this.handleOpenMenuClick : null}>
                                                    {(i18n.language === "en") ?
                                                        <DownloadLinkSt className="with-icon" href="documents/catalogue_eng.pdf" download>
                                                            <Svg type="file_catalog" />
                                                            <Medium>{i18n.t(`header.download`)}</Medium>
                                                        </DownloadLinkSt>
                                                        :
                                                        <DownloadLinkSt className="with-icon" href="documents/catalogue_rus.pdf" download>
                                                            <Svg type="file_catalog" />
                                                            <Medium>{i18n.t(`header.download`)}</Medium>
                                                        </DownloadLinkSt>
                                                    }
                                                </ItemSt>
                                            </MiddleListSt>
                                        </AdditionalWrapSt>
                                        <UserListSt
                                            className={this.state.menuProfileIsOpen && window.innerWidth < 768 ? "user-list user-list-opened" : "user-list"}>
                                            <ItemSt className="list-item"
                                                onClick={(e) => this.handleOpenProfileMenuClick(e)}
                                            >
                                                {((window.innerWidth > 768) && (window.innerHeight > 801)) && (isLoggedIn
                                                    ? <LinkSt
                                                        className="with-icon user-list-account"
                                                        to="/"
                                                        onClick={(e) => this.handleOpenProfileMenuClick(e)}
                                                    >
                                                        <Svg type="account_circle" />
                                                        <UserNameSt>{currentUser.full_name}</UserNameSt>
                                                        {window.innerWidth > 1260
                                                            && <ArrowSt className={this.state.menuProfileIsOpen ? "opened" : ""}> </ArrowSt>
                                                        }
                                                    </LinkSt>
                                                    :
                                                    <LinkSt
                                                        onClick={(e) => this.handleOpenPopupClick(e)}
                                                        className="with-icon"
                                                        to="/auth">
                                                        <Svg type="login" />
                                                        <Medium>{i18n.t(`header.logIn`)}</Medium>
                                                    </LinkSt>
                                                )}

                                                {(((window.innerHeight <= 800) && (window.innerWidth > 768)) || ((window.innerHeight === 1024) && (window.innerWidth === 768))) && (!isLoggedIn
                                                    ?
                                                    <LinkSt className="with-icon" to="/auth" >
                                                        <Svg type="login" />
                                                        <Medium>{i18n.t(`header.logIn`)}</Medium>
                                                    </LinkSt>
                                                    :
                                                    <LinkSt
                                                        className="with-icon user-list-account"
                                                        to="/"
                                                        onClick={(e) => this.handleOpenProfileMenuClick(e)}
                                                    >
                                                        <Svg type="account_circle" />
                                                        <UserNameSt>{currentUser.full_name}</UserNameSt>
                                                        {window.innerWidth > 1260
                                                            && <ArrowSt className={this.state.menuProfileIsOpen ? "opened" : ""}> </ArrowSt>
                                                        }
                                                    </LinkSt>
                                                )}

                                                {(this.state.menuProfileIsOpen && isLoggedIn)
                                                    && (
                                                        <OutsideClickHandler
                                                            onOutsideClick={() => { this.setState({ menuProfileIsOpen: false }) }}
                                                        >
                                                            <SecondLevelSt className="profile-menu">
                                                                {window.innerWidth < 1260
                                                                    && <SecondLevelItemSt className="profile-name"><Svg type="account_circle" /><Medium>{currentUser.full_name}</Medium></SecondLevelItemSt>
                                                                }
                                                                <SecondLevelItemSt
                                                                    onClick={(e) => this.handleOpenProfileMenuClick(e)}
                                                                >
                                                                    <LinkSt to="/profile"><Medium className="not-hover">{i18n.t(`header.profile`)}</Medium></LinkSt>
                                                                </SecondLevelItemSt>
                                                                <SecondLevelItemSt onClick={(e) => this.logOut(e)}>
                                                                    <Medium>{i18n.t(`header.logOut`)}</Medium>
                                                                </SecondLevelItemSt>
                                                            </SecondLevelSt>
                                                        </OutsideClickHandler>
                                                    )
                                                }
                                            </ItemSt>
                                        </UserListSt>
                                        <div style={{ position: 'absolute', paddingLeft: '0', right: '0' }}>
                                            <OutsideClickHandler onOutsideClick={() => { this.setState({ switchLangIsOpen: false }) }}>
                                                <ToggleLanguage
                                                    switchLangIsOpen={this.state.switchLangIsOpen}
                                                    handleOpenSwitchLangClick={this.handleOpenSwitchLangClick}
                                                />
                                            </OutsideClickHandler>
                                        </div>
                                    </WrapperSt>
                                </NavSt>
                            </HeaderWrapperSt>
                        </HeaderSt>
                    </Container>
                </ContainerHeaderSt>
                {
                    (this.state.popupIsOpen && !this.props.isLoggedIn && window.innerWidth > 768)
                    &&
                    <PopupNew handleOpenPopupClick={this.handleOpenPopupClick}>
                        <LogIn viewType="popup" />
                    </PopupNew>
                }
                {
                    (((window.innerWidth > 768) && (history.location.pathname === "/catalog" || history.location.pathname.indexOf("/channel") !== -1))
                        ||
                        ((window.innerWidth > 768) && history.location.pathname.match(/catalog/)))
                    &&
                    <WrapSecondRow>
                        <HeaderSecondRowSt className={isLoggedIn ? "login" : ""}>
                            <LogosWrapSt>
                                <Logos
                                    channelContent={channelContent}
                                    productions={true}
                                    container="header"
                                    color={c.white}
                                />
                            </LogosWrapSt>
                        </HeaderSecondRowSt>
                    </WrapSecondRow>
                }
            </>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        isLoggedIn: store.auth.isLoggedIn,
        currentUser: store.auth.user,
        favoritesCount: store.favorites.count
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutActions: () => dispatch(logout())
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header)));
