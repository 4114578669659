import {
    FEEDBACK_SUCCESS,
    FEEDBACK_FAIL,
    CLEAR_MESSAGES
} from "../types"

const initialState = {
    message: '',
    successRequest: false,
}

export const feedbackReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case FEEDBACK_SUCCESS:
            return {
                ...state,
                message: payload.message,
                successRequest: true
            };
        case FEEDBACK_FAIL:
            return {
                ...state,
                successRequest: false,
                message: payload.message,
            };
        case CLEAR_MESSAGES:
            return {
                ...state,
                message: '',
                successRequest: false,
            };
        default:
            return state;
    }
}