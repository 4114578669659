// преобразование данных для мультиселекта
 export const adaptTypeRightData = (item) => {
    return Object.assign({}, item, {
        id: item.id,
        name: item.name,
    });
};

// преобразование данных для мультиселекта
export const adaptData = (item) => {
    return Object.assign({}, item, {
        id: item.id,
        name: item.title,
    });
};


export const sortingData = (data) => {
    if (data === undefined) {
        return []
    } else
        return data.filter(item => item.checked === true)
            .map(item => item.id)
}