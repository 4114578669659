import React from 'react';
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";

import Carousel from "../Carousel/Carousel";
import Grid from "../Grid/Grid"
import TopBannerNew from "../TopBannerNew/TopBannerNew";
import {Loading} from "../Loading/Loading";

import {Big} from "../../styles/typography/typography";
import { HOST } from "../../store/types";

import i18n from "i18next";

import styled from "styled-components";

const ContainerLoadingSt = styled.div`
  ${props=>props.preloader ? "opacity: 0; height: 0; overflow: hidden;" : "opacity: 1; height: auto; overflow: visible;"}
`

const TextSt = styled(Big)`
  display: block;
  margin: 50px 0;
  font-weight: 400;
`


class GetCards extends React.Component {

    state = {
        cards: [], // массив со всеми картами для карусели
        titleCard: "",
        descriptionCards: "",
        preloader: true,
        alsoRecommend: [],
    };

    // Получаем содержимое Card

    onGetState(list, token) {
        if (token) {
            var bearer = 'Bearer ' + token
        }
        fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/${list}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': bearer,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((body) => {

                if(body.message){ //404 редирект
                    this.setState({ redirect: true })
                }

                let newArray = [];
                let alsoRecommend = [];
                // обработка ответа подборок для мероприятий
                if (this.props.getSelections || this.props.page === "collections" || this.props.page === "collection") {
                    try{
                        Object.values(body.data.projects).map((item) => {
                            return (newArray.push(item))
                        })
                        this.setState(() => {
                            return {
                                titleCard: body.data.title,
                                descriptionCards: body.data.description,
                            };
                        });
                    }catch (e) {}
                }
                // обработка ответа контента канала
                else if (this.props.getChannelContent) {
                    if (body[0]) {
                        if(body[this.props.getChannelContent[0]]){
                            body[this.props.getChannelContent[0]].data.map((item, i) => {
                                return (newArray.push(item))
                            })
                            this.setState(() => {
                                return { titleCard: body[this.props.getChannelContent[0]].name };
                            });
                        }
                    }

                }
                // обработка избранного
                else if(this.props.page === "favorites" && this.props.type === "catalog") {
                    if(token){
                        body.project.map((item) => {
                            return (newArray.push(item))
                        })
                    }
                }
                else if(this.props.page === "favorites" && this.props.type === "events") {
                    if(token){
                        body.event.map((item, i) => {
                            return (newArray.push(item))
                        })
                    }
                }
                else if(this.props.page === "favorites" && this.props.type === "collections") {
                    if(token){
                        body.collection.map((item, i) => {
                            return (newArray.push(item))
                        })
                    }
                }
                //
                else if(this.props.sourceCards==="slides"){
                    if(this.props.contentType==='event' || this.props.contentType==='мероприятие'){
                        body.data.map((item) => {
                            if (item.type === this.props.contentType) {
                                return (newArray.push(item))
                            }
                        })
                    }
                    else{
                        body.data.map((item, i) => {
                            return (newArray.push(item))
                        })
                    }
                }
                // обработка обычного ответа
                else {
                    if(this.props.filter){
                        body.recommended_projects && body.recommended_projects.map((item) => {
                            return (alsoRecommend.push(item))
                        })
                    }
                    body.data.map((item, i) => {
                        if (this.props.contentType && item.type === this.props.contentType) {
                            return (newArray.push(item))
                        }
                        else if (!this.props.contentType) {
                            return (newArray.push(item))
                        }
                    })
                }
                this.setState(() => {
                    return {
                        cards: newArray,
                        alsoRecommend: alsoRecommend,
                    };
                });
            }).then(() => {
            if(this.props._countCards){
                this.props._countCards(this.state.cards.length);
            }
            if(this.props._getCards){
                this.props._getCards(this.state.cards.map((item)=>{
                    return {title: item.title, id: item.id};
                }));
            }
            setTimeout(() => {
                this._preloaderFinished();
            }, 300)
        });
    }

    componentDidMount() {
        // Вызов функции получающий контент карт
        this.onGetState(this.props.sourceCards, this.props.token);
    }

    _preloaderFinished(){
        this.setState(() => {
            return {
                preloader: false
            }
        })
    }

    render() {
        const { cardDisplayType, type, getChannelContent, title, tile, page, link, render, filter, past, chooseCard, showFilter, description, widthCard, _getallchooseCard, plug, isMobile, contentType } = this.props
        const { redirect, preloader, cards, titleCard, descriptionCards, alsoRecommend } = this.state;

        //редирект на 404
        if (redirect) { return <Redirect to='/404'/>; }

        return (
            <>
                {/* Тип контейнера "carousel" */}
                {(preloader && cardDisplayType === "carousel") && <Loading/>}
                {(cardDisplayType === "carousel" && cards.length > 0) &&
                <ContainerLoadingSt preloader={preloader}>
                    <Carousel
                        type={type}
                        title={getChannelContent ? titleCard : title}
                        description={descriptionCards}
                        cards={cards}
                        cardsLength={cards}
                        tile={tile}
                        page={page}
                        link={link}
                        render={render && render}
                    />
                </ContainerLoadingSt>
                }

                {/* Тип контейнера "grid" */}
                {(preloader && cardDisplayType === "grid") && <Loading/>}
                {(filter && !preloader && cards.length===0) &&
                <TextSt>{i18n.t(`filter.no_matches_for_your_request`)}.<br/>{i18n.t(`filter.try_other_settings`)} :)</TextSt>}
                {(cardDisplayType === "grid" && cards.length > 0) &&
                <>
                    <ContainerLoadingSt preloader={preloader}>
                        <Grid
                            type={type}
                            title={titleCard ? titleCard : title}
                            description={descriptionCards ? descriptionCards : description}
                            past={past}
                            cards={cards}
                            cardsLength={cards}
                            tile={tile}
                            chooseCard={chooseCard}
                            showFilter={showFilter}
                            filter={filter}
                            page={page}
                            link={link}
                            widthCard={widthCard}
                            _getallchooseCard={_getallchooseCard}
                        />
                    </ContainerLoadingSt>
                </>
                }

                {/* Тип контейнера "grid", рекомендации только для компонента фильтр */}
                {filter && <ContainerLoadingSt>
                    <Grid
                        type={type}
                        title={i18n.t(`filter.we_also_recommend`)}
                        description={descriptionCards ? descriptionCards : description}
                        past={past}
                        cards={alsoRecommend}
                        tile={tile}
                        chooseCard={chooseCard}
                        showFilter={showFilter}
                        filter={filter}
                        page={page}
                        link={link}
                        widthCard={widthCard}
                        _getallchooseCard={_getallchooseCard}
                    />
                </ContainerLoadingSt>}

                {/* Тип контейнера "topbanner" */}
                {cardDisplayType === "topbanner" &&
                    <TopBannerNew
                        banners={cards}
                        plug={plug}
                        isMobile={isMobile}
                        contentType={contentType}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        token: store.auth.dataToken.token,
    };
}

export default connect(mapStateToProps)(GetCards);