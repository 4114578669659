import React from "react";

import {
  WrapperSt,
  InputSt,
  ErrorWrapperSt,
  ErrorSt,
} from "./style";

const TextareaField = (
  {
    handleFieldChange,
    type = "text",
    primary = true,
    required,
    placeholder,
    minLength,
    maxLength = 1200,
    name,
    width,
    className,
    disabled = false,
    value,
    error,
    ...props }) => {
  return (
    <>
      <WrapperSt width={width}>
        <InputSt
          type={type}
          placeholder={placeholder}
          name={name}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          primary={primary}
          disabled={disabled}
          onChange={handleFieldChange}
          value={value}
          className={className}
          rows={13}
          cols={20}
        />
        <ErrorWrapperSt>
          <ErrorSt>{error}</ErrorSt>
        </ErrorWrapperSt>
      </WrapperSt>
    </>
  )

};

export default TextareaField;