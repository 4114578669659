import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import { device as d} from "../../styles/variables.js"
export const HeaderStyled =  styled.div`

    margin: 100px 0 0px;
    padding: 35px 0 35px 0;
    border-bottom: 1px solid ${c.grey_light};
    @media ${d.tablet} {
        margin: 0;
    }

   @media ${d.laptop} {
        margin: 40px 30px 0;
        max-width: 1140px;
    }
    @media (min-width: 1240px) {

        margin: 0 auto;
        max-width: 1140px;

    }
   @media ${d.desktop} {
        margin: 40px auto 0;
        padding: 75px 0 75px 0;
       
   }
`;