import styled from "styled-components";
import { colors as c } from "../../styles/variables.js"

export const ScrollTopSt = styled.div` 
    position: fixed;
    bottom: 30px;
    animation: fadeIn .3s ease-in-out 1s both;
    z-index:99;
    cursor: pointer;
    width: 100%;
`;

export const ScrollTopBtnSt = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px ;
    height: 56px;
    border-radius: 50px;
    background: ${c.blue_active};
    box-shadow: 0px 4px 5px rgba(66, 165, 245, 0.12), 0px 1px 10px rgba(66, 165, 245, 0.2);
    opacity: 0.8;
    transition: opacity .3s; 
    position: absolute;
    right: 0;
    bottom: 0;
    &:hover,
    &:active {
        opacity: 1;
    }
    svg {
        display: flex;
        fill : ${c.white};
        transform: scale(1.5) rotate(180deg);
    }
`;
