import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import TextareaField from '../TextareaField/TextareaField';
import { sendFeedback, clearMessages } from '../../store/feedback/actions';
import { validateEmail, emptyField } from "../../services/utils";
import i18n from "i18next";
import { H4 } from "../../styles/typography/typography"
import {
    InputWrapStyled,
    RowStyled,
    ErrorStyled,
    ErrorBlockStyled,
} from "../../components/LogIn/style";

import {
    FormSt,
    PopupLoginSt,
    ContentSt,
    TabContainerSt,
    ButtonWrapSt,
    InfoFormSt,
    TitleResetSt,

} from "./style";
import Approval from "../Approval/Approval";


const initialState = {
    isShowFeedbackBlock: true,
    isShowSuccessBlock: false,
    fullName: "",
    approval: false,
    approvalError: false,
    email: "",
    topic: "",
    text: "",
    formErrors: { fullName: "", email: "", topic: "", text: "" },
    loading: false,
};

class Feedback extends React.Component {

    state = initialState;

    componentDidMount() {
        this.props.clearMessagesAction();
    }

    componentDidUpdate(prevProps) {
        if (this.props.successRequest !== prevProps.successRequest) {
            if (this.props.successRequest) {
                this.setState({ isShowFeedbackBlock: false, isShowSuccessBlock: true })
            }
        }
    }

    handleFieldChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(
            { [name]: e.target.value },
            () => { this.validateField(name, value) }
        );
    }

    // валидация полей 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;

        switch (fieldName) {
            case 'email':
                emailValid = validateEmail(value)
                fieldValidationErrors.email = (value === "") ? i18n.t(`login.fill_in_the_field`) : emailValid ? "" : i18n.t(`login.invalid_email_format`);
                break;
            case 'fullName':
                fieldValidationErrors.fullName = emptyField(value)
                break;
            case 'topic':
                fieldValidationErrors.topic = emptyField(value)
                break;
            case 'text':
                fieldValidationErrors.text = emptyField(value)
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
        },
        );
    }

    // кнопка отправить форму
    handleFeedbackSubmit(e) {
        e.preventDefault();
        const isValid = this.validateFormFeedback();

        if (!this.state.approval) {
            this.state.approvalError = true;
        }

        if (isValid && this.state.approval) {
            this.setState({ formErrors: { fullName: "", email: "", topic: "", text: "" } });
            // отправка на сервер
            this.props.sendFeedbackAction(this.state.fullName, this.state.email, this.state.topic, this.state.text)
        }
        else {
            this.setState({
                loading: false,
            });
        }
    }

    // валидация всех полей формы перед отправкой 
    validateFormFeedback = () => {
        let [fullName, email, topic, text] = ["", "", "", ""];

        const isValidateEmail = validateEmail(this.state.email);
        const textError = i18n.t(`login.fill_in_the_field`); //Заполните поле
        
        fullName = (!this.state.fullName) ? textError : ""
     
        email =  (!this.state.email)? textError : ""

        if (this.state.email && !isValidateEmail) {
            email = i18n.t(`login.invalid_email_format`) //Неверный формат e-mail
        }
        topic =  (!this.state.topic) ? textError : ""
        text =  (!this.state.text) ? textError : ""
        
        if (fullName || email || topic || text) {
            this.setState(
                {
                    formErrors: {
                        fullName: fullName,
                        email: email,
                        topic: topic,
                        text: text,
                    }
                });
            return false
        }
        return true
    }

    // Согласие на обработку персональных данных
    approvalCheck () {
        this.setState(()=> {
            return {
                approval: !this.state.approval
            }
        })
        this.state.approvalError = false;
    }

    render() {
        const message = this.props.message;

        const classForInputFullName = this.state.fullName ? "valid" : this.state.formErrors.fullName ? "invalid" : "";
        const classForInputEmail = this.state.emailValid ? "valid" : this.state.formErrors.email ? "invalid" : "";
        const classForInputTopic = this.state.topic ? "valid" : this.state.formErrors.topic ? "invalid" : "";
        const classForInputText = this.state.text ? "valid" : this.state.formErrors.text ? "invalid" : "";

        // блок с информацией об успешной отправки формы
        const contentSuccess =
            <FormSt>
                <InfoFormSt>{i18n.t(`feedback.success`)}</InfoFormSt>
            </FormSt>

        // все инпуты для формы
        const allInputs =
            [
                {
                    class: classForInputFullName,
                    type: 'text',
                    name: 'fullName',
                    placeholder: i18n.t(`feedback.name`),
                    minLength: "4",
                    maxLength: "50",
                    error: this.state.formErrors.fullName
                },
                {
                    class: classForInputEmail,
                    type: 'email',
                    name: 'email',
                    placeholder: i18n.t(`feedback.email`),
                    minLength: "4",
                    maxLength: "50",
                    error: this.state.formErrors.email,
                    primary: true
                },
                {
                    class: classForInputTopic,
                    type: 'text',
                    name: 'topic',
                    placeholder: i18n.t(`feedback.topic`),
                    maxLength: "50",
                    error: this.state.formErrors.topic,
                    primary: true
                },
            ];

        const allInputsInsert = allInputs.map((item, i) => {
            return (
                <RowStyled key = {i}>
                    <TextField
                        className={item.class}
                        type={item.type}
                        name={item.name}
                        placeholder={item.placeholder}
                        minLength={item.minLength}
                        maxLength={item.maxLength}
                        error={item.error}
                        value={item.value}
                        handleFieldChange={(e) => this.handleFieldChange(e)}
                    />
                </RowStyled>
            )
        })

        return (
            <>
                <PopupLoginSt>
                    <TabContainerSt>
                        <ContentSt>
                            {!this.state.isShowSuccessBlock
                                ?
                                <FormSt
                                    onSubmit={(e) => this.handleFeedbackSubmit(e)}
                                    action="#" method="post"
                                >
                                    <TitleResetSt>
                                        <H4>{i18n.t(`feedback.title`)}</H4>
                                    </TitleResetSt>
                                    {allInputsInsert}
                                    <RowStyled>
                                        <InputWrapStyled>
                                            <TextareaField
                                                handleFieldChange={(e) => this.handleFieldChange(e)}
                                                type="text"
                                                name="text"
                                                maxLength="5000"
                                                placeholder={i18n.t(`feedback.message`)}
                                                error={this.state.formErrors.text}
                                                className={classForInputText}
                                                primary
                                            />
                                        </InputWrapStyled>
                                    </RowStyled>

                                    {/* подтверждение согласия на обработку персональных данных */}
                                    <Approval
                                        checked={this.state.approval}
                                        approvalError={this.state.approvalError}
                                        link={'feedback'}
                                        approvalCheck={()=>this.approvalCheck()}
                                    />

                                    <ButtonWrapSt>
                                        <Button as="a" href="/" label={i18n.t(`feedback.feedback_button`)} size="big" type="submit" width="100%" />
                                    </ButtonWrapSt>
                                    {/* Здесь сообщение об ошибке */}
                                    <ErrorBlockStyled style={{ width: '100%' }}>
                                        <ErrorStyled style={{ textAlign: 'center' }}> {message}</ErrorStyled>
                                    </ErrorBlockStyled>
                                </FormSt>
                                : contentSuccess
                            }
                        </ContentSt>
                    </TabContainerSt>
                </PopupLoginSt>
            </>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        message: store.feedback.message,
        successRequest: store.feedback.successRequest,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        sendFeedbackAction: (fullName, email, topic, text) => dispatch(sendFeedback(fullName, email, topic, text)),
        clearMessagesAction: () => dispatch(clearMessages())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Feedback));
