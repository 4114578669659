import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from "../../components/Container/Container";
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import { getRegisterInfo, resetPasswordSecondStep } from '../../store/auth/actions';
import i18n from "i18next";
import { validatePass } from "../../services/utils";
import Svg from "../../../src/assets/icons/icons"
import { H4 } from "../../styles/typography/typography"
import {
    InputWrapStyled,
    InputIconWrapStyled,
    RowStyled,
    ErrorStyled,
    ErrorBlockStyled,
} from "../../components/LogIn/style";

import { ContainerCatalogStyled } from "../../components/Container/style";

import {
    FormSt,
    PopupLoginSt,
    ContentSt,
    TabContainerSt,
    ButtonWrapStyled,
    InfoFormStyled,
    TitleResetSt,
} from "./style";


const initialState = {
    isShowPass: false,
    isShowConfirmPass: false,
    newPassword: "",
    confirmPassword: "",
    formErrors: { newPassword: "", confirmPassword: "", },
    loading: false
};

class PasswordReset extends React.Component {

    state = initialState;

    componentDidMount() {
        this.props.getRegisterInfoAction()
    }

    // ввод данных в инпуты
    handleFieldChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(
            { [name]: e.target.value },
            () => { this.validateField(name, value) }
        );
    }

    // валидация формы 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let newPasswordValid = this.state.newPasswordValid;
        let confirmPasswordValid = this.state.confirmPasswordValid;

        switch (fieldName) {
            case 'newPassword':
                newPasswordValid = validatePass(value);
                fieldValidationErrors.newPassword = (!value) ? i18n.t(`login.fill_in_the_field`) : newPasswordValid ? "" : i18n.t(`login.password_length_less_than_8_characters`);
                break;
            case 'confirmPassword':
                if (this.state.newPassword && this.state.newPassword === this.state.confirmPassword) {
                    if (!validatePass(value)) {
                        confirmPasswordValid = false
                        fieldValidationErrors.newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
                    }
                }
                else {
                    fieldValidationErrors.confirmPassword = i18n.t(`login.passwords_do_not_match`) //Пароли не совпадают
                    confirmPasswordValid = false
                }
                if (this.state.newPassword === this.state.confirmPassword) {
                    fieldValidationErrors.confirmPassword = ""
                    confirmPasswordValid = true
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            newPasswordValid: newPasswordValid,
            confirmPasswordValid: confirmPasswordValid,
        },
        );
    }

    // отправка данных
    handlePassRecoverySubmit(e) {
        const email = this.props.history.location.search.replace(/\?email=/, "").replace(/%40/, "@");
        const token = this.props.history.location.pathname.replace(/\/password\/reset\//, "");
        e.preventDefault();
        const isValid = this.validatePassRecovery();
        if (isValid) {
            this.setState({ formErrors: { newPassword: "", confirmPassword: "" } });
            this.props.resetPasswordSecondStepAction(token, email, this.state.newPassword, this.state.confirmPassword);
        }
        else {
            this.setState({
                loading: false,
            });
        }
    }

    // валидация формы по кнопке отправить
    validatePassRecovery = () => {
        const isValidatePass = validatePass(this.state.newPassword);
        let [newPassword, confirmPassword] = ["", "",];

        if (!this.state.newPassword) {
            newPassword = i18n.t(`login.fill_in_the_field`) //Заполните поле
        }
        if (this.state.newPassword && !isValidatePass) {
            newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
            //Пароль должен содержать не менее 8 символов и не менее одной цифры 
        }

        if (this.state.newPassword && this.state.newPassword === this.state.confirmPassword) {
            if (!isValidatePass) {
                newPassword = i18n.t(`login.password_length_less_than_8_characters`) //Длина пароля менее 8 символов
            }
        }
        else {
            confirmPassword = i18n.t(`login.passwords_do_not_match`) //Пароли не совпадают
        }

        if (newPassword || confirmPassword) {
            this.setState(
                {
                    formErrors: {
                        newPassword: newPassword,
                        confirmPassword: confirmPassword,
                    }
                });
            return false
        }

        return true

    }
    render() {
        const { message, errors } = this.props;
        const serverErrors = Object.values(errors)

        const classNameForInput = this.state.newPasswordValid
            ? "valid"
            : this.state.formErrors.newPassword
                ? "invalid" : ""

        const classNameForConfirmPassInput = this.state.confirmPasswordValid
            ? "valid"
            : this.state.formErrors.confirmPassword
                ? "invalid" : ""

        return (
            <ContainerCatalogStyled style={{ paddingTop: "100px" }}>
                <Container>
                    <PopupLoginSt>
                        <TabContainerSt>
                            <ContentSt>
                                <FormSt
                                    onSubmit={(e) => this.handlePassRecoverySubmit(e)}
                                    action="#" method="post"
                                >
                                    <TitleResetSt>
                                        <H4>{i18n.t(`password-recovery.title`)}</H4>
                                        <InfoFormStyled>{i18n.t(`password-recovery.second_title`)}</InfoFormStyled>
                                    </TitleResetSt>

                                    <RowStyled>
                                        <InputWrapStyled>
                                            <TextField
                                                handleFieldChange={(e) => this.handleFieldChange(e)}
                                                error={this.state.formErrors.newPassword}
                                                className={classNameForInput}
                                                type={this.state.isShowPass ? "text" : "password"}
                                                name="newPassword"
                                                placeholder={i18n.t(`password-recovery.new_password`)}
                                            />
                                            <InputIconWrapStyled onClick={() => this.setState({ isShowPass: !this.state.isShowPass })}>
                                                <Svg type={this.state.isShowPass ? "visibility" : "visibility_off"} />
                                            </InputIconWrapStyled>
                                        </InputWrapStyled>
                                    </RowStyled>

                                    <RowStyled>
                                        <InputWrapStyled>
                                            <TextField
                                                handleFieldChange={(e) => this.handleFieldChange(e)}
                                                error={this.state.formErrors.confirmPassword}
                                                className={classNameForConfirmPassInput}
                                                type={this.state.isShowConfirmPass ? "text" : "password"}
                                                name="confirmPassword"
                                                placeholder={i18n.t(`password-recovery.confirm_password`)}
                                            />
                                            <InputIconWrapStyled onClick={() => this.setState({ isShowConfirmPass: !this.state.isShowConfirmPass })}>
                                                <Svg type={this.state.isShowConfirmPass ? "visibility" : "visibility_off"} />
                                            </InputIconWrapStyled>
                                        </InputWrapStyled>
                                    </RowStyled>
                                    <ButtonWrapStyled>
                                        <Button as="a" href="/" label={i18n.t(`password-recovery.button`)} size="big" type="submit" width="100%" />
                                    </ButtonWrapStyled>
                                    {/* Здесь сообщение об ошибке */}
                                    <ErrorBlockStyled style={{ width: '100%' }}>
                                        <ErrorStyled> {message}</ErrorStyled>
                                        {serverErrors.map((item) => (
                                            <ErrorStyled> {item}</ErrorStyled>
                                        ))}
                                    </ErrorBlockStyled>
                                </FormSt>
                            </ContentSt>
                        </TabContainerSt>
                    </PopupLoginSt>
                </Container>
            </ContainerCatalogStyled>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        message: store.auth.message,
        errors: store.auth.errors,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetPasswordSecondStepAction: (token, email, newPassword, confirmPassword) => dispatch(resetPasswordSecondStep(token, email, newPassword, confirmPassword)),
        getRegisterInfoAction: () => dispatch(getRegisterInfo()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));
