import styled from "styled-components"
import { colors, fonts, device} from "../../styles/variables.js"
import { IconFavoritesSt, IconWrapFavoritesSt} from "../Tabs-left/style.js"
import { H1 } from "../../styles/typography/typography.js";

export const WrapStyled = styled.div`
  margin-top: 30px;
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-top: 30px;
    border-top: 1px solid ${colors.grey_light};
    padding-top: 30px;
  }
`;

export const LineStyled = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${fonts.font_family};
  padding-bottom: 25px;
  align-items: baseline;
  &.profile {
    @media ${device.mobile} {
      justify-content: space-between;
    }
  }
`;

export const LineLeftStyled = styled.div`
  width: 50%;
  max-width: 220px;
  &.profile{
    max-width: 260px;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 40%;
      font-size: 12px;
    }
    span {
      display: block;
      max-width: 200px;
      /*от 0 до 767 px*/
      @media ${device.mobile} {
        font-size: 12px;
        max-width: 100px;
        padding-right: 0;
      }
    }
  }
`;

export const LineRighttyled = styled.div`
  width: 50%;
  position: relative;
  max-width: 350px;
  a {
    color: ${colors.blue_active};
  }
  &.profile{
    width: auto;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 60%;
      max-width: none;

    }
  }
`;

export const TooltipWrapStyled = styled.div`
  position: absolute;
  top: 20px;
  left: -40px;
  min-width: 220px;
  background-color: #fff;
  z-index: 1;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    left: -90px;
  }
`;
export const TextWrapStyled = styled.div`
  border-top: 1px solid ${colors.grey_light};
  padding-top: 30px;
  margin-top: 5px;
  margin-bottom: 50px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    padding-top: 30px;
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
`;

export const TextContentStyled = styled.div`
  font-size: 16px;
  font-family: ${fonts.font_family};
  white-space: pre-line;
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const MoreContentStyled =  styled.div`
  font-size: 12px;
  font-family: ${fonts.font_family};
  color: ${colors.blue_active};
  cursor: pointer;
  margin-top: 20px;
  font-weight: 700;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const SmallDescription =  styled.div`
  margin-top: 50px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-top: 10px;
  }
`;
export const WrapForMobilefavorites =  styled.div`
  display: none;

  @media ${device.mobile} {
    display: block;
    position: relative;
    ${IconWrapFavoritesSt} {
      top: calc(50% + 6px);
      transform: translateY(-50%);
      left: auto;
      right: 0;
    }
    ${H1} {
      padding-right: 40px;
    }
  }
`;

