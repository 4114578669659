import styled from "styled-components"
import { colors as c } from "../../styles/variables.js"
import {Small}  from "../../styles/typography/typography"

export const ContainerSt = styled.div`
    position: relative;
    top: -7px;
    background: ${c.blue_highlight};
    height: 32px;
    padding: 0 10px 0 10px;
    border-radius: 30px;
    margin: 5px 5px 0;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
        background: ${c.blue_active};
        svg path {
            fill: white;
        }
    }
`;

export const NameSt = styled(Small)`
   margin-right: 10px;
`;

export const ButtonWrapSt = styled.div`
    display: flex;
`;
