import React from 'react';
import { Container } from '../Container/Container'
import Button from '../Button/Button'
import Logos from '../Logos/Logos'
import PopupNew from '../PopupNew/PopupNew'
import Feedback from '../Feedback/Feedback'
import { Small } from "../../styles/typography/typography"
import { colors as c } from "../../styles/variables"
import {
    FooterSt,
    WrapperSt,
    WrapperLogosSt,
    WrapperBottomSt,
    LinkSt,
    WrapperTextSt,
    BrSt,
} from "./style";

import i18n from "i18next";
import { withTranslation } from "react-i18next";

class Footer extends React.Component {

    state = {
        popupIsOpen: false,
    };

    handleOpenPopupClick = (e) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                popupIsOpen: !state.popupIsOpen,
            }
        });
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const year = new Date().getFullYear();
        const channelContent = this.props.channelContent;
        let popupIsOpen = this.state.popupIsOpen;

        return (
            <>
                <FooterSt>
                    <Container padding="true">
                        <WrapperSt>
                            {(i18n.language === 'en') ?
                                <a href="documents/catalogue_eng.pdf" download >
                                    <Button as="a" href="/" label={i18n.t(`home.download_catalog`)} size="big" primary />
                                </a>
                                :
                                <a href="documents/catalogue_rus.pdf" download >
                                    <Button as="a" label={i18n.t(`footer.button`)} size="big" primary />
                                </a>
                            }
                            <WrapperLogosSt>
                                <Logos channelContent={channelContent} container="footer" size="medium" color={c.white} />
                            </WrapperLogosSt>
                        </WrapperSt>
                        <WrapperBottomSt>
                            <WrapperTextSt>
                                {(((window.innerHeight <= 800) && (window.innerWidth > 768)) ||
                                    ((window.innerHeight === 1024) && (window.innerWidth === 768)) ||
                                    window.innerWidth < 768)
                                    ?   
                                        <p onClick={() => this.scrollToTop()}>
                                            <LinkSt to="/feedback">{i18n.t(`footer.feedback`)}</LinkSt>
                                        </p>
                                    :   
                                        <p onClick={(e) => this.handleOpenPopupClick(e)}><Small> {i18n.t(`footer.feedback`)}</Small></p>
                                }
                                <p><Small>{(i18n.language === 'en') ?
                                    <a className="confidentiality" href={`documents/privacy_policy_en.pdf`} target="_blank">{i18n.t(`footer.confidentiality`)}</a>
                                    :
                                    <a className="confidentiality" href={`documents/privacy_policy_ru.pdf`} target="_blank">{i18n.t(`footer.confidentiality`)}</a>
                                }</Small></p>
                                <Small>Copyright {i18n.t(`footer.gpm`)} {year} <BrSt />©&nbsp;All Rights Reserved</Small>
                            </WrapperTextSt>
                        </WrapperBottomSt>
                    </Container>
                </FooterSt>
                {
                    (popupIsOpen && window.innerWidth > 768)
                    &&
                    <PopupNew handleOpenPopupClick={this.handleOpenPopupClick}>
                        <Feedback handleOpenPopupClick={this.handleOpenPopupClick} />
                    </PopupNew>
                }
            </>
        );
    }
}

export default withTranslation()(Footer);