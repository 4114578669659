import styled from "styled-components"
import { device } from "../../styles/variables.js"


export const  ContainerCatalogStyled  = styled.div`
    flex: 1 0 auto;
`;
export const ContainerSt = styled.div`
   width:100%;
   margin: 0 auto;
   padding:0 20px;
   @media ${device.tablet} {
       padding: ${props => props.padding === "true" ? "0 20px" : "0 20px"};  
   }
   @media ${device.laptop} {
        padding: ${props => props.padding === "true" ? "0 40px" : "0 20px"};  
   }
   @media (min-width: 1280px) {
        max-width: ${props => props.padding === "true" ? "1140px" : "1260px"};
        padding: ${props => props.padding === "true" ? "0" : "0 10px"}; 
        box-sizing: border-box;
   }

   @media (min-width: 1300px) {
        max-width: ${props => props.padding === "true" ? "1140px" : "1260px"};
        padding: ${props => props.padding === "true" ? "0" : "0"}; 
   }
   @media ${device.desktop} {
        max-width: ${props => props.padding === "true" ? "1140px" : "1260px"};
        padding: ${props => props.padding === "true" ? "0 0" : "0"}; 
   }
`;