import styled from "styled-components"
import {colors, fonts, device} from "../../styles/variables";
import {
  ChosenSt,
  ArrowSt,
  ListSt
} from '../Select/style.js';


export const FormSt = styled.form`

`;
export const WraperSt = styled.div`
  margin-top: 0;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-top: 50px;
  }
`;

export const EditSt = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.focus {
    border: 1px solid ${colors.blue_active};
    padding: 2px;
    border-radius: 0px;
    border-radius: 10px;
    display: flex;
  }
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  svg {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
  }
`;
export const ContainerAreaSt = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: ${fonts.font_family};
  font-weight: 500;
  position: relative;
  color: ${colors.black};
  display: inline;
  padding: 2px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    word-break: break-all;
    padding-right: 0;
  }
  &.header {
    font-size: 42px;
    line-height: 44px;
    font-weight: 800;

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      font-size: 20px;
      line-height: 22px;
      font-weight: 700;
      word-break: break-all;
      padding-right: 11px;
    }
  }
  &.header-small {
    font-size: 20px;
    line-height: 23px;
    font-weight: 800;

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      font-size: 14px;
      line-height: 16px;
      font-weight: 700; 
      word-break: break-all;
    }
  }
  &.visible {
    position: relative;
    color: ${colors.black};
    top: 5px;
  }
  &.focus {
    flex-grow: 1;
  }
  span.password {
    -webkit-text-security: disc;
  }
`;
export const AreaSt = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 14px;
  font-family: ${fonts.font_family};
  font-weight: 500;
  margin: 0;
  border: 0;
  background: transparent;
  overflow: auto;
  top: 0;
  left: 0;
  color: ${colors.black};
  resize: none;
  padding: 2px;
  padding-bottom: 5px;
  ::placeholder {
    color: ${colors.grey_passive}
  }
  :focus {
    margin: 0;
    outline: 0;
    padding: 2px;
    border-radius: 10px;
  }

  &.focus {
    border: 1px solid ${colors.blue_active};
    padding: 2px;
    border-radius: 0px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar{
    width: 0;
  }

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    font-size: 12px;
    line-height: 12px;
  }
  
  &.header {
    font-size: 42px;
    line-height: 44px;
    font-weight: 800;

    /*от 0 до 767 px*/
    @media ${device.mobile} {
      font-size: 19px;
      line-height: 22px;
      font-weight: 700;
      word-break: break-all;
      padding-right: 11px;
    }
  }
  &.header-small {
    font-size: 20px;
    line-height: 23px;
    font-weight: 800;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      font-size: 13px;
      line-height: 16px;
      font-weight: 700; 
      word-break: break-all;
    }
  }
`;

export const WrapContentSt = styled.div`
  margin-top:30px;
  margin-bottom: 100px;
  border-bottom: 1px solid ${colors.grey_light};
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

export const VerifedSt = styled.div`
display: flex;
  margin-bottom: 10px;
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    margin-bottom: 20px;
  }
`;

export const VerifedTextSt = styled.span`
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: unsets;
  margin-left: 5px;
  display: inline-block;
  &.line {
    border-bottom: 1px dashed ${colors.black_text}  
  }
`;

export const WrapTooltip = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonSaveSt = styled.button`
  cursor: pointer;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
`;

export const WrapSelectSt = styled.div`
  margin-left: -25px;
  ${ChosenSt} {
    border: 0;
    padding: 0 25px 0 25px ;
  }
  ${ArrowSt} {
    right: 0;
  }
  ${ListSt} {
    min-width: 280px;
  }
  @media ${device.mobile} {
    min-width: 200px;
  }
`;

export const WrapTopSt = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const WrapHeaderSt = styled.div`
  width: 79%;
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const WrapLogoSt = styled.div`
  width: 19%;
  text-align: right;
  img {
    width: auto;
    max-width: 100%;
  }
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
`;

export const LineSt = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${fonts.font_family};
  padding-bottom: 25px;
  align-items: baseline;
  &.profile {
    @media ${device.mobile} {
      justify-content: space-between;
    }
  }
`;

export const LineLeftSt = styled.div`
  width: 50%;
  max-width: 220px;
  &.profile{
    max-width: 260px;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 40%;
      font-size: 12px;
    }
    span {
      display: block;
      max-width: 200px;
      /*от 0 до 767 px*/
      @media ${device.mobile} {
        font-size: 12px;
        max-width: 100px;
        padding-right: 0;
      }
    }
  }
`;

export const LineRightSt = styled.div`
  width: 50%;
  position: relative;
  max-width: 350px;
  a {
    color: ${colors.blue_active};
  }
  &.profile{
    width: auto;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      width: 60%;
      max-width: none;

    }
  }
`;

export const TooltipWrapSt = styled.div`
  position: absolute;
  top: 30px;
  left: 103%;
  min-width: 220px;
  z-index: 1;

  @media ${device.mobile} {
    top: 35px;
    left: 60%;
  }
`;

export const SpanSt = styled.div`
  background-color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 15px 25px;
`;

