import styled from "styled-components";
import {colors as c} from "../../styles/variables.js"
import {Medium} from  "../../styles/typography/typography";
import { device } from "../../styles/variables.js"

export const SelectSt = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const СhosenSt = styled.div`
    padding: 9px 50px 9px 25px;
    border-radius: 50px;
    border: 1px solid ${props=>props.open ? c.blue_active :
        props=>props.action === "edit" ?  "none" : c.grey_light};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &.invalid,
    &:invalid {
        border: 1px solid ${c.error} !important;;
    }
    
    &.valid {
        border: 1px solid ${ props=>props.action === "edit" ?  "none" : c.black_text};
    }

    @media (min-width: 1460px) {
        width: ${props => props.width}; 
        padding:  ${props => props.primary ? "19px 50px 19px  30px" : "10px 30px 10px 20px;" };
    }
    
`;

export const MediumSt = styled(Medium)`
    display: block;
    flex-grow: 30;
    overflow: hidden;
    color: ${c.black_text};
    @media ${device.tablet} {
        font-size: 14px;
        line-height: 16px;
    }

`;

export const ArrowSt = styled.div`
    position: absolute;
    right: 20px;
    svg {
            display: flex;
            align-items:center;
        }
`;

export const ListSt = styled.div`
        position: absolute;
        z-index: 99;
        /* width: 100%; */
        min-width:  ${props => props.selectWidth ? "initial" : "100%" };
        width: ${props => props.selectWidth ? "100%" : "initial" };
        display: ${props=>props.open ? "block" : "none"};
        background: ${c.white};
        box-shadow: 0 1px 3px ${c.blue_active};
        padding: 30px 25px;
        @media ${device.laptop} {
            transform: ${props => (props.position === "top" &&  props.viewType === "popup") ? "translateY(-102%)" : "none"};
            top: ${props=>(props.position === "top"&&  props.viewType === "popup") ? "0" : "60px"}; 
        }
       
        &>ul{
                list-style-type: none;
                &>li{
                    white-space: nowrap;
                    cursor: pointer;
                    padding: 15px 27px;
                    &:hover{
                        background: ${c.blue_highlight};
                    }
                    &:first-child{
                        padding-top: 25px;
                    }
                    &:last-child{
                        padding-bottom: 25px;
                    }
                }
        }
       
`;

export const UlWrapSt = styled.div`
    overflow-y: scroll;
    padding: 0;
    max-height: 120px;
    @media (min-width: 1460px) {
        max-height: 240px;
    }
`;
export const UlSt = styled.ul`
        display:flex;
        flex-direction: column;
        justify-content: space-around;

        @media ${device.desktop} {
            max-width:1260px;
        }
        
`;

export const  SelectedLabelsSt = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${c.grey_light};
    overflow-y: scroll;
    padding: 0;
    max-height: 80px;
`;
export const  CheckboxContainerSt= styled.div`
    display: flex;
    align-items: center;

    label {
        cursor: pointer;
    }
`;
export const  ButtonWrapSt= styled.div`
    display:flex;
    justify-content: center;
    margin-top: 25px;
`;