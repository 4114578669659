import {
    HOST,
    FEEDBACK_SUCCESS,
    FEEDBACK_FAIL,
    CLEAR_MESSAGES
} from '../types'
import { checkStatus } from '../../services/utils';
import i18n from "i18next"

export const sendFeedback = (name, email, subject, text) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/feedback`, {
                method: "POST",
                headers: {
                    // 'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json' 
                },
                body: JSON.stringify({ email: email, name: name, subject: subject, text: text }),
            })
                .then(checkStatus)
                .then(
                    (data) => {
                        dispatch({
                            type: FEEDBACK_SUCCESS,
                            payload: data
                        })
                        return data
                    })
                .catch((err) => {
                    let errors;
                    if (err.errors && err.message) {
                        errors = { message: err.message, errors: err.errors }
                    } else if (err.message) {
                        errors = { message: err.message, errors: {} }
                    } else {
                        errors = {
                            message: (err.response &&
                                err.response.data &&
                                err.response.data.message) ||
                                err.message ||
                                err.toString(),
                            errors: {}
                        }
                    }
                    dispatch({
                        type: FEEDBACK_FAIL,
                        payload: errors
                    })

                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FEEDBACK_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const clearMessages = () => {
    return {
        type: CLEAR_MESSAGES
    }
}