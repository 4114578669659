import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './store/configureStore'
import Page from './layouts/Page.jsx';
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import ScrollToTopBtn from "./components/ScrollToTop/ScrollToTop";

import translation_en from "./locales/en/translation.json";
import translation_ru from "./locales/ru/translation.json";

const baseLang = window.location.hostname.includes('.com') ? 'en' : 'ru'

i18n
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    keySeparator: '.',
    fallbackLng: ["ru", "en"],
    lng: baseLang,
    returnObjectTrees: true,
    useSuspense: false,                           // language to use
    resources: {
      en: {
        translation: translation_en              // 'translation' is our custom namespace
      },
      ru: {
        translation: translation_ru
      },
    }
  });
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Page />
        <ScrollToTopBtn />
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);