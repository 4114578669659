import {CHANNEL_CONTENT, HOST, PRODUCTION_CONTENT, PAGE_CONTENT} from "../types";
import i18n from "i18next";

export const getChannelContent = (typeContent) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/${typeContent}`)
                .then((res)=>{
                    return res.json();
                })
                .then(
                    (data) => {
                        if(typeContent==="channels"){
                            dispatch({
                                type: CHANNEL_CONTENT,
                                payload: data
                            })
                        }
                       if(typeContent==="productions"){
                           dispatch({
                               type: PRODUCTION_CONTENT,
                               payload: data
                           })
                       }
                        return data
                })
        } catch (error){

        }
    }
}

export const getPageContent = (page) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/pages/${page}`)
                .then((res)=>{
                    return res.json();
                })
                .then(
                    (data) => {
                        dispatch({
                            type: PAGE_CONTENT,
                            payload: data
                        })
                        return data
                    })
        } catch (error){

        }
    }
}
