import {
    HOST,
    FAVORITES_INFO_REQUEST,
    FAVORITES_INFO_SUCCESS,
    FAVORITES_INFO_FAIL,
    FAVORITES_ADD_EVENT,
    FAVORITES_ADD_PROJECT,
    FAVORITES_ADD_COLLECTION,
    FAVORITES_DELETE_EVENT,
    FAVORITES_DELETE_PROJECT,
    FAVORITES_DELETE_COLLECTION,
    FAVORITES_INFO_ALL_ADDED
} from '../types'
import i18n from "i18next"
import { checkStatus } from '../../services/utils';

export const getFavotitesInfo = (token) => {
    return dispatch => {
        try {
            dispatch({
                type: FAVORITES_INFO_REQUEST,
            })
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/favourites`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(checkStatus)
                .then(data => {
                    dispatch({
                        type: FAVORITES_INFO_SUCCESS,
                        payload: data
                    })
                    return data
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    dispatch({
                        type: FAVORITES_INFO_FAIL,
                        payload: message
                    })
                })
        } catch (error) {

            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: FAVORITES_INFO_FAIL,
                payload: message
            })
            return Promise.reject();
        }
    }
}

export const requestFromManager = (ids, token) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/add-content-access`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "project_id": ids,
                })
            })
            .then(checkStatus)
            .then(data => {
                dispatch({
                    type: FAVORITES_INFO_FAIL,
                    payload: [data.added>0, data]
                })
            })
        }catch{}
    }
}

export const requestFromManagerbyContent = (ids, token) => {
    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/add-content-request`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "project_id": ids,
                })
            })
            .then(checkStatus)
            .then(data => {
                dispatch({
                    type: FAVORITES_INFO_FAIL,
                    payload: [data.added>0, data]
                })
            })
        }catch{}
    }
}

export const addToFavorites = (ids, type, token) => {
    if (type === 'catalog') {
        type = 'project'
    } else if (type === 'collections') {
        type = 'collection'
    } else if (type === 'events') {
        type = 'event'
    }

    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/favourites`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "entity_ids": ids,
                    "entity_type": type
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        throw new Error(data.message)
                    }
                    if(data.length<1){
                        dispatch({
                            type: FAVORITES_INFO_ALL_ADDED,
                            payload: "allProjectsAdded"
                        })
                        setTimeout(()=>{
                            dispatch({
                                type: FAVORITES_INFO_ALL_ADDED,
                                payload: ""
                            })
                        }, 2000)
                    }
                    if (type === 'event') {
                        dispatch({
                            type: FAVORITES_ADD_EVENT,
                            payload: data
                        })
                    } else if (type === 'project') {
                        dispatch({
                            type: FAVORITES_ADD_PROJECT,
                            payload: data
                        })
                    } else if (type === 'collection') {
                        dispatch({
                            type: FAVORITES_ADD_COLLECTION,
                            payload: data
                        })
                    }

                    return data
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    // dispatch({
                    //     type: FAVORITES_INFO_FAIL,
                    //     payload: message
                    // })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            // dispatch({
            //     type: FAVORITES_INFO_FAIL,
            //     payload: message
            // })
            return Promise.reject();
        }
    }
}

export const deleteFromFavorites = (ids, type, token) => {
    if (type === 'catalog') {
        type = 'project'
    } else if (type === 'collections') {
        type = 'collection'
    } else if (type === 'events') {
        type = 'event'
    }

    return dispatch => {
        try {
            return fetch(`${HOST}${(i18n.language === 'en') ? '.com' : '.ru'}/api/v1/favourites`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    "entity_ids": ids,
                    "entity_type": type
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        throw new Error(data.message)
                    }
                    if (type === 'event') {
                        dispatch({
                            type: FAVORITES_DELETE_EVENT,
                            payload: data
                        })
                    } else if (type === 'project') {
                        dispatch({
                            type: FAVORITES_DELETE_PROJECT,
                            payload: data
                        })
                    } else if (type === 'collection') {
                        dispatch({
                            type: FAVORITES_DELETE_COLLECTION,
                            payload: data
                        })
                    }

                    return data
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    // dispatch({
                    //     type: FAVORITES_INFO_FAIL,
                    //     payload: message
                    // })
                })
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            // dispatch({
            //     type: FAVORITES_INFO_FAIL,
            //     payload: message
            // })
            return Promise.reject();
        }
    }
}