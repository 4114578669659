import styled from "styled-components"
import {fonts, device, colors} from "../../styles/variables";

export const ErrorSt = styled.div`
  position: absolute;
  top: 80px;
  left: 5px;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  font-weight: ${fonts.regular};
  
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    top: 5px;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const ProjectImageSt = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  padding-right: 30px;
  padding-top: 76px;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
  &.profile {
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      margin-top: 40px;
    }
  }
`;

export const ProjectWrapEmailSt = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  position: relative;
    svg{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
`;

export const ImgTopSt = styled.img`
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: block;
    width: 104%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2%;
    right: 0;
    object-fit: cover;
  }
`;

export const WrapImgMobSt = styled.div`
  display: none;

  /*от 0 до 767 px*/
  @media ${device.mobile} {
    position: relative;
    display: block;
    margin-top: 60px;
    height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid ${colors.grey_light}
  }
`;

export const WrapImgSt = styled.div`
  position: relative;
  &.profile {
    ${ProjectWrapEmailSt} {
      position: absolute;
      left: 8px;
      bottom: 8px;
      height: 30px;
      width: 31px;
      padding: 0;
      margin: 0;
      background-color: rgba(49,57,78, .6);
      text-align: center;
      z-index: 5;
      svg {
        left: 2px;
      }
    }
  }
  /*от 0 до 767 px*/
  @media ${device.mobile} {
    display: none;
  }
  &.profile-mob {
    display: none;
    /*от 0 до 767 px*/
    @media ${device.mobile} {
      display: block !important;
    }
  }
`;

export const ProfileFotoInputSt = styled.input`
  position: absolute;
  z-index: 1;
  opacity: 0;
  height: 30px;
  width: 31px;
  left: 0;
  bottom: 0;
  font-size: 0;
  cursor: pointer;
`;