import React from 'react';
import { connect } from "react-redux";

import { updatePhoto } from '../../store/customer/actions';

import { colors } from "../../styles/variables.js"
import Svg from "../../assets/icons/icons";

import {
  ErrorSt,
  ProjectImageSt,
  ProjectWrapEmailSt,
  ImgTopSt,
  WrapImgMobSt,
  WrapImgSt,
  ProfileFotoInputSt
} from './style.js';

class AccountChangeFoto extends React.Component {

   state = {
      errors: false,
   };

  photoInput = React.createRef();

  focusTextInput() {
    const FILE_TYPES = ['jpg', 'jpeg', 'png'];
    const file = this.photoInput.current.files[0];
    const fileName = file && file.name.toLowerCase();
    const matches = file && FILE_TYPES.some(function (it) {
      return fileName.endsWith(it);
    });
    if (matches) {
      const reader = new FileReader();
      reader.onload = () => {
        let preview = document.querySelector('.profile-change');
        preview.src = reader.result;
      };
      reader.readAsDataURL(file);
      this.setState(() => ({errors: ""}));
      this.props.updatePhotoAction(file, this.props.token)
    } else {
      this.setState(() => ({errors: "некорректный формат файла"}));
    }
  }

  render() {

    const {imgPoster, type} = this.props

    return (
      <>
        <WrapImgSt className={type === "mobile" ? "profile profile-mob" : "profile"}>
          {type === "mobile" 
            ?<WrapImgMobSt className="profile">
              <ImgTopSt src={imgPoster} width="300" className="profile-change" />
            </WrapImgMobSt>
            :<ProjectImageSt src={imgPoster} width="300"  className="profile profile-change" />
          }
          <ProjectWrapEmailSt>
            <Svg width="24" height="24" type="add_a_photo" color={colors.white} />
            <ProfileFotoInputSt type="file" ref={this.photoInput} onChange={() => this.focusTextInput()} accept="image/jpeg,image/png,image/gif"/>
          </ProjectWrapEmailSt>
          <ErrorSt>{this.state.errors}</ErrorSt>
        </WrapImgSt>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.auth.dataToken.token,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updatePhotoAction: (file, token) => dispatch(updatePhoto(file, token))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountChangeFoto);
