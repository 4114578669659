import styled from "styled-components"

import { colors as c } from "../../styles/variables.js"
import { device as d } from "../../styles/variables.js"
import { H3 } from "../../styles/typography/typography"
import {Link} from "react-router-dom";


export const ContainerSt = styled.div`
   max-width: 1260px;
   margin: 0 -20px;
   padding: 30px 0 20px;
   position: relative;
   overflow: hidden;
   z-index: 8;
   @media ${d.tablet} {
        margin: 0 -20px;
   }
   @media ${d.laptop} {
        margin: 0 auto;
        padding: 30px 0 30px;
   }
`;

export const HeaderSt = styled(H3)`
   margin: 0 20px 30px;
   color: ${c.black_text};
   @media ${d.laptop} {
        margin: 0 30px 30px;
        max-width: 1140px;
   }

   @media (min-width: 1240px) {
        margin: 0 auto;
        max-width: 1140px;
   }
    @media (min-width: 1350px) {
        margin: 0 auto;
        max-width: 1140px;

    }
   @media ${d.desktop} {
        margin: 0 auto 30px;
        max-width: 1140px;
   }
`;

export const LinkSt = styled(Link)`
   padding-left: 5px;
   text-decoration: none;
`;

export const DescriptionContainerSt = styled.div`
   margin: 0 20px 30px;
   
   @media ${d.laptop} {
        max-width: 1140px;
        margin: 0 30px 30px;
   }
   @media ${d.desktop} {
        margin: 0 auto 30px;
   }
`;

export const ContentSt = styled.div`
    z-index: 1;
    margin: 0 20px;
    max-width: 1140px;
    position: relative;
    @media ${d.laptop} {
        margin: 0 30px;
        max-width: 1140px;
    }
    @media (min-width: 1240px) {
        margin: 0 auto;
        max-width: 1140px;
    }
    @media ${d.desktop} {
        margin: 0 auto;
        max-width: 1140px;
    }
`;

export const CardsContainerSt = styled.div`
    width: 100%;
    overflow-x: scroll; // Прокрутка свайпом - чтобы работало - убрать: overflow: hidden;
    overflow: visible;
    &::-webkit-scrollbar {
        width: 0;
    }
    @media ${d.laptop} {
        overflow: hidden;
    }
`;

export const CarouselSt = styled.div`
    width: ${props => props.widthEl * 51}%;
    margin-left: -${props => props.marginCount * 51}%;
    min-width: 104%;
    display: flex;
    transition: margin-left .5s;
    @media ${d.tablet} {
        width: ${props => props.widthEl * 25.25}%;
        margin-left: -${props => props.marginCount * 25.25}%;
        min-width: 101%;
    }
    @media ${d.laptop} {
        width: ${props => props.widthEl * 20.6}%;
        margin-left: -${props => props.marginCount * 20.6}%;
        min-width: 103%;
    }
`;


export const ButtonSt = styled.div`
    width: 13%;
    ${props => props.side}: -4%;
    opacity: .6;
    background: ${c.black};
    position: absolute;
    z-index: 98;
    bottom: 45%;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
    @media ${d.tablet} {
       width: 80px;
       ${props => props.side}: -20px;
    }
    @media ${d.laptop} {
       width: 80px;
       ${props => props.side}: -30px;
    }
     @media (min-width: 1240px) {
        ${props => props.side}: -30px;
    } 
    @media  (min-width: 1280px) and (max-width: 1300px)  {
       width: 80px;
       ${props => props.side}: -50px;
    }
    @media  (min-width: 1350px)  {
       width: 80px;
       ${props => props.side}: -60px;
    }
    @media (min-width: 1400px) {
       ${props => props.side}: -60px;
    } 
`;

export const ButtonStPadding = styled.div`
    padding-bottom: 100%;
`;

export const ArrowSt = styled.svg`
   width: 25%;
   position: absolute;
   margin: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
`;