import styled, { css } from 'styled-components'
import { fonts, colors } from "../variables.js"
import { device } from "../../styles/variables.js"
/* Main style for all H */
const FontHStyles = css`
    font-weight: ${fonts.extra_bold};
    text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};
    color: ${props => props.color ? props.color : fonts.black_text};
`;

/* Main style for another text */
const FontMainStyles = css`
    color: ${props => props.color ? props.color : fonts.black_text};
    font-weight: ${fonts.regular};
    text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};

    &.link {
        color: ${colors.blue_active};
    }
    a {
        color: ${colors.blue_active}; 
    }
`;
export const H1 = styled.h1`
    ${FontHStyles};
    font-weight: ${fonts.bold};
    font-size: ${props => props.size ? props.size : '30px'};
    line-height: 34px; 
    color: ${colors.black_text};
    @media (min-width: 1280px) {
        font-weight: ${fonts.extra_bold};
        font-size: ${props => props.size ? props.size : '42px'};
        line-height: 50px;
    }
    @media ${device.desktop} {
        font-weight: ${fonts.extra_bold};
        font-size: ${props => props.size ? props.size : '42px'};
        line-height: 50px;
    }
`;
export const H2 = styled.h2`
    ${FontHStyles};
    font-size: ${props => props.size ? props.size : '20px'};
    line-height: 24px;
    color: ${colors.black_text};
    @media (min-width: 1280px) {
        font-size: ${props => props.size ? props.size : '36px'};
        line-height: 42px;
    }
    @media ${device.desktop} {
        font-size: ${props => props.size ? props.size : '36px'};
        line-height: 42px;
    }
`;
export const H3 = styled.h3`
    ${FontHStyles};
    font-weight: ${fonts.bold};
   
    color: ${colors.black_text};
    font-size: ${props => props.size ? props.size : '20px'};
    line-height: 24px;
    @media (min-width: 1280px) {
        font-size: ${props => props.size ? props.size : '30px'};
        line-height: 34px;
        color: ${colors.black_text};
    }
    @media ${device.desktop} {
        font-size: ${props => props.size ? props.size : '30px'};
        line-height: 34px;
        color: ${colors.black_text};
        /* color: red; */
    }
`;

export const H4 = styled.h4`
    ${FontHStyles};
    font-weight: ${fonts.bold};
    font-size: ${props => props.size ? props.size : '20px'}; 
    line-height: 18px;
`;
export const P = styled.p`
    ${FontMainStyles};
    font-size: ${props => props.size ? props.size : '20px'};
    line-height: 25px;
`;
export const Big = styled.span`
    ${FontMainStyles};
    font-weight: ${fonts.medium};
    font-size: ${props => props.size ? props.size : '16px'};
    line-height: 16px;
    @media (min-width: 1280px) {
        font-size: ${props => props.size ? props.size : '16px'};
        line-height: 20px;
    }
    @media ${device.desktop} {
        font-size: ${props => props.size ? props.size : '16px'};
        line-height: 20px;
    }
`;
/* UI-Kit has this font in 3 variant: regular, extra_bold, extra_bold uppercase
By default here is regular, but you can set a different weight and uppercase through the prop 
*/
export const Medium = styled.span`
    ${FontMainStyles};
    /* bold || extra_bold || regular */
    font-weight: ${props => props.bold ? fonts.bold :
        props.extra_bold ? fonts.extra_bold : fonts.regular
    };
    font-size: ${props => props.size ? props.size : '14px'};
    line-height: 16px;
    color: ${colors.black_text};
    @media (min-width: 1280px) {
        font-size: ${props => props.size ? props.size : '14px'};
        line-height: 16px;
    }
    @media ${device.desktop} {
        font-size: ${props => props.size ? props.size : '14px'};
        line-height: 16px;
    }
`;
/* UI-Kit has this font in 3 variant: medium, bold uppercase, extra_bold 
By default here is medium, but you can set a different weight and uppercase through the prop 
*/
export const Small = styled.span`
    ${FontMainStyles};
     /* bold || extra_bold || medium */
    font-weight: ${props => props.bold ? fonts.bold :
        props.extra_bold ? fonts.extra_bold : fonts.medium
    };
    font-size: ${props => props.size ? props.size : '10px'};
    line-height: 12px;
    letter-spacing: 0.03em;
    @media (min-width: 1280px) {
        font-size: ${props => props.size ? props.size : '12px'};
        line-height: 14px;
    }

    @media ${device.desktop} {
        font-size: ${props => props.size ? props.size : '12px'};
        line-height: 14px;
    }
`;
export const Overline = styled.span`
    ${FontMainStyles};
    text-transform: uppercase;
    font-weight: ${props => props.weight ? props.weight : fonts.medium};
    font-size: ${props => props.size ? props.size : '10px'};
    line-height: 12px;
    letter-spacing: 0.04em;
    color: ${colors.grey_text}; /* ${fonts.night} */
`;




